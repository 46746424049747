import React from "react";

import { createUseStyles } from "react-jss";

import // motion
"framer-motion";

// import ThemeData from "data/ThemeData";

import {
  convertBedrooms,
  convertBathrooms,
  showTotalSpace,
  capitalizeOptions,
  getOrdinal,
} from "../util/helper";
import ConstantData from "data/ConstantData";
import { portraitMode } from "util/helper";

const useStyles = createUseStyles(
  {
    wrapper: {
      textAlign: "left",
      textTransform: "capitalize",
    },
    separator: (props) => ({
      color: props.ThemeData.colours.branding,
      fontWeight: 700,
      fontSize: 15,
      lineHeight: "10px",
    }),
  },
  {
    name: "UnitInfoLine",
  }
);

const UnitInfoLine = ({ project, data, className, ...props }) => {
  const classes = useStyles({
    ...props,
    ThemeData: project.ThemeData,
    isCompareMiniCard: props?.isCompareMiniCard,
  });

  const isIndustrial =
    project && project.projectType === ConstantData.projectType.industrial;
  const isFloorplan = data.__typename === "FloorPlan";
  const isPortraitMode = portraitMode();

  return (
    <div className={`${classes.wrapper} ${className}`}>
      {data && !data.info_line_override && (
        <>
          {isIndustrial ? (
            <>
              {capitalizeOptions(data.floorPlan.floorPlanType)}
              <>
                {" "}
                <span className={classes.separator}>&#x2022;</span>{" "}
                {showTotalSpace(data, isFloorplan)} {data.sizeUnit}
              </>
              {isIndustrial && !isFloorplan ? (
                <>
                  {" "}
                  <span className={classes.separator}>&#x2022;</span>{" "}
                  {`${getOrdinal(data.floor)} ${
                    data.floor >= 0 ? "Floor" : ""
                  }`}{" "}
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              {isPortraitMode ? (
                <>
                  {/* {props.doShowUnitNumber && data && data.unitNumber !== null && data.__typename === "Unit" ? `Unit ${data.unitNumber} | ` : ``}
								{`${data.floorPlan.name}`} */}
                  {data.floorPlan.bedRooms !== null
                    ? `${convertBedrooms(
                        data.floorPlan.bedRooms,
                        data.floorPlan.juniorBedRooms,
                        data.floorPlan.studio,
                        data.floorPlan.flex
                      )}`
                    : ""}
                  <>
                    {" "}
                    <span className={classes.separator}>&#x2022;</span>{" "}
                  </>
                  {props.doShowBaths &&
                    data &&
                    data.floorPlan.bathRooms &&
                    `${convertBathrooms(data.floorPlan.bathRooms)}`}
                  <>
                    {" "}
                    <span className={classes.separator}>&#x2022;</span>{" "}
                  </>
                  {props.doShowIntSqft &&
                    data &&
                    ` ${data.floorPlan.interiorSize} ${data.floorPlan.sizeUnit}`}
                  {props.doShowExtSqft && data && (
                    <>
                      <span className={classes.separator}>&#x2022;</span>
                      {` ${data.floorPlan.exteriorSize} ext. ${data.floorPlan.sizeUnit}`}
                    </>
                  )}
                </>
              ) : (
                <>
                  {/* {data.floorPlan && !props.donotShowPlanName ? `Plan ${data.floorPlan.name} | ` : ``} */}
                  {props.doShowFloor &&
                    data &&
                    data.floor > 0 &&
                    `Floor ${data.floor} | `}
                  {props.doShowUnitNumber &&
                  data &&
                  data.unitNumber !== null &&
                  data.__typename === "Unit"
                    ? `Unit ${data.unitNumber} | `
                    : ``}
                  {data.floorPlan.bedRooms !== null
                    ? `${convertBedrooms(
                        data.floorPlan.bedRooms,
                        data.floorPlan.juniorBedRooms,
                        data.floorPlan.studio,
                        data.floorPlan.flex
                      )}`
                    : ""}
                  {props.doShowBaths &&
                    data &&
                    data.floorPlan.bathRooms &&
                    ` | ${convertBathrooms(data.floorPlan.bathRooms)}`}
                  {data &&
                    ` | ${showTotalSpace(data, isFloorplan)} ${
                      data.floorPlan.sizeUnit
                    }`}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

UnitInfoLine.defaultProps = {
  doShowFloor: false,
  doShowUnitNumber: false,
  doShowHomeType: true,
  doShowBaths: true,
  doShowFloorPlan: false,
};

export default UnitInfoLine;
