const domain = window.location.host;
export const PoliciesCopyData = {
  termsOfService: {
    title: 'Terms of Service',
    introduction:
      <div>
        <p>Effective Date: March 14, 2022</p>

        <p>This Terms of Service (the “<b>TOS</b>”) is entered into between you and LNG Productions Inc., and
          its subsidiaries and affiliates, (collectively, “<b>Proxima</b>”) and applies to any mobile applications
          controlled by Proxima, all Proxima websites, including <b>{domain}</b> (the
          “<b>Website</b>”), and any other services or products on which Proxima chooses to apply this TOS
          (collectively, the “<b>Service</b>”).</p>

        <b><p>BY CLICKING “I AGREE” OR SIMILAR CONFIRMATION OR BY USING THE
          SERVICE YOU ARE AGREEING TO BE BOUND BY THE TOS.</p>

          <p>IF YOU ARE USING THE SERVICE ON BEHALF OF AN ORGANIZATION, YOU
            REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND THE ORGANIZATION
            TO THE TOS AND ARE AGREEING TO THE TOS FOR THAT ORGANIZATION.
            WHERE YOU ARE USING THE SERVICE ON BEHALF OF AN ORGANIZATION,
            “YOU” AND “YOUR” REFER TO THE ORGANIZATION.</p></b>
      </div>,
    paragraphs: [
      {
        subheading: '1.   DESCRIPTION OF THE SERVICE',
        description:
          <div>
            Proxima provide a product for real estate sellers (each, a “<b>Seller</b>”) to use to help create digital
            environments to aid real estate buyers (each, a “<b>Buyer</b>”) to view homes before construction even
            begins. Sellers can paint immersive 3D and 2D floor models and interactions and control the
            detail that they want to express around their properties. Buyers can explore the models and
            compare floor plans to one another, allowing details of their potential purchases to be
            consolidated and sent right to their inbox. Sales and marketing firms (each, a “<b>Marketer</b>”) may
            also use the Service to receive analytics data on what the Buyers are looking at and what pages
            or floorplans shown on the Service are high performing.
          </div>
      },
      {
        subheading: '2.   SUBSCRIPTIONS AND LICENSES',
        description:
          <div>
            <p><b>2.1 Subscription and License.</b> Proxima grants you a limited, revocable, non-exclusive, non-
              sublicensable, non-transferrable, subscription to access the Service and to use features associated
              with an Account. If you download our mobile application, Proxima grants you a limited,
              revocable, non-exclusive, non-sublicensable, non-transferrable, license to access the Service
              through such mobile application and to use features associated with an Account. You
              acknowledge and agree that we may modify, suspend or remove features of any of our mobile
              applications, the Website, your Account or any part the Service at any time acting in our sole and
              absolute discretion.</p>

            <p><b>2.2 User Content License.</b> User content includes any information that you create, submit,
              transmit or upload while using the Service, for example, any submissions, comments, questions,
              testimonials, tutorials, advice, floorplans, models, images, videos, designs, suggestions, ideas or
              other information you directly or indirectly provide to Proxima through any method existing now
              or in the future (altogether, “User Content”). By providing User Content you represent and
              warrant that you have all consents, licenses and rights necessary to provide and license the User
              Content and you grant Proxima a non-exclusive, irrevocable, fully-paid, royalty-free, perpetual,
              sub-licensable, transferrable, worldwide license to User Content under all intellectual property
              and other rights you own or control to use, broadcast, disclose, display, distribute, modify, make
              derivative works of, publicly perform, publish, record, reproduce, sublicense (on multiple
              levels), translate, transmit or otherwise exploit for all purposes and in all formats and mediums
              and with any technology now known or hereafter developed and for all purposes without
              attribution, notice, permission or payment to you or a third party. Proxima reserves the right to
              review, edit, delete or block access to User Content without notice.</p>
          </div>
      },
      {
        subheading: '3.   ACCESS, ACCOUNTS AND FEES',
        description:
          <div>
            <p><b>3.1 Age of Access.</b> You must be at least the age of majority in your jurisdiction (whichever
              is older) in order to use the Service and you represent and warrant that you have the right and
              authority to enter into and comply with this TOS.</p>
            <p><b>3.2 Account.</b> If you are a Seller or a Marketer, the Service will require you to register a user
              account with Proxima (each, an “Account”). Buyers may also be required to create an Account
              to access certain features of the Service, such as to save floorplan layouts. Proxima strongly
              recommends that you keep any Account login information confidential. You agree to be bound
              by any use of your Account, including charges and purchases, whether or not authorized. You
              represent and warrant that you are permitted to use the method of payment associated with your
              Account and agree to pay for charges and fees incurred by your Account, which are non-
              refundable.</p>
            <p><b>3.3 Access.</b> Access to the Service may require hardware including but not limited to a
              compatible computer, a mobile device and an Internet connection, all meeting certain minimum
              specifications set by Proxima (subject to change as Proxima makes changes to the Service)
              described on the Website, as updated on one or more occasions. </p>
            <p><b>3.4 Personal Data.</b> Accessing the Service may require you to provide Proxima with
              personal data, which is collected, used and disclosed in accordance with Proxima’s Privacy
              Policy, if any, and in compliance with legal standards applicable in your jurisdiction. You agree
              to provide accurate and complete personal data to Proxima and to update your data if it changes.
              The Privacy Policy is available at <b style={{ cursor: 'pointer' }}><a href="/">Privacy Policy</a></b>.</p>
            <p><b>3.5 Fees for Sellers and Marketers.</b> As the Service is still being developed, the payment
              features of the Service are also in development and being tested. As such, Proxima may charge
              each Seller or Marketer (each, a “Business”) on a per ‘seat’ system, charging a fee for each
              potential Buyer, or each ‘seat’, that has accessed the Service. In addition, Proxima may charge
              each Business on a per transaction basis, with payments made through the Service. Proxima may
              charge each Business in connection with each Account on different terms (terms of which shall
              be previously disclosed prior to the introduction of any new fee terms) along with the already
              above-described fees (each, a “Fee”). In any case, if you are a Business, you shall provide
              Proxima with and execute any necessary preauthorized debit forms, preauthorized bill payment
              forms or electronic payment information to pay the Fee (“Authorized Payment”). Proxima, or
              any third party acting on Proxima’s behalf, is authorized and has the right to automatically
              charge the Fee to such Authorized Payment anytime a Fee is due, unless you terminate the
              Service as described in this TOS. The current Fee structure is listed on the Website at <b>{domain}</b>,
              which may change at any time at Proxima’s sole discretion, but Proxima shall provide
              prior written notice of any Fee increase. The Fee is earned upon payment and is non-refundable.</p>
            <p><b>3.6 Taxes.</b> The Fee excludes applicable taxes, which Proxima may charge as required by the
              laws of your jurisdiction.</p>
          </div>
      },
      {
        subheading: '4.   LIMITATIONS ON USE OF THE SERVICE',
        description:
          <div>
            <p><b>4.1 Limitations.</b> You agree that you will not use the Service in the following manner:</p>
            <p> a. engage in conduct that Proxima determines, in its sole discretion, to constitute
              improper use of the Service;</p>
            <p> b. decompile, disassemble or reverse engineer the Service or otherwise attempt to
              derive the Service source code; </p>
            <p> c. except if expressly permitted by Proxima, reproduce, rearrange, modify, translate,
              create derivative works from, display perform, publish or distribute the Service;</p>
            <p> d. post any unauthorized commercial communications on the Service, including
              multi-level marketing or pyramid schemes;</p>
            <p> e. sell, lease or remove/alter advertising for the Service placed by Proxima; </p>
            <p> f. break, attempt or otherwise assist with the disruption of any computer used to
              support the Service or experience of another user;</p>
            <p> g. upload files that contain viruses, Trojan horses, worms, time bombs, corrupted
              files or data or other similar services or programs that may damage operation of the Service or
              the computers of other users;</p>
            <p> h. upload, promote or link any content or images, video or other content associated
              with illegal activity;</p>
            <p> i. engage in abusive, defamatory, libellous, threatening or any other conduct that is
              objectionable or offensive;</p>
            <p> j. abuse, bully, harass, harm or publish false or misleading information about users
              or encourage others to do the same;</p>
            <p> k. use hate speech, hate terms, racist speech, racist terms or any other words or
              symbols that signify hate towards any person or group of people, including derogatory comments
              against women or minorities;</p>
            <p> l. infringe or violate the rights of a third party including but not limited to: (i)
              contractual rights; (ii) copyright, patent, trademark or trade secret rights; (iii) privacy rights; (iv)
              publicity rights; or (v) confidential information; or</p>
            <p> m. impersonate another person or an employee of Proxima,</p>

            <p>as determined by Proxima in its sole discretion. The above limitations also apply to information
              posted on public forums or through communication tools related to Proxima or controlled by
              Proxima including, but not limited to, the Proxima Instagram page.</p>
          </div>
      },
      {
        subheading: '5.   TERM AND TERMINATION',
        description:
          <div>
            <p><b>5.1 Term.</b> Proxima may also suspend or terminate your access to the Service at any time for
              any reason (or for no reason) including but not limited to your violation of the TOS, as
              determined in its sole discretion. Whether a suspension will be lifted is at the sole discretion of
              Proxima. You may terminate your access to the Service at any time by ceasing to use the
              Service and deleting any parts of the Service you may have installed. Data collected by Proxima
              may be retained for a period after your access to the Service is terminated, as described further in
              the Privacy Policy and as allowed by the laws applicable in your jurisdiction.</p>

            <p><b>5.2 Effect of Suspension or Termination.</b> Upon suspension or termination, you
              acknowledge and agree that Proxima shall cease to provide access to the Service and may delete
              data associated with your use of the Service. You are not entitled to compensation of any kind as
              a result of suspension or termination, regardless of whether effected by you or Proxima, and
              Proxima is not liable to you or any third party for such suspension or termination and any related
              loss.</p>
          </div>
      },
      {
        subheading: '6.   PROXIMA OWNERSHIP RIGHTS',
        description:
          <div>
            <p><b>6.1 Ownership.</b> All right, title and interest in and to the Service including but not limited to
              copyrights, patents, trademarks, trade secrets, trade names, computer code (source and object),
              titles, avatars, animation, audio-visual effects, sound effects, musical works, look and feel,
              methods of operation and items built within the Service by users, are owned by Proxima.
              Proxima reserves all rights in the Service.</p>
            <p><b>6.2 DMCA.</b> Proxima responds to notices alleging copyright infringement that comply with
              the United States Digital Millennium Copyright Act (the “DMCA”). If you wish to make a
              DMCA claim, you must provide the following information in writing in your DMCA notice:</p>
            <p>a. identify the copyrighted work that you claim has been infringed;</p>
            <p>b. identify the material claimed to be infringing and where it is located;</p>
            <p>c. provide reasonably sufficient information to allow Proxima to contact you, such
              as your address, phone number and e-mail address;</p>
            <p>d. provide a statement that you have a good faith belief that use of the material in the
              manner complained of is not authorized by the copyright owner, its agent or law;</p>
            <p>e. provide a statement, made under penalty of perjury, that the above information is
              accurate and that you are the copyright owner or are authorized to act on behalf of the owner;
              and</p>
            <p>f. provide an electronic or physical signature of a person authorized to act on behalf
              of the copyright owner.</p>

            <p>Proxima may provide you notice if your communication, post, submission or upload was
              removed as a result of Proxima receiving a DMCA notice from a copyright owner. If you
              receive notice from Proxima, you may provide a counter-notification in writing to Proxima’s
              designated DMCA agent through the means provided below.</p>

            <p>DMCA notices can be sent to Proxima by emailing support@Proxima.net or by mail:
              <br />
              <br />LNG Productions Inc.
              <br />DMCA Notice
              <br />403-134 Abbott Street
              <br />Vancouver, BC, V6B 2K4</p>
          </div>
      },
      {
        subheading: '7.   DISCLAIMER, LIMITATION OF LIABILITY AND INDEMNIFICATION',
        description:
          <div>
            <p><b>7.1 DISCLAIMER.</b> THE SERVICE IS STILL UNDER DEVELOPMENT AND MAY
              CONTAIN DEFECTS AND COMPATIBILITY ISSUES AND IS PROVIDED BY PROXIMA
              “AS IS”. PROXIMA, ITS DIRECTORS, OFFICERS, EMPLOYEES, AGENTS,
              REPRESENTATIVES AND ASSIGNS (COLLECTIVELY, ITS “REPRESENTATIVES”)
              DISCLAIM ALL WARRANTIES AND CONDITIONS, EXPRESS, IMPLIED OR
              STATUTORY, INCLUDING WITHOUT LIMITATION MERCHANTABILITY, FITNESS
              FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT OF THIRD-PARTY
              RIGHTS, TO THE EXTENT PERMITTED BY LAW. WITHOUT LIMITING THE
              FOREGOING, PROXIMA AND ITS REPRESENTATIVES MAKE NO REPRESENTATIONS
              OR WARRANTIES THAT THE SERVICE WILL MEET YOUR REQUIREMENTS OR
              THAT OPERATION OF THE SERVICE WILL BE UNINTERRUPTED, CONTINUOUSLY
              AVAILABLE, ERROR FREE, WILL NOT HARM YOUR HARDWARE OR RESULT IN
              LOST DATA OR BE SECURE AGAINST UNAUTHORIZED ACCESS. THE SERVICE IS
              UNSUPPORTED AND THE TOS DOES NOT CREATE ANY OBLIGATION UPON
              PROXIMA TO DEVELOP, MAKE AVAILABLE, OFFER FOR SALE, REPAIR, SUPPORT
              OR RELEASE THE SERVICE. NO ORAL ADVICE OR WRITTEN INFORMATION
              PROVIDED BY PROXIMA OR ITS REPRESENTATIVES, WILL CREATE ANY
              WARRANTY AND YOU SHALL NOT RELY UPON SUCH ADVICE OR INFORMATION.
              YOU BEAR THE ENTIRE RISK AS TO THE PERFORMANCE, OPERATION AND
              QUALITY OF THE SERVICE.</p>
            <p><b>7.2 LIMITATION OF LIABILITY.</b> PROXIMA AND ITS REPRESENTATIVES,
              SHALL NOT BE LIABLE TO YOU FOR ANY CLAIM, LOSS OR DAMAGE OF ANY KIND
              ARISING OUT OF OR RELATING TO THE SERVICE INCLUDING WITHOUT
              LIMITATION DIRECT, CONSEQUENTIAL, INCIDENTAL OR SPECIAL DAMAGES,
              EVEN IF PROXIMA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH CLAIM,
              LOSS OR DAMAGE. TO THE EXTENT THAT THE ABOVE LIMITATION OF LIABILITY
              IS NOT APPLICABLE IN YOUR JURISDICTION, ANY CLAIM THAT YOU MAY HAVE
              AGAINST PROXIMA AND ITS REPRESENTATIVES MUST BE COMMENCED NO
              LATER THAN 6 MONTHS AFTER THE DAY ON WHICH THE CLAIM IS DISCOVERED
              OR OUGHT TO HAVE BEEN DISCOVERED BY YOU. TO THE EXTENT PERMITTED
              BY APPLICABLE LAW, YOU AGREE TO WAIVE ANY RIGHT TO BRING A CLASS
              ACTION AGAINST PROXIMA AND ITS REPRESENTATIVES, AND ANY LEGAL
              MATTER YOU RAISE AGAINST THEM SHALL BE HANDLED ON AN INDIVIDUAL
              BASIS.</p>
            <p><b>7.3 PROXIMA’S AGGREGATE LIABILITY.</b> PLEASE NOTE THAT SOME
              JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF CERTAIN TERMS OR
              WARRANTIES OR EXCLUSION OF LIABILITY FOR CERTAIN DAMAGES AND, IF
              ANY DISCLAIMER OR LIMITATION OF LIABILITY IS FOUND UNENFORCEABLE,
              VOID OR DOES NOT FULLY SHIELD PROXIMA AND ITS REPRESENTATIVES FROM
              LIABILITY, YOU AGREE THAT YOUR SOLE REMEDY, AND PROXIMA AND ITS
              REPRESENTATIVES’S AGGREGATE LIABILITY, IS FOR YOU TO TERMINATE USE OF
              THE SERVICE AND MAXIMUM LIABILTY OF THE AMOUNT YOU PAID TO PROXIMA
              IN THE PRECEEDING 6 MONTH PERIOD PRIOR TO ANY CLAIM.</p>
            <p><b>7.4 Indemnity.</b> You shall defend and indemnify Proxima against any claim, demand, suit or
              proceeding (each, a “Claim”) made or brought against Proxima in connection with your use of
              the Service, including Claims that through the use of the Service you (i) infringe or
              misappropriate a third party’s intellectual property rights, (ii) violate any applicable laws, (iii)
              breach the terms of this TOS, or (iv) commit an act of gross negligence or intentional
              misconduct.</p>
          </div>
      },
      {
        subheading: '8.   UPDATES',
        description:
          <div>
            Proxima agrees to notify you of an update to the TOS by a posting on the Website. You are
            deemed to accept an update by continuing to use the Service. Unless Proxima states otherwise,
            updates are automatically effective 30 days after posting.
          </div>
      },
      {
        subheading: '9.   CONFIDENTIALITY AND NON-SOLICITATION',
        description:
          <div>
            <p><b>9.1 Confidential Information.</b> Proxima may receive confidential information not known or
              available outside of your Business (except when known outside you and under a duty of
              confidentiality) including but not limited to: business plans, product or service plans, analyses,
              forecasts, predictions or projections, intellectual property, trade secrets, software, code,
              hardware, prototypes, technology, technical information, business models, real estate models,
              designs, pricing and pricing strategies, marketing ideas, data (including sales data), sales
              projections, customer lists, employee lists, financing plans, valuations, capitalization, budgets
              and other non-public information (collectively, “Confidential Information”). Notwithstanding
              the above, Confidential Information excludes: (a) any information generally available to the
              public (otherwise than through any act or omission of Proxima); (b) any information obligated to
              be disclosed by law; and (c) any information known by Proxima prior to the Effective Date or
              obtained from a third party who is not under a confidentiality obligation to you and who has not
              received such Confidential Information from another in breach of a duty of confidentiality to you
              (such knowledge must be proven with the burden on Proxima).</p>
            <p><b>9.2 Non-Disclosure.</b> For Businesses, Proxima shall not disclose or use Confidential
              Information for any purpose other than to provide the Service to you and to undertake work
              requested by you that requires use of Confidential Information. For Businesses, Proxima shall
              not disclose any Confidential Information of yours except to Proxima employees, contractors or
              agents on a need-to-know basis and only if such employees, contractors or agents agreed to
              similar confidentiality terms. Proxima acknowledges and agrees that no rights or licenses to
              Confidential Information are granted or implied by this TOS except as already explicitly stated. </p>
            <p><b>9.3 Non-Solicitation.</b> During the Term and for a period of 12 months immediately following
              the end of the Term, you shall not, directly or indirectly, on its own or on behalf of a third party,
              hire, retain or solicit any client, employee or independent contractor of Proxima about whom you
              became aware of, or with whom you had contact, over the course the Term.</p>
          </div>
      },
      {
        subheading: '10.   GENERAL',
        description:
          <div>
            <p><b>10.1 Governing law.</b> The TOS is governed by and construed under the laws of British
              Columbia. If a dispute arises, you agree that such dispute be resolved by courts located in
              Vancouver, British Columbia and hereby submit to the personal jurisdiction of such courts.</p>
            <p><b>10.2 Severability.</b> To the extent any section, clause, provision or sentence or part thereof of
              the TOS is determined to be illegal, invalid or unenforceable by competent authority in any
              jurisdiction, then that portion shall be severed, and the remainder of the TOS given full force and
              effect.</p>
            <p><b>10.3 No Waiver.</b> Proxima’s failure to assert or enforce any right contained in the TOS shall
              not constitute a waiver of that right.</p>
            <p><b>10.4 Survival.</b> Sections 3.3, 4.1, 5.2 and 6 though 10 survive termination of the TOS.</p>
            <p><b>10.5 Entire Agreement.</b> The TOS constitutes the entire agreement between you and Proxima
              with respect to the subject matter hereof and supersedes any prior oral or written agreements,
              communications, representations or undertakings provided.</p>
            <p><b>10.6 Contact.</b> You may contact Proxima regarding this TOS by emailing
              hello@proximahq.io.</p>
          </div>
      },
    ]
  },
  privacyPolicy: {
    title: 'Privacy Policy',
    introduction:
      <div>
        <p>Effective Date: March 14, 2022</p>

        <p>This Privacy Policy applies to LNG Productions Inc., and any affiliates or subsidiaries
          (collectively, "<b>Proxima</b>", "<b>we</b>", "<b>our</b>" or "<b>us</b>"), and applies to any mobile applications
          controlled by Proxima, Proxima websites, including <b>{domain}</b>
          (the “Website”) and any other product or service to which we choose to apply this Privacy
          Policy (collectively the “Service”), and details how we collect, use, and disclose your data.</p>
      </div>,
    paragraphs: [
      {
        subheading: '1.   HOW WE OBTAIN PERSONAL DATA',
        description:
          <div>
            <p>Through the Service, we collect and process personal data, which is information about you or
              that identifies you, as well as anonymous data, which does not identify you. If you have any
              questions or concerns about our disclosure of personal data, please contact us at your earliest
              convenience using the contact information found below.</p>

            <p>Subject to legal, contractual and technical requirements, you may choose not to provide Proxima
              with certain data or request the deletion of certain data, which may impact the quality of the
              Service provided.</p>
          </div>
      },
      {
        subheading: '2.   PERSONAL DATA WE COLLECT',
        description:
          <div>
            <p>The following is a description of the personal data that we may collect, use and process in
              connection with the Service:</p>

            <ul>
              <p>
                <li>your name, email address if you sign up for access to an account (each, an “Account”),
                  passwords associated with an Account, city, postal code and your province/state; and
                </li>
              </p>
              <p>
                <li>your status as a realtor or details relating to whether you are working with a realtor;</li>
              </p>
              <p>
                <li>filter questions, such as type of home you are interested in, and detailed about such home,
                  including number of bedrooms, price range, timeframe of purchase and orientation of the home;
                </li>
              </p>
              <p>
                <li>usage activity, such as tracking your activity when using the website, how long you are
                  on different sections of the website, and which pages you are viewing, floorplan details;
                </li>
              </p>
              <p>
                <li>information about your computer, phone, web-connected devices or other devices used to
                  connect with the Service including but not limited to, operating system information, geo-location
                  information, operations performed on the device and unique identifiers that may identify you
                  over time and across different websites including your IP address or other device identifiers.
                </li>
              </p>
            </ul>

            We also collect anonymous data that does not identify you, including but not limited to
            responses to anonymous feedback requests, metrics and other types of statistical data, which can
            be derived from use of the Service.
          </div>
      },
      {
        subheading: '3.   PROCESSING OF YOUR PERSONAL DATA',
        description:
          <div>
            <p>We process your personal data primarily for our business purposes. These uses include but are
              not limited to:</p>

            <ul>
              <p>
                <li>providing the Service to you, analyzing functionality and technical issues, tracking use of
                  the Service and generating reports and data models;
                </li>
              </p>
              <p>
                <li>supporting the security and integrity of the Service including controlling access to the
                  Service, detecting and protecting Proxima and third-parties against privacy breaches, error,
                  fraud, theft, suspicious activity, violations of any service agreement you have entered into with
                  Proxima and other illegal activity or for contractual, legal, regulatory or audit obligations; and
                </li>
              </p>
              <p>
                <li>contacting you for various reasons including feedback requests or marketing purposes, if
                  you previously consented to such contact, analyzing functionality and technical issues, tracking
                  use of the Service and generating reports and data models that we use to improve the service.
                  We may disclose your personal data to our parent companies, affiliates, subsidiaries, employees
                  and contractors for the same purposes described above. We may also disclose your personal data
                  to third parties, who may be located in a foreign jurisdiction and subject to foreign laws,
                  although we do not currently share your personal data with third parties. The situations in the
                  future where we may disclose your personal data to third parties include, but are not limited to:
                  <ul>
                    <p>
                      <li>processing your data as described above;</li>
                    </p>
                    <p>
                      <li>providing customer support and feedback, analyzing functionality and technical issues,
                        tracking use of the Service and generating reports and data models that we use to improve the
                        service;
                      </li>
                    </p>
                    <p>
                      <li>responding to requests from law enforcement or a government agency asserting lawful
                        authority to obtain the data or where Proxima has reasonable grounds to believe the data could
                        be useful in the investigation of unlawful activity, to comply with a subpoena or warrant or an
                        order made by a court, person or body with jurisdiction to compel the production of data, to
                        comply with court rules regarding the production of records and data, or to our legal counsel; and
                      </li>
                    </p>
                    <p>
                      <li>defending Proxima in a legal, regulatory or administrative proceeding or in a contractual
                        dispute.
                      </li>
                    </p>
                  </ul></li>
              </p>
            </ul>
          </div>
      },
      {
        subheading: '4.   THIRD-PARTY SERVICES',
        description:
          <div>
            This Privacy Policy only applies to personal data that Proxima collects, processes and discloses
            and does not apply to the collection, processing and disclosure of data by third parties through
            third-party services, which may be broader than set forth in this Privacy Policy, and that may be
            embedded into the Service. While we do not directly share your personal data with third parties,
            in many cases, third-parties may collect personal data about your online activities over time and
            across different websites and services. We currently use Mailchimp, Google, Facebook,
            LinkedIn, Instagram and myZone. We strongly recommend that you read all third-party privacy
            policies carefully before using them. Please contact us directly using the information found
            below if you would like to learn more about our third-party data practices.
          </div>
      },
      {
        subheading: '5.   COOKIES AND TRACKING TECHNOLOGIES',
        description:
          <div>
            The Service may use tracking technologies, such as cookies, web beacons, JavaScript tags and
            clear GIFs to collect data about you, such as your IP address and device information. Currently
            we use Google Analytics. Based on this data, third-parties may be able to resolve your identity
            across multiple devices. This data is collected, used and disclosed in accordance with the terms
            of this Privacy Policy and the applicable third-party privacy policies. Certain Service features
            may rely on tracking technologies and by declining to accept cookies, disabling JavaScript or by
            changing certain settings on your device, you may not have access to these features (which may
            include a loss of access to any forums provided on the Service).
          </div>
      },
      {
        subheading: '6.   OBTAINING, RECTIFYING AND CONTROLLING YOUR PERSONAL DATA',
        description:
          <div>
            <p>You may contact Proxima to obtain a copy of any personal data we collect about you, the
              production of which may be subject to a fee as permitted by applicable law. In addition, you
              may contact Proxima to correct inaccurate personal data or to complete incomplete personal data.</p>

            <p>You may be able to opt-out of some or all of the ways in which your personal data is processed,
              or request the deletion of certain personal data, except where the personal data is necessary or
              vital for:</p>
            <ul>
              <p>
                <li>performance of contractual or legal obligations, such as due to a service agreement you
                  have entered into with Proxima;
                </li>
              </p>
              <p>
                <li>protecting your interests or those of another person; and</li>
              </p>
              <p>
                <li>our legitimate interests or the legitimate interests of a third-party,</li>
              </p>
            </ul>
            <p>and may request deletion by contacting us using the contact information found below.</p>
          </div>
      },
      {
        subheading: '7.   DATA STORAGE AND RETENTION',
        description:
          <div>
            <p>While most of the personal data we collect is stored securely within the Canada, the data you
              provide to us through the Service may be stored and processed by third parties in countries
              around the world, including the United States. You authorize Proxima and third parties acting on
              our behalf to process your data in any country of their choosing, which may cause your data,
              including personal data, to be subject to privacy protections and legal rights that may not be
              equivalent to those in your country.</p>

            <p>Your personal data is retained for the shorter of:</p>

            <p>(a) until you request its deletion;</p>
            <p>(b) until Proxima no longer requires such data for the purpose for which it was collected;</p>
            <p>(c) 90 days for server logs which contain IP addresses; or</p>
            <p>(d) 5 years forum post linked to the Service.</p>
          </div>
      },
      {
        subheading: '8.   DO NOT TRACK DISCLOSURE',
        description:
          <div>
            Do Not Track ("<b>DNT</b>") is a web or device setting that allows users to request that receivers of
            personal data stop their tracking activities. When you choose to turn on the DNT setting in your
            browser or device or use alternative consumer choice mechanisms, your browser or device sends
            a special signal to websites, analytics companies, advertising networks, plug-in providers and
            other web services you encounter to stop tracking your activity. Currently, there are no DNT
            technology standards, and it is possible that there may never be any DNT technology standards.
            As a result, we do not respond to DNT requests.
          </div>
      },
      {
        subheading: '9.   CHILDREN',
        description:
          <div>
            Proxima does not collect any personal information on individuals under the age of consent in any
            jurisdiction. If we learn that we inadvertently collected personal data from a child under the age
            of consent beyond allowable legal exceptions, we will delete that data as quickly as possible. If
            you are a parent or guardian of a child under the age of majority or age of consent in your
            jurisdiction, who you believe provided Proxima with personal data without your consent, please
            contact us at <b>info@lngstudios.com</b>.
          </div>
      },
      {
        subheading: '10.   EUROPEAN   USERS   AND   RIGHTS   OF   EUROPEAN   ECONOMIC  AREA RESIDENTS',
        description:
          <div>
            <p>When we collect your email and any other account information such as your name and when you
              desire to access forums provided on the Service, the processing of your personal data is
              necessary for our provision of the Services to you, as described in Article 6(1)(b) of the
              European Union’s General Data Protection Regulation (the “GDPR”). In other cases, such as
              when you provide consent to contact you using your contact information, Proxima may collect
              such personal data pursuant to Article 6(1)(a) of the GDPR. For all other personal data collected
              as mentioned in this Privacy Policy, such as the collection of certain device information and IP
              addresses, Proxima has a legitimate interest, as described in Article 6(1)(f) of the GDPR, in
              collecting such personal data for the purposes described in this Privacy Policy.</p>

            <p>If the GDPR or similar laws such as the UK GDPR apply to you, you have certain rights
              regarding your personal data. These rights include:</p>

            <ul>
              <p>
                <li><b>A Right of Access.</b> You have the right to access your personal data that we hold about
                  you free of charge in most circumstances.
                </li>
              </p>
              <p>
                <li><b>A Right to Rectification.</b> If your personal data is inaccurate or incomplete, you can
                  change the information you provided by contacting Proxima using the e-mail contact listed
                  herein.
                </li>
              </p>
              <p>
                <li><b>A Right to Erasure.</b> You have the right to obtain deletion of your personal data under
                  most circumstances. In most cases, you may simply request the deletion by using the contact
                  information found below. Please be careful as deletion of data in this manner is permanent and
                  the data cannot be recovered.
                </li>
              </p>
              <p>
                <li><b>A Right to Object.</b> If the processing of your personal data is based on legitimate
                  interests according to Article 6(1)(f) of the GDPR or if your personal data was collected based
                  on your consent according to Article 6(1)(a) of the GDPR, you have the right to object to this
                  processing. If you object, we will no longer process your personal data unless there are
                  compelling and prevailing legitimate grounds for the processing as described in Article 21 of the
                  GDPR; in particular, if the personal data is necessary for the establishment, exercise or defense
                  of legal claims or if personal data is required for the provision of the Service and you still wish to
                  use the Service.
                </li>
              </p>
              <p>
                <li><b>A Right to file a Complaint.</b> You have the right to file a complaint with the appropriate
                  supervisory authority in your jurisdiction.
                </li>
              </p>
              <p>
                <li><b>A Right to Restriction of Processing of your Personal Data.</b> You have the right to
                  obtain restrictions on the processing of your personal data as described in Article 18 of the
                  GDPR.
                </li>
              </p>
              <p>
                <li><b>A Right to Personal Data Portability.</b> You have the right to receive your personal data
                  in a structured, commonly used and machine-readable format and have the right to transmit such
                  data to another controller under the conditions described in Article 20 of the GDPR.
                </li>
              </p>
              <p>
                <li><b>A Right to Post-Mortem Control of Your Personal Data.</b> Certain jurisdictions grant
                  post-mortem controls of your personal data and if such rights are applicable in your jurisdiction,
                  you may have the right to establish guidelines for the preservation, the deletion and the
                  transmission of your personal data after your death through a will or through your estate.
                </li>
              </p>
              <p>
                <li><b>A Right to Opt-out of Marketing Communications.</b> You have the right to opt-out of
                  marketing communications we send you at any time. If you receive any marketing e-mails from
                  us, you can exercise your right to stop such communications by clicking on the “unsubscribe” or
                  “opt-out” link on any marketing e-mails Proxima sends you. To opt-out of other forms of
                  marketing, please contact us using the contact details provided below.
                </li>
              </p>
            </ul>
          </div>
      },
      {
        subheading: '11.   CHANGE OF OWNERSHIP OR BUSINESS TRANSITION',
        description:
          <div>
            In the event of, or in preparation for, a change of ownership or control of Proxima or a business
            transition such as the sale of some or all Proxima’s assets, we may disclose and/or transfer your
            personal data to third-parties who will have the right to continue to collect and use such data in
            the manner set forth in this Privacy Policy.
          </div>
      },
      {
        subheading: '12.   SECURITY',
        description:
          <div>
            We are committed to ensuring that your data is secure. To prevent unauthorized access,
            disclosure, or breach, we have put in place suitable physical, electronic, and administrative
            procedures to safeguard and secure the data we collect and process.
          </div>
      },
      {
        subheading: '13.   CONTACT PREFERENCES',
        description:
          <div>
            We communicate with you primarily through the Service itself, but we may sometimes collect
            your email address in order to respond to support requests or comments. If you have provided us
            with your email address and would like to change the email preferences, we associate with you
            (for example, unsubscribing from receiving certain types of email) you may do so by clicking a
            link within certain types of emails that we send to you or, if no link is available, by replying with
            “unsubscribe” in the email title or body or by modifying your email settings within the Service.
            On rare occasions, some types of email are necessary for the Service and cannot be unsubscribed
            from if you continue to use the Service.
          </div>
      },
      {
        subheading: '14.   UPDATES',
        description:
          <div>
            Proxima reserves the right, in its sole discretion, to modify the Privacy Policy at any time (each
            an “<b>Update</b>”) and shall make each Update available on the Website. You are deemed to accept
            an Update by continuing to use the Service. Unless Proxima states otherwise, an Update is
            automatically effective 30 days after posting on the Website.
          </div>
      },
      {
        subheading: '15.   CONTACT US',
        description:
          <div>
            <p>If you have requests, questions or comments about the Privacy Policy or our data collection in
              general, please contact our Data Privacy Officer at the Website or at:</p>

            <p><b>Data Privacy Officer</b></p>
            <p><b>
              LNG Studios<br />
              25-60 Smith St, Vancouver, BC V5B0B9<br />
              info@lngstudios.com<br />
            </b></p>
          </div>
      },
    ]
  },
  cookiePolicy: {
    title: 'COOKIE POLICY AND LIST OF THIRD-PARTY SERVICES',
    introduction:
      <div>
        <p>Last updated: Jul 13, 2022</p>
        <p>LNG Studios (<b>"Proxima"</b>, <b>"us"</b>, <b>"we"</b> or <b>"our"</b>) uses cookies and other tracking technologies at {domain} (the <b>"Website"</b>). By using the Website, you consent to the use of essential tracking technologies. Any
          capitalized terms not defined in this cookie policy have the meaning ascribed to them in the Proxima <b style={{ cursor: 'pointer' }}><a href="/">Privacy Policy</a></b>.</p>
        <p></p>
      </div>,
    paragraphs: [
      {
        description:
          <div>Cookies are small pieces of text files stored in your web browser and allows the Website or a third-party to recognize you and make your next visit easier and the Website more useful to you. Cookies can be "persistent" or "session"
            cookies. Persistent cookies remain on your personal computer or mobile device when you go offline, while session cookies are deleted as soon as you close your web browser. Tracking technologies operate similarly.
          </div>
      },
      {
        description:
          <div>
            When you use the Website, Proxima may place tracking technology files in your web browser. Proxima uses essential tracking technologies that are required to authenticate users, operate the Website and prevent fraudulent use of LNG
            Studio’s other services. Essential tracking technologies that Proxima uses include:
            <ul>
              <li><b>Google Recaptcha</b> - Used as an additional security measure that is used when logging in to Proxima.</li>
              <li><b>Google Analytics</b> - Analytics cookies help us understand how visitors interact with our Services to help us optimize the user experience by checking for anomalies and provide required maintenance. <b><a
                href="https:policies.google.com/privacy?hl=en-US" target="_blank" rel="noopener noreferrer">Google Privacy Policy.</a></b></li>
            </ul>
          </div>

      },
      {
        description:
          <div>
            Proxima also uses non-essential cookies and tracking technologies on the Website in order to improve the user experience and help market our company. While most of this tracking for marketing purposes is done via third parties, sometimes
            Proxima may embed certain features from these third parties into our own Website. You may change the settings of our non-essential tracking technologies either through the Website or by using the links below:
            <ul>
              <li><b>Data Tracking</b> - Date and time the user accessed the website. Date and time the Website was accessed on the website. This information helps Proxima track user performance by linking such information to user identifiers. This
                is a premium feature that must be consented to before turned on.
              </li>
              <li><b>Google Analytics</b> - Analytics cookies help us understand how visitors interact with our Services to determine the effectiveness of marketing campaigns. <b><a href="https:policies.google.com/privacy?hl=en-US" target="_blank"
                                                                                                                                                                                      rel="noopener noreferrer">Google Privacy Policy.</a></b></li>
              <li><b>Facebook</b> - Facebook identifiers are used to help us determine the effectiveness of Facebook marketing campaigns.</li>
              <li><b>Google Ads</b> – Google ad identifiers are used to help us determine the effectiveness of Google marketing campaigns.</li>
            </ul>
          </div>
      },
      {
        description:
          <div>
            If you would like to delete cookies or tracking technologies entirely or instruct your browser to delete or refuse cookies or tracking technologies, please do so through your Website settings. You may also email us at info@lngstudios.com.
            In some cases, you may lose all access to the Website if you make this request if such technology is essential for the Website to function. Be aware that if you delete tracking technologies or refuse to accept them, you might not be able
            to use all of the features of the Website or the Website may not work at all.
          </div>
      },
      {
        description:
          <div>
            Residents of the European Economic Area have certain privacy rights pursuant to the General Data protection Regulation (“<b>GDPR</b>”) and Directive 2002/58/EC of the European Parliament (“<b>e-Privacy Directive</b>”). Proxima complies
            with the GDPR and e-Privacy Directive when it comes to cookies and tracking technologies. For more information about the GDPR or the e-Privacy Directive please visit the following links: <a
            href="https:eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32016R0679" target="_blank" rel="noopener noreferrer">GDPR</a> and <a href="https:eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32002L0058&from=EN" target="_blank"
                                                                                                                                                    rel="noopener noreferrer">e-Privacy Directive.</a>
            <br />
            You can learn more about cookies at <a href="http:allaboutcookies.org/" target="_blank" rel="noopener noreferrer">allaboutcookies.org</a> or <a href="http:networkadvertising.org/" target="_blank"
                                                                                                                                                            rel="noopener noreferrer">networkadvertising.org</a>.
            <br />
            Proxima does not sell any personal information from its users to any third-party.
          </div>
      }
    ]
  }

};

export default PoliciesCopyData;