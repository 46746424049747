import React, {} from 'react';

import {
  createUseStyles,
} from 'react-jss';

import {
  // motion
} from 'framer-motion';

import ThemeData from 'data/ThemeData';
import ConstantData from 'data/ConstantData';

const useStyles = createUseStyles(
  {
    wrapper: props => ({
      margin: '0 0 10px 0',

      color: props.color,

      fontFamily: props.fontFamily,
      fontSize: props.fontSize,
      textAlign: 'left',

    }),
  },
  {
    name: 'UnitInfoPlanNameTitle',
  }
);

// data contains a Unit
const UnitInfoPlanNameTitle = ({ data, className, ...props }) => {
  const project = props.project;
  const classes = useStyles({
    ...props,
    color: props.color ? props.color : project.ThemeData.colours.primaryBg,
    fontFamily: project.ThemeData.fonts.titleSub,
  });
  const isIndustrial = project && project.projectType === ConstantData.projectType.industrial;
  const isFloorplan = data.__typename === 'FloorPlan';
  return (
    <div className={`${className} ${classes.wrapper}`}>
      {isIndustrial
        ? <>{data && <> {isFloorplan ? data.name : (project.settings?.showFullTitle ? `${data.floorPlan.name} | Unit ${data.unitNumber}` : data.unitNumber)}</>}</>
        :
        <>
          {data && data.floorPlan.name && `${data.floorPlan.name}`}
          {props.doShowUnitNumber && data.unitNumber !== null && ` | Unit ${data && data.unitNumber}`}
        </>
      }
    </div>
  );
};

UnitInfoPlanNameTitle.defaultProps = {
  color: ThemeData.colours.primaryBg,
  fontFamily: ThemeData.fonts.titleSub,
  fontSize: 36,
  doShowUnitNumber: true,

};

export default UnitInfoPlanNameTitle;
