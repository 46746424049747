import React, { useState, useEffect, useContext } from "react";

import { useLocation } from "react-router-dom";

import { createUseStyles } from "react-jss";

import { motion, AnimatePresence } from "framer-motion";

import ConstantData from "data/ConstantData";
import useSavedHomes from "hooks/useSavedHomes";
import useGlobalUnitsFilter from "hooks/useGlobalUnitsFilter";
import useSiteFeatureToggles from "hooks/useSiteFeatureToggles";
import { portraitMode } from "util/helper";

import { ReactComponent as NavArrowLeft } from "../img/nav_arrow_left.svg";
import { ReactComponent as NavArrowRight } from "../img/nav_arrow_right.svg";
import { ReactComponent as ListView } from "../img/prox_units-list-view.svg";
import { ReactComponent as GridView } from "../img/prox_units-grid-view.svg";
import { sortDataByName } from "util/helper";
import UnitFcnContext from "contexts/UnitFcnContext";

const useStyles = createUseStyles(
  {
    wrapper: {
      display: "flex",
      height: "100%",
      width: "100%",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    cards: (props) => ({
      display: "flex",
      flexWrap: "no-wrap",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "start",
      overflowX: "auto",
      gap: 15,
      paddingBottom: 20,
      alignSelf: "flex-end",
      width: "calc(100vw - 35px)",

      ...props.ThemeData.styles.thinScrollbarDarkOnLight,
    }),
    tableItems: (props) => ({
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      overflowY: "auto",
      gap: 10,
      height: 435,
      borderRadius: 10,
      padding: "10px 20px 10px 10px",
      marginRight: 40,
      boxShadow: `0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset`,
      ...props.ThemeData.styles.thinScrollbarDarkOnLight,
    }),
    nav: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      overflow: "hidden",
      minHeight: 45,
    },
    navButtons: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      gap: 25,
    },
    arrowButton: (props) => ({
      width: 70,
      height: 45,
      padding: 10,
      borderRadius: 15,
      border: `2px solid ${props.ThemeData.colours.branding}`,
      backgroundColor: props.ThemeData.colours.white,
      cursor: "pointer",
      "&:active, &:hover": {
        backgroundColor: props.ThemeData.colours.branding,
        "& > svg": {
          fill: props.ThemeData.colours.primaryFontHighestContrast,
        },
      },
      "& > svg": {
        width: 22,
        height: 20,
        fill: props.ThemeData.colours.branding,
      },
      "&.disabled": {
        opacity: 0.25,
        "& > svg": {
          opacity: 0.5,
        },
        "&:hover": {
          backgroundColor: "transparent",
          "& > svg": {
            opacity: 0.25,
            fill: props.ThemeData.colours.branding,
          },
        },
      },
    }),
    viewButtons: (props) => ({
      position: "absolute",
      right: 40,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      gap: 5,

      "& > svg": {
        width: 25,
        height: 22,
        fill: props.ThemeData.colours.branding,
        cursor: "pointer",
      },

      "& .disabled": {
        fill: props.ThemeData.colours.lightGrey,
      },
    }),
  },
  {
    name: "FloorplanCardsParent",
  }
);

const RenderFloorplanCardsParent = (
  project,
  isPortraitMode,
  classes,
  filteredUnitsData,
  currentIndex,
  hooks,
  props
) => {
  const { ThemeData } = project;
  const { setFilteredUnitsDataContext, filteredUnitsDataContext } =
    useContext(UnitFcnContext);
  const [temp, setTemp] = useState();
  useEffect(() => {
    if (filteredUnitsData.length > 0) {
      let tempFilteredCountUnits = filteredUnitsData.length;
      setTemp(tempFilteredCountUnits);
    }
  }, [filteredUnitsData]);

  useEffect(() => {
    setFilteredUnitsDataContext(temp);
  }, [temp]);

  // setFilteredUnitsDataContext(filteredUnitsData);

  const navController = (index) => {
    if (filteredUnitsData[index])
      props.setSelectedFloorplanId(filteredUnitsData[index].floorPlan.id);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.nav}>
        {props.floorplanFilterViewMode ===
          ConstantData.unitFilterViewModes.card && (
          <div className={classes.navButtons}>
            <button
              key='prevButton'
              className={`${classes.arrowButton} ${
                currentIndex > 0 ? "" : "disabled"
              }`}
              onClick={() => navController(currentIndex - 1)}>
              <NavArrowLeft id='content-page-navigate-left' />
            </button>
            <button
              key='nextButton'
              className={`${classes.arrowButton} ${
                currentIndex < filteredUnitsData.length - 1 ? "" : "disabled"
              }`}
              onClick={() => navController(currentIndex + 1)}>
              <NavArrowRight id='content-page-navigate-right' />
            </button>
          </div>
        )}
        <div className={classes.viewButtons}>
          <GridView
            ThemeData={project.ThemeData}
            className={
              props.floorplanFilterViewMode !==
              ConstantData.unitFilterViewModes.card
                ? "disabled"
                : ""
            }
            onClick={() => {
              navController(0);
              props.setFloorplanFilterViewMode(
                ConstantData.unitFilterViewModes.card
              );
            }}
          />
          <ListView
            ThemeData={project.ThemeData}
            className={
              props.floorplanFilterViewMode ===
              ConstantData.unitFilterViewModes.card
                ? "disabled"
                : ""
            }
            onClick={() => {
              navController(0);
              props.setFloorplanFilterViewMode(
                ConstantData.unitFilterViewModes.list
              );
            }}
          />
        </div>
      </div>
      <AnimatePresence>
        <motion.div
          key={"floorplan-generic"}
          {...ThemeData.anim.defaultOpacityFade}>
          <AnimatePresence exitBeforeEnter={true}>
            <motion.div {...ThemeData.anim.defaultOpacityFade}>
              <div
                id='floorplanItemsDirectParent'
                className={`${
                  props.floorplanFilterViewMode ===
                  ConstantData.unitFilterViewModes.card
                    ? classes.cards
                    : classes.tableItems
                }`}>
                <AnimatePresence>
                  {props.children &&
                    filteredUnitsData &&
                    [...filteredUnitsData].map((dataUnit, idx) => (
                      <motion.div
                        id={`floorplan-${dataUnit.floorPlan.id}`}
                        className={classes.unitCardWrapper}
                        key={dataUnit.floorPlan.id}
                        custom={dataUnit.floorPlan.id}
                        {...ThemeData.anim.defaultOpacityFade}
                        layout='position'>
                        <div id={dataUnit.floorPlan.id} />
                        {props.children(
                          classes.unitCard,
                          dataUnit,
                          idx,
                          idx === 0
                        )}
                      </motion.div>
                    ))}
                </AnimatePresence>
              </div>
            </motion.div>
          </AnimatePresence>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

const FloorplanCardsParent = ({ ...props }) => {
  const {
    project,
    project: { filterData: data },
  } = props;
  const isPortraitMode = portraitMode();
  const location = useLocation();
  const classes = useStyles({
    ThemeData: project.ThemeData,
    isPortraitMode,
    ...props,
  });
  const { setFilteredUnitsDataContext, filteredUnitsDataContext } =
    useContext(UnitFcnContext);

  const { savedHomes, toggleSavedHome } = useSavedHomes();
  const globalUnitsFilter = useGlobalUnitsFilter();
  const siteFeatureToggles = useSiteFeatureToggles(props.project);
  const [currentIndex, setCurrentIndex] = useState(0);

  let hooks = {
    savedHomes,
    toggleSavedHome,
    globalUnitsFilter,
    siteFeatureToggles,
    setCurrentIndex,
  };

  const [filteredUnitsData, setFilteredUnitsData] = useState([]);
  useEffect(() => {
    if (project?.units) {
      setFilteredUnitsData(
        globalUnitsFilter.applyFilter(
          project.settings.showUnits === false &&
            !location.pathname.includes("/proximation")
            ? sortDataByName(data.units)
            : sortDataByName(project.units)
        )
      );
    }
  }, [globalUnitsFilter.globalUnitsFilter, project, data]);

  useEffect(() => {
    if (filteredUnitsData.length > 0) {
      if (props.selectedFloorplanId !== -1) {
        const foundIndex = filteredUnitsData.findIndex(
          (item) => item.floorPlan.id === props.selectedFloorplanId
        );
        if (foundIndex !== -1) setCurrentIndex(foundIndex);
        // filteredUnitsData.length > 0 && setFilteredUnitsDataContext(filteredUnitsData);
      } else {
        props.setSelectedFloorplanId(filteredUnitsData[0].floorPlan.id);
      }
      if (props.setResetCarouselIndex) props.setResetCarouselIndex(true);
    }

    // Scroll to selected floorplan
    if (props.selectedFloorplanId && props.selectedFloorplanId !== -1) {
      let directParentElm = document.getElementById(
        "floorplanItemsDirectParent"
      );
      if (!directParentElm) return;

      let floorplanItemElm = directParentElm.querySelector(
        `[id='${props.selectedFloorplanId}']`
      );
      if (!floorplanItemElm) return;

      floorplanItemElm.parentElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

  }, [filteredUnitsData, props.selectedFloorplanId]);

  return RenderFloorplanCardsParent(
    project,
    isPortraitMode,
    classes,
    filteredUnitsData,
    currentIndex,
    hooks,
    props
  );
};

FloorplanCardsParent.defaultProps = {
  dontShowFilterSort: false,
  dontShowFilter: false,
  dontShowSort: false,
  doSelectFirstItem: false,
};

export default FloorplanCardsParent;
