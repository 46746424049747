import React from 'react';

import { createUseStyles } from 'react-jss';
import { useState } from 'react';
import FormModal from './FormModal';
import downloadIcon2 from '../img/icon_download_2.svg';
import { portraitMode } from 'util/helper';

const useStyles = createUseStyles(
  {
    wrapper: (props) => ({
      marginTop: (props) => (props.isPortraitMode ? 0 : 10),
      fontFamily: props.ThemeData.fonts.copy,
      '& ul': {
        padding: 0,

        '& li': {
          margin: '5px 0',

          listStyleType: 'none',

          '& a': {
            display: 'flex',
            alignItems: 'center',
            borderRadius: '10px',
            margin: '0 -1vw',
            paddingLeft: '1vw',
            marginBottom: '5px',
          },
          '& > a:hover': {
            backgroundColor: 'black',
            color: 'white',
          },
          ...props.ThemeData.styles.linkColourRemoval,
        },
      },
    }),
    hr: {
      margin: '20px 0',
    },
    iconItem: {
      fontSize: '14px',
      color: (props) => props.ThemeData.colours.black,
    },
    iconItemText: {
      flex: '1 0 auto',
      color: (props) => props.ThemeData.colours.black,
      letterSpacing: '.05em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '4vh',
      borderRadius: '10px',
      marginLeft: '-1vw',
      paddingLeft: '1vw',

      '&:hover': {
        backgroundColor: 'black',
        color: 'white',
        transition: '0.4s',
        '& $iconItemIcon': {
          backgroundColor: (props) => props.ThemeData.colours.white,
          transition: '0.4s',
        },
      },
    },
    iconItemIcon: {
      flex: '0 0 auto',
      display: 'inline-block',
      width: 16,
      height: 16,

      margin: 4,
      marginRight: 10,
      background: 'no-repeat center',
      backgroundColor: (props) => props.ThemeData.colours.black,
      maskImage: `url( ${downloadIcon2} )`,
      maskSize: '16px 16px',
    },

    emailText: {
      marginTop: (props) => (props.isPortraitMode ? 23 : 0),
      cursor: 'pointer',
      height: '4vh',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '10px',
      margin: '0 -1vw',
      paddingLeft: '1vw',
      '&:hover': {
        backgroundColor: 'black',
        color: 'white',
        transition: '0.5s',
      },
    },
  },
  { name: 'MainMenuIconItems' }
);

function MainMenuIconItems ({ project }) {
  const isPortraitMode = portraitMode();
  const classes = useStyles({ ThemeData: project.ThemeData, isPortraitMode });

  const [isSendToEmailOpen, toggleIsSendToEmailOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();

  const labels = {
    brochure: 'Download brochure',
    allFloorPlans: 'Download all floor plans',
  };
  const context = {
    brochure: 'brochure',
    allFloorPlans: 'all floor plans',
  };
  let mainMenuIconItems = [];
  if (project) {
    ['brochure', 'allFloorPlans'].forEach((f) => {
      if (project[f] && project[f].length > 0) {
        mainMenuIconItems.push({
          text: labels[f],
          file: project[f][0],
          context: context[f],
          type: f,
        });
      }
    });
  }

  return (
    <>
      {project && mainMenuIconItems.length > 0 && (
        <>
          {/* {!isPortraitMode && <hr className={classes.hr} />} */}
          <div className={classes.wrapper}>
            <ul>
              {mainMenuIconItems.map((item, index) => {
                return (
                  <li className={classes.iconItem} key={item.id + index}>
                    {isPortraitMode ? (
                      <>
                        {item.file.link && (
                          <div
                            style={{ cursor: 'pointer' }}
                            className={classes.emailText}
                            onClick={() => {
                              toggleIsSendToEmailOpen(!isSendToEmailOpen);
                              setSelectedIndex(index);
                            }}>
                            Email {item.context}
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <a
                          href={item.file.link}
                          target="_blank"
                          rel="noreferrer"
                          download="Thing.pdf">
                          <div className={classes.iconItemText}>
                            {item.text}
                            <div className={classes.iconItemIcon} />
                          </div>
                        </a>
                        {item.file.link && (
                          <div
                            style={{ cursor: 'pointer' }}
                            className={classes.emailText}
                            onClick={() => {
                              toggleIsSendToEmailOpen(!isSendToEmailOpen);
                              setSelectedIndex(index);
                            }}>
                            Email {item.context}
                          </div>
                        )}
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
          {mainMenuIconItems.map((item, index) => (
            <FormModal
              key={`menu-${index}`}
              project={project}
              doShow={selectedIndex === index && isSendToEmailOpen}
              toggleIsOpen={() => toggleIsSendToEmailOpen()}
              pdfUrl={item.file.link && `${item.file.link}`}
              emailCopy={item.text.replace('Download ', '')}
              sendType={item.type}
              titleText={`Send ${item.context} to email`}
            />
          ))}
        </>
      )}
    </>
  );
}

export default MainMenuIconItems;
