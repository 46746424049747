import React, { useState, useEffect, useRef } from 'react';

import { createUseStyles } from 'react-jss';
import NetData from 'data/NetData';
import ConstantData from 'data/ConstantData';
import richifyTextBasic from 'util/richifyTextBasic';
import ClearConfirmationDialogGeneric from './ClearConfirmationDialogGeneric';
import MainMenuIconItems from './MainMenuIconItems';
import useRegistration from 'hooks/useRegistration';
import useSavedHomes from 'hooks/useSavedHomes';
import useOpenClosePane from 'hooks/useOpenClosePane';
import PoliciesModal from './PoliciesModal';
import PoliciesCopyData from 'data/PoliciesData';
import { resetCookieConsentValue } from 'react-cookie-consent';
import { portraitMode, switchViewMode } from 'util/helper';
import { ReactComponent as InstagramIcon } from '../img/icon_instagram.svg';
import { ReactComponent as FacebookIcon } from '../img/icon_facebook.svg';

import iconwhite from '../img/icon_close_button_white.svg';
import iconblack from '../img/icon_close_button.svg';
import iconPortrait from '../img/icon_portrait_mode.svg';
import iconPortraitWhite from '../img/icon_portrait_mode_white.svg';

const useStyles = createUseStyles(
  {
    wrapper: (props) => ({
      position: 'relative',
      textAlign: 'left',
      fontFamily: props.ThemeData.fonts.copy,
    }),
    menuContent: (props) => ({
      display: 'block',
      position: 'relative',
      textAlign: 'left',
      padding: props.isPortraitMode ? '30px 45px 70px 45px' : '16px 36px',
      background: props.ThemeData.colours.menuBg,
      backdropFilter: 'blur(10px)',
      height: 'fit-content',
      borderRadius: props.isPortraitMode
        ? '0px 20px 20px 0px'
        : '0px 20px 20px 0px',

      '& hr': {
        margin: props.isPortraitMode ? '' : '20px 0px 20px 0',

        borderTop: `1px solid ${props.ThemeData.colours.vibrantGreen}`,
        
      },
    }),
    buttonWrapper: (props) => ({
      display: 'flex',
      marginBottom: 48,
      justifyContent: 'space-between',
      alignItems: 'center',
    }),
    navListItem: (props) => ({
      position: 'relative',

      padding: '20px 0',

      flex: '0 1 auto',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      listStyleType: 'none',
      letterSpacing: 0,

      color: props.ThemeData.colours.topMenuFont,
    }),
    leftColumnBottomContent: (props) => ({
      // border: "1px solid red",
    }),
    navListItemCount: (props) => ({
      position: 'absolute',

      left: -50,

      fontSize: 15.2,
    }),
    overlayContentPage: (props) => ({
      boxSizing: 'border-box',
      width: '100%',
      height: '100%',

      backgroundColor: props.ThemeData.colours.primaryBg,

      zIndex: 4,
    }),
    labelTextColumn: (props) => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      gap: 50,
    }),
    itemLabelText: (props) => ({
      display: 'flex',
      justifyContent: 'start',
      // border: "1px solid red",

      marginBottom: props.isPortraitMode ? 0 : 20,
      fontSize: 14,
      textAlign: 'left',
      lineHeight: '23px',
      color: props.ThemeData.colours.black,
      padding: props.isPortraitMode ? '10px 0' : '5px 0',
    }),
    itemLabel: (props) => ({
      display: 'inline-block',
      width: '8vw',
      // border: '1px solid red',
      // fontWeight: 700,
      fontFamily: props.ThemeData.fonts.title,
      color: props.ThemeData.colours.topMenuFont,
      letterSpacing: '1.4px',
    }),
    itemText: (props) => ({
      display: 'inline-block',
      fontFamily: props.ThemeData.fonts.copy,
      color: props.ThemeData.colours.topMenuFont,
      letterSpacing: '.05em',
      whiteSpace: 'pre-wrap',
      // border: '1px solid blue',
      width: '100%',
    }),
    contactSocial: (props) => ({
      display: 'inline-block',

      marginRight: 50,
      ...props.ThemeData.styles.linkColourRemovalTopMenu,
      '& a': {
        color: props.ThemeData.colours.black,
      },
    }),
    partnerImagesWrapper: (props) => ({
      display: 'flex',
      alignItems: 'center',
    }),
    partnerImageWrapper: (props) => ({
      flex: '1 0 auto',

      marginRight: 40,
    }),
    partnerImage: (props) => ({
      height: 40,
      maxWidth: 200,

      margin: 0,
    }),
    disclaimer: (props) => ({
      marginBottom: 0,
      fontFamily: props.ThemeData.fonts.copy,
      color: props.ThemeData.colours.black,
      fontSize: 10,
      letterSpacing: 0.1,
      lineHeight: 1.5,
      '& > p': {
        margin: 0,
      },
    }),
    resetPoweredGroup: (props) => ({
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      // border: '1px solid red',
      fontSize: 9,
      fontFamily: props.ThemeData.fonts.copy,
      paddingTop: props.isPortraitMode ? 55 : 20,
    }),
    resetSiteButton: (props) => ({
      // width: props.isPortraitMode ? "100%" : "",
      // border: '1px solid blue',
      cursor: 'pointer',
      color: props.ThemeData.colours.black,
      // display: props.isPortraitMode ? "flex" : "",
      // justifyContent: "flex-end",
      // alignItems: 'baseline',

      whiteSpace: 'nowrap',
      padding: props.isPortraitMode ? '12px 12px' : '12px 12px',
      fontSize: props.isPortraitMode ? '10px' : 10,
      marginRight: props.isPortraitMode ? 0 : 36,
      borderRadius: '10px',
      // border: "1px solid red",
      // padding:'12px 12px',
      // marginBottom:'5px',

      '&:hover': {
        // color: props.ThemeData.colours.vibrantGreen
        // color: "rgba(125, 32, 182, 1)",
        // display:'flex',
        // justifyContent:'center',
        // alignItems:'center',
        // padding:'0 0.4vw',
        color: 'white',
        backgroundColor: 'black',
        transition: '0.5s',
      },
    }),
    poweredByProximaWrapper: (props) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: props.isPortraitMode ? 'flex-end' : 'center',

      width: '100%',
    }),
    poweredByProximaText: (props) => ({
      fontFamily: props.ThemeData.fonts.subTitle,
      width: 200,
      fontSize: '12px',
      textAlign: 'center',
      marginRight: props.isPortraitMode ? '0' : '35px',
      marginBottom: 20,
      letterSpacing: '1.2px',
      color: props.ThemeData.colours.black,
      fontStyle: 'normal',
      fontWeight: 400,
    }),
    policyLinksWrapper: (props) => ({
      flex: '1 0 auto',
      marginTop: 10,
      marginBottom: 20,
      cursor: 'pointer',
      color: props.ThemeData.colours.black,
      '& > div:hover': {
        // color: props.ThemeData.colours.vibrantGreen
        // color: "rgba(125, 32, 182, 1)",
        backgroundColor: 'black',
        color: 'white',
        transition: '0.5s',
      },
    }),
    policyLinks: (props) => ({
      //   flex: "1 0 auto",
      marginTop: props.isPortraitMode ? 10 : 10,
      cursor: 'pointer',
      height: '4vh',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '10px',
      margin: '0 -0.4vw',
      paddingLeft: '0.4vw',
    }),
    termsConditionsPrivacyPolicyLabels: (props) => ({
      fontFamily: props.ThemeData.fonts.copy,
      color: props.ThemeData.colours.topMenuFont,
      fontSize: 14,
      letterSpacing: '.05em',
      textAlign: 'left',
    }),
    closeButton: (props) => ({
      display: 'flex',
      marginTop: props.isPortraitMode ? '' : '-10px',
      justifyContent: 'center',
      cursor: 'pointer',
      // border: '1px solid red',
      borderRadius: '10px',
      marginLeft: props.isPortraitMode ? '-10px' : '-20px',

      '&:hover': {
        backgroundColor: 'black',
        transition: '0.5s',
        '& $closeBtnIcon': {
          background: `no-repeat center/contain url(${iconwhite})`,
        },
      },
    }),
    closeBtnIcon: (props) => ({
      width: '17.545px',
      height: '17.545px',
      paddingTop: 6,
      margin: '6px 9px 6px 9px',
      background: `no-repeat center/contain url(${iconblack})`,
    }),

    portraitModeIcon: (props) => ({
      marginLeft: props.isPortraitMode ? 24 : 0,
      display: 'flex',
      marginRight: !props.isPortraitMode ? '-15px' : '-10px',
      // marginRight:'-15px',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '8px 9px',
      // border: '1px solid red',
      // marginTop:'-5px',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: 'black',
        transition: '0.5s',
        '& $portraitIcon': {
          background: `no-repeat center/contain url(${iconPortraitWhite})`,
        },
      },
    }),
    portraitIcon: (props) => ({
      height: '18px',
      width: '23px',
      background: `no-repeat center/contain url(${iconPortrait})`,
    }),
  },
  {
    name: 'TopMenu',
  }
);

const TopMenu = ({ project, className, ...props }) => {
  const {
    partnerPhoto = [],
    address,
    contact,
    socialContact,
    ThemeData,
  } = project;

  const hasSocialContact =
    Object.keys(socialContact).filter(
      (k) => socialContact[k] && k !== '__typename'
    ).length > 0;

  const isPortraitMode = portraitMode();
  const classes = useStyles({ ThemeData, isPortraitMode });
  const registration = useRegistration();
  const savedHomes = useSavedHomes();
  const [splitedContact, setContacts] = useState([]);
  const [splitedAdresses, setAdresses] = useState([]);
  useEffect(() => {
    if (contact) {
      let res = contact.email.split(',');
      setContacts(res);
    }
  }, [contact]);

  useEffect(() => {
    if (address) {
      let res = address.project.split('/');
      setAdresses(res);
    }
  }, [address]);

  const {
    isClearConfirmationDialogOpen,
    setIsClearConfirmationDialogOpenToOpen,
    setIsClearConfirmationDialogOpenToClosed,
  } = useOpenClosePane('ClearConfirmationDialog');

  const {
    isPrivacyPolicyDialogOpen,
    setIsPrivacyPolicyDialogOpenToOpen,
    setIsPrivacyPolicyDialogOpenToClosed,
  } = useOpenClosePane('PrivacyPolicyDialog');

  const {
    isTermsOfServiceDialogOpen,
    setIsTermsOfServiceDialogOpenToOpen,
    setIsTermsOfServiceDialogOpenToClosed,
  } = useOpenClosePane('TermsOfServiceDialog');

  const {
    isCookiePolicyDialogOpen,
    setIsCookiePolicyDialogOpenToOpen,
    setIsCookiePolicyDialogOpenToClosed,
  } = useOpenClosePane('CookiePolicyDialog');

  useEffect(() => {
    !props.isOpen && setIsPrivacyPolicyDialogOpenToClosed();
    setIsTermsOfServiceDialogOpenToClosed();
    setIsCookiePolicyDialogOpenToClosed();
  }, [props.isOpen]);

  const [_, setSectionContentToDisplay] = useState(
    ConstantData.TopMenu.defaultSectionContentToDisplay
  );

  const renderLinks = () => {
    return (
      <div
        className={classes.leftColumnBottomContent}
        onClick={() =>
          setSectionContentToDisplay(
            ConstantData.TopMenu.defaultSectionContentToDisplay
          )
        }
        onMouseEnter={() =>
          setSectionContentToDisplay(
            ConstantData.TopMenu.defaultSectionContentToDisplay
          )
        }>
        <MainMenuIconItems
          project={project}
          toggleIsSendToEmailOpen={props.toggleIsSendToEmailOpen}
        />
        <hr className={classes.hr} />
        <div className={classes.policyLinksWrapper}>
          <div className={classes.policyLinks}>
            <div
              className={classes.termsConditionsPrivacyPolicyLabels}
              onClick={() => setIsTermsOfServiceDialogOpenToOpen()}>
              Terms of Service
            </div>
          </div>
          <div
            className={classes.policyLinks}
            onClick={() => setIsPrivacyPolicyDialogOpenToOpen()}>
            <div className={classes.termsConditionsPrivacyPolicyLabels}>
              Privacy Policy
            </div>
          </div>
          <div
            className={classes.policyLinks}
            onClick={() => setIsCookiePolicyDialogOpenToOpen()}>
            <div className={classes.termsConditionsPrivacyPolicyLabels}>
              Cookies Policy
            </div>
          </div>
          <div
            className={classes.policyLinks}
            onClick={() => {
              resetCookieConsentValue('ProximaByLngCookies');
              window.location.reload();
            }}>
            <div className={classes.termsConditionsPrivacyPolicyLabels}>
              Cookie Settings
            </div>
          </div>
        </div>
        <hr className={classes.hr} />
      </div>
    );
  };
  const renderSocialIcons = (icon) => {
    if (isPortraitMode) {
      if (icon === 'Facebook') {
        return <FacebookIcon />;
      } else if (icon === 'Instagram') {
        return <InstagramIcon />;
      } else {
        return icon;
      }
    }
    return icon;
  };
  const switchViewModeFunc = () =>
    switchViewMode(isPortraitMode ? 'landscape' : 'portrait');

  const menuRef = useRef(null);

  function useOutsideClickMenu (ref) {
    useEffect(() => {
      function handleClickOutside (event) {
        if (ref?.current && !ref?.current.contains(event.target)) {
          props.toggleOpen((isOpen) => !isOpen);
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideClickMenu(
    isPortraitMode
      ? !props.isOpen
        ? menuRef
        : null
      : props.isOpen
        ? menuRef
        : null
  );
  return (
    <div className={`${classes.wrapper} ${className}`} ref={menuRef}>
      <div className={`${classes.menuContent}`}>
        {isPortraitMode && (
          <div
            className={classes.poweredByProximaWrapper}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
            }}>
            <div
              className={`${classes.itemLabel} ${classes.poweredByProximaText}`}>
              Powered by PROXIMA
            </div>
          </div>
        )}
        {!isPortraitMode && (
          <div className={classes.buttonWrapper}>
            <div className={`${classes.closeButton}`}>
              <div
                className={`${classes.closeBtnIcon}`}
                onClick={() => props.toggleOpen((isOpen) => !isOpen)}
              />
             
            </div>

            <div style={{ display: 'flex' }}>
              <div
                className={classes.resetSiteButton}
                onClick={() => setIsClearConfirmationDialogOpenToOpen()}>
                Clear Site Data
              </div>
              <div
                className={classes.portraitModeIcon}
                onClick={() => {
                  switchViewModeFunc();
                  window.location.reload();
                }}>
                <div className={classes.portraitIcon} />
                {/* <PortraitModeIcon width={23} height={18} /> */}
              </div>
            </div>
          </div>
        )}

        {isPortraitMode && renderLinks()}

        {!isPortraitMode && (
          <>
            <MainMenuIconItems
              project={project}
              toggleIsSendToEmailOpen={props.toggleIsSendToEmailOpen}
            />
            <hr className={classes.hr} />
          </>
        )}

        <div>
          <div className={classes.itemLabelText}>
            <div className={classes.itemLabel}>Contact</div>
            <div className={classes.itemText}>
              {splitedContact.length > 0 &&
                splitedContact.map((item, index) => <div key={item + index}>{item}</div>)}
              {/* {contact.email} */}
              <br />
              {contact.phone}
            </div>
          </div>
          <div className={classes.itemLabelText}>
            <div className={classes.itemLabel}>Visit Us</div>
            <div className={classes.itemText}>
              {splitedAdresses.length > 0 &&
                splitedAdresses.map((item, index) => <div key={item + index}>{item}</div>)}
            </div>
          </div>
        </div>

        {hasSocialContact && Object.keys(socialContact).length > 1 && (
          <div className={classes.itemLabelText}>
            <div className={classes.itemLabel}>Socials</div>
            <div className={classes.itemText}>
              {Object.keys(socialContact)
                .filter((k) => socialContact[k] && k !== '__typename')
                .map((k) => {
                  return (
                    <div className={classes.contactSocial} key={k}>
                      <a href={socialContact[k]}>{renderSocialIcons(k)}</a>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        {partnerPhoto && partnerPhoto.length > 0 && (
          <div className={classes.itemLabelText}>
            <div className={classes.itemLabel}>Our Team</div>
            <div className={classes.itemText}>
              <div className={classes.partnerImagesWrapper}>
                {partnerPhoto.map((partnerImage) => {
                  return (
                    <div
                      className={classes.partnerImageWrapper}
                      key={partnerImage.id}>
                      <img
                        className={classes.partnerImage}
                        src={`${NetData.imgUri(partnerImage.link)}`}
                        alt="Partner"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {!isPortraitMode && (
          <>
            <hr className={classes.hr} />
            <div className={classes.policyLinksWrapper}>
              <div className={classes.policyLinks}>
                <div
                  className={classes.termsConditionsPrivacyPolicyLabels}
                  onClick={() => setIsTermsOfServiceDialogOpenToOpen()}>
                  Terms of Service
                </div>
              </div>
              <div
                className={classes.policyLinks}
                onClick={() => setIsPrivacyPolicyDialogOpenToOpen()}>
                <div className={classes.termsConditionsPrivacyPolicyLabels}>
                  Privacy Policy
                </div>
              </div>
              <div
                className={classes.policyLinks}
                onClick={() => setIsCookiePolicyDialogOpenToOpen()}>
                <div className={classes.termsConditionsPrivacyPolicyLabels}>
                  Cookies Policy
                </div>
              </div>
              <div
                className={classes.policyLinks}
                onClick={() => {
                  resetCookieConsentValue('ProximaByLngCookies');
                  window.location.reload();
                }}>
                <div className={classes.termsConditionsPrivacyPolicyLabels}>
                  Cookie Settings
                </div>
              </div>
            </div>
          </>
        )}

        {project?.settings.disclaimer && (
          <>
            <hr className={classes.hr} />
            <div className={classes.disclaimer}>
              <p
                dangerouslySetInnerHTML={{
                  __html: richifyTextBasic(project.settings.disclaimer),
                }}
              />
            </div>
          </>
        )}
        <div style={{}} className={classes.resetPoweredGroup}>
          {isPortraitMode && (
            <div className={`${classes.closeButton}`}>
              <div
                className={`${classes.closeBtnIcon}`}
                onClick={() => props.toggleOpen((isOpen) => !isOpen)}
              />
              {/* <CloseIcon
                style={{
                  width: "17.545px",
                  height: "17.545px",
                  paddingTop: 6,
                  marginRight: 20,
                }}
                onClick={() => props.toggleOpen((isOpen) => !isOpen)}
              /> */}
              {/* <div
                className={`${classes.closeMainMenuText}`}
                onClick={() => props.toggleOpen((isOpen) => !isOpen)}>
                <p>Close Menu</p>
              </div> */}
            </div>
          )}
          {!isPortraitMode && (
            <div className={classes.poweredByProximaWrapper}>
              <div
                className={`${classes.itemLabel} ${classes.poweredByProximaText}`}>
                Powered by Proxima
              </div>
              <div className={classes.poweredByProximaImg}></div>
            </div>
          )}
          {isPortraitMode && (
            <div style={{ display: 'flex' }}>
              <div
                className={classes.resetSiteButton}
                onClick={() => setIsClearConfirmationDialogOpenToOpen()}>
                Clear Site Data
              </div>
              <div
                className={classes.portraitModeIcon}
                onClick={() => {
                  switchViewModeFunc();
                  window.location.reload();
                }}>
                {/* <PortraitModeIcon width={23} height={18} /> */}
                <div className={classes.portraitIcon} />
              </div>
            </div>
          )}
          <ClearConfirmationDialogGeneric
            dataTypeToClearName={'Site Data'}
            project={project}
            isClearConfirmationDialogOpen={isClearConfirmationDialogOpen}
            setIsClearConfirmationDialogOpenToClosed={
              setIsClearConfirmationDialogOpenToClosed
            }
            afterClearConfirmationPerformFunction={() => {
              registration.clearRegistration();
              savedHomes.clearSavedHomes();
            }}
          />
          {/* {isPortraitMode &&
            
               <div
              className={classes.portraitModeIcon}
              onClick={() => {
                switchViewModeFunc();
                window.location.reload();
              }}>
              <PortraitModeIcon
                fill={
                  props.isPortraitMode
                    ? ThemeData.colours.black
                    : ThemeData.colours.white
                }
              />
            </div>
            } */}
        </div>
      </div>
      <PoliciesModal
        data={PoliciesCopyData.privacyPolicy}
        isDialogOpen={isPrivacyPolicyDialogOpen}
        setIsDialogOpenToClosed={setIsPrivacyPolicyDialogOpenToClosed}
      />
      <PoliciesModal
        data={PoliciesCopyData.termsOfService}
        isDialogOpen={isTermsOfServiceDialogOpen}
        setIsDialogOpenToClosed={setIsTermsOfServiceDialogOpenToClosed}
        onClickFcn={setIsPrivacyPolicyDialogOpenToOpen}
      />
      <PoliciesModal
        data={PoliciesCopyData.cookiePolicy}
        isDialogOpen={isCookiePolicyDialogOpen}
        setIsDialogOpenToClosed={() => setIsCookiePolicyDialogOpenToClosed()}
        onClickFcn={() => setIsPrivacyPolicyDialogOpenToOpen()}
      />
    </div>
  );
};

export default TopMenu;
