import NetData from 'data/NetData';
import ConstantData from 'data/ConstantData';

let imgFunctions = {};

imgFunctions.GetImgBySizeProp = (imgData, sizeToGet, propToGet) => {

  if (!imgData)
    return null;

  if (sizeToGet === ConstantData.imgSizes.raw)
    return imgData[propToGet];

  if (imgData.formats && imgData.formats[sizeToGet])
    return imgData.formats[sizeToGet][propToGet];

  return imgData[propToGet];

};

imgFunctions.GetImgBySizeUri = (imgData, sizeToGet, fallbackUri = null) => {

  const uriReturned = imgFunctions.GetImgBySizeProp(imgData, sizeToGet, 'url');

  if (!uriReturned)
    return fallbackUri;

  return NetData.imgUri(uriReturned);

};
imgFunctions.GetImgBySizeWidth = (imgData, sizeToGet) => {

  const returned = imgFunctions.GetImgBySizeProp(imgData, sizeToGet, 'width');

  if (!returned)
    return null;

  return returned;

};
imgFunctions.GetImgBySizeHeight = (imgData, sizeToGet) => {

  const returned = imgFunctions.GetImgBySizeProp(imgData, sizeToGet, 'height');

  if (!returned)
    return null;

  return returned;

};

imgFunctions.GetAllImgElmProps = (imgData, sizeToGet) => {

  let imgProps = {
    src: imgFunctions.GetImgBySizeUri(imgData, sizeToGet),
    width: imgFunctions.GetImgBySizeWidth(imgData, sizeToGet),
    height: imgFunctions.GetImgBySizeHeight(imgData, sizeToGet),
  };

  return imgProps;
};

export default imgFunctions;

export const getImageBySize = (photo, size = 'small') => {
  if (!photo) return;

  return size !== 'original' && photo[0].formats && photo[0].formats.length > 0
    ? (photo[0].formats.filter((f) => f.size === size)[0] || photo[0])
    : photo[0];
};

export const getImageBySizeMultiple = (photo, size = 'small') => {
  if (!photo) return;

  let largePhoto = {};
  if (photo.formats && photo.formats.length > 1) {
    photo.formats.filter((p) => p.size === size ? largePhoto = p : null);
  } else {
    largePhoto = photo;
  }
  return largePhoto;
};

export const getMultipleImageSize = (multiImg, size = 'small') => {
  if (!multiImg) return;

  const photos = multiImg.flatMap(img =>
    img.formats ?
      img.formats.filter(p => p.size === size).map(p => ({
        fileTitle: img.fileTitle,
        link: p.link,
        size: p.size
      }))
      : []
  );

  return photos;
};