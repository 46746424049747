import React, { useContext, useEffect } from "react";

import { createUseStyles } from "react-jss";

import useSavedHomes from "hooks/useSavedHomes";
import { portraitMode } from "util/helper";
import useCompareHomes from "hooks/useCompareHomes";
import { ReactComponent as IconBookmark } from "img/icon_bookmark.svg";
import { ReactComponent as IconBookmarkNoFill } from "img/icon_bookmark_nofill.svg";
import ThemeData from "data/ThemeData";
import UnitFcnContext from "contexts/UnitFcnContext";

const useStyles = createUseStyles(
  {
    wrapper: {
      padding: 20,

      textAlign: "left",
    },
    savedHomes: (props) => ({
      flex: "0 0 auto",

      // width: 175,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: props.isPortraitMode ? 43 : 50,
      cursor: "pointer",
      marginTop: "10px",
      color:
        props.isSaveHome || props.isCompareHomes
          ? ThemeData.colours.saveCompareHomesTextButtonPortrait
          : "",
      ...props.ThemeData.typography.nameplateNoColor,
    }),
    savedHomesNumberText: (props) => ({
      display: "inline",

      margin: "0 5px",
      padding: "3px 4px",

      color: props.ThemeData.colours.white,
      backgroundColor: props.ThemeData.colours.primaryAccent,

      borderRadius: 10,

      ".mainMenuHeader &": {
        backgroundColor: props.ThemeData.colours.primaryAccent,
      },
    }),
    saveHomesIconWrapper: (props) => ({
      display: "flex",
      //   paddingTop: props.isSaveHome ? 10 : 0,
      //   padding:'30px'
    }),
    bookmarkIcon: (props) => ({
      position: "absolute",
      //   border:'1px solid red'
    }),
    bookmarkIconFooter: (props) => ({
      paddingTop: props.height === 100 ? 40 : 72,
    }),
    saveHomesNumber: (props) => ({
      position: "relative",
      color: props.ThemeData.colours.white,
      width: props.isSaveHome ? 24 : 25,
      display: "flex",
      justifyContent: "center",
      paddingTop: props.isSaveHome ? 2 : 0,
      paddingBottom: props.isSaveHome ? 0 : 2,
    }),
  },
  {
    name: "SavedHomesTitle",
  }
);

const SavedHomesTitle = ({ project, ...props }) => {
  const isPortraitMode = portraitMode();
  const classes = useStyles({
    ...props,
    ThemeData: project.ThemeData,
    isPortraitMode,
    isSaveHome: props.isSaveHome,
    height: props.height,
  });
  const {
    savedHomes,
    // toggleSavedHome,
  } = useSavedHomes();
  const { compareHomes } = useCompareHomes();
  const { savedHomesCount, setSavedHomesCount } = useContext(UnitFcnContext);

  useEffect(() => {
    let number =
      props?.isCompareHomes && props.isCompareHomes
        ? compareHomes.length
        : savedHomes.length;
    setSavedHomesCount(number);
  }, [compareHomes, savedHomes]);

  return (
    <div
      className={`${classes.savedHomes} ${props.className}`}
      onClick={props.onClick}>
      {isPortraitMode ? (
        <>
          <p id='saved-homes'>
            {props.titleText === "Saved Homes"
              ? project.projectType?.includes("industrial")
                ? "Saved Plans"
                : `${props.titleText}`
              : props.titleText}{" "}
            &nbsp;
            {props.isCompareHomes && (
              <span className={classes.savedHomesNumberText}>
                &nbsp;&nbsp;
                {props?.isCompareHomes && props.isCompareHomes
                  ? compareHomes.length
                  : savedHomes.length}
                &nbsp;&nbsp;
              </span>
            )}
          </p>
          <div className={classes.saveHomesIconWrapper}>
            {props.isSaveHome ? (
              <IconBookmark
                className={classes.bookmarkIcon}
                fill={project.ThemeData.colours.branding}
                stroke={project.ThemeData.colours.branding}
              />
            ) : (
              <>
                {!props.isCompareHomes && (
                  <IconBookmarkNoFill
                    className={`${classes.bookmarkIcon} ${classes.bookmarkIconFooter}`}
                  />
                )}
              </>
            )}

            {!props.isCompareHomes && (
              <span className={classes.saveHomesNumber}>
                {props?.isCompareHomes && props.isCompareHomes
                  ? compareHomes.length
                  : savedHomes.length}
              </span>
            )}
          </div>
        </>
      ) : (
        <p id='saved-homes'>
          {props.titleText === "Saved Homes"
            ? project.projectType?.includes("industrial")
              ? "Saved Plans"
              : `${props.titleText}`
            : props.titleText}{" "}
          &nbsp;
          <span className={classes.savedHomesNumberText}>
            &nbsp;&nbsp;
            {props?.isCompareHomes && props.isCompareHomes
              ? compareHomes.length
              : savedHomes.length}
            &nbsp;&nbsp;
          </span>
        </p>
      )}
    </div>
  );
};

SavedHomesTitle.defaultProps = {
  titleText: "Saved Homes",
};

export default SavedHomesTitle;
