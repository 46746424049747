import {
	useMemo,
} from 'react';

import createPersistedState from "use-persisted-state";

import ConstantData from "data/ConstantData";


const useCompareHomesState = createPersistedState( ConstantData.localStorageKeys.compareHomes );


const useCompareHomes = () => {

	const [compareHomes, setCompareHomes] = useCompareHomesState( [] );

	const compareHomesArray = useMemo( () => {
		return compareHomes.map( compareHomes => compareHomes.id );
	}, [compareHomes])

	return {
		compareHomes,
		toggleCompareHome: unitId => {
			let prevUnitIdExistsIdx = compareHomes.findIndex(elm => elm.id === unitId);
			if (prevUnitIdExistsIdx === -1){
                setCompareHomes([...compareHomes, { id: unitId }]);
			}
			else {
				let newCompareHomes = compareHomes;
				newCompareHomes.splice(prevUnitIdExistsIdx, 1);
				setCompareHomes([...newCompareHomes]);
			}
		},
		removeCompareHome: unitId => {
			let prevUnitIdExistsIdx = compareHomes.findIndex(elm => elm.id === unitId);
			let newCompareHomes = compareHomes;
			newCompareHomes.splice(prevUnitIdExistsIdx, 1);
			setCompareHomes([...newCompareHomes]);
		},
		compareHomesArray: compareHomesArray,
		compareHomesIncludes: unitId => compareHomesArray.includes( unitId ),
		clearCompareHomes: () => {
			setCompareHomes( [] );
		},
	}

}


export default useCompareHomes;