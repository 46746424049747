import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { createUseStyles } from 'react-jss';

import { ReactComponent as NavArrowLeft } from '../img/nav_arrow_left.svg';
import { ReactComponent as NavArrowRight } from '../img/nav_arrow_right.svg';
import ButtonCircle from './ButtonCircle';
import useSavedHomes from 'hooks/useSavedHomes';
import useGlobalUnitsFilter from 'hooks/useGlobalUnitsFilter';
import useSiteFeatureToggles from 'hooks/useSiteFeatureToggles';
import { portraitMode } from 'util/helper';

const useStyles = createUseStyles(
  {
    wrapper: {
      //marginBottom: 30,
    },

    navigationButtons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      gap: 80,
      '& > div': {
        alignSelf: 'flex-end'
      }
    },
    filteredResultTitle: {
      display: 'flex',
      fontSize: 24,
      fontWeight: 400,
      color: props => props.ThemeData.colours.black,
      padding: '0 40px 0 40px',
      height: 60,
      '& > div': {
        alignSelf: 'center',
      },
    },
    carouselContainer: {
      height: 'calc(100vh - var(--content-pane-height) - var(--portrait-header-height) - var(--footer-height) - var(--accessibility-footer) - 130px)',

      //height: '100%',//'calc(720px - var(--accessibility-footer))',
      display: 'flex',
      flexWrap: 'nowrap',
      overflow: 'hidden',
      margin: '0 auto',
      color: 'black'
    },
    carouselItem: {
      minWidth: 780,
      padding: '0px 16.5px 10px 16.5px',
      display: 'flex',
      alignItems: 'self-start',
      justifyContent: 'center',
      transition: '1s cubic-bezier(0.39, 0.575, 0.565, 1)',
      backgroundColor: '#ffffff',
    },
    itemWithoutLeft: {
      margin: 0,
      marginLeft: 'calc((100vw - 803px) / 2)',
    },
    unitCard: {
      color: props => props.ThemeData.colours.black,
      width: '100%',
    },
    unitCardWithOpacity: {
      opacity: 0.3
    }
  },
  {
    name: 'UnitCardsParentCarousel',
  },
);

const RenderUnitCardsParentCarousel = (project, isPortraitMode, classes, data, filteredUnitsData, error, isOpen, toggleIsOpen, hooks, props) => {
  const { ThemeData } = project;
  let selectedUnitIndex = props.selectedUnitId !== -1
    ? filteredUnitsData.findIndex(u => u.threeDId === props.selectedUnitId)
    : 0;

  const [currentIndex, setCurrentIndex] = useState(0);

  if (error) {
    console.log('RenderFloorplans', error.message);
  }

  const leftButtonDisplay = currentIndex > 0;
  const rightButtonDisplay = currentIndex < filteredUnitsData.length - 1;

  const scrollNext = () => {
    if (currentIndex === filteredUnitsData.length - 1) return;
    setCurrentIndex(currentIndex + 1);
    props.setSelectedUnitId(filteredUnitsData[currentIndex + 1].threeDId);
    if (props.setInteractModalFrom) props.setInteractModalFrom('carousel');
  };

  const scrollPrevious = () => {
    if (currentIndex === 0) return;
    setCurrentIndex(currentIndex - 1);
    props.setSelectedUnitId(filteredUnitsData[currentIndex - 1].threeDId);
    if (props.setInteractModalFrom) props.setInteractModalFrom('carousel');
  };

  useEffect(() => {
    if (selectedUnitIndex !== currentIndex) {
      setCurrentIndex(selectedUnitIndex);
    } else if (currentIndex === 0 && currentIndex < filteredUnitsData.length) {
      //props.setSelectedUnitId(filteredUnitsData[currentIndex].threeDId);
      if (props.setInteractModalFrom) props.setInteractModalFrom('carousel');
    } else if (filteredUnitsData.length > 0 && (currentIndex === -1 || currentIndex >= filteredUnitsData.length)) {
      props.setSelectedUnitId(filteredUnitsData[0].threeDId);
    }
  }, [currentIndex, filteredUnitsData, selectedUnitIndex]);
  return (
    <div className={classes.wrapper}>
      <div className={classes.filteredResultTitle}>
        <div>Showing {filteredUnitsData.length} homes</div>
      </div>
      <div className={classes.carouselContainer}>
        {props.children && filteredUnitsData &&
          (
            [...filteredUnitsData]
              .map((dataUnit, idx) => (
                <div
                  id={`unit-card-${dataUnit.id}`}
                  className={`
											${classes.carouselItem}
											${idx === 0 ? classes.itemWithoutLeft : ''}
										`}
                  key={dataUnit.id}
                  custom={dataUnit.id}
                  {...ThemeData.anim.defaultOpacityFade}
                  style={{ transform: `translate(-${currentIndex * 100}%)` }}
                  layout="position"
                >
                  <div data-unitId={dataUnit.threeDId} />
                  {props.children(
                    `${classes.unitCard} ${currentIndex === idx ? '' : classes.unitCardWithOpacity}`,
                    dataUnit,
                    idx,
                    idx === 0,
                    () => hooks.toggleSavedHome(dataUnit.id)
                  )}
                </div>
              ))
          )
        }
      </div>

      <div className={classes.navigationButtons}>
        <ButtonCircle
          ThemeData={project.ThemeData}
          widthHeight={38}
          onClick={() => scrollPrevious()}
          borderColor={leftButtonDisplay ? project.ThemeData.colours.primaryAccent : project.ThemeData.colours.grey25}
          disabled={leftButtonDisplay ? false : true}
        >
          <NavArrowLeft
            fill={leftButtonDisplay ? project.ThemeData.colours.primaryAccent : project.ThemeData.colours.grey25}
            id="content-page-navigate-left" />
        </ButtonCircle>

        <ButtonCircle
          ThemeData={project.ThemeData}
          widthHeight={38}
          onClick={() => scrollNext()}
          borderColor={rightButtonDisplay ? project.ThemeData.colours.primaryAccent : project.ThemeData.colours.grey25}
          disabled={rightButtonDisplay ? false : true}
        >
          <NavArrowRight
            fill={rightButtonDisplay ? project.ThemeData.colours.primaryAccent : project.ThemeData.colours.grey25}
            id="content-page-navigate-left" />
        </ButtonCircle>
      </div>
    </div>
  );
};

const UnitCardsParentCarousel = ({ isOpen, toggleIsOpen, ...props }) => {
  const { project, project: { filterData: data } } = props;

  const isPortraitMode = portraitMode();
  const location = useLocation();
  const classes = useStyles({ ThemeData: project.ThemeData, isPortraitMode, ...props });
  const { savedHomes, toggleSavedHome } = useSavedHomes();
  const globalUnitsFilter = useGlobalUnitsFilter();
  const siteFeatureToggles = useSiteFeatureToggles(props.project);

  let hooks = {
    savedHomes,
    toggleSavedHome,
    globalUnitsFilter,
    siteFeatureToggles,
  };

  const [filteredUnitsData, setFilteredUnitsData] = useState([]);

  useEffect(() => {
  }, [props.selectedUnitId, props.setSelectedUnitId]);

  // FIXME
  useEffect(() => {
    if (project?.units) {
      setFilteredUnitsData(globalUnitsFilter.applyFilter(
        project.settings.showUnits === false && !location.pathname.includes('/proximation')
          ? data.units
          : project.units
      ));

    }
  }, [globalUnitsFilter, project, data, location.pathname]);

  useEffect(() => {
    if (props.doSelectFirstItem) {
      if (
        !props.selectedUnitId ||
        (filteredUnitsData.length > 0 &&
          filteredUnitsData.filter(unitData => unitData.threeDId === props.selectedUnitId).length === 0)
      ) {
        props.setSelectedUnitId(filteredUnitsData[0].threeDId);
        return;
      }

    }
  });

  useEffect(() => {
    if (props.setDataUnitCustomId1) {

      if (data && props.compareUnitCustomId1) {
        props.setDataUnitCustomId1(data.units.filter(dataUnit => dataUnit.threeDId === props.compareUnitCustomId1)[0]);
        return;
      }

      props.setDataUnitCustomId1(null);
    }
  }, [props.compareUnitCustomId1, data, props]);

  useEffect(() => {
    if (props.setDataUnitCustomId2) {
      if (data && props.compareUnitCustomId2) {
        props.setDataUnitCustomId2(data.units.filter(dataUnit => dataUnit.threeDId === props.compareUnitCustomId2)[0]);
        return;
      }

      props.setDataUnitCustomId2(null);
    }
  }, [props.compareUnitCustomId2, data, props]);

  /**
   * - Scroll Unit card selected in WebGL into view if not already in view
   * - Animate card
   */
  useEffect(() => {
    if (props.setSelectedUnitData)
      props.setSelectedUnitData(filteredUnitsData.filter(unitData => unitData.threeDId === props.selectedUnitId)[0]);

    if (props.selectedUnitId && props.selectedUnitId !== -1) {
      // scroll item into view

      let directParentElm = document.getElementById('unitItemsDirectParent');
      if (!directParentElm) return;

      let unitItemElm = directParentElm.querySelector(`[data-unitId='${props.selectedUnitId}']`);
      if (!unitItemElm) return;

      unitItemElm.parentElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [props.selectedUnitId, filteredUnitsData, props]);

  return RenderUnitCardsParentCarousel(project, isPortraitMode, classes, data, filteredUnitsData, null, isOpen, toggleIsOpen, hooks, props);
};

UnitCardsParentCarousel.defaultProps = {
  dontShowFilterSort: false,
  dontShowFilter: false,
  dontShowSort: false,
  doSelectFirstItem: false,
};

export default UnitCardsParentCarousel;
