import React, { useContext } from 'react';

import { createUseStyles } from 'react-jss';
import { motion, useCycle } from 'framer-motion';
import HeaderContextData from '../data/HeaderContextData';
import HeaderComponents from './HeaderComponents';
import TopMenu from './TopMenu';
import mainMenuOpenIcon from '../img/main_menu_open.svg';
import mainMenuOpen4KIcon from '../img/main_menu_open4K.svg';
import mainMenuCloseIcon from '../img/main_menu_close.svg';
import { portraitMode } from 'util/helper';
import UnitFcnContext from 'contexts/UnitFcnContext';

export const useStyles = createUseStyles(
  {
    mainMenuButton: {
      //   border: "2px solid red",
      flex: '0 1 auto',
      width: (props) => (props.isBigScreen ? 200 : 100),
      height: (props) => (props.isBigScreen ? 200 : 100),
      cursor: 'pointer',
      background: 'no-repeat center',
      fontFamily: (props) => props.ThemeData.fonts.title,
    },
    openMainMenuButton: {
      backgroundImage: (props) =>
        props.isBigScreen
          ? `url( ${mainMenuOpen4KIcon} )`
          : `url( ${mainMenuOpenIcon} )`,
    },
    closeMainMenuButton: {
      backgroundImage: `url( ${mainMenuCloseIcon} )`,
    },
    closeMainMenuText: (props) => ({
      ...props.ThemeData.typography.nameplate,

      cursor: 'pointer',
    }),
    closeMainMenuTextButton: {
      flex: '1 0 auto',
      height: 100,
      width: 350,
      display: 'flex',
      alignItems: 'center',
    },
    mainMenu: (props) => ({
      flexDirection: 'column',
      justifyContent: 'space-between',

      position: 'absolute',
      top: props.isPortraitMode ? 0 : 0,
      bottom: props.isPortraitMode ? '' : '',
      // top: "",
      // bottom: "",
      left: 0,

      width: props.isPortraitMode ? '500px' : '490px',
      height: props.isPortraitMode ? '' : '854px',
      display: 'none',
    }),
    mainMenuContent: {},
  },
  {
    name: 'HeaderContainer',
  }
);

const variants = {
  open: {
    y: 0,
    zIndex: 100,
    display: 'block',
  },
  closed: {
    y: '-100vh',
    display: 'block',
    transitionEnd: { zIndex: -2, display: 'none' },
  },
  openPortrait: {
    y: 0,
    zIndex: 100,
    display: 'block',
  },
  closedPortrait: {
    y: '-100vh',
    display: 'block',
    transitionEnd: { zIndex: -2, display: 'none' },
  },
};

const HeaderContainer = ({ project, ...props }) => {
  const isPortraitMode = portraitMode();
  const [isOpen, toggleOpen] = useCycle(isPortraitMode, !isPortraitMode);
  const { isBigScreen } = useContext(UnitFcnContext);
  const classes = useStyles({
    ThemeData: project.ThemeData,
    isPortraitMode,
    isBigScreen,
  });
  return (
    <header>
      <motion.div
        className={classes.mainMenu}
        initial={false}
        animate={
          isPortraitMode
            ? isOpen
              ? 'closedPortrait'
              : 'openPortrait'
            : isOpen
              ? 'open'
              : 'closed'
        }
        variants={variants}
        transition={{ type: 'spring', bounce: 0.25, duration: 0.5 }}>
        <div className={`${classes.mainMenuContent}`}>
          <TopMenu
            project={project}
            isOpen={isOpen}
            toggleOpen={toggleOpen}
            toggleIsSendToEmailOpen={props.toggleIsSendToEmailOpen}
          />
        </div>
      </motion.div>
      <HeaderComponents
        headerContext={HeaderContextData.siteHeaderContext}
        toggleIsSavedHomesSlideoutOpen={() =>
          props.toggleIsSavedHomesSlideoutOpen()
        }
        toggleIsRegisterOpen={() => props.toggleIsRegisterOpen()}
        backgroundColor={project.ThemeData.colours.headerBg}
        siteLogo={props.siteLogo}
        location={props.location}
        project={project}>
        {project.settings.showHamburgerMenu && (
          <>
            {isOpen && !isPortraitMode ? (
              <div className={`${classes.closeMainMenuTextButton}`}>
                <div
                  className={`${classes.mainMenuButton} ${classes.closeMainMenuButton}`}
                  onClick={() => toggleOpen((isOpen) => !isOpen)}
                />
                <div
                  className={`${classes.closeMainMenuText}`}
                  onClick={() => toggleOpen((isOpen) => !isOpen)}>
                  <p>Close Menu</p>
                </div>
              </div>
            ) : (
              <div
                className={`${classes.mainMenuButton} ${classes.openMainMenuButton}`}
                onClick={() => toggleOpen((isOpen) => !isOpen)}
              />
            )}
          </>
        )}
      </HeaderComponents>
    </header>
  );
};

export default HeaderContainer;
