import { useContext, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Link, useHistory, useLocation } from 'react-router-dom';
import SavedHomesTitle from './SavedHomesTitle';
import { ReactComponent as BackArrow } from '../img/back-arrow.svg';
import { portraitMode, convertSlug } from 'util/helper';
import UnitFcnContext from 'contexts/UnitFcnContext';

const BreadCrumbNav = (props) => {
  const { ThemeData } = props.project;
  const isPortraitMode = portraitMode();
  const { isBigScreen } = useContext(UnitFcnContext);
  const useStyles = createUseStyles(
    {
      header: {
        zIndex: '100',
        display: 'flex',
        justifyContent: 'space-between',
        height: isBigScreen
          ? 'calc( var(--header-height) + var(--header-height))'
          : 'var( --header-height )',

        background:
          ThemeData.colours.headerBg || ThemeData.colours.primaryHeaderBg,
        position: 'relative',
      },
      headerRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        marginLeft:'88%',
        ...ThemeData.typography.nameplate,
      },
      headerMiddle: {
        position: 'absolute',
        left: '29%',
        display: 'flex',
        height: isBigScreen
          ? 'calc(2 * var(--header-height) )'
          : 'var( --header-height )',
        background: 'no-repeat center',
        width: '46vw',
        backgroundImage: `url( ${props.siteLogo} )`,
        backgroundSize: isPortraitMode
          ? isBigScreen
            ? 'auto 328px'
            : 'auto 164px'
          : 'contain',

        cursor: 'pointer',
      },
      mainMenuButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: ThemeData.colours.white,
        minWidth: isBigScreen ? 200 : 100,
        height: isBigScreen
          ? 'calc(2 * var(--header-height))'
          : 'var(--header-height)',
        // height:100,
        cursor: 'pointer',
        background: 'no-repeat center',
        fontSize: isBigScreen ? 40 : 20,
        // backgroundSize:'40px',
        fontFamily: ThemeData.fonts.title,
        borderRight: `1px ${ThemeData.colours.white50} solid`,
        ...ThemeData.ux.fillToLeft,
      },
      breadCrumbsWrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        '& > ol > li': {
          marginLeft: 60,
          fontFamily: ThemeData.fonts.title,
        },
      },
      register: {
        flex: '0 0 auto',
        width: 175,
        lineHeight: '100px',
        ...ThemeData.ux.fillToRight,
        borderLeft: `1px ${ThemeData.colours.white50} solid`,
        cursor: 'pointer',
        color: ThemeData.colours.white,
        '& p': {
          margin: 0,
        },
      },
      headerRightItem: {
        height: 'calc( var( --header-height ) - 1px )',
        // lineHeight: "var( --header-height )",
        '& > p': {
          margin: 0,
        },
      },
      savedHomes: {
        flex: '0 0 auto',
        width: 175,
        marginRight: 50,
        cursor: 'pointer',
        color: ThemeData.colours.white,
      },
      lastListItem: {
        color: `${ThemeData.colours.white} !important`,
        borderBottom: `3px solid ${ThemeData.colours.primaryAccent}`,
      },
      fadeItem: {
        color: `${ThemeData.colours.white50} !important`,
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    { name: 'BreadCrumbNav' }
  );

  const location = useLocation();
  const history = useHistory();

  const proximationPathLocation = location.pathname.slice(0, -1);

  const [_, setNavState] = useState({
    [proximationPathLocation]: convertSlug(props.project.slug),
  });
  const [pathnames, setPathNames] = useState(['proximation']);

  useEffect(() => {
    props.unitDetailOpen &&
    props.selectedUnitData &&
    props.selectedUnitData &&
    setNavState({
      [proximationPathLocation]: convertSlug(props.project.slug),
      [`${location.pathname}plan${props.selectedUnitData.floorPlan.name}`]: `Plan ${props.selectedUnitData.floorPlan.name}`,
    });
    props.unitDetailOpen &&
    props.selectedUnitData &&
    setPathNames([
      'proximation',
      `plan${props.selectedUnitData.floorPlan.name}`,
    ]);
  }, [props.selectedUnitData, props.unitDetailOpen]);

  const classes = useStyles();
  return (
    <header className={`${classes.header}`}>
      <>
        <Link
          to="/"
          onClick={() =>
            props.setIsUnitDetailOpen && props.setIsUnitDetailOpen(false)
          }
          className={`${classes.headerSection} ${classes.headerMiddle}`}></Link>
      </>

      <div className={`${classes.headerSection} ${classes.headerRight}`}>
        {/* <SavedHomesTitle
          project={props.project}
          height={100}
          className={`${classes.savedHomes} ${classes.headerRightItem}`}
          onClick={() => props.toggleIsSavedHomesSlideoutOpen()}
        /> */}
        <div style={{ width: 175 }}>
          {!isPortraitMode && (
            <div
              className={`${classes.register} ${classes.headerRightItem}`}
              onClick={() => props.toggleIsRegisterOpen()}>
              <p id="breadcrumb-register">Register</p>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default BreadCrumbNav;
