import React from 'react';

import {
  Link,
} from 'react-router-dom';

import {
  createUseStyles,
} from 'react-jss';

import {
  // motion
} from 'framer-motion';

import ButtonOutline from 'components/ButtonOutline';

import { portraitMode } from 'util/helper';
import { getImageBySize } from 'util/imgFunctions';
import UnitDetailCompareCardMini from './UnitDetailCompareCardMini';

const useStyles = createUseStyles(
  {
    wrapper: props => ({
      position: 'relative',
      width: props.isCompareHomes ? 470 : (props.isPortraitMode ? 300 : 575),
      height: props.isPortraitMode ? (props.isSaveHome ? 327 : 951) : 'none',
      margin: props.isPortraitMode ? (props.isSaveHome ? 23 : 0) : 0,
      ...props.ThemeData.styles.hoverCard,
      borderRadius: props.isPortraitMode ? '10px' : '0',
      boxShadow: props.isPortraitMode ? 'none' : '0px 4px 10px rgb(0 0 0 / 33%)',

    }),
    contentContainer: props => ({
      minHeight: props.isPortraitMode ? 0 : 530,
      maxHeight: props.isPortraitMode ? '' : 590,
      display: 'flex',
      flexDirection: 'column',
      boxSizing: props.isPortraitMode ? '' : 'border-box',
      padding: '10px 20px',
      textAlign: 'left',
      marginBottom: props.isPortraitMode && props.isSaveHome ? 5 : 'none',
    }),
    hr: {
      width: '100%',

      marginTop: 35,

      borderTop: props => props.isCompareHomes ? 'none' : `1px ${props.ThemeData.colours.eggshellTextBg} solid`,
      '@media (max-width: 1200px)': {
        marginTop: 10
      }
    },
    removeHomeButton: props => ({
      position: 'absolute',
      top: props.isPortraitMode ? 10 : 20,
      right: props.isPortraitMode ? 7 : 20,

      width: props.isPortraitMode ? 26 : 48,
    }),
    floorplanContainer: props => ({
      position: 'relative',
      flex: (props.isPortraitMode && !props.isSaveHome) ? '' : '0 0 auto',
      height: props.isPortraitMode ? (props.isSaveHome ? 131 : 540) : 300,
      width: props.isPortraitMode ? (props.isSaveHome ? 260 : 430) : 'auto',
      marginLeft: props.isPortraitMode ? 0 : 0,
      marginRight: props.isPortraitMode ? 0 : 0,
      borderRadius: 4,
      marginBottom: props.isPortraitMode ? (props.isCompareHomes ? 10 : 5) : 0,
      justifyContent: 'center'
    }),
    floorplanImg: props => ({
      height: props.isPortraitMode ? (props.isSaveHome ? '100%' : 480) : '100%',
      width: '100%',

      objectFit: 'contain',
    }),
    planButtonsContainer: {
      marginTop: 10,
      '@media (max-width: 1200px)': {
        marginTop: 0
      }
    },
    floorKeyPlanButtons: {
      borderRadius: 50,

      '@media (min-width: 3800px)': {
        fontSize: '24px',
        height: '60px'
      },
    },
    info: {
      flex: props => props.isPortraitMode ? '0.02 0 auto' : '1 0 auto',
    },
    unitInfoPlanNameTitle: {
      marginTop: 0,

    },
    unitInfoLine: {
      color: props => props.ThemeData.colours.black,

      fontSize: props => props.isPortraitMode ? (props.isSaveHome ? 12 : 24) : 16,
      '@media (min-width: 3800px)': {
        fontSize: '36px',
        height: '70px'
      },
    },
    unitInfoIconList: {
      color: props => props.ThemeData.colours.black,

    },
    viewDetailButtonArea: {
      paddingTop: 10,
    },
    removeCompareButton: {
      borderRadius: '0 0 10px 10px',
      color: props => props.ThemeData.colours.white
    },
    addCompareButton: {
      borderTop: props => `1px solid ${props.ThemeData.colours.vibrantGreen}`,
      // color: "white",
      borderRadius: '0 0 10px 10px',
    },
    floorplanFrame: {
      position: 'relative',
    },
    imageScrollerLeft: {
      position: 'absolute',
      top: '50%'
    },
    imageScrollerRight: {
      position: 'absolute',
      top: '50%',
      right: 0,
    },
    floorLabel: {
      textAlign: 'center',
      fontSize: 12,
      fontWeight: 700,
    },
    discoverButton: props => ({
      padding: '19px 0',
      border: `2px solid ${props.ThemeData.colours.vibrantGreen}`,
      borderRadius: '10px',
      textTransform: 'capitalize',
      bottom: 10,
    })
  },
  {
    name: 'SaveHomesBrowseCard',
  }
);

// data == a single Unit
const SaveHomesBrowseCard = ({ project, dataUnit, ...props }) => {
  const { ThemeData } = project;
  const classes = useStyles({ ThemeData, isPortraitMode: portraitMode(), isSaveHome: props.isSaveHome, isCompareHomes: props.isCompareHomes });
  const renderImage = () => {
    const siteplan = project.navs.filter(nav => nav.slug === 'floorplans')[0]?.pages.filter(page => page.slug === 'site-plan')[0];
    const photo = siteplan && getImageBySize(siteplan.photo, 'original');
    return photo && <img src={photo.link} style={{ objectFit: 'contain', display: 'block', maxWidth: '100%', maxHeight: '100%', backgroundSize: 'cover', }} alt="Site Plan" />;
  };

  return (
    <>
      <div className={`${classes.wrapper} ${props.className}`}>
        <div className={classes.contentContainer}>
          {props.isSaveHome &&
            <>
              <div style={{ width: '100%', height: 250, display: 'flex', justifyContent: 'center' }}>
                {renderImage()}
              </div>
              <Link to={'/proximation'}>
                <ButtonOutline
                  ThemeData={project.ThemeData}
                  id="discover-homes-button"
                  className={classes.discoverButton}
                  borderColour={project.ThemeData.colours.vibrantGreen}
                  textColour={project.ThemeData.colours.vibrantGreen}
                  backgroundColour={project.ThemeData.colours.white}
                  hoverBackgroundColour={project.ThemeData.colours.white}
                  hoverTextColour={project.ThemeData.colours.vibrantGreen}
                  onClick={() => props.toggleIsOpen()}
                >
                  Discover Homes
                </ButtonOutline>
              </Link>
            </>
          }
          {props.isCompareHomes &&
            <div style={{ height: 830, overflowY: 'auto', width: '100%', paddingLeft: '5px' }}>
              <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', rowGap: '30px', columnGap: '10px' }}>
                {project.units && props.savedHomes.map(savedHomeUnit => {
                  let unitIdx = project.units.findIndex(elm => elm.id === savedHomeUnit.id);

                  if (unitIdx !== -1 && !props.compareHomesIncludes(project.units[unitIdx].id)) {
                    return <div key={`unit-${savedHomeUnit.id}`} style={{ paddingTop: '10px', textAlign: 'center' }}>
                      <UnitDetailCompareCardMini
                        key={savedHomeUnit.id}
                        project={project}
                        dataUnit={project.units[unitIdx]}
                        compareHomesIncludes={props.compareHomesIncludes}
                        compareHomes={props.compareHomes}
                        toggleCompareHome={props.toggleCompareHome}
                        toggleSavedHome={props.toggleSavedHome}
                      />
                    </div>;
                  }

                  return null;
                })}
                {
                  project.floorPlans && props.savedHomes.map(savedHomeUnit => {
                      let floorplanIdx = project.floorPlans.findIndex(elm => elm.id === savedHomeUnit.id);
                      if (floorplanIdx !== -1 && !props.compareHomesIncludes(project.floorPlans[floorplanIdx].id)) {
                        return <div key={`floorplan-${savedHomeUnit.id}`} style={{ paddingTop: '10px', textAlign: 'center' }}>
                          <UnitDetailCompareCardMini
                            project={project}
                            dataUnit={project.floorPlans[floorplanIdx]}
                            compareHomesIncludes={props.compareHomesIncludes}
                            compareHomes={props.compareHomes}
                            toggleCompareHome={props.toggleCompareHome}
                          />

                        </div>;
                      }
                      return <></>;

                    }
                  )
                }

              </div>
              <div style={{ position: 'absolute', bottom: '30px', width: '261px', height: '51px', marginLeft: 90 }}>
                <Link to={'/proximation'}>
                  <ButtonOutline
                    ThemeData={project.ThemeData}
                    id="discover-homes-button"
                    className={classes.discoverButton}
                    borderColour={project.ThemeData.colours.vibrantGreen}
                    textColour={project.ThemeData.colours.vibrantGreen}
                    backgroundColour={project.ThemeData.colours.white}
                    hoverBackgroundColour={project.ThemeData.colours.white}
                    hoverTextColour={project.ThemeData.colours.vibrantGreen}
                    onClick={() => props.toggleIsOpen()}
                  >
                    Discover Homes
                  </ButtonOutline>
                </Link>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default SaveHomesBrowseCard;
