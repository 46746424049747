import React, {} from 'react';

import {
  createUseStyles,
} from 'react-jss';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonOutline from './ButtonOutline';
import DialogGeneric from './DialogGeneric';
import { portraitMode } from 'util/helper';

const useStyles = createUseStyles(
  {
    clearSavedHomesConfirmationDialogTitle: props => ({
      color: props.ThemeData.colours.primaryFontColour,
      backgroundColor: props.isPortraitMode ? props.ThemeData.colours.white : props.ThemeData.colours.primaryBg,
      textTransform: 'uppercase',

      fontFamily: props.ThemeData.fonts.title,
      fontSize: 20,
    }),
    clearConfirmationTitle: {
      fontFamily: props => props.ThemeData.fonts.title,
      color: props => props.isPortraitMode ? props.ThemeData.colours.primaryFontColour : props.ThemeData.colours.white,
    },
    clearSavedHomesConfirmationDialogBody: props => ({
      color: props.ThemeData.colours.primaryFontColour,
      backgroundColor: props.isPortraitMode ? props.ThemeData.colours.white : props.ThemeData.colours.primaryBg,

      fontFamily: props.ThemeData.fonts.copy,
      fontSize: 14,
      '&& > p': {
        color: `${props.ThemeData.colours.primaryFontColour} !important`
      }
    }),
    clearConfirmationBody: {
      fontFamily: props => props.ThemeData.fonts.copy,
      color: props => props.isPortraitMode ? props.ThemeData.colours.primaryFontColour : props.ThemeData.colours.white,
    },
    clearSavedHomesConfirmationDialogButtons: props => ({
      borderRadius: props.isPortraitMode ? 15 : 5,
      fontFamily: props.ThemeData.fonts.title,
    }),
    clearSavedHomesConfirmationDialogBg: {
      background: props => props.isPortraitMode ? '' : props.ThemeData.colours.black,
      borderRadius: '0 !important',
    },
    clearConfirmationButton: {
      color: props => props.ThemeData.colours.primaryFontColour,
      fontFamily: props => props.ThemeData.fonts.title,
    }
  },
  { name: 'ClearConfirmationDialogGeneric' }
);

const ConfirmationDialogGeneric = ({ data, project, ...props }) => {
  const isPortraitMode = portraitMode();
  const classes = useStyles({ ...props, ThemeData: project.ThemeData, isPortraitMode });
  const confirmFormRef = props.confirmFormRef;

  return (
    <DialogGeneric
      isDialogOpen={props.isClearConfirmationDialogOpen}
      setIsDialogOpenToClosed={props.setIsClearConfirmationDialogOpenToClosed}
      ariaLabelledBy="confirm-dialog-title"
      ariaDescribedBy="confirm-dialog-description"

    >
      <DialogTitle
        id="confirm-dialog-title"
        className={classes.clearSavedHomesConfirmationDialogTitle}
      >
        <div className={classes.clearConfirmationTitle}>{`Clear ${props.dataTypeToClearName} Confirmation`}</div>
      </DialogTitle>
      <DialogContent
        className={classes.clearSavedHomesConfirmationDialogBody}
      >
        <DialogContentText
          id="confirm-dialog-description"
        >
          <div className={classes.clearConfirmationBody}>Are you sure you would like to clear your {props.dataTypeToClearName.toLowerCase()}?</div>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        className={`${classes.clearSavedHomesConfirmationDialogButtons} ${classes.clearSavedHomesConfirmationDialogBg}`}
      >
        <div ref={confirmFormRef}>
          <ButtonOutline
            className={classes.clearSavedHomesConfirmationDialogButtons}
            onClick={props.setIsClearConfirmationDialogOpenToClosed}
            ThemeData={project.ThemeData}
            borderColour={isPortraitMode ? project.ThemeData.colours.primaryAccent : project.ThemeData.colours.mainBorder}
            textColour={project.ThemeData.colours.white}
            hoverTextColour={isPortraitMode ? project.ThemeData.colours.vibrantGreen : project.ThemeData.colours.white}
            backgroundColour={project.ThemeData.colours.vibrantGreen}
            hoverBackgroundColour={isPortraitMode ? project.ThemeData.colours.white : project.ThemeData.colours.black}
            textTransform={'capitalized'}
            padding={16}
            fontSize={12}
          >
            Don't Clear
          </ButtonOutline>
        </div>
        <div ref={confirmFormRef}>
          <ButtonOutline
            className={classes.clearSavedHomesConfirmationDialogButtons}
            onClick={() => {
              props.afterClearConfirmationPerformFunction();
              props.setIsClearConfirmationDialogOpenToClosed();
            }}
            ThemeData={project.ThemeData}
            borderColour={isPortraitMode ? project.ThemeData.colours.primaryAccent : project.ThemeData.colours.mainBorder}
            textColour={project.ThemeData.colours.white}
            hoverTextColour={isPortraitMode ? project.ThemeData.colours.vibrantGreen : project.ThemeData.colours.white}
            backgroundColour={project.ThemeData.colours.vibrantGreen}
            hoverBackgroundColour={isPortraitMode ? project.ThemeData.colours.white : project.ThemeData.colours.black}
            textTransform={'capitalized'}
            padding={16}
            fontSize={12}

          >
            Clear {props.dataTypeToClearName}
          </ButtonOutline>
        </div>
      </DialogActions>
    </DialogGeneric>
  );
};

export default ConfirmationDialogGeneric;
