import React, { useContext, useEffect, useState } from "react";
import ButtonOutline from "components/ButtonOutline";
import ButtonGroup from "./ButtonGroup";
import InputCheckedList from "./InputCheckedList";
import InputRangeSlider from "./InputRangeSlider";

import ConstantData from "data/ConstantData";

import { FaCaretDown } from "react-icons/fa";

import { useLocation } from "react-router-dom";

import { createUseStyles } from "react-jss";
import useGlobalUnitsFilter from "hooks/useGlobalUnitsFilter";
import { InputAdornment } from "@mui/material";
import { filterableSourcesHeaderButtons } from "data/FilterData";
import { portraitMode } from "util/helper";
import { ReactComponent as ArrowUp } from "../img/arrow_up.svg";
import { ReactComponent as CloseIcon } from "../img/icon_white_close_button.svg";
import UnitFcnContext from "contexts/UnitFcnContext";

const useStyles = createUseStyles(
  {
    filterHeaderWrapper: (props) => ({
      zIndex: 2,
      position: "relative",
      padding: props.isPortraitMode ? 0 : "18px 0 14px 0",
      backgroundColor: props.isPortraitMode
        ? props.ThemeData.colours.white
        : props.ThemeData.colours.white,
      height: props.isPortraitMode ? 100 : "auto",
      display: "flex",
      alignItems: "center",
    }),
    filterButtons: {
      flex: "0 0 auto",
      padding: "0 20px",
    },
    filterButton: (props) => ({
      display: "flex",
      borderRadius: props.isPortraitMode ? 15 : 100,
    }),
    filterList: {
      color: (props) => props.ThemeData.colours.primaryFont,
    },
    filterRange: (props) => ({
      color: props.ThemeData.colours.secondaryFont,
      backgroundColor: props.ThemeData.colours.white,
    }),
    allFilters: (props) => ({
      "& > div": {
        border: `1px solid ${props.ThemeData.colours.vibrantGreen}`,
        padding: "16px",
        borderRadius: 100,
        color: props.ThemeData.colours.vibrantGreen,
        backgroundColor: "transparent",
        fontFamily: props.ThemeData.fonts.title,
        letterSpacing: "0.025em",
        marginRight: 10,
        textTransform: "uppercase",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: props.ThemeData.colours.vibrantGreen,
          color: props.ThemeData.colours.white,
        },
      },
    }),
    allFiltersPortrait: (props) => ({
      borderRight: `2px solid ${"#EEEDE6"}`,
      "& > div": {
        border: `1px solid ${
          props.ThemeData.colours.filterColourPortrait
            ? props.ThemeData.colours.filterColourPortrait
            : props.ThemeData.colours.vibrantGreen
        }`,
        // border: `1px solid ${props.ThemeData.colours.vibrantGreen}`,
        padding: "10px 16px",
        borderRadius: 15,
        color: props.ThemeData.colours.filterColourPortrait
          ? props.ThemeData.colours.filterColourPortrait
          : props.ThemeData.colours.vibrantGreen,
        // color: props.ThemeData.colours.vibrantGreen,
        backgroundColor: "transparent",
        fontFamily: props.ThemeData.fonts.title,
        letterSpacing: "0.025em",
        marginRight: 10,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: `rgba(
                    ${parseInt(
                      props.ThemeData.colours.vibrantGreen.slice(1, 3),
                      16
                    )},
                    ${parseInt(
                      props.ThemeData.colours.vibrantGreen.slice(3, 5),
                      16
                    )},
                    ${parseInt(
                      props.ThemeData.colours.vibrantGreen.slice(5, 7),
                      16
                    )},
                    0.16
                )`,
        },
        "&:active": {
          boxShadow: `
                    inset 4px 4px 4px rgba(
                        ${parseInt(
                          props.ThemeData.colours.vibrantGreen.slice(1, 3),
                          16
                        )},
                        ${parseInt(
                          props.ThemeData.colours.vibrantGreen.slice(3, 5),
                          16
                        )},
                        ${parseInt(
                          props.ThemeData.colours.vibrantGreen.slice(5, 7),
                          16
                        )},
                        0.25
                    )
                `,
        },
      },
      "& > svg": {
        "& :hover": {
          stroke: props.ThemeData.colours.vibrantGreen,
        },
      },
    }),
    activeFilters: (props) => ({
      display: "flex",
      "& div": {
        borderRadius: 15,
        marginRight: 10,
        border: `1px solid ${props.ThemeData.colours.vibrantGreen}`,
        backgroundColor: props.ThemeData.colours.vibrantGreen,
        color: props.ThemeData.colours.white,
        padding: "9px 14px",
        fontFamily: props.ThemeData.fonts.title,
        letterSpacing: "0.025em",
        cursor: "pointer",
        boxShadow: `inset -4px -4px 4px rgba(0, 0, 0, 0.25)`,
        "&:hover": {
          boxShadow: "none",
          filter: "brightness(0.85)",
        },
        "&:active": {
          filter: "brightness(0.85)",
          boxShadow: "inset 4px 4px 4px rgba(0, 0, 0, 0.25)",
        },
      },
    }),
    clearAllButton: (props) => ({
      "&:hover": {
        "& svg": {
          fill: props.ThemeData.colours.vibrantGreen,
          stroke: props.ThemeData.colours.vibrantGreen,
        },
      },
    }),
  },
  { name: "FilterHeader" }
);

const FilterHeader = (props) => {
  const {
    interactModalFrom,
    setInteractModalFrom,
    setSelectedUnitId,
    activeUnit,
    isIndustrial,
    project: {
      filterData,
      ThemeData,
      settings: { showUnits },
      filterSettings: { unitFilter: unitFilterSettings, hideFloorPlanFilter },
    },
  } = props;
  const isPortraitMode = portraitMode();
  const classes = useStyles({ ThemeData, isPortraitMode });
  const [activeFilters, setActiveFilters] = useState([]);

  const {
    // currentActiveFilters,
    setCurrentActiveFilters,
    setFilteredUnitsDataContext,
    filteredUnitsDataContext,
  } = useContext(UnitFcnContext);

  const globalUnitsFilter = useGlobalUnitsFilter();
  let hooks = {
    globalUnitsFilter,
    setInteractModalFrom,
    setSelectedUnitId,
  };

  const location = useLocation();
  const [isHover] = useState(null);
  // const [filteredUnitsData] = useState([]);
  const useUnitFilters =
    props.isSiteMapPage ||
    showUnits ||
    !location.pathname.includes("/proximation");

  useEffect(() => {
    const filters = hooks.globalUnitsFilter.globalUnitsFilter.filter;
    const newArr = [];
    if (filters) {
      Object.keys(filters).forEach((filterName) => {
        if (!filterName.includes("price") && !filterName.includes("size")) {
          Object.keys(filters[filterName]).forEach((key) => {
            const filter = filters[filterName][key];
            if (filter.doFilterByOr) {
              const button = filterableSourcesButtons.find(
                (btn) => btn.unitRelationDataType === filterName
              );
              if (button) {
                const existingItem = newArr.find(
                  (item) =>
                    item.filterType === button.unitRelationDataType ||
                    item.label === button.label
                );
                if (!existingItem) {
                  newArr.push({
                    label: button.label,
                    filterType: button.unitRelationDataType,
                  });
                }
              }
            }
          });
        } else {
          const existingItem = newArr.find(
            (item) => item.filterType === ("price" | "size")
          );
          if (!existingItem)
            newArr.push({ label: filterName, filterType: filterName });
        }
      });
      setActiveFilters(newArr);
    }
  }, [hooks.globalUnitsFilter.globalUnitsFilter.filter, setActiveFilters]);

  useEffect(() => {
    setCurrentActiveFilters(activeFilters);
  }, [activeFilters]);

  const hideAllFilters = location.pathname.includes("/proximation")
    ? false
    : hideFloorPlanFilter;
  if (props.hideAllFilters)
    return <div className={classes.filterHeaderWrapper}></div>;
  let filterableSourcesButtons =
    filterableSourcesHeaderButtons(unitFilterSettings);

  const checkIsActive = (filter) => {
    if (filter === "price" || filter === "size") {
      return (
        hooks.globalUnitsFilter.globalUnitsFilter.filter &&
        hooks.globalUnitsFilter.globalUnitsFilter.filter[filter] &&
        (hooks.globalUnitsFilter.globalUnitsFilter.filter[filter].low !==
          filterableSourcesButtons.filter(
            (item) => item.unitRelationDataType === filter
          )[0].rangeMinimumValue ||
          hooks.globalUnitsFilter.globalUnitsFilter.filter[filter].high !==
            filterableSourcesButtons.filter(
              (item) => item.unitRelationDataType === filter
            )[0].rangeMaximumValue)
      );
    }
    return (
      hooks.globalUnitsFilter.globalUnitsFilter.filter !== undefined &&
      hooks.globalUnitsFilter.globalUnitsFilter.filter[filter] &&
      Object.keys(
        hooks.globalUnitsFilter.globalUnitsFilter.filter[filter]
      ).filter(
        (key) =>
          hooks.globalUnitsFilter.globalUnitsFilter.filter[filter][key]
            .doFilterByOr === true
      ).length > 0
    );
  };

  const calculateFilterButtonName = (
    data,
    filterLabel,
    currentLabel,
    inputType
  ) => {
    const filters = hooks.globalUnitsFilter.globalUnitsFilter.filter;
    const labelArray =
      filters &&
      filters[filterLabel] &&
      Object.keys(filters[filterLabel]).filter(
        (item) => filters[filterLabel][item].doFilterByOr === true
      );
    const filteredOptions =
      labelArray &&
      labelArray.length > 0 &&
      data.filter((item) => item.id === labelArray[0]);

    if (labelArray && labelArray.length > 1 && filteredOptions.length > 0)
      return `${
        currentLabel === "Orientation"
          ? filteredOptions[0].direction
          : filteredOptions[0].name
      } and  ${labelArray.length - 1} more`;

    if (labelArray && labelArray.length === 1 && filteredOptions.length > 0)
      return currentLabel === "Orientation"
        ? filteredOptions[0].direction
        : filteredOptions[0].name;

    if (
      filters &&
      filters[filterLabel] &&
      inputType === ConstantData.unitFilterTypes.range &&
      currentLabel === "Price"
    )
      return `$${filters[filterLabel].low / 1000}K - $${
        filters[filterLabel].high / 1000
      }K`;

    if (
      filters &&
      filters[filterLabel] &&
      inputType === ConstantData.unitFilterTypes.range &&
      currentLabel === "Size"
    )
      return `${filters[filterLabel].low} sqft - ${filters[filterLabel].high} sqft`;

    return currentLabel;
  };

  if (useUnitFilters) {
    let newFilterableSourcesButtons = [];
    const unableFilterButtons = ["availability", "price", "orentation"];
    filterableSourcesButtons.forEach((b) => {
      if (!unableFilterButtons.includes(b.id)) {
        newFilterableSourcesButtons.push(b);
      }
    });
    filterableSourcesButtons = newFilterableSourcesButtons;
  }
  const showClearFilters = !filterableSourcesButtons.every(
    (filterableSource) => !checkIsActive(filterableSource.unitRelationDataType)
  );
  const clearFilters = () => {
    if (hooks.setSelectedUnitId) hooks.setSelectedUnitId(-1);
    hooks.globalUnitsFilter.resetFilter();
  };

  const removeFilter = (filter) => {
    hooks.globalUnitsFilter.deactivateFilter(filter.filterType);
    const newArr = activeFilters.filter(
      (f) => f.filterType !== filter.filterType
    );

    setActiveFilters(newArr);
  };

  return (
    <div className={classes.filterHeaderWrapper}>
      <ButtonGroup className={classes.filterButtons} justifyContent={"left"}>
        {!props.hideFilter &&
          filterableSourcesButtons
            .filter(
              (filterableSource) =>
                (filterableSource.filterType ===
                  ConstantData.unitFilterTypes.checkedList &&
                  filterData[filterableSource.unitRelationDataType] &&
                  filterData[filterableSource.unitRelationDataType].length >
                    0) ||
                filterableSource.filterType ===
                  ConstantData.unitFilterTypes.range
            )
            .map((filterableSource, idx) => {
              return (
                <ButtonOutline
                  ThemeData={ThemeData}
                  key={idx}
                  id={`filter-button-${idx}`}
                  textColour={ThemeData.colours.secondaryFont}
                  hoverTextColour={ThemeData.colours.white}
                  backgroundColour={ThemeData.colours.white}
                  hoverBackgroundColour={ThemeData.colours.primaryAccent}
                  borderColour={ThemeData.colours.eggshellTextBg}
                  className={classes.filterButton}
                  isActive={checkIsActive(
                    filterableSource.unitRelationDataType
                  )}
                  hideFilter={props.hideFilter}
                  popupWindow={
                    filterData && (
                      <div className={classes.filterList}>
                        {filterableSource.filterType ===
                          ConstantData.unitFilterTypes.checkedList && (
                          <InputCheckedList
                            ThemeData={ThemeData}
                            className={"inputchecklist"}
                            key={idx}
                            data={
                              filterData &&
                              filterData[filterableSource.unitRelationDataType]
                            }
                            listLabelField={
                              filterableSource.dataListItemLabelField
                            }
                            filterType={filterableSource.unitRelationDataType}
                            hooks={hooks}
                            filterSettings={
                              hooks.globalUnitsFilter.globalUnitsFilter.filter
                            }
                            setFilterSettings={
                              hooks.globalUnitsFilter.setFilter
                            }
                            modelType={props.modelType}
                            interactModalFrom={interactModalFrom}
                          />
                        )}
                        {filterableSource.filterType ===
                          ConstantData.unitFilterTypes.range && (
                          <InputRangeSlider
                            ThemeData={ThemeData}
                            className={classes.filterRange}
                            // label={filterableSource.label}
                            filterType={filterableSource.unitField}
                            min={filterableSource.rangeMinimumValue}
                            max={filterableSource.rangeMaximumValue}
                            step={filterableSource.rangeStep}
                            displayedDataFormattingFunction={
                              filterableSource.displayedDataFormattingFunction
                            }
                            filterSettings={
                              hooks.globalUnitsFilter.globalUnitsFilter.filter
                            }
                            setFilterSettings={
                              hooks.globalUnitsFilter.setFilter
                            }
                            modelType={props.modelType}
                            inputLabels={["min", "max"]}
                            adornment={
                              filterableSource.unitField === "price" && (
                                <InputAdornment position='start'>
                                  $
                                </InputAdornment>
                              )
                            }
                            isClearButtonVisible={true}
                            interactModalFrom={interactModalFrom}
                          />
                        )}
                      </div>
                    )
                  }
                  firstInColl={idx === 0 ? "true" : null}>
                  {calculateFilterButtonName(
                    filterData[filterableSource.unitRelationDataType],
                    filterableSource.unitRelationDataType,
                    filterableSource.label,
                    filterableSource.filterType
                  )}
                  &nbsp;
                  <FaCaretDown />
                </ButtonOutline>
              );
            })}
        {isPortraitMode && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingRight: 10,
            }}>
            <span>
              Showing{" "}
              {location.pathname.includes("/floor-plans", "/floorplans") 
                ? filteredUnitsDataContext
                : activeUnit?.length > 0
                ? activeUnit.length
                : 0}{" "}
              {isIndustrial
                ? activeUnit?.length === 1
                  ? "unit"
                  : "units"
                : filteredUnitsDataContext === 1
                ? "home"
                : "homes"}
            </span>
          </div>
        )}
        {(!hideAllFilters || isPortraitMode) && (
          <div
            className={
              isPortraitMode ? classes.allFiltersPortrait : classes.allFilters
            }>
            <div onClick={() => props.setDoShowFilter(!props.doShowFilter)}>
              All filters
              {isPortraitMode && (
                <ArrowUp
                  fill={
                    ThemeData.colours.filterColourPortrait
                      ? ThemeData.colours.filterColourPortrait
                      : ThemeData.colours.vibrantGreen
                  }
                  style={{ marginLeft: 10, marginTop: 4 }}
                />
              )}
            </div>
          </div>
        )}
        {showClearFilters && !isPortraitMode && (
          <ButtonOutline
            ThemeData={ThemeData}
            id='clear-filters-button'
            padding={16}
            textColour={ThemeData.colours.primaryAccent}
            textDecoration='underline'
            hoverTextColour={ThemeData.colours.vibrantGreen}
            backgroundColour={
              isPortraitMode
                ? ThemeData.colours.vibrantGreen
                : ThemeData.colours.white
            }
            hoverBackgroundColour={ThemeData.colours.white}
            border='none'
            onClick={() => clearFilters()}>
            Clear Filters
          </ButtonOutline>
        )}
        {isPortraitMode && (
          <div className={classes.activeFilters}>
            {activeFilters.map((filter) => (
              <div onClick={() => removeFilter(filter)}>
                {filter.label}
                <CloseIcon
                  fill={
                    isHover === filter.label
                      ? ThemeData.colours.vibrantGreen
                      : ThemeData.colours.white
                  }
                  stroke={
                    isHover === filter.label
                      ? ThemeData.colours.vibrantGreen
                      : ThemeData.colours.white
                  }
                  style={{ marginLeft: 8, width: 12, height: 9, paddingTop: 4 }}
                />
              </div>
            ))}
          </div>
        )}
      </ButtonGroup>
    </div>
  );
};

export default FilterHeader;
