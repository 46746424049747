import React, { useEffect, useRef } from 'react';

import {
  createUseStyles,
} from 'react-jss';

import {
  motion,
  AnimatePresence,
} from 'framer-motion';

import ThemeData from 'data/ThemeData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import useSavedHomes from 'hooks/useSavedHomes';
import useOpenClosePane from 'hooks/useOpenClosePane';

import ButtonOutline from './ButtonOutline';
import ButtonGroup from './ButtonGroup';
import ClearConfirmationDialogGeneric from './ClearConfirmationDialogGeneric';
import SavedHomesTitle from './SavedHomesTitle';
import UnitDetailListItemMini from './UnitDetailListItemMini';

import xWhiteMedium from 'img/x_white_medium.svg';
import { ReactComponent as IconTrash } from 'img/icon_trash.svg';
import { portraitMode } from 'util/helper';
import UnitDetailCompareCard from './UnitDetailCompareCard';
import useCompareHomes from 'hooks/useCompareHomes';
import accessibilityPane from 'hooks/accessibilityPane';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

const jsStyles = {
  wrapper: props => ({
    '--header-height': props.isPortraitMode ? '164px' : '100px',
    '--footer-height': '100px',
    '--portrait-header-height': '150px',
    '--navigation-height': '112px',
    '--app-height': '100%',
    '--small-widget-height': '586px',
    '--content-pane-height': '723px',
    '--accessibility-footer': (props.isPortraitMode && props.showAccessibilityFooter) ? '100px' : '0px',
    position: 'fixed',
    // top: props.isPortraitMode ? 1083 : 0,
    //top: props.isPortraitMode ? "calc(50vh + 100px + 112px)" : 0,
    // top: 'calc(100vh - var(--header-height) - var(--navigration-height))',
    bottom: props.isPortraitMode
      ? (props.isProximation
          ? 'calc(var(--footer-height) + var(--accessibility-footer) - 5px)'
          : (
            props.noNavigation
              ? 'calc(var(--footer-height) + var(--accessibility-footer))'
              : 'calc(var(--header-height) + var(--navigation-height) + var(--accessibility-footer) - 5px)'
          )
      )
      : 0,
    //borderBottom: props.isPortraitMode ? `1px solid ${props.ThemeData.colours.vibrantGreen}` : '0',
    right: 0,

    width: props.isPortraitMode ? '100%' : 450,
    height: props.isPortraitMode ? 540 : '100%',
    zIndex: 1002,
    boxSizing: props.isPortraitMode ? '' : 'border-box',
    contentVisibility: 'visible',

    color: props.isPortraitMode ? props.ThemeData.colours.black : props.ThemeData.colours.white,

    // background: props.isPortraitMode ? 'rgba(255, 255, 255, 0.50)' : props.ThemeData.colours.primaryBg,
    background: props.isPortraitMode ? ThemeData.colours.saveCompareHomesBgPortrait : props.ThemeData.colours.primaryBg,
    // FIX
    backdropFilter: 'blur(5px)',
    //...props.ThemeData.styles.hoverPaneBoxShadow,

    letterSpacing: '.025em',

  }),
  openMark: props => ({
    position: 'absolute',
    top: 30,
    left: -40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    width: 40,
    height: 40,

    backgroundColor: props.ThemeData.colours.primaryBg,

    cursor: 'pointer',

    zIndex: -1,
  }),
  bgAmp: props => ({
    zIndex: -1,
    position: 'absolute',
    left: -50,
    bottom: -100,

    color: props.ThemeData.colours.primaryAccent08,
    fontFamily: props.ThemeData.fonts.copy,
    fontSize: 500,
  }),
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    contentVisibility: 'visible',
    padding: '30px 25px 25px 25px',
    //width: '100%',
    height: props => props.isPortraitMode ? 'auto' : '100%',
  },
  closeX: {
    position: 'absolute',
    top: 20,
    right: 20,

    cursor: 'pointer',
    display: props => props.isPortraitMode ? 'flex' : ''
  },
  savedHomesTitle: {
    textAlign: 'left',
  },
  top: {
    flex: '0 0 auto',

    height: 50,
    marginLeft: props => props.isPortraitMode ? 23 : 0,
  },
  topRightContainer: {
    position: 'absolute',
    top: 24,
    right: 50,

    display: 'flex',
    alignItems: 'center',
  },
  middle: {
    flex: props => props.isPortraitMode ? '0 0 auto' : '1 0 auto',
    maxHeight: 'calc( 100vh - 290px )',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none',
    display: props => props.isPortraitMode ? 'flex' : '',
    overflowX: props => props.isPortraitMode ? 'auto' : 'none',

  },
  bottom: props => ({
    position: 'relative',
    display: 'flex',
    flex: '0 0 auto',

    boxSizing: props.isPortraitMode ? '' : 'border-box',
    margin: props.isPortraitMode ? '10px 23px' : '',
    paddingTop: props.isPortraitMode ? 0 : 25,

    height: props.isPortraitMode ? 'auto' : 102,
    borderTop: props.isPortraitMode ? '' : `1px ${props.ThemeData.colours.black} solid`,
    justifyContent: props.isPortraitMode ? 'flex-start' : 'center',
    marginBottom: props.isPortraitMode ? '' : 50,
  }),
  closeButton: {
    position: 'absolute',
    right: 0,
    padding: 16,
    borderRadius: props => props.isPortraitMode ? 15 : 100,
    gap: 10,
    marginInline: 'auto',
  },
  trashIconPortrait: {
    marginInline: 'auto',
    marginTop: props => props.isPortraitMode ? 0 : 25,
    borderRadius: props => props.isPortraitMode ? 15 : 100,
    width: props => props.isPortraitMode ? 156 : 150,
    '& > div': {
      marginLeft: 5,
      fontSize: 10
    }
  },
  trashIcon: {
    marginInline: 'auto',
    marginBottom: 25,
    marginTop: props => props.isPortraitMode ? 0 : 25,
    borderRadius: props => props.isPortraitMode ? 15 : 100,
    // marginLeft: "500px !important",
    width: props => props.isPortraitMode ? 156 : 150,
    '& > div': {
      marginLeft: 5,
      fontSize: 10
    }
  },
  bottomButton: {
    justifyContent: 'center',
    // margin: 5,
    fontSize: props => props.isPortraitMode ? 12 : 14,
    letterSpacing: '.1em',
    textTransform: 'capitalized !important',
    color: props => props.isPortraitMode ? ThemeData.colours.saveCompareHomesTextButtonPortrait : '',
    borderRadius: props => props.isPortraitMode ? 15 : 0,
    width: props => props.isPortraitMode ? 180 : 'none',
    margin: props => props.isPortraitMode ? '0 10px' : 5,
  },
  bgMountainscapeClipper: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 90,
    width: '100%',
    overflow: 'clip',

    zIndex: -1,
  },
  bgMountainscapeWrapper: {
    position: 'relative',
    bottom: 0,
    left: -12,
    right: 0,

    width: '105%',
    overflow: 'clip',

    zIndex: -1,
  },
  bgMountainscapeImg: {
    width: '100%',
    // paddingBottom: 15,
  },
};

const useStyles = createUseStyles(
  jsStyles,
  {
    name: 'SavedHomesSlideout',
  },
);

const wrapperAnimVariants = {
  open: {
    x: 0,
  },
  closed: {
    x: '110%',
  },
  openPortrait: {
    y: 0,
  },
  closedPortrait: {
    y: '200%',
  }

};

const RenderSavedHomesSlideout = (classes, project, error, isOpen, toggleIsOpen, isPortraitMode, hooks, props) => {
  if (error) {
    console.log('RenderSavedHomesSlideout', error.message);
  }

  function stopPropagation (e) {
    e.stopPropagation();
  }

  const [_, setToOpenCompareHomes] = useState(false);

  const saveHomesRef = useRef(null);

  // const isArchetype = project.slug === 'archetype';

  function useOutsideClickSaveHomes (ref) {
    useEffect(() => {
      function handleClickOutside (event) {
        if (ref?.current && !ref?.current.contains(event.target)) {
          if (props.unitDetailRef?.current === null && props.confirmFormRef?.current === null) {
            toggleIsOpen();
            props.toggleSetIsSaveHomesInteractive(false);
            props.setIsContentPageCarousel(false);
          }
        }
      }

      document.addEventListener('mousedown', handleClickOutside, true);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside, true);
      };
    }, [ref]);
  }

  useOutsideClickSaveHomes(isOpen ? saveHomesRef : null);
  return (
    <motion.div
      id="saved-homes-slideout"
      className={classes.wrapper}
      initial={isPortraitMode ? 'closedPortrait' : 'closed'}
      animate={isPortraitMode ? (isOpen ? 'openPortrait' : 'closedPortrait') : (isOpen ? 'open' : 'closed')}
      variants={wrapperAnimVariants}
      transition={{
        type: isPortraitMode ? 'tween' : 'linear',
        duration: .5,
      }}
      onMouseDown={(e) => stopPropagation(e)}
      onMouseUp={(e) => stopPropagation(e)}
      ref={saveHomesRef}
    >
      <div className={classes.contentContainer}>
        <div className={classes.top}>
          <SavedHomesTitle
            project={project}
            isSaveHome={true}
            className={classes.savedHomesTitle}
            titleText="Save and Compare"
            numberBgColor={ThemeData.colours.primaryAccent}
          />


          {isPortraitMode ? <>
              {hooks.savedHomes && hooks.savedHomes.length > 0 && <div className={classes.topRightContainer}>
                {/*{!isArchetype && <ButtonOutline*/}
                {/*  ThemeData={project.ThemeData}*/}
                {/*  id="compare-saved-homes-button"*/}
                {/*  borderColour={project.ThemeData.colours.vibrantGreen}*/}
                {/*  textColour={isPortraitMode ? ThemeData.colours.saveCompareHomesTextButtonPortrait : ThemeData.colours.saveCompareHomesTextButtonLandscape}*/}
                {/*  hoverBackgroundColour={'transparent'}*/}
                {/*  className={classes.bottomButton}*/}
                {/*  textTransform={'capitalized'}*/}
                {/*  compareHomes={() => hooks.compareHomes}*/}
                {/*  onClick={() => {*/}
                {/*    if (isPortraitMode) {*/}
                {/*      if (hooks.savedHomes.length > 0 && hooks.compareHomes.length > 0) {*/}
                {/*        toggleIsOpen();*/}
                {/*        props.toggleIsSavedHomesComparePortraitOpen();*/}
                {/*        setToOpenCompareHomes(true);*/}
                {/*      }*/}
                {/*    } else if (!isPortraitMode) {*/}
                {/*      props.toggleIsSavedHomesCompareOpen();*/}
                {/*    }*/}
                {/*  }}*/}
                {/*>*/}
                {/*  Compare {project.projectType?.includes('industrial') ? `Plans` : `Homes`} (2 max)*/}
                {/*</ButtonOutline>}*/}
                {project.settings?.showEmailButton && <ButtonOutline
                  disabled={true}
                  ThemeData={project.ThemeData}
                  borderColour={project.ThemeData.colours.vibrantGreen}
                  textColour={isPortraitMode ? ThemeData.colours.saveCompareHomesTextButtonPortrait : ThemeData.colours.saveCompareHomesTextButtonLandscape}
                  hoverBackgroundColour={'transparent'}
                  firstInColl
                  className={classes.bottomButton}
                  textTransform={'capitalized'}
                  onClick={() => props.toggleIsRegisterOpen()}
                >
                  Send To Email
                </ButtonOutline>}
                <ButtonOutline
                  ThemeData={project.ThemeData}
                  className={classes.trashIconPortrait}
                  borderColour={!isPortraitMode ? project.ThemeData.colours.primaryAccent : project.ThemeData.colours.vibrantGreen}

                  backgroundColour={isPortraitMode ? '' : project.ThemeData.colours.primaryAccent}
                  hoverBackgroundColour={isPortraitMode ? 'transparent' : project.ThemeData.colours.primaryAccent}
                  textColour={isPortraitMode ? ThemeData.colours.saveCompareHomesTextButtonPortrait : ThemeData.colours.saveCompareHomesTextButtonLandscape}
                  hoverTextColour={isPortraitMode ? ThemeData.colours.saveCompareHomesTextButtonPortrait : ThemeData.colours.saveCompareHomesTextButtonLandscape}
                  onClick={() => hooks.savedHomes.length > 0 && hooks.setIsClearConfirmationDialogOpenToOpen()}
                >
                  <IconTrash style={{ width: isPortraitMode ? 13 : 'auto', height: isPortraitMode ? 13 : 'auto' }} />
                  <div>Remove All</div>
                </ButtonOutline>
              </div>}
            </>
            :
            <div
              className={classes.closeX}
              onClick={() => toggleIsOpen()}
            >
              <img src={xWhiteMedium} alt="Slide Away Saved Homes" />
            </div>
          }


        </div>
        <div className={classes.middle} style={{ height: hooks.savedHomes.length === 0 && 373 }}>
          <AnimatePresence>
            {/* {
							project.units && hooks.savedHomes.map( savedHomeUnit => {
								let unitIdx = project.units.findIndex( elm => elm.id === savedHomeUnit.id );
								if ( unitIdx !== -1 )
									return <UnitDetailListItemMini
										project={project}
										data={project.units[unitIdx]}
										key={unitIdx}
										isPortraitMode={isPortraitMode}
										toggleSavedHome={() => hooks.toggleSavedHome( savedHomeUnit.id )}
									/>;
	
								return <></>;
	
							}
							)
						}	 */}
            {
              project.units && hooks.savedHomes.map(savedHomeUnit => {
                  let unitIdx = project.units.findIndex(elm => elm.id === savedHomeUnit.id);

                  if (unitIdx !== -1) {
                    if (isPortraitMode)
                      return <div
                        className={classes.unitDetailCompareCardWrapper}
                        key={unitIdx}
                      >
                        <UnitDetailCompareCard
                          project={project}
                          dataUnit={project.units[unitIdx]}
                          isSaveHome={true}
                          isPortraitMode={isPortraitMode}
                          toggleSavedHome={() => hooks.toggleSavedHome(savedHomeUnit.id)}
                          toggleCompareHome={() => hooks.toggleCompareHome(savedHomeUnit.id)}
                          compareHomesIncludes={() => hooks.compareHomesIncludes(savedHomeUnit.id)}
                          toggleUnitDetailOpen={() => props.toggleUnitDetailOpen()}
                          isSaveHomePortrait={props.isSaveHomePortrait}
                          setIsSaveHomePortrait={props.setIsSaveHomePortrait}
                          setSelectedUnitId={props.setSelectedUnitId}
                          setSelectedFloorplanId={props.setSelectedFloorplanId}
                          compareHomes={hooks.compareHomes}
                          toggleSetIsSaveHomesInteractive={(val) => props.toggleSetIsSaveHomesInteractive(val)}
                          removeCompareHome={() => hooks.removeCompareHome(savedHomeUnit.id)}
                        />
                      </div>;
                    else
                      return <UnitDetailListItemMini
                        project={project}
                        data={project.units[unitIdx]}
                        key={unitIdx}
                        toggleSavedHome={() => hooks.toggleSavedHome(savedHomeUnit.id)}
                      />;

                  }
                }
              )
            }
            {
              project.floorPlans && hooks.savedHomes.map(savedHomeUnit => {
                  let floorplanIdx = project.floorPlans.findIndex(elm => elm.id === savedHomeUnit.id);
                  if (floorplanIdx !== -1) {
                    if (isPortraitMode)
                      return <div
                        className={classes.unitDetailCompareCardWrapper}
                        key={floorplanIdx}
                      >
                        <UnitDetailCompareCard
                          project={project}
                          dataUnit={project.floorPlans[floorplanIdx]}
                          isSaveHome={true}
                          isPortraitMode={isPortraitMode}
                          toggleSavedHome={() => hooks.toggleSavedHome(savedHomeUnit.id)}
                          toggleCompareHome={() => hooks.toggleCompareHome(savedHomeUnit.id)}
                          compareHomesIncludes={() => hooks.compareHomesIncludes(savedHomeUnit.id)}
                          toggleUnitDetailOpen={() => props.toggleUnitDetailOpen()}
                          isSaveHomePortrait={props.isSaveHomePortrait}
                          setIsSaveHomePortrait={props.setIsSaveHomePortrait}
                          setSelectedUnitId={props.setSelectedUnitId}
                          setSelectedFloorplanId={props.setSelectedFloorplanId}
                          compareHomes={hooks.compareHomes}
                          toggleSetIsSaveHomesInteractive={(val) => props.toggleSetIsSaveHomesInteractive(val)}
                          removeCompareHome={() => hooks.removeCompareHome(savedHomeUnit.id)}
                        />
                      </div>;
                    else
                      return <UnitDetailListItemMini
                        project={project}
                        data={project.floorPlans[floorplanIdx]}
                        key={floorplanIdx}
                        toggleSavedHome={() => hooks.toggleSavedHome(savedHomeUnit.id)}
                      />;
                  }

                }
              )
            }

          </AnimatePresence>
        </div>
        {!isPortraitMode &&
          <>
            {
              hooks.savedHomes && hooks.savedHomes.length > 0 ?
                <ButtonOutline
                  ThemeData={project.ThemeData}
                  className={classes.trashIcon}
                  borderColour={project.ThemeData.colours.mainBorder}
                  textColour={project.ThemeData.colours.primaryAccent}
                  hoverTextColour={project.ThemeData.colours.primaryFontHighestContrast}
                  onClick={() => {
                    hooks.setIsClearConfirmationDialogOpenToOpen();
                    props.toggleSetIsSaveHomesInteractive(true);
                  }}
                  textTransform={'capitalized'}
                >
                  <IconTrash />
                  <div>Remove All</div>
                </ButtonOutline>
                :
                <div style={{ height: 100 }}></div>
            }
          </>
        }

        <div className={classes.bottom}>
          <ButtonGroup
            className={classes.bottomButtons}
            justifyContent="center"
          >
            {!isPortraitMode && hooks.savedHomes && <>
              <ButtonOutline
                ThemeData={project.ThemeData}
                id="compare-saved-homes-button"
                borderColour={project.ThemeData.colours.vibrantGreen}
                firstInColl
                className={classes.bottomButton}
                textTransform={'capitalized'}
                compareHomes={() => hooks.compareHomes}
                onClick={() => {
                  if (isPortraitMode) {
                    if (hooks.savedHomes.length > 0 && hooks.compareHomes.length > 0) {
                      toggleIsOpen();
                      props.toggleIsSavedHomesComparePortraitOpen();
                      setToOpenCompareHomes(true);
                    }
                  } else if (!isPortraitMode) {
                    props.toggleIsSavedHomesCompareOpen();
                  }
                }}
              >
                Compare {project.projectType?.includes('industrial') ? `Plans` : `Homes`}
              </ButtonOutline>
              {project.settings?.showEmailButton && hooks.savedHomes && hooks.savedHomes.length > 0 &&
                <ButtonOutline
                  disabled={true}
                  ThemeData={project.ThemeData}
                  borderColour={project.ThemeData.colours.vibrantGreen}
                  padding={8}
                  firstInColl
                  className={classes.bottomButton}
                  textTransform={'capitalized'}
                  onClick={() => props.toggleIsRegisterOpen()}
                >
                  Send To Email
                </ButtonOutline>
              }
            </>}
            {isPortraitMode && <ButtonOutline
              ThemeData={project.ThemeData}
              className={classes.closeButton}
              borderColour={isPortraitMode ? project.ThemeData.colours.vibrantGreen : project.ThemeData.colours.primaryAccent}
              backgroundColour={isPortraitMode ? '' : project.ThemeData.colours.primaryAccent}
              hoverBackgroundColour={isPortraitMode ? 'transparent' : project.ThemeData.colours.primaryAccent}
              // textColour={isPortraitMode ? "" : project.ThemeData.colours.white}
              textColour={isPortraitMode ? ThemeData.colours.saveCompareHomesTextButtonPortrait : ThemeData.colours.saveCompareHomesTextButtonLandscape}
              hoverTextColour={isPortraitMode ? ThemeData.colours.saveCompareHomesTextButtonPortrait : ThemeData.colours.saveCompareHomesTextButtonLandscape}
              textTransform={'capitalized'}
              onClick={() => {
                toggleIsOpen();
                props.setIsContentPageCarousel(false);
              }}
            >
              <FontAwesomeIcon icon={faChevronDown} />
              <div>Close</div>
            </ButtonOutline>}
          </ButtonGroup>
          <ClearConfirmationDialogGeneric
            dataTypeToClearName={project.projectType.includes('industrial') ? 'Saved Plans' : 'Saved Homes'}
            project={project}
            confirmFormRef={props.confirmFormRef}
            isClearConfirmationDialogOpen={hooks.isClearConfirmationDialogOpen}
            setIsClearConfirmationDialogOpenToClosed={hooks.setIsClearConfirmationDialogOpenToClosed}
            afterClearConfirmationPerformFunction={() => {
              hooks.clearSavedHomes();
              isPortraitMode && hooks.clearCompareHomes();
            }}
            // toggleSetIsSaveHomesInteractive={(val) => props.toggleSetIsSaveHomesInteractive(val)}
          />
        </div>
      </div>
      {/*<div className={classes.bgMountainscapeClipper}>
				<div className={classes.bgMountainscapeWrapper}>
					<img
						className={classes.bgMountainscapeImg}
						src={neuMountainscape}
						alt="Mountainscape BG"
					/>
				</div>
			</div>*/}
    </motion.div>
  );
};

const SavedHomesSlideout = ({ isOpen, project, toggleIsOpen, ...props }) => {
  const isPortraitMode = portraitMode();
  const location = useLocation();
  const pathname = location.pathname;
  const isProximation = pathname.includes('/proximation') ? true : false;
  const noNavigation = /\/.*\/(.*)?$/.test(pathname) || pathname.includes('/gallery') || props.isContentPageCarousel === true;

  const { accessibility: showAccessibilityFooter } = accessibilityPane();
  const classes = useStyles({ ThemeData: project.ThemeData, isPortraitMode, showAccessibilityFooter, isProximation, noNavigation });
  const { savedHomes, toggleSavedHome, clearSavedHomes } = useSavedHomes();
  const { compareHomes, toggleCompareHome, compareHomesIncludes, clearCompareHomes, removeCompareHome } = useCompareHomes();
  const {
    isClearConfirmationDialogOpen,
    setIsClearConfirmationDialogOpenToOpen,
    setIsClearConfirmationDialogOpenToClosed,
  } = useOpenClosePane('ClearConfirmationDialog');

  let hooks = {
    savedHomes,
    toggleSavedHome,
    clearSavedHomes,
    compareHomes,
    toggleCompareHome,
    removeCompareHome,
    compareHomesIncludes,
    clearCompareHomes,
    isClearConfirmationDialogOpen,
    setIsClearConfirmationDialogOpenToOpen,
    setIsClearConfirmationDialogOpenToClosed,

  };
  return RenderSavedHomesSlideout(classes, project, null, isOpen, toggleIsOpen, isPortraitMode, hooks, props);

};

export default SavedHomesSlideout;
