import { ConstantData } from "./ConstantData";
import {
  getOrdinal,
  filterConvertBedrooms,
  determineFloorPlanStatus,
  isAvailableStatus,
} from "util/helper";

export const filterableSources = {
  rooms: {
    label: "Rooms",
    filterType: ConstantData.unitFilterTypes.checkedList,
    unitRelationDataType: "unitHomeTypes",
    dataListItemLabelField: "name",
  },
  floors: {
    label: "Floors",
    filterType: ConstantData.unitFilterTypes.checkedList,
    unitRelationDataType: "unitFloors",
    dataListItemLabelField: "name",
  },
  orientation: {
    label: "Orientation",
    filterType: ConstantData.unitFilterTypes.checkedList,
    unitRelationDataType: "unitExposures",
    dataListItemLabelField: "direction",
  },
  floorPlans: {
    label: "Floor Plans",
    filterType: ConstantData.unitFilterTypes.checkedList,
    unitRelationDataType: "floorPlans",
    dataListItemLabelField: "name",
  },
  buildings: {
    label: "Building",
    filterType: ConstantData.unitFilterTypes.checkedList,
    unitRelationDataType: "unitBuildings",
    dataListItemLabelField: "name",
  },
  price: {
    label: "Price",
    filterType: ConstantData.unitFilterTypes.range,
    unitField: "price",
    rangeMinimumValue: 800000,
    rangeMaximumValue: 2500000,
    rangeStep: 100000,
    displayedDataFormattingFunction: (value) => {
      return `$${value / 1000}K`;
    },
  },
  size: {
    label: "Size",
    filterType: ConstantData.unitFilterTypes.range,
    unitField: "size",
    rangeMinimumValue: 1340,
    rangeMaximumValue: 1640,
    rangeStep: 10,
    displayedDataFormattingFunction: (value) => {
      return `${value} sqft`;
    },
  },
  availability: {
    label: "Availability",
    filterType: ConstantData.unitFilterTypes.checkedList,
    unitRelationDataType: "unitAvailabilityStates",
    dataListItemLabelField: "name",
  },
  floorplanAvailability: {
    label: "Availability",
    filterType: ConstantData.unitFilterTypes.checkedList,
    unitRelationDataType: "floorplanAvailabilityStates",
    dataListItemLabelField: "name",
  },
  categories: {
    label: "Category",
    filterType: ConstantData.unitFilterTypes.checkedList,
    unitRelationDataType: "unitCategoryStates",
    dataListItemLabelField: "name",
  },
  floorplanCategories: {
    label: "Category",
    filterType: ConstantData.unitFilterTypes.checkedList,
    unitRelationDataType: "floorplanCategoryStates",
    dataListItemLabelField: "name",
  },
};

export const filterableSourcesHeaderButtons = (unitFilter) => {
  let filterButtons = [];
  [
    {
      id: "availability",
      label: "Availability",
      filterType: ConstantData.unitFilterTypes.checkedList,
      unitRelationDataType: "unitAvailabilityStates",
      dataListItemLabelField: "name",
    },
    {
      id: "floorplanAvailability",
      label: "Availability",
      filterType: ConstantData.unitFilterTypes.checkedList,
      unitRelationDataType: "floorplanAvailabilityStates",
      dataListItemLabelField: "name",
      filterSettingName: "availability",
    },
    {
      id: "floorPlans",
      label: "Floor Plans",
      filterType: ConstantData.unitFilterTypes.checkedList,
      unitRelationDataType: "floorPlans",
      dataListItemLabelField: "name",
    },
    {
      id: "price",
      label: "Price",
      filterType: ConstantData.unitFilterTypes.range,
      unitField: "price",
      unitRelationDataType: "price",
      rangeMinimumValue: 800000,
      rangeMaximumValue: 2500000,
      rangeStep: 100000,
      displayedDataFormattingFunction: (value) => {
        return `$${value / 1000}K`;
      },
    },
    {
      id: "rooms",
      label: "Unit Types",
      filterType: ConstantData.unitFilterTypes.checkedList,
      unitRelationDataType: "unitHomeTypes",
      dataListItemLabelField: "name",
    },
    {
      id: "floors",
      label: "Floors",
      filterType: ConstantData.unitFilterTypes.checkedList,
      unitRelationDataType: "unitFloors",
      dataListItemLabelField: "name",
    },
    {
      id: "size",
      label: "Size",
      unitRelationDataType: "size",
      filterType: ConstantData.unitFilterTypes.range,
      unitField: "size",
      rangeMinimumValue: 910,
      rangeMaximumValue: 1852,
      rangeStep: 10,
      displayedDataFormattingFunction: (value) => {
        return `${value} sqft`;
      },
    },
    {
      id: "orientation",
      label: "Orientation",
      filterType: ConstantData.unitFilterTypes.checkedList,
      unitRelationDataType: "unitExposures",
      dataListItemLabelField: "name",
    },
    {
      id: "buildings",
      label: "Building",
      filterType: ConstantData.unitFilterTypes.checkedList,
      unitRelationDataType: "unitBuildings",
      dataListItemLabelField: "name",
    },
    {
      id: "categories",
      label: "Categories",
      filterType: ConstantData.unitFilterTypes.checkedList,
      unitRelationDataType: "unitCategoryStates",
      dataListItemLabelField: "name",
      filterSettingName: "floorPlanType",
    },
    {
      id: "floorplanCategories",
      label: "Categories",
      filterType: ConstantData.unitFilterTypes.checkedList,
      unitRelationDataType: "floorplanCategoryStates",
      dataListItemLabelField: "name",
      filterSettingName: "floorPlanType",
    },
  ].forEach((item) => {
    if (item.id.search(/price|size/) === 0) {
      if (
        unitFilter[item.id].enable &&
        unitFilter[item.id].min &&
        unitFilter[item.id].max
      ) {
        filterButtons.push({
          ...item,
          rangeMinimumValue: unitFilter[item.id].min,
          rangeMaximumValue: unitFilter[item.id].max,
        });
      }
    } else if (unitFilter[item.id] || unitFilter[item.filterSettingName]) {
      filterButtons.push(item);
    }
  });
  return filterButtons;
};

export const createFilterData = (project, showUnits = true) => {
  if (!project) return;

  // let units = project.units
  let units = [];
  project.units.forEach((u) => {
    units.push({
      ...u,
      floorPlan: {
        ...u.floorPlan,
        bedRooms:
          u.floorPlan.bedRooms === "Studio"
            ? "Studio"
            : u.floorPlan.flex
            ? u.floorPlan.bedRooms + 0.1
            : u.floorPlan.bedRooms,
      },
    });
  });

  let floorPlans = [];
  project.floorPlans.forEach((f) => {
    if (f.building.disabled) {
      if (floorPlans.filter((item) => item.name === f.name).length > 0) return;
      floorPlans.push({
        id: f.id,
        name: f.name,
        floorplanUnitType: f.floorplanUnitType,
        buildingId: f.building.id,
      });
    }
  });

  if (!showUnits) {
    let newUnits = [];
    let count = 1;
    project.floorPlans
      .filter((f) => f.units && f.units.length > 0)
      .forEach((f) => {
        // Need overhaul on filtering...
        const floorPlanStatus = determineFloorPlanStatus(f);

        if (f.building.disabled) {
          // TODO:
          // DETERMINE WHY BUILDING IS ALWAYS COMING THROUGH AS DISABLED.
          // THIS IS ILLOGICAL AND DANGEROUS LONG TERM.
          if (newUnits.filter((u) => u.floorPlan.name === f.name).length > 0) {
            // If 2 buildings exist for the same floor plan

            const existingUnitIndex = newUnits.findIndex(
              (u) => u.floorPlan.name === f.name
            );
            if (existingUnitIndex !== -1) {
              const existingUnit = newUnits[existingUnitIndex];

              // Create a new object by copying the existing one
              const updatedUnit = {
                ...existingUnit,
                building: [
                  ...existingUnit.building,
                  {
                    disabled: f.building.disabled,
                    id: f.building.id,
                    name: f.building.name,
                  },
                ],
                units: [...existingUnit.units, ...f.units],
              };

              // Replace the existing unit in the array with the updated unit
              newUnits[existingUnitIndex] = updatedUnit;
            }
          } else {
            newUnits.push({
              ...f,
              building: [
                {
                  disabled: f.building.disabled,
                  id: f.building.id,
                  name: f.building.name,
                },
              ],
              threeDId: count,
              floorPlan: f,
              status: isAvailableStatus(floorPlanStatus)
                ? "available"
                : floorPlanStatus,
              unitNumber: null,
              parking: null,
              __typename: "FloorPlan",
            });
          }

          count += 1;
        }
      });
    units = newUnits;
  }

  let buildings = [];
  project.buildings.forEach((building) => {
    if (building.disabled) buildings.push(building);
  });

  const numFloors = [
    ...new Set(
      project.units
        .filter((u) => u.floor !== undefined && u.floor !== null)
        .map((u) => u.floor)
    ),
  ];

  const floors = numFloors.sort().map((f) => {
    let buildings = [];
    if (project.buildings.length === 1) {
      buildings = [project.buildings[0].id];
    } else {
      buildings = [
        ...new Set(
          units.filter((u) => u.floor === f).map((u) => u.floorPlan.building.id)
        ),
      ];
    }
    return {
      id: f,
      name: `${getOrdinal(f)} ${f >= 0 ? "floor" : ""}`,
      value: f,
      buildings,
    };
  });

  const rooms = [
    ...new Set(
      units.map((u) => (u.floorPlan.studio ? "Studio" : u.floorPlan.bedRooms))
    ),
  ];
  // remove disabled from project.units
  const unitStatuses = [...new Set(project.units.map((u) => u.status))];

  let floorplanCategories = [];
  project.floorPlans.forEach((f) => {
    floorplanCategories = [
      ...new Set([
        ...floorplanCategories,
        ...(f.floorPlanType || "industrial").split(","),
      ]),
    ];
  });
  const unitHomeTypes = rooms.map((i) => {
    return {
      id: i === "Studio" ? 0 : i,
      name: filterConvertBedrooms(i),
      bedRooms: i === "Studio" ? 0 : i,
    };
  });

  unitHomeTypes.sort((a, b) => a.id - b.id);
  const exposures = [
    ...new Set(
      units
        .filter((u) => u.exposures && u.exposures.length > 0)
        .map((u) => [...u.exposures])
        .flat(1)
    ),
  ];

  const unitAvailabilityStates = unitStatuses.map((s) => {
    return {
      id: s,
      name: ConstantData.unitStatuses[s] || s,
      value: s,
    };
  });

  const floorplanAvailabilityStates = ["available", "sold"].map((s) => {
    return {
      id: s,
      name: ConstantData.unitStatuses[s] || s,
      value: s,
    };
  });

  const unitCategoryStates = floorplanCategories.map((s) => {
    return {
      id: s,
      name: ConstantData.unitStatuses[s] || s,
      value: s,
    };
  });
  const floorplanCategoryStates = floorplanCategories.map((s) => {
    return {
      id: s,
      name: ConstantData.floorplanCategories[s] || s,
      value: s,
    };
  });
  const unitExposures = exposures.map((direction) => {
    return {
      id: direction,
      directionAbbrev: direction.substring(0, 1).toUpperCase(),
      direction,
    };
  });
  return {
    floorPlans,
    unitFloors: floors,
    unitBuildings: buildings,
    unitHomeTypes: unitHomeTypes,
    unitAvailabilityStates,
    floorplanAvailabilityStates,
    unitCategoryStates,
    floorplanCategoryStates,
    unitExposures,
    units,
  };
};
