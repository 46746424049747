import React from "react";

import { createUseStyles } from "react-jss";

import { useLocation } from "react-router-dom";

import ButtonOutline from "./ButtonOutline";
import FloorplanDetail from "./FloorplanDetail";
import { sendGoogleAnalyticsEvent } from "util/sendGoogleAnalyticsEvent";
import { determineFloorPlanStatus, isAvailableStatus } from "util/helper";

const useStyles = createUseStyles(
  {
    notAvailableWrapper: {
      opacity: 0.5,
    },
    notAvailableDetails: {
      opacity: 0.5,
    },
    wrapper: (props) => ({
      borderRadius: 4,
      display: "flex",
      border: `0.5px solid ${props.ThemeData.colours.sameBlack}`,
      alignItems: "center",
      flexDirection: "row",
      color: (props) => props.ThemeData.colours.sameBlack,
      textAlign: "center",
      justifyContent: "space-between",
      borderRadius: 10,
      padding: "10px 30px",
    }),
    selectedItem: (props) => ({
      border: `2px solid ${props.ThemeData.colours.branding}`,
    }),
    floorplanImg: {
      flex: "0 0 auto",
      height: 75,
      margin: "0 2%",
      backgroundColor: (props) => props.ThemeData.colours.clear,
    },
    info: {
      flex: "1 0 auto",
    },
    moreDetailsButtonArea: {
      zIndex: 2,
    },
    infoTextWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    moreDetailsButton: (props) => ({
      borderRadius: 15,
      padding: 16,
      border: `1px solid ${props.ThemeData.colours.branding}`,
      color: props.ThemeData.colours.branding,
      ...props.ThemeData.typography.title,
      fontSize: 12,
      lineHeight: "normal",
      textTransform: "capitalize",
      "&:hover, &:active, &.active": {
        backgroundColor: props.ThemeData.colours.branding,
        color: props.ThemeData.colours.brandingText,
      },
    }),
    title: (props) => ({
      ...props.ThemeData.typography.contentPageTitle,
      fontSize: 20,
      lineHeight: "52px",
    }),
    infoLine: (props) => ({
      ...props.ThemeData.typography.contentPageTitle,
      fontWeight: 600,
      fontSize: 20,
      lineHeight: "52px",
    }),
  },
  {
    name: "FloorplanDetailTableItem",
  }
);

const FloorplanDetailTableItem = ({ project, dataFloorplan, ...props }) => {
  const { ThemeData } = project;
  const location = useLocation();
  const classes = useStyles({ ThemeData });

  const isTownHouse = project.settings.isTownHouse;

  const filteredUnits = project.units.filter((unit) =>
    isTownHouse
      ? unit.floorPlan.name === dataFloorplan.name
      : unit.floorPlan.id === dataFloorplan.id
  );

  if (filteredUnits.length === 0) {
    return null;
  }

  // Determine the floor plan status
  const groupedUnits = filteredUnits.reduce((acc, unit) => {
    const useIdOrName = isTownHouse ? unit.floorPlan.name : unit.floorPlan.id;
    const floorPlanId = useIdOrName;

    if (!acc[floorPlanId]) {
      acc[floorPlanId] = { units: [] };
    }
    acc[floorPlanId].units.push(unit);
    return acc;
  }, {});

  const useIdOrName = isTownHouse ? dataFloorplan.name : dataFloorplan.id;

  const floorPlanStatus = determineFloorPlanStatus(groupedUnits[useIdOrName]);

  const isAvailable = isAvailableStatus(floorPlanStatus);

  return (
    <>
      <div
        id={
          dataFloorplan.id === props.selectedFloorplanId
            ? `floorplan-detail-tableItem-${props.itemIdx}`
            : `floorplan-detail-tableItem-active-${props.itemIdx}`
        }
        className={`${classes.wrapper} ${
          props.itemIdx % 2 === 0
            ? classes.rowBGColourOne
            : classes.rowBGColourTwo
        } ${
          dataFloorplan.id === props.selectedFloorplanId
            ? classes.selectedItem
            : ""
        }
        ${!isAvailable ? classes.notAvailableWrapper : ""}
        `}
        onClick={() => {
          props.setSelectedFloorplanId(dataFloorplan.id);
        }}
      >
        <div className={classes.infoTextWrapper}>
          <FloorplanDetail
            project={project}
            ThemeData={ThemeData}
            data={dataFloorplan}
            titleClassName={classes.title}
            infoClassName={classes.infoLine}
            status={floorPlanStatus}
            separator="dots"
          />
        </div>
        <div className={classes.moreDetailsButtonArea}>
          <ButtonOutline
            ThemeData={ThemeData}
            id={`more-details-button-list-view-${props.itemIdx}`}
            className={`${classes.moreDetailsButton} ${
              props.isUnitDetailOpen &&
              props.selectedFloorplanId === dataFloorplan.id
                ? "active"
                : ""
            }`}
            label="Details"
            onClick={() => {
              if (!isAvailable) {
                return;
              }
              props.setSelectedFloorplanId(dataFloorplan.id);
              props.toggleUnitDetailPaneFcn();
              sendGoogleAnalyticsEvent(
                `Floor Plan View - ${location.pathname}`,
                `User Clicks ${dataFloorplan.name}`,
                `${dataFloorplan.name}`,
                `${dataFloorplan.name}`
              );
            }}
            borderRadius={4}
          />
        </div>
      </div>
    </>
  );
};

FloorplanDetailTableItem.defaultProps = {
  isToAllowSelection: false,
};

export default FloorplanDetailTableItem;
