import createPersistedState from "use-persisted-state";

import ConstantData from "data/ConstantData";

import useGlobalUnitsFilterResult from "hooks/useGlobalUnitsFilterResult";

const useGlobalUnitsFilterState = createPersistedState(
  ConstantData.localStorageKeys.unitsFilter
);

const useGlobalUnitsFilter = () => {
  const [globalUnitsFilter, setGlobalUnitsFilter] = useGlobalUnitsFilterState({
    unitCustomIdsFiltered: [],
  });

  const globalUnitsFilterResult = useGlobalUnitsFilterResult();

  const filterPreds = {
    floorPlans: (unitData, filter) => {
      if (!unitData.floorPlan) return true;

      if (
        filter["floorPlans"].hasOwnProperty(unitData.floorPlan.name) &&
        filter["floorPlans"][unitData.floorPlan.name].doFilterByOr
      )
        return true;
      return false;
    },
    unitHomeTypes: (unitData, filter) => {
      if (!unitData.floorPlan) return true;

      if (
        filter["unitHomeTypes"].hasOwnProperty(unitData.floorPlan.bedRooms) &&
        filter["unitHomeTypes"][unitData.floorPlan.bedRooms].doFilterByOr
      )
        return true;

      return false;
    },
    unitBuildings: (unitData, filter) => {
      if (!unitData.floorPlan.building) return true;

      if (unitData.__typename === "Unit") {
        if (
          filter["unitBuildings"].hasOwnProperty(
            unitData.floorPlan.building.id
          ) &&
          filter["unitBuildings"][unitData.floorPlan.building.id].doFilterByOr
        )
          return true;
      } else {
        const found = unitData.building?.some((building) => {
          return (
            filter["unitBuildings"].hasOwnProperty(building.id) &&
            filter["unitBuildings"][building.id].doFilterByOr
          );
        });
        if (found) {
          return true;
        }
      }

      return false;
    },
    unitFloors: (unitData, filter) => {
      // TODO: Figure out if returning true if the unit doesn't have a floor ref is okay
      if (unitData.floor === null || unitData.floor === undefined) return true;

      if (
        filter["unitFloors"].hasOwnProperty(unitData.floor) &&
        filter["unitFloors"][unitData.floor].doFilterByOr
      )
        return true;

      return false;
    },
    unitExposures: (unitData, filter) => {
      if (!unitData.exposures) return false;

      for (const unitExposureIdx in unitData.exposures) {
        if (
          filter["unitExposures"].hasOwnProperty(
            unitData.exposures[unitExposureIdx]
          ) &&
          filter["unitExposures"][unitData.exposures[unitExposureIdx]]
            .doFilterByOr
        )
          return true;
      }

      return false;
    },
    unitAvailabilityStates: (unitData, filter) => {
      if (!unitData.status) return false;

      if (
        filter["unitAvailabilityStates"].hasOwnProperty(unitData.status) &&
        filter["unitAvailabilityStates"][unitData.status].doFilterByOr
      )
        return true;

      return false;
    },

    floorplanAvailabilityStates: (floorplan, filter) => {
      if (!floorplan.status) return false;

      if (
        filter["floorplanAvailabilityStates"].hasOwnProperty(
          floorplan.status
        ) &&
        filter["floorplanAvailabilityStates"][floorplan.status].doFilterByOr
      )
        return true;

      return false;
    },

    unitCategoryStates: (unitData, filter) => {
      if (!unitData.status || !unitData.floorPlan.floorPlanType) return false;

      let found = false;
      unitData.floorPlan.floorPlanType.split(",").forEach((type) => {
        if (
          filter["unitCategoryStates"].hasOwnProperty(type) &&
          filter["unitCategoryStates"][type].doFilterByOr
        ) {
          found = true;
          return;
        }
      });

      return found;
    },

    floorplanCategoryStates: (floorplan, filter) => {
      if (!floorplan.floorPlanType) return false;

      let found = false;
      floorplan.floorPlanType.split(",").forEach((type) => {
        if (
          filter["floorplanCategoryStates"].hasOwnProperty(type) &&
          filter["floorplanCategoryStates"][type].doFilterByOr
        ) {
          found = true;
          return;
        }
      });
      return found;
    },

    price: (unitData, filter) => {
      if (!unitData.price) return true;

      return (
        unitData.price <= filter["price"].high &&
        unitData.price >= filter["price"].low
      );
    },
    size: (unitData, filter) => {
      if (!unitData.floorPlan.interiorSize) return true;

      return (
        unitData.floorPlan.interiorSize <= filter["size"].high &&
        unitData.floorPlan.interiorSize >= filter["size"].low
      );
    },
  };

  return {
    globalUnitsFilter: globalUnitsFilter,
    setSort: (fieldName, sortDirection) => {
      setGlobalUnitsFilter({
        ...globalUnitsFilter,
        sortBy: fieldName,
        sortDirection: sortDirection,
      });
    },
    setFilterByFloor: (floorId) => {
      setGlobalUnitsFilter({
        ...globalUnitsFilter,
        floorId: floorId,
      });
    },
    checkAndInit: (newFilterType) => {
      // if ( !newFilterSettings.hasOwnProperty( props.filterType ) )
      // 	newFilterSettings[props.filterType] = {};
    },
    setFilter: (_filter) => {
      setGlobalUnitsFilter({
        ...globalUnitsFilter,
        filter: _filter,
      });
    },
    applyFilter: (unitsData) => {
      let haveFoundActiveFilter = false;
      let activeFilterFcns = [];

      for (const filterName in globalUnitsFilter.filter) {
        // Check that there is an active filter for this type
        for (const filterItemInstance in globalUnitsFilter.filter[filterName]) {
          if (
            globalUnitsFilter.filter[filterName][filterItemInstance]
              .doFilterByOr ||
            (filterName === "price" && globalUnitsFilter.filter[filterName]) ||
            (filterName === "size" && globalUnitsFilter.filter[filterName])
          ) {
            haveFoundActiveFilter = true;

            if (activeFilterFcns.indexOf(filterPreds[filterName]) === -1)
              activeFilterFcns.push(filterPreds[filterName]);
          }
        }
      }
      let unitCustomIdsFiltered = [];
      if (haveFoundActiveFilter)
        unitsData = unitsData.filter((unit) => {
          for (const filterFcnIdx in activeFilterFcns) {
            if (!activeFilterFcns[filterFcnIdx](unit, globalUnitsFilter.filter))
              return false;
          }

          unitCustomIdsFiltered.push(unit.threeDId);
          return true;
        });

      globalUnitsFilterResult.setUnitCustomIdsFiltered(unitCustomIdsFiltered);
      return unitsData;
    },
    resetFilter: () => {
      setGlobalUnitsFilter({
        ...globalUnitsFilter,
        filter: {},
      });
    },
    deactivateFilter: (targetFilter) => {
      let activeFilterFcns = globalUnitsFilter.filter;
      if (targetFilter === "price" || targetFilter === "size") {
        delete activeFilterFcns[targetFilter];
      } else {
        Object.keys(activeFilterFcns).forEach((filterName) => {
          if (filterName === targetFilter) {
            Object.keys(activeFilterFcns[filterName]).forEach((filter) => {
              activeFilterFcns[filterName][filter].doFilterByOr = false;
            });
          }
        });
      }
      setGlobalUnitsFilter({
        ...globalUnitsFilter,
        filter: activeFilterFcns,
      });
    },
    // toggleSavedHome: unitId => {
    // 	let prevUnitIdExistsIdx = globalUnitsFilter.findIndex(elm => elm.id == unitId);
    // 	if (prevUnitIdExistsIdx === -1)
    // 		setGlobalUnitsFilter([...globalUnitsFilter, { id: unitId }]);
    // 	else {
    // 		let newSavedHomes = globalUnitsFilter;
    // 		newSavedHomes.splice(prevUnitIdExistsIdx, 1);
    // 		setGlobalUnitsFilter([...newSavedHomes]);
    // 	}
    // }
  };
};

export default useGlobalUnitsFilter;
