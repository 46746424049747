import React from "react";

import { createUseStyles } from "react-jss";

import { useLocation } from "react-router-dom";

import ButtonOutline from "./ButtonOutline";

import useSavedHomes from "hooks/useSavedHomes";
import { BookMarkButton } from "./BookMarkButton";
import { capitalizeOptions, convertBedrooms } from "../util/helper";
import {
  portraitMode,
  getOrdinal,
  getStatusClass,
  isAvailableStatus,
} from "../util/helper";
import ConstantData from "data/ConstantData";

const useStyles = createUseStyles(
  {
    wrapper: {
      height: 90,
      width: (props) => (props.isPortraitMode ? "98%" : "100%"),
      borderRadius: 4,
      display: "flex",
      boxSizing: "border-box",
      alignItems: "center",
      flexDirection: "row",
      color: (props) => props.ThemeData.colours.secondaryFont,
      margin: "8px auto",
      textAlign: "center",
      border: "1px solid #EEEDE6",
      justifyContent: "space-between",
      opacity: (props) => (props.isAvailable ? 1 : 0.5),
    },
    selectedItem: (props) => ({
      // backgroundColor: props.ThemeData.colours.primaryAccentFade,
      border: `2px solid ${props.ThemeData.colours.primaryAccent}`,
    }),
    floorplanImg: {
      flex: "0 0 auto",
      height: 75,
      margin: "0 2%",
      backgroundColor: (props) => props.ThemeData.colours.clear,
    },
    info: {
      flex: "1 0 auto",
    },
    unitInfoPlanNameTitle: {
      marginTop: 0,
    },
    unitInfoLine: {
      color: (props) => props.ThemeData.colours.white75,

      textTransform: "uppercase",
    },
    removeHomeButton: {
      flex: "0 0 auto",
      width: 48,
    },
    tableItem: {
      flex: "1 0 auto",
      width: 100,
      flexDirection: "row",
    },
    moreDetailsButtonArea: {
      zIndex: 2,
      marginRight: 15,
    },
    infoTextWrapper: {
      display: "flex",
      flexDirection: "column",
      minWidth: 255,
    },
    planAvailableWrapper: {
      display: "flex",
      flexDirection: "row",
    },
    planInfoWrapper: {
      display: "flex",
      flexDirection: "row",
      fontSize: 14,
      fontWeight: "lighter",
      paddingTop: 5,
      textTransform: "capitalize",
      "& > div": {
        padding: "0 3px",
        fontWeight: "lighter",
      },
      "@media (max-width: 1200px)": {
        fontSize: 11,
      },
    },
    planText: {
      fontSize: 16,
    },
    availabilityText: () => ({
      padding: "2px 6px",
      borderRadius: "10px",
      fontSize: "12px",
      fontWeight: 600,
      color: "white",
      textTransform: "uppercase",
      marginBottom: "5px",
      marginTop: "5px",
      height: "min-content",
      width: "fit-content",
    }),

    soldTag: {
      backgroundColor: "#d24d2f",
    },

    unavailableTag: {
      backgroundColor: "#d24d2f",
    },

    inProgressTag: {
      backgroundColor: "#d24d2f",
    },

    finalUnitTag: {
      backgroundColor: "#6c7079",
    },

    finalHomeTag: {
      backgroundColor: "#6c7079",
    },
    moreDetails: (props) => ({
      borderRadius: 4,
      cursor: props.isAvailable ? "pointer" : "not-allowed",
      "@media (max-width: 1200px)": {
        fontSize: 9,
      },
    }),
    separator: (props) => ({
      color: props.ThemeData.colours.branding,
      fontWeight: 700,
      fontSize: 15,
      lineHeight: "12px",
    }),
  },
  {
    name: "UnitDetailTableItem",
  }
);

const UnitDetailTableItem = ({ project, dataUnit, ...props }) => {
  const { ThemeData } = project;
  const location = useLocation();
  const isPortraitMode = portraitMode();
  const showAvailability = project.settings?.showAvailability ?? false;
  const isFloorplan = dataUnit.__typename === "FloorPlan";

  const unitOrFloorplanStatus = isFloorplan
    ? dataUnit.floorPlanStatus
    : dataUnit.status;

  const isAvailable = isAvailableStatus(
    isFloorplan ? dataUnit.floorPlanStatus : dataUnit.status
  );

  const classes = useStyles({ isAvailable, ThemeData, isPortraitMode });
  const { savedHomes, toggleSavedHome, savedHomesIncludes } = useSavedHomes();
  let hooks = {
    savedHomes,
    toggleSavedHome,
    savedHomesIncludes,
  };

  const isIndustrial =
    project && project.projectType === ConstantData.projectType.industrial;

  return (
    <>
      <div
        id={
          dataUnit.threeDId === props.selectedUnitId
            ? `unit-detail-tableItem-${props.itemIdx}`
            : `unit-detail-tableItem-active-${props.itemIdx}`
        }
        className={`${classes.wrapper} ${
          props.itemIdx % 2 === 0
            ? classes.rowBGColourOne
            : classes.rowBGColourTwo
        } ${
          dataUnit.threeDId === props.selectedUnitId ? classes.selectedItem : ""
        }`}
        onClick={() => {
          if (props.isToAllowSelection) {
            props.setSelectedUnitId(dataUnit.threeDId);
            if (props.setInteractModalFrom)
              props.setInteractModalFrom("carousel");
          }
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 10,
          }}
        >
          {showAvailability && getStatusClass(unitOrFloorplanStatus) !== "" ? (
            <div
              className={`${classes.availabilityText} ${
                classes[getStatusClass(unitOrFloorplanStatus)]
              }`}
            >
              {unitOrFloorplanStatus.replace(/([A-Z])/g, " $1").toUpperCase()}
            </div>
          ) : null}
          <div className={classes.infoTextWrapper}>
            <div className={classes.planAvailableWrapper}>
              {isIndustrial ? (
                <div className={classes.planText}>
                  {isFloorplan
                    ? dataUnit.floorPlan.name
                    : project.settings?.showFullTitle
                    ? `${dataUnit.floorPlan.name} | Unit ${dataUnit.unitNumber}`
                    : dataUnit.unitNumber}
                </div>
              ) : (
                <div className={classes.planText}>
                  {dataUnit.floorPlan.name}
                  {project.settings.showUnits === false &&
                  !location.pathname.includes("/proximation") ? (
                    ``
                  ) : (
                    <>
                      {dataUnit.unitNumber !== null
                        ? `| ${
                            project?.projectType?.includes("industrial")
                              ? ``
                              : `Unit`
                          } ${dataUnit.unitNumber}`
                        : ``}
                    </>
                  )}
                </div>
              )}
            </div>
            <div className={classes.planInfoWrapper}>
              <div>
                {isIndustrial ? (
                  <>{capitalizeOptions(dataUnit.floorPlan.floorPlanType)}</>
                ) : (
                  convertBedrooms(
                    dataUnit.floorPlan.bedRooms,
                    dataUnit.floorPlan.juniorBedRooms,
                    dataUnit.floorPlan.studio,
                    dataUnit.floorPlan.flex
                  )
                )}
              </div>
              <span className={classes.separator}>&middot;</span>
              <div>
                {dataUnit.floorPlan.interiorSize} {dataUnit.floorPlan.sizeUnit}
              </div>

              <div>
                {isIndustrial && !isFloorplan ? (
                  <>
                    <span className={classes.separator}>&middot;</span>
                    {`${getOrdinal(dataUnit.floor)} ${
                      dataUnit.floor >= 0 ? "Floor" : ""
                    }`}{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginRight: 10,
          }}
        >
          <div className={classes.moreDetailsButtonArea}>
            <ButtonOutline
              ThemeData={ThemeData}
              id={`more-details-button-list-view-${props.itemIdx}`}
              className={classes.moreDetails}
              label="Details"
              padding={10}
              textColour={ThemeData.colours.primaryAccent}
              hoverTextColour={ThemeData.colours.white}
              border="1px solid #EEEDE6"
              backgroundColour={ThemeData.colours.white}
              onClick={() => {
                if (!isAvailable) return;
                if (dataUnit.__typename === "Unit") {
                  props.setSelectedUnitId(dataUnit.threeDId);
                  props.setSelectedFloorplanId(-1);
                } else {
                  props.setSelectedUnitId(-1);
                  props.setSelectedFloorplanId(dataUnit.id);
                }
                props.toggleIsUnitDetailOpen();
              }}
              borderRadius={4}
            />
          </div>
          <BookMarkButton
            ThemeData={ThemeData}
            dataUnit={dataUnit}
            hooks={hooks}
            height={30}
            width={20}
            margin={"0px 10px 0px 0px"}
          />
        </div>
      </div>
    </>
  );
};

UnitDetailTableItem.defaultProps = {
  isToAllowSelection: false,
};

export default UnitDetailTableItem;
