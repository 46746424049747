import React, { useState } from "react";

import { createUseStyles } from "react-jss";
import { motion } from "framer-motion";
import ConstantData from "data/ConstantData";
import ButtonGroup from "./ButtonGroup";
import ButtonOutline from "./ButtonOutline";
import UnitInfoIconList from "./UnitInfoIconList";
import VirtualTour from "./VirtualTour";
import ViewStudy from "./ViewStudy";
import useSavedHomes from "hooks/useSavedHomes";
import useOpenClosePane from "hooks/useOpenClosePane";
import { getImageBySize } from "util/imgFunctions";
import navArrowLeftDark from "../img/nav_arrow_left_dark.svg";
import SlideUpGenericHolder from "./SlideUpGenericHolder";
import PageComponents from "./PageComponents";
import crossIcon from "../img/cross_icon.svg";
import { portraitMode } from "util/helper";
import FloorplanPhoto from "./FloorplanPhoto";
import SiteMap from "./SiteMap";

const useStyles = createUseStyles(
  {
    wrapper: (props) => ({
      position: "absolute",
      top: props.isPortraitMode ? "" : 100,
      bottom: props.isPortraitMode ? 41 : "",
      left: "calc((100vw - 969px) / 2)",
      width: props.isPortraitMode ? 969 : "-webkit-fill-available",
      height: props.isPortraitMode ? 1358 : "calc(100vh - 100px)",
      color: props.ThemeData.colours.secondaryFont,
      backgroundColor: props.ThemeData.colours.white,
      fontFamily: props.ThemeData.fonts.copy,
      fontWeight: 600,
      borderRadius: props.isPortraitMode ? 20 : 0,
      border: `1px solid ${props.ThemeData.colours.vibrantGreen}`,
      "& ::-webkit-scrollbar": {
        display: "none",
      },
      overflow: "hidden",
      boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.25)",
    }),
    buttonGroup: {
      "@media (max-width: 1200px)": {
        margin: "15px 20px 0 20px",
      },
      margin: (props) =>
        props.isPortraitMode ? "25px 20px 0 20px" : "10px 20px 0 20px",
      left: "0",
      right: "0",
      top: "-20px",
    },
    header: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: 100,
      backgroundColor: (props) => props.ThemeData.colours.primaryHeaderBg,
    },
    backButton: {
      width: 100,
      height: 100,
      cursor: "pointer",
      color: "#FFF",
      background: "no-repeat center",
      backgroundImage: `url( ${navArrowLeftDark} )`,
    },
    twoColumn: (props) => ({
      display: "flex",
      flexDirection: props.isPortraitMode ? "column" : "row",
      justifyContent: props.isPortraitMode ? "auto" : "center",

      height: props.isPortraitMode ? "auto" : "calc(100vh - 100px)",
    }),
    column: (props) => ({
      flex: props.isPortraitMode ? "0 1 auto" : "1 0 auto",

      boxSizing: "border-box",
      width: props.isPortraitMode ? "auto" : "calc( 50% - 200px )",

      // paddingTop: '40px',
    }),
    leftColumn: (props) => ({
      position: "relative",
      overflowY: props.isPortraitMode ? "hidden" : "scroll",
      backgroundColor: (props) => props.ThemeData.colours.white,
      height: 900,
      marginTop: 30,
    }),
    rightColumn: (props) => ({
      // overflowY: 'scroll',
      // padding: props.isPortraitMode ? '20px 0 0 0' : '12px 50px 0 50px',
      // backgroundColor: props.ThemeData.colours.secondaryBgHighContrast,
      height: props.isPortraitMode ? 450 : "auto",
    }),
    floorPlanImgExpanded: {
      height: "calc(100vh - 100px)",
      width: "100%",
      objectFit: "contain",
    },
    floorPlanButtons: {
      margin: "0 auto",
      maxWidth: 550,
    },
    keyplan: {
      // paddingTop: props => props.isPortraitMode ? 25 : 0,
      display: "flex",
      justifyContent: "center",
      // width: 214,
      // height: 198
      width: 330,
      height: 250,
    },
    floorplanModalContainer: {
      display: "flex",
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,0.8)",
      width: "100%",
      height: "100vh",
      overflow: "hidden",
      zIndex: 99999,
      justifyContent: "center",
      alignItems: "center",
    },
    floorplanModalContent: {
      display: "block",
      justifyContent: "center",
      alignItems: "center",
    },
    floorplanModalHeader: {
      display: "flex",
      width: "100%",
      height: "60px",
      borderBottom: "1px solid #EEEDE6",
      alignItems: "center",
      backgroundColor: (props) => props.ThemeData.colours.white,
      "& > img": {
        alignSelf: "flex-end",
        height: 14,
        width: 14,
        marginRight: 28,
        margin: "auto 0",
        cursor: "pointer",
      },
      "& > div": {
        alignSelf: "center",
        flexGrow: "3",
        textAlign: "center",
        paddingLeft: 42,
        fontSize: 18,
        fontFamily: (props) => props.ThemeData.fonts.title,
        color: (props) => props.ThemeData.colours.primaryBg,
      },
    },
    nArrow: {
      flex: "0 0 auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",

      marginRight: 50,
    },
    nArrowArrow: {
      flex: "0 0 auto",

      width: 20,
      height: 33,
    },
    nArrowN: (props) => ({
      flex: "0 0 auto",

      color: props.ThemeData.colours.primaryBg,
      fontFamily: props.ThemeData.fonts.titleSub,
      fontSize: 23,
    }),
    floorplanName: (props) => ({
      color: props.ThemeData.colours.secondaryTitleFont,

      textAlign: "left",
      fontFamily: props.ThemeData.fonts.title,
      fontSize: 40,
    }),
    hr: {
      // width: '100%',
      "@media (max-width: 1200px)": {
        margin: "15px 20px 0 20px",
      },
      margin: (props) =>
        props.isPortraitMode ? "25px 70px 0 70px" : "10px 20px 0 20px",
      borderTop: (props) =>
        `1px ${props.ThemeData.colours.lighterBorderDarkOnLight} solid`,
    },
    actionButtons: {
      width: "100%",
      marginTop: (props) => (props.isPortraitMode ? 0 : 10),
      marginInline: "auto",
    },
    detailListItems: {
      margin: 0,
      marginTop: 50,
      padding: 0,

      display: "flex",
      justifyContent: "left",

      listStyleType: "none",

      textAlign: "left",
      letterSpacing: ".05em",
      // "& div": {
      //     fontFamily: props => props.ThemeData.fonts.copy,
      // }
    },
    detailList: {},
    detailListItem: {
      width: 150,

      flex: "0 0 auto",
    },
    detailListItemIcon: {},
    detailListItemInline: {
      display: "inline-block",
      marginRight: 10,
    },
    detailListItemTitle: (props) => ({
      color: props.ThemeData.colours.grey,

      fontFamily: props.ThemeData.fonts.titleSub,
      fontSize: 10,
      textTransform: "uppercase",
    }),
    detailListItemData: {
      marginTop: 10,

      letterSpacing: ".05em",
      color: (props) => props.ThemeData.colours.primaryBg,
      fontSize: 16,
    },
    detailListItemDataModifier: {
      // fontFamily: ThemeData.fonts.copy,
    },
    unitDescription: (props) => ({
      // margin: '20px 10px 0 10px',
      margin: "20px 87px 0 87px",
      color: props.ThemeData.colours.grey,

      textAlign: "left",
      lineHeight: "1.9em",
      fontFamily: props.ThemeData.fonts.copy,
      fontSize: 14,
      letterSpacing: ".025em",
    }),
    furtherUnitDetailsButtons: {
      marginTop: 40,
    },
    keyplanImg: {
      // flex: '0 0 auto',
      // minWidth: 160,
      // maxWidth: '50%',
      // maxHeight: '100%',
      // objectFit: 'contain',
      // marginBottom: 5,
    },
    bookMarkWrap: {
      paddingRight: "100px",
    },
    disclaimer: {
      display: "flex",
      textAlign: "center",
      fontSize: 8,
      fontStyle: "italic",
      fontWeight: 100,
      margin: "30px 100px",
    },
    modalContainer: {
      position: "fixed",
      backgroundColor: "rgba(255, 255, 255, 0.50)",
      backdropFilter: "blur(6px)",
      width: "100%",
      // height: '100vh',
      height: "calc(100vh - 250px)",
      top: 150,
      display: "flex",
      color: "black",
      overflow: "hidden",
      zIndex: 9999,
      justifyContent: "center",
    },
    unitCarousel: {
      padding: "0 20px",
    },
    planText: {
      fontWeight: "700",
      fontSize: "24px",
      lineHeight: "47px",
      whiteSpace: "nowrap",
      color: "inherrit",
      fontFamily: (props) => props.ThemeData.fonts.title,
    },
    bedsText: {
      fontWeight: "700",
      fontSize: "10px",
      lineHeight: "15px",
      color: "inherit",
    },
    unitDetails: {
      display: "flex",
      justifyContent: "center",
      overflowX: "auto",
    },
    button: {
      borderRadius: 10,
      margin: "0 10px",
      width: 100,
    },
    buttonsWrapper: {
      position: "absolute",
      bottom: 0,
      width: "100%",
    },
    carouselImg: {
      width: "auto",
      maxWidth: 919,
      maxHeight: 700,
    },
    photoTitle: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      marginTop: 5,
      "& span": {
        fontFamily: (props) => props.ThemeData.fonts.title,
        fontSize: 16,
        textTransform: "capitalize",
      },
    },
    pageIndicator: (props) => ({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      margin: "15px 0 20px 0",
      gap: 4,
      "& > span": {
        display: "flex",
        height: 6,
        width: 6,
        borderRadius: 4,
        border: `1px solid ${props.ThemeData.colours.branding}`,
        cursor: "pointer",
      },
    }),
    selectedIndicator: (props) => ({
      backgroundColor: props.ThemeData.colours.branding,
    }),
    pageIndicatorLabel: (props) => ({
      color: props.ThemeData.colours.branding,
      fontSize: 12,
      fontWeight: 700,
      letterSpacing: "1.8px",
      display: "flex",
      justifyContent: "center",
      width: "100%",
    }),
    swiper: (props) => ({
      "--swiper-theme-color": props.ThemeData.colours.white,
      "--swiper-navigation-size": "50px",
      borderRadius: 20,
      width: "100%",
      height: 700,
      "& .swiper-button-next, & .swiper-button-prev": {
        marginTop: "340px",
        display: "none",
      },
    }),
    swiperSlideImg: {
      width: 10,
    },
    bottomButtons: {
      borderTop: (props) => `1px solid ${props.ThemeData.colours.vibrantGreen}`,
      "&:hover": {
        backgroundColor: (props) => props.ThemeData.colours.vibrantGreen,
        color: (props) => props.ThemeData.colours.white,
      },
    },
    featureButton: {
      borderLeft: (props) =>
        `1px solid ${props.ThemeData.colours.vibrantGreen}`,
    },
    floorPlan: {
      height: 800,
    },
    floorPlanFrame: {
      width: "100%",
    },
    mainFloorplanImg: {
      width: "auto",
      height: 800,
    },
    titleText: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      marginBottom: 10,
    },
    closeButton: (props) => ({
      borderTop: `1px solid ${
        props.ThemeData.colours.filterColourPortrait
          ? props.ThemeData.colours.filterColourPortrait
          : props.ThemeData.colours.vibrantGreen
      }`,
      padding: 14,
      color: props.ThemeData.colours.filterColourPortrait
        ? props.ThemeData.colours.filterColourPortrait
        : props.ThemeData.colours.vibrantGreen,
      fontSize: 14,
      "&:active": {
        backgroundColor: props.ThemeData.colours.vibrantGreen,
        color: props.ThemeData.colours.white,
      },
      "&:hover": {
        backgroundColor: props.ThemeData.colours.vibrantGreen,
        color: props.ThemeData.colours.white,
      },
    }),
  },
  {
    name: "UnitDetailPortrait",
  }
);

function RenderUnitDetailPortrait(
  isPortraitMode,
  project,
  unitId,
  unit,
  classes,
  error,
  isOpen,
  toggleUnitDetailFcn,
  hooks,
  setUnitFromCarousel,
  setGlobalUnit,
  isSavedHomesCompareListOpen,
  isSaveHomesInteractive,
  isCompareHomesInteractive,
  unitDetailRef
) {
  const { ThemeData } = project;
  const wrapperAnimVariants = {
    open: {
      y: 0,
      zIndex: isSavedHomesCompareListOpen ? 9999 : 999,
      display: "block",
    },
    closed: {
      y: "100vh",
      display: "block",
      transitionEnd: {
        zIndex: -2,
        display: "none",
      },
    },
  };
  const { disclaimerData } = ConstantData;
  const [isFloorplanModalOpen, setIsFloorplanModalOpen] = useState(false);
  const [doShowFloorplanImg, setDoShowFloorplanImg] = useState("floorPlan");

  const toggleDoShowFloorplanImg = (currentPlan) => {
    switch (currentPlan) {
      case "floorPlan":
        setDoShowFloorplanImg("floorPlan");
        break;
      default:
        break;
    }
  };
  if (!unit) return <></>;

  const getMediaUrl = (data, field, defaultUrl) => {
    if (!data || !field) return;

    if (data.__typename === "unit") {
      return data[field]
        ? data[field]
        : data.floorPlan[field]
        ? data.floorPlan[field]
        : defaultUrl;
    } else {
      return data[field] ? data[field] : defaultUrl;
    }
  };
  const floorplan = unit.__typename === "FloorPlan" ? unit : unit.floorPlan;
  const virtualTourUrl = getMediaUrl(
    unit,
    "virtualTour",
    project.settings?.threeDUrl
  );
  const viewStudyUrl = getMediaUrl(
    unit,
    "viewStudyUrl",
    project.settings?.viewStudyUrl
  );
  const floorplanPhoto = getImageBySize(floorplan.photo, "large");
  const keyPlanPhoto = getImageBySize(floorplan.keyPlan, "small");

  const _renderUnitDetail = () => {
    return (
      <motion.div
        id="unit-details"
        className={classes.wrapper}
        initial={false}
        animate={isOpen ? "open" : "closed"}
        variants={wrapperAnimVariants}
        transition={{
          type: "linear",
          duration: 0.5,
        }}
        ref={
          isSaveHomesInteractive || isCompareHomesInteractive
            ? unitDetailRef
            : null
        }
      >
        <div className={classes.disclaimer}>
          {disclaimerData.disclaimer_2}
          {disclaimerData.disclaimer_3}
        </div>
        <div className={classes.twoColumn}>
          <div className={`${classes.column} ${classes.leftColumn}`}>
            <div className={classes.floorPlan}>
              <FloorplanPhoto ThemeData={project.ThemeData} data={floorplan} />
            </div>
            <div className={classes.titleText}>
              {(isSaveHomesInteractive ||
                isCompareHomesInteractive ||
                unit.__typename === "Unit") && (
                <div className={classes.planText}>Unit {unit.name} |&nbsp;</div>
              )}
              <div className={classes.planText}>
                Plan {floorplan && floorplan.name}
              </div>
            </div>
            <div className={classes.floorPlanButtons}>
              <ButtonGroup
                justifyContent={
                  viewStudyUrl && virtualTourUrl ? "space-between" : "center"
                }
              >
                {(viewStudyUrl || virtualTourUrl) && (
                  <ButtonOutline
                    ThemeData={ThemeData}
                    className={classes.button}
                    id="floorplan-unit-details"
                    label={
                      unit.floorPlan?.carouselPhoto?.length > 0
                        ? "Floor Plans"
                        : "Floor Plan"
                    }
                    padding={"13px 15px"}
                    fontSize={10}
                    textTransform={"capitalize"}
                    border={`1px solid ${ThemeData.colours.primaryAccent}`}
                    textColour={ThemeData.colours.vibrantGreen}
                    backgroundColour={ThemeData.colours.white}
                    hoverTextColour={ThemeData.colours.vibrantGreen}
                    hoverBackgroundColour={ThemeData.colours.white}
                    firstInColl
                    onClick={() => toggleDoShowFloorplanImg("floorPlan")}
                    isActive={doShowFloorplanImg === "floorPlan"}
                  />
                )}
                {viewStudyUrl && (
                  <ButtonOutline
                    ThemeData={ThemeData}
                    className={classes.button}
                    id="enjoy-the-view-unit-details"
                    label="Views"
                    padding={"13px 15px"}
                    fontSize={10}
                    textTransform={"capitalize"}
                    border={`1px solid ${ThemeData.colours.primaryAccent}`}
                    textColour={ThemeData.colours.primaryAccent}
                    hoverTextColour={ThemeData.colours.white}
                    borderColour={ThemeData.colours.lighterBorderDarkOnLight}
                    to="."
                    firstInColl
                    onClick={() => {
                      hooks.toggleIsViewStudySlideUpOpen();
                      toggleDoShowFloorplanImg("enjoyTheView");
                    }}
                  />
                )}
                {virtualTourUrl && (
                  <ButtonOutline
                    ThemeData={ThemeData}
                    className={classes.button}
                    label="Virtual Tour"
                    padding={"13px 15px"}
                    fontSize={10}
                    border={`1px solid ${ThemeData.colours.primaryAccent}`}
                    textTransform={"capitalize"}
                    textColour={ThemeData.colours.primaryAccent}
                    hoverTextColour={ThemeData.colours.white}
                    borderColour={ThemeData.colours.lighterBorderDarkOnLight}
                    to="."
                    firstInColl
                    onClick={() => {
                      hooks.toggleIsVirtualTourOpen();
                      toggleDoShowFloorplanImg("virtualTour");
                    }}
                  />
                )}
                {project?.settings.showFeaturesButton && (
                  <ButtonOutline
                    ThemeData={ThemeData}
                    className={classes.button}
                    label="Feature"
                    padding={"13px 15px"}
                    fontSize={10}
                    border={`1px solid ${ThemeData.colours.primaryAccent}`}
                    textTransform={"capitalize"}
                    textColour={ThemeData.colours.primaryAccent}
                    hoverTextColour={ThemeData.colours.white}
                    borderColour={ThemeData.colours.lighterBorderDarkOnLight}
                    to="."
                    firstInColl
                    onClick={() => {
                      hooks.toggleIsVirtualTourOpen();
                      toggleDoShowFloorplanImg("virtualTour");
                    }}
                  />
                )}
              </ButtonGroup>
            </div>
          </div>
          <div className={`${classes.column} ${classes.rightColumn}`}>
            <div className={classes.unitDetails}>
              <div>
                <UnitInfoIconList
                  project={project}
                  dataUnit={unit}
                  isUnitDetailPortrait={true}
                />
                {floorplan.description && (
                  <div className={classes.unitDescription}>
                    {floorplan.description}
                  </div>
                )}
              </div>
              <div>
                {keyPlanPhoto ? (
                  <div className={classes.keyplan}>
                    <img
                      className={classes.keyplanImg}
                      src={keyPlanPhoto.link}
                      alt={floorplan.name}
                    />
                  </div>
                ) : (
                  <SiteMap
                    project={project}
                    selectedUnitId={unit.threeDId}
                    floorplan={unit.__typename === "FloorPlan" ? unit : null}
                    detailCard={true}
                    imageWidth={300}
                  />
                )}
              </div>
            </div>

            {isPortraitMode && (
              <div className={classes.buttonsWrapper}>
                <div
                  className={classes.closeButton}
                  onClick={() => {
                    toggleUnitDetailFcn();
                    hooks.toggleSetIsSaveHomesInteractive(false);
                    hooks.toggleSetIsCompareHomesInteractive(false);
                  }}
                >
                  Close
                </div>
              </div>
            )}
          </div>
        </div>
      </motion.div>
    );
  };

  return (
    <>
      {isOpen && (
        <div className={classes.modalContainer}>{_renderUnitDetail()}</div>
      )}
      {isFloorplanModalOpen && floorplan && floorplanPhoto && (
        <div className={classes.floorplanModalContainer}>
          <div className={classes.floorplanModalContent}>
            <div className={classes.floorplanModalHeader}>
              <div>{floorplan.name}</div>
              <img
                onClick={() => setIsFloorplanModalOpen(false)}
                src={crossIcon}
                alt="close"
              />
            </div>
            <img
              className={classes.floorPlanImgExpanded}
              src={floorplanPhoto.link}
              alt={floorplan.name}
              width={floorplanPhoto.width}
              height={floorplanPhoto.height}
            />
            <div>
              <p className={classes.disclaimer}>
                {disclaimerData.disclaimer_2}
              </p>
              <p className={classes.disclaimer}>
                {disclaimerData.disclaimer_3}
              </p>
            </div>
          </div>
        </div>
      )}
      <VirtualTour
        project={project}
        isOpen={hooks.isVirtualTourOpen}
        src={virtualTourUrl && virtualTourUrl}
        toggleVirtualTourFcn={() => hooks.toggleIsVirtualTourOpen()}
        doUseHeader={true}
      />

      <SlideUpGenericHolder
        project={project}
        slideUpIsOpen={hooks.isFeaturesSlideUpOpen}
        toggleSlideUpIsOpen={hooks.toggleIsFeaturesSlideUpOpen}
        doUseHeader={true}
      >
        <PageComponents
          project={project}
          currentSection={project.navs.filter((n) => n.slug === "features")[0]}
          currentNav={project.navs.filter((n) => n.slug === "features")[0]}
          overrideUrlContentPageSlug="home-features"
          // overrideUrlContentPageSlug={project.navs.filter((n) => n.slug === "features")[0].slug}
          doRenderHeader={false}
          noRenderSaveHomes={true}
          toggleSlideUpIsOpen={hooks.toggleIsFeaturesSlideUpOpen}
        />
      </SlideUpGenericHolder>
      <ViewStudy
        project={project}
        isOpen={hooks.isViewStudySlideUpOpen}
        toggleViewStudyFcn={hooks.toggleIsViewStudySlideUpOpen}
        viewUrl={viewStudyUrl && viewStudyUrl}
        doUseHeader={true}
      />
    </>
  );
}

const UnitDetailPortrait = ({
  project,
  unitId,
  floorplanId,
  isOpen,
  toggleUnitDetailFcn,
  setSelectedUnitId,
  isSavedHomesCompareListOpen,
  ...props
}) => {
  const isPortraitMode = portraitMode();
  const classes = useStyles({
    isOpen,
    ThemeData: project.ThemeData,
    isPortraitMode,
  });
  const { savedHomes, toggleSavedHome, savedHomesIncludes } = useSavedHomes();

  const [unitState, setUnit] = useState(unitId);

  const { isVirtualTourOpen, toggleIsVirtualTourOpen } =
    useOpenClosePane("VirtualTour");
  const { isViewStudySlideUpOpen, toggleIsViewStudySlideUpOpen } =
    useOpenClosePane("ViewStudySlideUp");
  const { isFeaturesSlideUpOpen, toggleIsFeaturesSlideUpOpen } =
    useOpenClosePane("FeaturesSlideUp");

  let hooks = {
    savedHomes,
    toggleSavedHome,
    savedHomesIncludes,
    isVirtualTourOpen,
    toggleIsVirtualTourOpen,
    isViewStudySlideUpOpen,
    toggleIsViewStudySlideUpOpen,
    isFeaturesSlideUpOpen,
    toggleIsFeaturesSlideUpOpen,
    toggleSetIsSaveHomesInteractive: props.toggleSetIsSaveHomesInteractive,
    toggleSetIsCompareHomesInteractive:
      props.toggleSetIsCompareHomesInteractive,
  };

  unitId = unitState === -1 || unitState !== unitId ? unitId : unitState;
  let unit = null;
  if (unitId !== -1 && project.units.length > 0) {
    unit = project.units.filter((unit) => unit.threeDId === unitId)[0];
  } else if (floorplanId !== -1) {
    unit = project.floorPlans.filter((f) => f.id === floorplanId)[0];
  }

  const unitDetailRef = props.unitDetailRef;
  const isSaveHomesInteractive = props.isSaveHomesInteractive;
  const isCompareHomesInteractive = props.isCompareHomesInteractive;
  return RenderUnitDetailPortrait(
    isPortraitMode,
    project,
    unitId,
    unit,
    classes,
    null,
    isOpen,
    toggleUnitDetailFcn,
    hooks,
    setUnit,
    setSelectedUnitId,
    isSavedHomesCompareListOpen,
    isSaveHomesInteractive,
    isCompareHomesInteractive,
    unitDetailRef
  );
};

export default UnitDetailPortrait;
