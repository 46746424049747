import React, {
  useState,
  useMemo,
  useEffect,
  useRef
} from 'react';

import {
  createUseStyles,
} from 'react-jss';

import {
  // motion
} from 'framer-motion';

import UnitInfoLine from './UnitInfoLine';
import ButtonGroup from 'components/ButtonGroup';
import ButtonOutline from 'components/ButtonOutline';
import UnitInfoIconListPortrait from './UnitInfoIconListPortrait';
import { ReactComponent as FingerTap } from 'img/one_finger_tap.svg';
import {
  Controller,
  Pagination,
  Navigation,
} from 'swiper';
import { ReactComponent as IconTrash } from 'img/icon_trash.svg';

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

const useStyles = createUseStyles(
  {
    wrapper: props => ({
      position: 'relative',
      width: props.isCompareHomes ? 470 : (props.isPortraitMode ? 300 : 575),
      height: props.isPortraitMode ? (props.isSaveHome ? 327 : 951) : 'none',
      margin: props.isPortraitMode ? (props.isSaveHome ? 23 : 0) : 0,
      ...props.ThemeData.styles.hoverCard,
      borderRadius: props.isPortraitMode ? '10px' : '0',
      boxShadow: 'none',

      '@media (min-width: 3800px)': {
        minHeight: 800,
        maxHeight: 800,
      },
    }),
    contentContainer: props => ({
      minHeight: props.isPortraitMode ? 0 : 530,
      maxHeight: props.isPortraitMode ? '' : 590,
      display: 'flex',
      flexDirection: 'column',
      boxSizing: props.isPortraitMode ? '' : 'border-box',
      padding: props.isSaveHome ? 20 : '20px 20px 0 20px',
      textAlign: 'left',
      marginBottom: props.isPortraitMode && props.isSaveHome ? 5 : 'none',
    }),
    hr: {
      width: '100%',

      marginTop: 35,

      borderTop: props => props.isCompareHomes ? 'none' : `1px ${props.ThemeData.colours.eggshellTextBg} solid`,
      '@media (max-width: 1200px)': {
        marginTop: 10
      }
    },
    removeHomeButton: props => ({
      position: 'absolute',
      top: props.isPortraitMode ? 10 : 20,
      right: props.isPortraitMode ? 7 : 20,

      width: props.isPortraitMode ? 26 : 48,
    }),
    floorplanContainer: props => ({
      position: 'relative',
      // flex: (props.isPortraitMode && !props.isSaveHome) ? '' : '0 0 auto',
      // height: 490,
      height: 536,
      width: 430,
      marginLeft: props.isPortraitMode ? 0 : 0,
      marginRight: props.isPortraitMode ? 0 : 0,
      borderRadius: 4,
      marginBottom: 29,
      justifyContent: 'center',
    }),
    floorplanImg: props => ({
      height: props.isPortraitMode ? (props.isSaveHome ? '100%' : 450) : '100%',
      width: '100%',

      objectFit: 'contain',
    }),
    carouselImg: props => ({
      width: 400,
      height: 'auto',
      maxHeight: 453,

      objectFit: 'contain',
    }),
    planButtonsContainer: {
      marginTop: 10,
      '@media (max-width: 1200px)': {
        marginTop: 0
      }
    },
    floorKeyPlanButtons: {
      borderRadius: 50,

      '@media (min-width: 3800px)': {
        fontSize: '24px',
        height: '60px'
      },
    },
    info: {
      flex: '0.02 0 auto',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 31,
      '& div': {
        fontSize: 20,
        fontFamily: props => props.ThemeData.fonts.title,
      }
    },
    unitInfoPlanNameTitle: {
      marginTop: 0,

    },
    unitInfoLine: {
      color: props => props.ThemeData.colours.black,

      fontSize: props => props.isPortraitMode ? (props.isSaveHome ? 12 : 24) : 16,
      '@media (min-width: 3800px)': {
        fontSize: '36px',
        height: '70px'
      },
    },
    unitInfoIconList: {
      color: props => props.ThemeData.colours.black,

    },
    viewDetailButtonArea: {
      paddingTop: 10,
    },
    removeButton: {
      width: '100%',
      height: '31px',
      borderRadius: '0px 0px 10px 10px',
    },
    removeCompareButton: {
      borderRadius: '0 0 10px 10px',
      color: props => props.ThemeData.colours.white
    },
    addCompareButton: {
      borderTop: props => `1px solid ${props.ThemeData.colours.vibrantGreen}`,
      borderRadius: '0 0 10px 10px',
    },
    floorplanFrame: {
      width: '100%'
    },
    swiperCarousel: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    },
    photoTitle: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      '& span': {
        fontFamily: props => props.ThemeData.fonts.title,
        fontSize: 12,
        textTransform: 'capitalize'
      }
    },
    imageScrollerLeft: {
      position: 'absolute',
      top: '50%'
    },
    imageScrollerRight: {
      position: 'absolute',
      top: '50%',
      right: 0,
    },
    floorLabel: {
      textAlign: 'center',
      fontSize: 12,
      fontWeight: 700,
    },
    pageIndicator: props => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '25px 0 20px 0',
      gap: 4,
      '& > span': {
        display: 'flex',
        height: 6,
        width: 6,
        borderRadius: 4,
        border: `1px solid ${props.ThemeData.colours.branding}`,
        cursor: 'pointer',
      },
    }),
    selectedIndicator: props => ({
      backgroundColor: props.ThemeData.colours.branding,
    }),
    pageIndicatorLabel: props => ({
      color: props.ThemeData.colours.branding,
      fontSize: 12,
      fontWeight: 700,
      letterSpacing: '1.8px',
      display: 'flex',
      justifyContent: 'center',
      width: '100%'
    }),
    swiper: props => ({
      '--swiper-theme-color': props.ThemeData.colours.white,
      '--swiper-navigation-size': '50px',
      borderRadius: 20,
      width: '100%',
      '& .swiper-button-next, & .swiper-button-prev': {
        marginTop: '340px',
        display: 'none',
      }
    }),
    swiperSlideImg: props => ({
      width: 10,
    }),
  },
  {
    name: 'UnitDetailCompareCardPortrait',
  }
);

// data == a single Unit
const UnitDetailCompareCardPortrait = ({ project, dataUnit, ...props }) => {
  const { ThemeData } = project;

  const [doShowFloorplanImg, setDoShowFloorplanImg] = useState('floorPlan');

  const toggleDoShowFloorplanImg = (currentPlan) => {
    switch (currentPlan) {
      case 'floorPlan':
        setDoShowFloorplanImg('floorPlan');
        break;
      case 'keyPlan':
        setDoShowFloorplanImg('keyPlan');
        break;
      default:
        break;
    }
  };
  if (dataUnit.__typename === 'FloorPlan') {
    dataUnit = {
      id: dataUnit.id,
      name: dataUnit.name,
      exteriorSize: dataUnit.exteriorSize,
      interiorSize: dataUnit.interiorSize,
      sizeUnit: dataUnit.sizeUnit,
      floorPlan: dataUnit,
      status: '',
      bedRooms: dataUnit.bedRooms,
      bathRooms: dataUnit.bathRooms,
      unitNumber: null,
      parking: null,
      __typename: 'FloorPlan'
    };
  }

  const isEnzo = project.slug === 'liveenzo';
  const classes = useStyles({ ThemeData, isPortraitMode: props.isPortraitMode, isSaveHome: props.isSaveHome, isCompareHomes: props.isCompareHomes });

  // NEW
  const [swiperSlideIndex, setSwiperSlideIndex] = useState(0);
  const [_, setMediaSwiper] = useState(null);
  const [textSwiper] = useState(null);
  const [photoTitle, setPhotoTitle] = useState('');
  const swiperRef = useRef(null);

  const textMediaPairs = useMemo(() => {
    if (dataUnit.floorPlan && dataUnit.floorPlan.carouselPhoto?.length > 0) {
      return dataUnit.floorPlan.carouselPhoto;
    }
    return null;
  }, [dataUnit]);

  const setMediaSwiperHandler = () => {
    if (swiperRef.current) {
      setMediaSwiper(swiperRef.current.swiper);
    }
  };
  useEffect(() => {
    if (dataUnit.floorPlan && dataUnit.floorPlan.carouselPhoto?.length > 0) {
      setPhotoTitle(dataUnit.floorPlan.carouselPhoto[swiperSlideIndex].fileTitle);
    }
  }, [swiperSlideIndex, dataUnit.floorPlan]);

  return (
    <>
      <div className={`${classes.wrapper} ${props.className}`}>
        <div className={classes.contentContainer}>
          {<div className={classes.floorplanContainer}>
            {dataUnit && dataUnit.floorPlan && <>
              {doShowFloorplanImg === 'floorPlan' && isEnzo &&
                <>
                  {dataUnit.floorPlan.carouselPhoto?.length > 0 ?
                    <>
                      <Swiper
                        key={dataUnit.floorPlan.carouselPhoto.length}
                        className={classes.swiper}
                        modules={[Controller, Pagination, Navigation]}
                        onSwiper={setMediaSwiperHandler}
                        onSlideChange={(props) => setSwiperSlideIndex(props.activeIndex)}
                        controller={{ control: textSwiper }}
                        slidesPerView={1}
                        buttonWrapperStyle={{ marginTop: '100px' }}
                        mousewheel={true}
                        ref={swiperRef}
                      >
                        {dataUnit && dataUnit.floorPlan.carouselPhoto?.map((tmp, idx) => (
                          <SwiperSlide
                            className={classes.swiperSlideImg}
                            key={idx}
                          >
                            <div className={classes.swiperCarousel}>{/* style={{backgroundRepeat: 'no-repeat',  height: 453, border: '1px solid red', backgroundImage: `url(${tmp.link})`, backgroundPosition: 'center', backgroundSize: 'contain'}}>*/}
                              <img
                                className={classes.carouselImg}
                                src={tmp.link} alt={dataUnit.floorPlan.name} />
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>

                      <div className={classes.photoTitle}><span>{photoTitle}</span></div>
                      <div className={classes.pageIndicator}>
                        {textMediaPairs?.map((tmp, idx) =>
                          <span key={`indicator-${idx}`}
                                className={`${swiperSlideIndex === idx ? classes.selectedIndicator : ''}`}
                                onClick={() => {
                                  setSwiperSlideIndex(idx);

                                  if (swiperRef.current && swiperRef.current.swiper) {
                                    swiperRef.current.swiper.slideTo(idx);
                                  }
                                }}
                          ></span>
                        )}
                      </div>
                      <div className={classes.pageIndicatorLabel}><FingerTap width={18} height={18} stroke={ThemeData.colours.branding} /> Swipe to see more</div>
                    </>
                    :
                    <div className={classes.floorplanFrame}>
                      <img
                        className={classes.floorplanImg}
                        src={dataUnit.floorPlan.photo[0].link}
                        alt={dataUnit.floorPlan.name} />
                    </div>
                  }
                </>
              }

              {doShowFloorplanImg === 'floorPlan' && !isEnzo && dataUnit.floorPlan.photo &&
                <img
                  className={classes.floorplanImg}
                  src={dataUnit.floorPlan.photo[0].link}
                  alt={dataUnit.floorPlan.name} />
              }
              {doShowFloorplanImg === 'keyPlan' && dataUnit.floorPlan.keyPlan &&
                <img
                  className={classes.floorplanImg}
                  src={dataUnit.floorPlan.keyPlan[0].link}
                  alt={dataUnit.floorPlan.name} />
              }
            </>
            }
          </div>}
          {/* {((props.isPortraitMode && props.isSaveHome) || !props.isPortraitMode) && <div className={classes.floorplanContainer}>
						{dataUnit && dataUnit.floorPlan &&
							<>
								{doShowFloorplanImg === 'floorPlan' && dataUnit.floorPlan.photo &&
									<img
										className={classes.floorplanImg}
										src={dataUnit.floorPlan.photo[0].link}
										alt={dataUnit.floorPlan.name} />
								}
								{doShowFloorplanImg === 'keyPlan' && dataUnit.floorPlan.keyPlan &&
									<img
										className={classes.floorplanImg}
										src={dataUnit.floorPlan.keyPlan[0].link}
										alt={dataUnit.floorPlan.name} />
								}
							</>
						}
					</div>} */}
          <div className={classes.info}>
            <UnitInfoLine
              project={project}
              data={dataUnit}
              className={classes.unitInfoLine}
              isPortraitMode={props.isPortraitMode}
              doShowUnitNumber={true}
              doShowHomeType={false}
              doShowBaths={false}
              doShowIntSqft={false}
            />
          </div>
          <ButtonGroup
            justifyContent={'center'}
            marginLeft={12}
          >
            {!props.isPortraitMode && dataUnit && dataUnit.floorPlan.photo &&
              <div className={classes.planButtonsContainer}>
                <ButtonOutline
                  ThemeData={ThemeData}
                  fontSize={8}
                  border={`1px solid ${ThemeData.colours.vibrantGreen}`}
                  className={classes.floorKeyPlanButtons}
                  textColour={ThemeData.colours.primaryAccent}
                  backgroundColour={ThemeData.colours.white}
                  hoverTextColour={ThemeData.colours.white}
                  hoverBackgroundColour={ThemeData.colours.primaryAccent}
                  textTransform={'capitalize'}
                  firstInColl
                  onClick={() => toggleDoShowFloorplanImg('floorPlan')}
                  isActive={doShowFloorplanImg === 'floorPlan'}
                >
                  Floor Plan
                </ButtonOutline>
              </div>
            }
            {props.isPortraitMode && !props.isSaveHome && dataUnit && dataUnit.floorPlan.photo &&
              <div className={classes.planButtonsContainer}>
                <ButtonOutline
                  ThemeData={ThemeData}
                  fontSize={8}
                  padding={'8px 10px'}
                  border={`1px solid ${ThemeData.colours.vibrantGreen}`}
                  textColour={ThemeData.colours.vibrantGreen}
                  backgroundColour={ThemeData.colours.white}
                  hoverTextColour={ThemeData.colours.vibrantGreen}
                  hoverBackgroundColour={ThemeData.colours.white}
                  textTransform={'capitalize'}
                  isRounded
                  onClick={() => toggleDoShowFloorplanImg('floorPlan')}
                  isActive={doShowFloorplanImg === 'floorPlan'}
                >
                  Floor Plans
                </ButtonOutline>
              </div>
            }

            {!props.isPortraitMode && dataUnit && dataUnit.floorPlan.keyPlan &&
              <div className={classes.planButtonsContainer}>
                <ButtonOutline
                  ThemeData={ThemeData}
                  fontSize={8}
                  border={`1px solid ${ThemeData.colours.vibrantGreen}`}
                  className={classes.floorKeyPlanButtons}
                  textColour={ThemeData.colours.primaryAccent}
                  backgroundColour={ThemeData.colours.white}
                  hoverTextColour={ThemeData.colours.white}
                  hoverBackgroundColour={ThemeData.colours.primaryAccent}
                  textTransform={'capitalize'}
                  onClick={() => toggleDoShowFloorplanImg('keyPlan')}
                  isActive={doShowFloorplanImg === 'keyPlan'}
                >
                  Key Plan
                </ButtonOutline>
              </div>
            }
            {props.isPortraitMode && !props.isSaveHome && dataUnit && dataUnit.floorPlan.keyPlan &&
              <div className={classes.planButtonsContainer}>
                <ButtonOutline
                  ThemeData={ThemeData}
                  border={`1px solid ${ThemeData.colours.vibrantGreen}`}
                  fontSize={8}
                  padding={'8px 10px'}
                  textColour={ThemeData.colours.vibrantGreen}
                  backgroundColour={ThemeData.colours.white}
                  hoverTextColour={ThemeData.colours.vibrantGreen}
                  hoverBackgroundColour={ThemeData.colours.white}
                  textTransform={'capitalize'}
                  isRounded
                  onClick={() => toggleDoShowFloorplanImg('keyPlan')}
                  isActive={doShowFloorplanImg === 'keyPlan'}
                >
                  Key Plans
                </ButtonOutline>
              </div>
            }
            {props.isPortraitMode && <div className={classes.planButtonsContainer}>
              <ButtonOutline
                ThemeData={ThemeData}
                border={`1px solid ${ThemeData.colours.vibrantGreen}`}
                fontSize={8}
                padding={'8px 10px'}
                textColour={ThemeData.colours.primaryAccent}
                backgroundColour={ThemeData.colours.white}
                textTransform={'capitalize'}
                isRounded={true}
                onClick={() => {
                  if (dataUnit.__typename === 'Unit') {
                    props.setSelectedUnitId(dataUnit.threeDId);
                    props.setSelectedFloorplanId(-1);
                  } else {
                    props.setSelectedUnitId(-1);
                    props.setSelectedFloorplanId(dataUnit.id);
                  }

                  if (props.isSaveHome)
                    props.toggleSetIsSaveHomesInteractive(true);
                  if (props.isCompareHomes)
                    props.toggleSetIsCompareHomesInteractive(true);

                  props.toggleUnitDetailOpen();
                }}
              >

                More Details
              </ButtonOutline>
            </div>
            }
            <ButtonOutline
              ThemeData={project.ThemeData}
              className={classes.trashIcon}
              border={`1px solid ${ThemeData.colours.vibrantGreen}`}
              textColour={ThemeData.colours.primaryAccent}
              padding={'8px 10px'}
              textTransform={'capitalize'}
              fontSize={8}
              isRounded={true}
              onClick={() => props.toggleCompareHome()}
            >
              <IconTrash style={{ width: '9.158px', height: '9.653px', paddingRight: 5 }} fill={ThemeData.colours.white} /> Remove from Compare
            </ButtonOutline>
          </ButtonGroup>

        </div>

        <UnitInfoIconListPortrait
          project={project}
          className={classes.unitInfoIconList}
          dataUnit={dataUnit}
          marginTopItemsRow={25}
          doShowFacingItem={false}
          isCompareHomes={props.isCompareHomes}
        />
      </div>
    </>
  );
};

export default UnitDetailCompareCardPortrait;
