/**
 * Type: 
 * 	Colour
 * Structure: 
 * 	{ r, g, b, a }
 * Subtypes: 
 * 	r, g, b, a are floats 0..1
 * 	a nullable
 */

let CreateColourFromHexString = ( hexColour ) => {

	let colour = {
		r: null,
		g: null,
		b: null,
		a: null,
	};

	if ( hexColour[0] === "#" )
		hexColour = hexColour.substring( 1, hexColour.length );

	let currIdx = 0;
	do {

		let valueToProc = hexColour.substring( 0, 2 );
		hexColour = hexColour.substring( 2, hexColour.length );

		colour[Object.keys( colour )[currIdx]] = parseInt( valueToProc, 16 ) / 255;

		currIdx++;

	} while ( currIdx <= 3 && hexColour !== "" )

	return colour;

};

let CreateHexStringFromColour = ( colour ) => {
	let hexString = "#";

	for ( let i = 0; i <= 3; i++ ) {
		let convValue = colour[Object.keys( colour )[i]];
		if ( convValue )
			hexString += parseInt(convValue * 255).toString( 16 );
	}

	return hexString;
};

let AverageHexStrings = ( ...toAvgHex ) => {
	let toAvgColours = toAvgHex.map(
		hexColour =>
			CreateColourFromHexString( hexColour )
	);

	return CreateHexStringFromColour( AverageColours( ...toAvgColours ) );
};

let AverageColours = ( ...toAvgColours ) => {

	let colourOut = {
		r: null,
		g: null,
		b: null,
		a: null,
	};


	let anAWasNull = false;

	toAvgColours.forEach( colour => {
		colourOut.r += colour.r;
		colourOut.g += colour.g;
		colourOut.b += colour.b;
		colourOut.a += colour.a;
		if ( !colour.a )
			anAWasNull = true;
	} );


	colourOut.r /= toAvgColours.length;
	colourOut.g /= toAvgColours.length;
	colourOut.b /= toAvgColours.length;
	colourOut.a /= toAvgColours.length;

	if ( anAWasNull )
		colourOut.a = null;


	return colourOut;

};


let defaultOutputTransparentAmountsCollection = [
	{
		name: '05',
		amount: .05,
	},
	{
		name: '15',
		amount: .15,
	},
	{
		name: '20',
		amount: .20,
	},
	{
		name: '25',
		amount: .25,
	},
	{
		name: '50',
		amount: .50,
	},
	{
		name: '75',
		amount: .75,
	},
];


let OutputTransparentColours = ( primaryColor, outputTransparentAmountsCollection = defaultOutputTransparentAmountsCollection ) => {
	// TODO: Implement
}

const getThemeColours = (settings) => settings?.colours ? JSON.parse(settings.colours) : {}
const getThemeFonts = (settings) => settings?.fontName ? JSON.parse(settings.fontName) : {}


export {
	CreateColourFromHexString,
	CreateHexStringFromColour,
	AverageHexStrings,
	AverageColours,
	OutputTransparentColours,
	getThemeColours,
	getThemeFonts
};