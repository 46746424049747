import React, { useEffect, useState } from "react";

import { createUseStyles } from "react-jss";
import { motion } from "framer-motion";

import FloorplansGeneric from "./FloorplansGeneric";
import FloorplansGenericPortrait from "./FloorplansGenericPortrait";
import FilterPane from "./FilterPane";
import { portraitMode } from "util/helper";
import FilterHeader from "./FilterHeader";

const useStyles = createUseStyles(
  {
    wrapper: {
      display: "flex",
      width: "100%",
      height: "100%",
      flexDirection: "column",
      // marginTop: (props) => props.isPortraitMode && " 100px",
      background: (props) => props.ThemeData.colours.white,
      "--content-pane-height": "580px",
    },
    floorplanHeader: (props) => ({
      backgroundColor: props.ThemeData.colours.white,
      fontSize: 24,
      display: "none",
    }),
    filterResult: {
      display: "flex",
      height: 104,
      marginBottom: "auto",
      alignItems: "flex-end",
      padding: "0 43px 36px 43px",
      borderBottom: (props) => `1px solid ${props.ThemeData.colours.lightTan}`,
    },
    title: {
      display: "flex",
      textTransform: "uppercase",
      color: (props) => props.ThemeData.colours.vibrantDarkBlue,
      fontSize: 12,
      fontWeight: 700,
      height: 280,
      alignItems: "center",
      justifyContent: "center",
      gap: 11,
    },
    floorplanWrapper: (props) => ({
      position: "relative",
      flex: 1,
      display: "flex",
      height: "calc((100vh - var(--header-height)) * 0.60 )",
    }),
    child: {
      flex: 1,
      // background: 'green',
    },
    filterWrapper: (props) => ({
      height: "calc((100vh - var(--header-height)) * 0.40 )",
      // background: props.ThemeData.colours.darkBlue,
      padding: "0px 43px",
    }),
    filterNavigation: (props) => ({
      display: "flex",
      height: 80,
      alignItems: "center",

      justifyContent: "left",
      // background: props.ThemeData.colours.darkBlue,
    }),
    filterButtons: {
      flex: "0 0 auto",
      padding: "0 20px",
    },
    filterButton: {
      borderRadius: 100,
      border: "1px solid #EEEDE6",
    },

    filterWrapper2: (props) => ({
      flex: "0 0 auto",
      display: "flex",
      flexDirection: "column",
      padding: "0px 43px",
      background: props.ThemeData.colours.darkBlue,
      height: "calc((100vh - var(--header-height)) * 0.40 )",
    }),
    filterContainer: {
      height: "calc((100vh - var(--header-height)) * 0.40 )",
    },
  },
  {
    name: "Floorplans",
  }
);

const Floorplans = ({ project, className, ...props }) => {
  const { ThemeData } = project;
  const hideFloorPlanFilter = false;
  const isPortraitMode = portraitMode();
  const classes = useStyles({ ThemeData, isPortraitMode, hideFloorPlanFilter });

  const [selectedUnitData, setSelectedUnitData] = useState(null);
  const [doShowFilter, setDoShowFilter] = useState(false);

  return (
    <div className={`${classes.wrapper} ${className}`}>
      <div className={classes.floorplanWrapper}>
        <div className={classes.child}>
          {isPortraitMode ? (
            <FloorplansGenericPortrait
              doShowFilter={doShowFilter}
              unitCardActionButtonProps={props.unitCardActionButtonProps}
              isViewStudySlideUpOpen={props.isViewStudySlideUpOpen}
              toggleIsViewStudySlideUpOpenFcn={
                props.toggleIsViewStudySlideUpOpen
              }
              setSelectedUnitId={props.setSelectedUnitId}
              setSelectedFloorplanId={props.setSelectedFloorplanId}
              viewUrl={props.viewUrl}
              selectedUnitData={selectedUnitData}
              setSelectedUnitData={setSelectedUnitData}
              className={classes.filterButton}
              calledByFloorplan={true}
              project={project}
              hideFloorPlanFilter={hideFloorPlanFilter}
            />
          ) : (
            <FloorplansGeneric
              isVirtualTourOpen={props.isVirtualTourOpen}
              toggleIsVirtualTourOpen={props.toggleIsVirtualTourOpen}
              overrideBackgroundColor={props.overrideBackgroundColor}
              unitCardActionButtonProps={props.unitCardActionButtonProps}
              isViewStudySlideUpOpen={props.isViewStudySlideUpOpen}
              toggleIsViewStudySlideUpOpenFcn={
                props.toggleIsViewStudySlideUpOpen
              }
              setSelectedUnitId={props.setSelectedUnitId}
              setSelectedFloorplanId={props.setSelectedFloorplanId}
              viewUrl={props.viewUrl}
              selectedUnitData={selectedUnitData}
              setSelectedUnitData={setSelectedUnitData}
              className={classes.filterButton}
              calledByFloorplan={true}
              project={project}
              isSiteMapPage={props.isSiteMapPage}
            />
          )}
        </div>
      </div>
      {isPortraitMode && !hideFloorPlanFilter && (
        <>
          {!doShowFilter && (
            <div className={classes.filterNavigation}>
              <FilterHeader
                project={project}
                hideFilter={true}
                hideAllFilters={hideFloorPlanFilter}
                doShowFilter={doShowFilter}
                isSiteMapPage={props.isSiteMapPage}
                setDoShowFilter={setDoShowFilter}
              />
            </div>
          )}
          {doShowFilter && (
            <motion.div
              initial={{ y: "40vh" }}
              animate={{ y: 0 }}
              exit={{ y: "40vh" }}
              transition={{ type: "tween", duration: 0.5 }}
              className={classes.filterWrapper}>
              <FilterPane
                project={project}
                doShowFilter={doShowFilter}
                setDoShowFilter={setDoShowFilter}
              />
            </motion.div>
          )}
        </>
      )}
    </div>
  );
};

Floorplans.defaultProps = {
  overrideBackgroundColor: null,
};

export default Floorplans;
