import React from 'react';

import {
  createUseStyles,
} from 'react-jss';

import {
  // motion
} from 'framer-motion';

import {
  useLocation,
} from 'react-router-dom';

import ConstantData from 'data/ConstantData';
import useSiteFeatureToggles from 'hooks/useSiteFeatureToggles';
import { convertBathrooms, convertBedrooms, portraitMode } from '../util/helper';
import facingArrowIcon from 'img/icon_facing_arrow.svg';
import { showExterior, showTotalSpace } from '../util/helper';

const useStyles = createUseStyles(
  {
    wrapper: {
      padding: '8px 0 0 0',
      margin: '0 20px',
      textAlign: 'center',
    },
    detailListItem: props => ({
      marginTop: 14,
      marginLeft: 75,
      display: 'grid',
      gridTemplateColumns: '105px 1fr',
      listStyleType: 'none',
      textAlign: 'left',
      '@media (max-width: 1200px)': {
        marginBlock: 10,
      }
    }),
    detailListItemWider: {
      width: 200,
    },
    detailListItemIcon: {
      color: props => props.ThemeData.colours.primaryAccent,
    },
    detailListItemInline: {
      display: 'inline-block',
      marginRight: 0,
    },
    detailListItemTitle: props => ({
      color: props.ThemeData.colours.grey,
      fontFamily: props.ThemeData.fonts.copy,
      fontSize: 8,
      textTransform: 'capitalize',
      textAlign: 'left',
      paddingTop: 5,
    }),
    detailListItemTitleShort: {
      width: 60
    },
    detailListItemTitleMedium: {
      width: 80
    },
    detailListItemData: {
      fontSize: 16,
      textAlign: 'left',
    },
    detailListItemDataModifier: {
      fontFamily: props => props.ThemeData.fonts.copy,
    },
  },
  {
    name: 'UnitInfoIconListPortrait',
  }
);

const UnitInfoIconListPortrait = ({ dataUnit, project, className, ...props }) => {
  const { ThemeData, labelSettings: labels } = project;
  const classes = useStyles({ ...props, ThemeData, isPortraitMode: portraitMode(), isSaveHome: props.isSaveHome, isCompareHomes: props.isCompareHomes });
  const location = useLocation();
  const siteFeatureToggles = useSiteFeatureToggles(project && project.settings);
  const isFloorplan = dataUnit.__typename === 'FloorPlan';

  return (
    <div className={`${classes.wrapper} ${className}`}>
      <div className={classes.detailListItem}>
        <div className={classes.detailListItemTitle}>
          {labels?.interiorSize ? labels?.interiorSize : 'Interior'}
        </div>
        <div className={classes.detailListItemData}>
          {dataUnit && <> {dataUnit.floorPlan.interiorSize} <span className={classes.detailListItemDataModifier}>{dataUnit.floorPlan.sizeUnit}</span></>}
        </div>
      </div>

      {dataUnit && (dataUnit.exteriorSize || dataUnit.floorPlan.exteriorSize) && (

        <div className={classes.detailListItem}>
          <div className={classes.detailListItemTitle}>
            {labels?.exteriorSize ? labels?.exteriorSize : 'Exterior'}
          </div>
          <div className={classes.detailListItemData}>
            {showExterior(dataUnit)} <span className={classes.detailListItemDataModifier}>{dataUnit.floorPlan.sizeUnit}</span>
          </div>
        </div>
      )}
      {project && project.settings.showUnits &&
        <div className={classes.detailListItem}>
          {dataUnit && (
            <>
              <div className={classes.detailListItemTitle}>
                <div className={classes.detailListItemTitle}>Unit Number</div>
              </div>
              <div className={classes.detailListItemData}>
                <div className={`${classes.detailListItemData} ${classes.detailListItemInline}`}>{dataUnit.unitNumber}</div>
              </div>
            </>
          )}
        </div>
      }
      <div className={classes.detailListItem}>
        <div className={classes.detailListItemTitle}>
          Total Space
        </div>
        <div className={classes.detailListItemData}>
          {dataUnit && <> {showTotalSpace(dataUnit, isFloorplan)} <span className={classes.detailListItemDataModifier}>{dataUnit.floorPlan.sizeUnit}</span></>}
        </div>
      </div>

      {dataUnit && dataUnit.floorPlan.bedRooms !== null &&
        <div className={classes.detailListItem}>
          <div className={classes.detailListItemTitle}>
            Bedroom
          </div>
          <div className={classes.detailListItemData}>
            {convertBedrooms(
              dataUnit.floorPlan.bedRooms,
              dataUnit.floorPlan.juniorBedRooms,
              dataUnit.floorPlan.studio,
              dataUnit.floorPlan.flex,
            )}
          </div>
        </div>
      }

      {dataUnit && dataUnit.floorPlan.bathRooms &&
        <div className={classes.detailListItem}>
          <div className={classes.detailListItemTitle}>
            Bath
          </div>
          <div className={classes.detailListItemData}>
            {convertBathrooms(dataUnit.floorPlan.bathRooms)}
          </div>
        </div>
      }

      {dataUnit && project.settings.showUnits === true && dataUnit.options?.parking === true && dataUnit.parking > 0 &&
        <div className={classes.detailListItem}>
          <div className={classes.detailListItemTitle}>
            Parking
          </div>
          <div className={classes.detailListItemData}>
            {dataUnit.parking}
          </div>
        </div>
      }
      {props.doShowFacingItem && dataUnit && dataUnit.unitExposures &&
        <div className={classes.detailListItem}>
          <div className={classes.detailListItemTitle}>
            <img src={facingArrowIcon} alt="Facing Direction Icon" />
          </div>
          <div className={classes.detailListItemData}>
            {dataUnit.unit_exposures && dataUnit.unitExposures.map(unitExposure => (
              <span key={unitExposure.id}>{unitExposure.direction}</span>
            ))} <span className={classes.detailListItemDataModifier}>facing</span>
          </div>
        </div>
      }

      {project && project.settings.showUnits === false && location.pathname.includes('/proximation') &&
        <div className={classes.detailListItem}>
          {
            siteFeatureToggles.isPricesOn &&
            props.doShowPrice &&
            dataUnit && dataUnit.price &&
            <>
              <div className={classes.detailListItemTitle}>
                Price
              </div>
              <div className={classes.detailListItemData}>
                <span>{ConstantData.dataFormattingFunctions.prices(dataUnit.price)}</span>
              </div>
            </>
          }
        </div>
      }
    </div>
  );
};

UnitInfoIconListPortrait.defaultProps = {
  marginTopItemsRow: 30,

  doShowFacingItem: false,
  doShowPrice: true,
};

export default UnitInfoIconListPortrait;
