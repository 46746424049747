import fetchGraphQL from "./fetchGraphQL";

export const getMapBox = async (slug) => {
  const query = `query MapboxInfo($slug: String!, $pageCategory: String) {
        project(slug: $slug, pageCategory: $pageCategory) {
            id
            slug
            mapBox
            settings {
                mapBoxStyleUrl
                latitude
                longitude
                mapBoxZoom
            }
        }
    }`;

  const variables = {
    slug,
  };
  return await fetchGraphQL(query, variables, "project");
};

export const getLogo = async (slug) => {
  const query = `query MapboxInfo($slug: String!, $pageCategory: String) {
        project(slug: $slug, pageCategory: $pageCategory) {
            logo {
                link
            }
        }
    }`;

  const variables = {
    slug,
  };
  return await fetchGraphQL(query, variables, "project", "logo");
};

export const getSiteMap = async (slug) => {
  const query = `query BuildSiteMaps($slug: String!, $pageCategory: String) {
        project(slug: $slug, pageCategory: $pageCategory) {
            buildings {
                name
                siteMaps {
                  id
                  areas {
                    id
                    points
                    coords
                    width
                    height
                    shape
                  }
                  fileName
                  link
                  fileTitle
                  filePosition
                  floor
                  width
                  height
                  formats {
                    width
                    height
                    size
                    link
                    type
                  }
                }
            }
        }
    }`;

  const variables = {
    slug,
  };
  return await fetchGraphQL(query, variables, "project", "buildings");
};

export const getUnits = async (slug) => {
  const query = `query GetUnits($slug: String!, $pageCategory: String) {
    project(slug: $slug, pageCategory: $pageCategory) {
      projectType 
      settings{
        isTownHouse
        colours
        showFullTitle          
        showAvailability
      }
        units {
            id
            name
            unitNumber
            status
            price
            petAllowed
            virtualTour
            viewStudyUrl
            floor
            interiorSize                
            sizeUnit
            threeDId
            exteriorSize
            mezzanineSize   
            type             
            floorPlan{
              id
                bathRooms
                bedRooms
                description
                interiorSize
                exteriorSize
                sizeUnit
                floorPlanType
                mezzanineSize
                name
                photo{
                  link
                  formats{
                    height
                    width
                    size
                    link
                    type
                  }                        
                }
                keyPlan {
                    link
                    
                }
                carouselPhoto{
                    link
                }                    
            }
        }
    }
}`;

  const variables = {
    slug,
  };

  return await fetchGraphQL(query, variables, "project");
};
