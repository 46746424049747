import { createUseStyles } from "react-jss";
import accessibilityPane from "hooks/accessibilityPane";

const useStyles = createUseStyles({
  wrapper: props => ({
    height: 'var(--accessibility-footer)',
    backgroundColor: props.ThemeData.colours.vibrantDarkBlue,
  })
}, { name: 'AccessiblityFooter'})

function AccessiblityFooter({ project }) {
  const {accessibility} = accessibilityPane()
  const classes = useStyles({ ThemeData: project.ThemeData });
  return accessibility && <div className={classes.wrapper}></div>
}

export default AccessiblityFooter;