import React, { useContext } from "react";

import { Link, useLocation } from "react-router-dom";

import { createUseStyles } from "react-jss";

import Nav from "./Nav";
import HeaderContainer from "./HeaderContainer";
import AccessiblityFooter from "./AccessibilityFooter";
import Footer from "./Footer";

import SlugData from "../data/SlugData";
import ThemeData from "data/ThemeData";
import BreadCrumbNav from "./BreadCrumbNav";
import { portraitMode } from "../util/helper";
import { getImageBySize } from "util/imgFunctions";
import FooterComponents from "./FooterComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import UnitFcnContext from "contexts/UnitFcnContext";

const useStyles = createUseStyles(
  {
    wrapperWrapper: {
      height: "calc(var(--app-height))",
    },
    wrapper: {
      position: "relative",
      height: "100%",

      display: "flex",
      flexDirection: "column",

      fontFamily: ThemeData.fonts.titleSub,
    },
    backButtonWrapper: {
      display: "flex",
      alignItems: "center",
    },
    backButton: {
      flex: "0 0 auto",
      cursor: "pointer",
      paddingLeft: 40,

      color: ThemeData.colours.primaryFont,
      fontSize: 24,
    },
    bgBlack: {
      backgroundColor: ThemeData.colours.primaryBgTransparent,
    },
    bgWrapper: {
      height: "100%",
      backgroundImage: (props) => `url(${props.backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundAttachment: "fixed",
      backgroundSize: "cover",
    },
    bgDarker: {
      // opacity: .25,
    },
    bgNone: {
      backgroundImage: "none",
    },
    contentPageHeaderWrapper: {
      backgroundColor: ThemeData.colours.neuVibrantDarkBlue,
    },
    equalSized: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    middle: {
      flex: "1 1 auto",
      backgroundColor: (props) =>
        props.homepage ? "none" : "rgba(0,0,0,0.75)",
    },
  },
  {
    name: "Layout",
  }
);

function RenderLayout(data, props, children) {
  const {
    project,
    project: { logo = [] },
  } = props;
  const siteLogo = logo.length > 0 ? logo[0].link : null;
  const location = useLocation();
  const currentLocationPathname = SlugData.determineCurrentLocation(
    location.pathname
  );
  const homepage = currentLocationPathname === SlugData.HOME_SLUG_NAME;
  const mainPhoto = getImageBySize(project.mainPhoto, "large");
  const backgroundImage = mainPhoto ? mainPhoto.link : null; //mainPhoto.length > 0 ? mainPhoto[0].link : null
  const classes = useStyles({
    homepage,
    backgroundImage,
    ThemeData: project.ThemeData,
    isPortraitMode: portraitMode(),
  });
  const { isOpenNavBar } = useContext(UnitFcnContext);

  const isPortraitMode = portraitMode();

  const renderHeaderContainer = (needToRender) => {
    return needToRender ? (
      <>
        {location.pathname.includes("/proximation") ? (
          <div>
            {!isOpenNavBar && (
              <BreadCrumbNav
                project={project}
                isVirtualTourOpen={props.isVirtualTourOpen}
                setIsVirtualTourOpenToClosed={
                  props.setIsVirtualTourOpenToClosed
                }
                isViewStudySlideUpOpen={props.isViewStudySlideUpOpen}
                setIsViewStudySlideUpOpenToClosed={
                  props.setIsViewStudySlideUpOpenToClosed
                }
                selectedUnitData={props.selectedUnitData}
                unitDetailOpen={props.isUnitDetailOpen}
                setUnitDetailOpen={props.setUnitDetailOpen}
                toggleIsSavedHomesSlideoutOpen={() =>
                  props.toggleIsSavedHomesSlideoutOpen()
                }
                toggleIsRegisterOpen={() => props.toggleIsRegisterOpen()}
                siteLogo={siteLogo}
              />
            )}
          </div>
        ) : (
          <>
            <HeaderContainer
              toggleIsSavedHomesSlideoutOpen={() =>
                props.toggleIsSavedHomesSlideoutOpen()
              }
              toggleIsRegisterOpen={() => props.toggleIsRegisterOpen()}
              toggleIsSendToEmailOpen={props.toggleIsSendToEmailOpen}
              siteLogo={siteLogo}
              location={currentLocationPathname}
              project={project}
            />
          </>
        )}
      </>
    ) : (
      <></>
    );
  };

  const renderHeaderGallery = () => {
    return (
      <>
        <div style={{ position: "absolute", bottom: "0", width: "100%" }}>
          {isPortraitMode && (
            <Footer ThemeData={project.ThemeData} hidden={props.footerHidden}>
              <Nav
                navItems={project.navs}
                project={project}
                navLocation='bottomNav'
                toggleIsSavedHomesSlideoutOpen={() =>
                  props.toggleIsSavedHomesSlideoutOpen()
                }
                isNavBar={false}
              />
            </Footer>
          )}
        </div>
      </>
    );
  };

  return (
    <div className={classes.wrapperWrapper}>
      <div className={`${classes.equalSized} ${classes.bgBlack}`} />
      <div
        className={`${classes.equalSized} ${classes.bgWrapper} ${
          currentLocationPathname !== SlugData.HOME_SLUG_NAME
            ? classes.bgNone
            : ""
        }`}
      />
      <div
        className={`${classes.equalSized} ${classes.bgWrapper} ${
          currentLocationPathname !== SlugData.HOME_SLUG_NAME
            ? classes.bgDarker
            : ""
        }`}
      />
      <div className={`${classes.wrapper}`}>
        {!isPortraitMode && renderHeaderContainer(!isPortraitMode)}
        {/* {isPortraitMode && currentLocationPathname !== "gallery"
          ? renderHeaderContainer(isPortraitMode)
          : renderHeaderGallery()} */}
        {renderHeaderContainer(isPortraitMode)}
        {renderHeaderGallery()}
        <div className={classes.middle}>{children}</div>
        {!isPortraitMode ? (
          <Footer ThemeData={project.ThemeData} hidden={props.footerHidden}>
            <Nav
              navItems={project.navs}
              project={project}
              navLocation='bottomNav'
              toggleIsSavedHomesSlideoutOpen={() =>
                props.toggleIsSavedHomesSlideoutOpen()
              }
              isNavBar={false}
            />
          </Footer>
        ) : (
          <>
            {currentLocationPathname !== "gallery" ? (
              <Footer ThemeData={project.ThemeData} hidden={props.footerHidden}>
                <Nav
                  navItems={project.navs}
                  project={project}
                  navLocation='bottomNav'
                  toggleIsSavedHomesSlideoutOpen={() =>
                    props.toggleIsSavedHomesSlideoutOpen()
                  }
                  isNavBar={false}
                />
              </Footer>
            ) : (
              <></>
            )}
          </>
        )}
        {/* <AccessiblityFooter project={project} /> */}
        {currentLocationPathname === "/proximation" && (
          <FooterComponents
            project={project}
            noRenderSaveHomes={props.noRenderSaveHomes}
            toggleIsSavedHomesSlideoutOpen={() =>
              props.toggleIsSavedHomesSlideoutOpen()
            }
            setIsContentPageCarousel={props.setIsContentPageCarousel}
            isContentPage={true}
            isNavBar={true}
          />
        )}
      </div>
    </div>
  );
}

function Layout({ children, ...props }) {
  return RenderLayout(null, props, children);
}

export default Layout;
