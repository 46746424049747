import montserratBold from "./fonts/Montserrat-Bold.woff";
import montserratRegular from "./fonts/Montserrat-Regular.woff";
import montserratLight from "./fonts/Montserrat-Light.woff";
import gothamBold from "./fonts/Gotham-Bold.woff";
import gothamBook from "./fonts/Gotham-Book.woff";
import gothamMedium from "./fonts/Gotham-Medium.woff";
import timess from "./fonts/Timess.ttf";
import publicRegular from "./fonts/PublicSans-VariableFont_wght.ttf";
import publicBold from "./fonts/PublicSans-Bold.ttf";
import beaufortLight from "./fonts/Beaufort-Light.woff";
import ptSans from "./fonts/PTSans.ttc";
import baskerVille from "./fonts/Baskerville.ttc";
import futuraPtLight from "./fonts/Futura-PT-Light.otf";
import futuraPtBook from "./fonts/Futura-PT-Book.ttf";
import ivyOraDisplayRegular from "./fonts/IvyOraDisplay-Regular.ttf";
import avenirBook from "./fonts/Avenir-Book.ttf";
import avenirHeavy from "./fonts/Avenir-Heavy.ttf";
import bemboStd from "./fonts/BemboStd.woff";

import { createUseStyles } from "react-jss";

const useFonts = createUseStyles(
  {
    "@font-face": [
      {
        fontFamily: "Avenir Heavy",
        src: [`url( '${avenirHeavy}' ) format('ttf')`],
      },
      {
        fontFamily: "Avenir Book",
        src: [`url( '${avenirBook}' ) format('ttf')`],
      },
      {
        fontFamily: "BemboStd",
        src: [`url( '${bemboStd}' ) format('woff')`],
      },
      {
        fontFamily: "Montserrat Bold",
        src: [`url( '${montserratBold}' ) format('woff')`],
        fontWeight: 300,
        style: "normal",
      },
      {
        fontFamily: "Montserrat Regular",
        src: [`url( '${montserratRegular}' ) format('woff')`],
        fontWeight: 200,
        style: "normal",
      },
      {
        fontFamily: "Montserrat Light",
        src: [`url( '${montserratLight}' ) format( 'woff' )`],
        fontWeight: "normal",
        style: "normal",
      },

      {
        fontFamily: "Gotham Bold",
        src: [`url( '${gothamBold}' ) format('woff')`],
        fontWeight: 300,
        style: "normal",
      },
      {
        fontFamily: "Gotham Medium",
        src: [`url( '${gothamMedium}' ) format('woff')`],
        fontWeight: 300,
        style: "normal",
      },
      {
        fontFamily: "Gotham Book",
        src: [`url( '${gothamBook}' ) format('woff')`],
        fontWeight: 300,
        style: "normal",
      },
      {
        fontFamily: "Timess",
        src: [`url( '${timess}' ) format('ttf')`],
        fontWeight: 300,
        style: "normal",
      },
      {
        fontFamily: "Public Regular",
        src: [`url( '${publicRegular}' ) format('ttf')`],
        fontWeight: 300,
        style: "normal",
      },
      {
        fontFamily: "Public Bold",
        src: [`url( '${publicBold}' ) format('ttf')`],
      },
      {
        fontFamily: "Beaufort Light",
        src: [`url( '${beaufortLight}' ) format('woff')`],
      },
      {
        fontFamily: "PTSans",
        src: [`url( '${ptSans}' ) format('ttc')`],
        fontWeight: 300,
        style: "normal",
      },
      {
        fontFamily: "Baskerville",
        src: [`url( '${baskerVille}' ) format('ttc')`],
      },
      {
        fontFamily: "Futura PT Light",
        src: [`url( '${futuraPtLight}' ) format('otf')`],
      },
      {
        fontFamily: "Futura PT Book",
        src: [`url( '${futuraPtBook}' ) format('ttf')`],
      },
      {
        fontFamily: "IvyOraDisplay Regular",
        src: [`url( '${ivyOraDisplayRegular}' ) format('ttf')`],
      },
    ],
  },
  { name: "App" }
);

export default useFonts;
