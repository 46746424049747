import React, {
  useState,
} from 'react';

import {
  createUseStyles,
} from 'react-jss';

import ButtonGroup from 'components/ButtonGroup';
import ButtonCircle from 'components/ButtonCircle';
import ButtonOutline from 'components/ButtonOutline';
import xWhiteSmall from 'img/x_white_small.svg';
import UnitInfoIconList from './UnitInfoIconList';
import { ReactComponent as NavArrowLeft } from '../img/nav_arrow_left.svg';
import { ReactComponent as NavArrowRight } from '../img/nav_arrow_right.svg';

import UnitInfoPlanNameTitle from './UnitInfoPlanNameTitle';

const useStyles = createUseStyles(
  {
    wrapper: props => ({
      // border: '1px solid red',
      position: 'relative',
      width: props.isCompareHomes ? 470 : (props.isPortraitMode ? 300 : 575),
      height: props.isPortraitMode ? (props.isSaveHome ? 327 : 951) : '800px',
      margin: props.isPortraitMode ? (props.isSaveHome ? 23 : 0) : '0px',
      ...props.ThemeData.styles.hoverCard,
      borderRadius: props.isPortraitMode ? '10px' : '0',
      boxShadow: props.isPortraitMode ? 'none' : '0px 4px 10px rgb(0 0 0 / 33%)',
      // marginLeft: '100px',

      '@media (min-width: 3800px)': {
        minHeight: 800,
        maxHeight: 800,
      },
    }),
    contentContainer: props => ({

      minHeight: props.isPortraitMode ? 0 : 530,
      maxHeight: props.isPortraitMode ? '' : 590,
      display: 'flex',
      flexDirection: 'column',
      boxSizing: props.isPortraitMode ? '' : 'border-box',
      padding: props.isSaveHome ? 20 : '20px 20px 0 20px',
      textAlign: 'left',
      marginBottom: props.isPortraitMode && props.isSaveHome ? 5 : 'none',
    }),
    hr: {
      width: '100%',

      marginTop: 35,

      borderTop: props => props.isCompareHomes ? 'none' : `1px ${props.ThemeData.colours.eggshellTextBg} solid`,
      '@media (max-width: 1200px)': {
        marginTop: 10
      }
    },
    removeHomeButton: props => ({
      position: 'absolute',
      top: props.isPortraitMode ? 10 : 20,
      right: props.isPortraitMode ? 7 : 20,

      width: props.isPortraitMode ? 26 : 48,
    }),
    floorplanContainer: props => ({
      position: 'relative',
      flex: (props.isPortraitMode && !props.isSaveHome) ? '' : '0 0 auto',
      height: props.isPortraitMode ? (props.isSaveHome ? 100 : 540) : 300,
      width: props.isPortraitMode ? (props.isSaveHome ? 260 : 430) : 'auto',
      marginLeft: props.isPortraitMode ? 0 : 0,
      marginRight: props.isPortraitMode ? 0 : 0,
      borderRadius: 4,
      marginBottom: props.isPortraitMode ? (props.isCompareHomes ? 10 : 5) : 0,
      justifyContent: 'center'
    }),
    floorplanImg: props => ({
      height: props.isPortraitMode ? (props.isSaveHome ? '100%' : 480) : '100%',
      width: '100%',

      objectFit: 'contain',
    }),
    viewPort: props => ({
      margin: '10px auto',
      height: 500,
      width: props.floorPlanImagePositionX === '28px' ? 255 : 275,
      background: 'no-repeat',
      backgroundSize: '1120px auto',
      backgroundPosition: 'fixed',
      backgroundPositionX: props.floorPlanImagePositionX || '28px', //30px, -235px, -520px, -820px
      backgroundPositionY: '-200px',
    }),
    viewPortTall: props => ({
      margin: '10px auto',
      height: 500,
      width: 200,
      background: 'no-repeat',
      backgroundSize: 'auto 100%',
      backgroundPosition: 'fixed',
      backgroundPositionX: props.floorPlanImagePositionX || '-35px',//-35px, '-225px', '-395px', '-575px'
      backgroundPositionY: '-5px',
    }),
    planButtonsContainer: {
      marginTop: 10,
      '@media (max-width: 1200px)': {
        marginTop: 0
      }
    },
    floorKeyPlanButtons: {
      borderRadius: 50,

      '@media (min-width: 3800px)': {
        fontSize: '24px',
        height: '60px'
      },
    },
    info: {
      flex: props => props.isPortraitMode ? '0.02 0 auto' : '1 0 auto',
    },
    unitInfoPlanNameTitle: {
      marginTop: 0,

    },
    unitInfoLine: {
      color: props => props.ThemeData.colours.black,
      fontSize: props => props.isPortraitMode ? (props.isSaveHome ? 20 : 24) : 16,
      fontWeight: props => props.isPortraitMode ? (props.isSaveHome ? 'bold' : '') : '',
      '@media (min-width: 3800px)': {
        fontSize: '36px',
        height: '70px'
      },
    },
    unitInfoIconList: {
      color: props => props.ThemeData.colours.black,

    },
    viewDetailButtonArea: {
      paddingTop: 10,
    },
    removeButton: {
      width: '100%',
      height: '31px',
      borderRadius: '0px 0px 10px 10px',
      //backgroundColor: props => props.ThemeData.colours.primaryAccent,
    },
    removeCompareButton: {
      borderRadius: '0 0 10px 10px',
      color: props => props.ThemeData.colours.white
    },
    addCompareButton: {
      borderTop: props => `1px solid ${props.ThemeData.colours.vibrantGreen}`,
      // color: "white",
      borderRadius: '0 0 10px 10px',
    },
    floorplanFrame: {
      position: 'relative',
    },
    imageScrollerLeft: {
      position: 'absolute',
      top: '50%'
    },
    imageScrollerRight: {
      position: 'absolute',
      top: '50%',
      right: 0,
    },
    floorLabel: {
      textAlign: 'center',
      fontSize: 12,
      fontWeight: 700,
    }
  },
  {
    name: 'UnitDetailCompareCard',
  }
);

// data == a single Unit
const UnitDetailCompareCard = ({ project, dataUnit, ...props }) => {
  const { ThemeData } = project;

  const [doShowFloorplanImg, setDoShowFloorplanImg] = useState('floorPlan');
  const [floorplanImgIndex] = useState(1);

  const toggleDoShowFloorplanImg = (currentPlan) => {
    switch (currentPlan) {
      case 'floorPlan':
        setDoShowFloorplanImg('floorPlan');
        break;
      case 'keyPlan':
        setDoShowFloorplanImg('keyPlan');
        break;
      default:
        break;
    }
  };
  if (dataUnit.__typename === 'FloorPlan') {
    dataUnit = {
      id: dataUnit.id,
      name: dataUnit.name,
      exteriorSize: dataUnit.exteriorSize,
      interiorSize: dataUnit.interiorSize,
      sizeUnit: dataUnit.sizeUnit,
      floorPlan: dataUnit,
      status: '',
      bedRooms: dataUnit.bedRooms,
      bathRooms: dataUnit.bathRooms,
      unitNumber: null,
      parking: null,
      __typename: 'FloorPlan'
    };
  }

  // const isEnzo = project.slug === 'liveenzo';
  const isEnzo = true;
  const isPlanTall = dataUnit && dataUnit.floorPlan && dataUnit.floorPlan.name.search(/^B/) !== -1;
  let floorplanImgFrames = [];

  if (props.isPortraitMode && isEnzo) {
    floorplanImgFrames = isPlanTall ? ['20px', '-165px', '-330px', '-490px'] : ['28px', '-235px', '-515px', '-820px'];
  }

  const classes = useStyles({ ThemeData, isPortraitMode: props.isPortraitMode, isSaveHome: props.isSaveHome, isCompareHomes: props.isCompareHomes, floorPlanImagePositionX: floorplanImgFrames[floorplanImgIndex] });

  // NEW
  const [floorplanIdx, setFloorplanIdx] = useState(0);
  const enableNextFloorplan = floorplanIdx < dataUnit.floorPlan.carouselPhoto?.length - 1;
  const enablePrevFloorplan = floorplanIdx > 0;

  return (
    <>
      <div className={`${classes.wrapper} ${props.className}`}>
        <div className={classes.contentContainer}>
          <div className={classes.removeHomeButton}>
            <ButtonCircle
              ThemeData={project.ThemeData}
              src={xWhiteSmall}
              alt="Remove This Saved Home"
              widthHeight={props.isPortraitMode ? 24 : 46}
              iconWidthHeight={24}
              borderColour={ThemeData.colours.white}
              textColour={ThemeData.colours.primaryAccent25}
              backgroundColour={ThemeData.colours.vibrantGreen || ThemeData.colours.primaryBg}
              hoverBackgroundColour={ThemeData.colours.vibrantGreen || ThemeData.colours.primaryBg}
              onClick={() => {
                if (props.isCompareHomes) {
                  props.toggleCompareHome();
                } else if (props.isSaveHome) {
                  props.toggleSavedHome();
                  let prevUnitIdExistsIdx = props.compareHomes.findIndex(elm => elm.id === dataUnit.id);
                  if (prevUnitIdExistsIdx !== -1) {
                    props.removeCompareHome();
                  }

                } else {
                  props.toggleSavedHome();
                }
              }}
            />
          </div>
          <div className={classes.info}>
            <UnitInfoPlanNameTitle
              project={project}
              data={dataUnit}
              color={project.ThemeData.colours.primaryAccent}
              fontSize={20}
              className={classes.unitInfoPlanNameTitle}
            />
          </div>
          {props.isPortraitMode && props.isCompareHomes && <div className={classes.floorplanContainer}>
            {dataUnit && dataUnit.floorPlan && <>
              {doShowFloorplanImg === 'floorPlan' && dataUnit.floorPlan.carouselPhoto?.length > 0 && isEnzo ?
                <div className={classes.floorplanFrame}>
                  {/* <ButtonCircle
									ThemeData={project.ThemeData}
									widthHeight={25}
									className={classes.imageScrollerLeft}
									onClick={() => enablePrevFloorplanImg && setFloorplanImgIndex(floorplanImgIndex - 1)}
									borderColor={enablePrevFloorplanImg ? project.ThemeData.colours.primaryAccent : project.ThemeData.colours.grey25}
									disabled={!enablePrevFloorplanImg}
								>
									<NavArrowLeft
										width={15}
										fill={enablePrevFloorplanImg ? project.ThemeData.colours.primaryAccent : project.ThemeData.colours.grey25}
										id="content-page-navigate-left" />
								</ButtonCircle>
								<div className={`${isPlanTall ? classes.viewPortTall : classes.viewPort}`} style={{ backgroundImage: `url(${getImageBySize(dataUnit.floorPlan.photo, 'large').link})` }}></div>
								<div className={classes.floorLabel}>{getOrdinal(floorplanImgIndex + 1)} Floor</div>
								<ButtonCircle
									ThemeData={project.ThemeData}
									widthHeight={25}
									className={classes.imageScrollerRight}
									onClick={() => enableNextFloorplanImg && setFloorplanImgIndex(floorplanImgIndex + 1)}
									borderColor={enableNextFloorplanImg ? project.ThemeData.colours.primaryAccent : project.ThemeData.colours.grey25}
									disabled={!enableNextFloorplanImg}
								>
									<NavArrowRight
										width={15}
										fill={enableNextFloorplanImg ? project.ThemeData.colours.primaryAccent : project.ThemeData.colours.grey25}
										id="content-page-navigate-left" />

								</ButtonCircle> */}

                  <ButtonCircle
                    ThemeData={project.ThemeData}
                    widthHeight={25}
                    className={classes.imageScrollerLeft}
                    onClick={() => enablePrevFloorplan && setFloorplanIdx(floorplanIdx - 1)}
                    borderColor={enablePrevFloorplan ? project.ThemeData.colours.primaryAccent : project.ThemeData.colours.grey25}
                    disabled={!enablePrevFloorplan}
                  >
                    <NavArrowLeft
                      width={15}
                      fill={enablePrevFloorplan ? project.ThemeData.colours.primaryAccent : project.ThemeData.colours.grey25}
                      id="content-page-navigate-left" />
                  </ButtonCircle>
                  <img
                    className={classes.floorplanImg}
                    src={dataUnit.floorPlan.carouselPhoto[floorplanIdx].link}
                    alt={dataUnit.floorPlan.name} />

                  <ButtonCircle
                    ThemeData={project.ThemeData}
                    widthHeight={25}
                    className={classes.imageScrollerRight}
                    onClick={() => enableNextFloorplan && setFloorplanIdx(floorplanIdx + 1)}
                    borderColor={enableNextFloorplan ? project.ThemeData.colours.primaryAccent : project.ThemeData.colours.grey25}
                    disabled={!enableNextFloorplan}
                  >
                    <NavArrowRight
                      width={15}
                      fill={enableNextFloorplan ? project.ThemeData.colours.primaryAccent : project.ThemeData.colours.grey25}
                      id="content-page-navigate-left" />
                  </ButtonCircle>
                </div>
                :
                <div className={classes.floorplanFrame}>
                  <img
                    className={classes.floorplanImg}
                    src={dataUnit.floorPlan.photo[0].link}
                    alt={dataUnit.floorPlan.name} />
                </div>
              }
              {doShowFloorplanImg === 'floorPlan' && !isEnzo && dataUnit.floorPlan.photo &&
                <img
                  className={classes.floorplanImg}
                  src={dataUnit.floorPlan.photo[0].link}
                  alt={dataUnit.floorPlan.name} />
              }
              {doShowFloorplanImg === 'keyPlan' && dataUnit.floorPlan.keyPlan &&
                <img
                  className={classes.floorplanImg}
                  src={dataUnit.floorPlan.keyPlan[0].link}
                  alt={dataUnit.floorPlan.name} />
              }
            </>
            }
          </div>}
          {((props.isPortraitMode && props.isSaveHome) || !props.isPortraitMode) && <div className={classes.floorplanContainer}>
            {dataUnit && dataUnit.floorPlan &&
              <>
                {doShowFloorplanImg === 'floorPlan' && dataUnit.floorPlan.photo &&
                  <img
                    className={classes.floorplanImg}
                    src={dataUnit.floorPlan.photo[0].link}
                    alt={dataUnit.floorPlan.name} />
                }
                {doShowFloorplanImg === 'keyPlan' && dataUnit.floorPlan.keyPlan &&
                  <img
                    className={classes.floorplanImg}
                    src={dataUnit.floorPlan.keyPlan[0].link}
                    alt={dataUnit.floorPlan.name} />
                }
              </>
            }
          </div>}
          <ButtonGroup
            justifyContent={'center'}
            marginLeft={props.isPortraitMode ? 'none' : 20}

          >
            {!props.isPortraitMode && dataUnit && dataUnit.floorPlan.photo &&
              <div className={classes.planButtonsContainer}>
                <ButtonOutline
                  ThemeData={ThemeData}
                  borderColour={ThemeData.colours.grey25}
                  className={classes.floorKeyPlanButtons}
                  textColour={ThemeData.colours.primaryAccent}
                  backgroundColour={ThemeData.colours.white}
                  hoverTextColour={ThemeData.colours.white}
                  hoverBackgroundColour={ThemeData.colours.primaryAccent}
                  firstInColl
                  onClick={() => toggleDoShowFloorplanImg('floorPlan')}
                  isActive={doShowFloorplanImg === 'floorPlan'}
                >
                  Floor Plan
                </ButtonOutline>
              </div>
            }
            {props.isPortraitMode && !props.isSaveHome && dataUnit && dataUnit.floorPlan.photo &&
              <div className={classes.planButtonsContainer}>
                <ButtonOutline
                  ThemeData={ThemeData}
                  border={'none'}
                  fontSize={12}
                  padding={'8px 10px'}
                  textColour={ThemeData.colours.vibrantGreen}
                  backgroundColour={ThemeData.colours.white}
                  hoverTextColour={ThemeData.colours.vibrantGreen}
                  hoverBackgroundColour={ThemeData.colours.white}
                  isRounded
                  onClick={() => toggleDoShowFloorplanImg('floorPlan')}
                  isActive={doShowFloorplanImg === 'floorPlan'}
                >
                  Floor Plan
                </ButtonOutline>
              </div>
            }

            {!props.isPortraitMode && dataUnit && dataUnit.floorPlan.keyPlan &&
              <div className={classes.planButtonsContainer}>
                <ButtonOutline
                  ThemeData={ThemeData}
                  borderColour={ThemeData.colours.grey25}
                  className={classes.floorKeyPlanButtons}
                  textColour={ThemeData.colours.primaryAccent}
                  backgroundColour={ThemeData.colours.white}
                  hoverTextColour={ThemeData.colours.white}
                  hoverBackgroundColour={ThemeData.colours.primaryAccent}
                  onClick={() => toggleDoShowFloorplanImg('keyPlan')}
                  isActive={doShowFloorplanImg === 'keyPlan'}
                >
                  Key Plan
                </ButtonOutline>
              </div>
            }
            {props.isPortraitMode && !props.isSaveHome && dataUnit && dataUnit.floorPlan.keyPlan &&
              <div className={classes.planButtonsContainer}>

                <ButtonOutline
                  ThemeData={ThemeData}
                  border={'none'}
                  fontSize={12}
                  padding={'8px 10px'}
                  textColour={ThemeData.colours.vibrantGreen}
                  backgroundColour={ThemeData.colours.white}
                  hoverTextColour={ThemeData.colours.vibrantGreen}
                  hoverBackgroundColour={ThemeData.colours.white}
                  isRounded
                  onClick={() => toggleDoShowFloorplanImg('keyPlan')}
                  isActive={doShowFloorplanImg === 'keyPlan'}
                >
                  Key Plan
                </ButtonOutline>
              </div>
            }
            {props.isPortraitMode && <div className={classes.planButtonsContainer}>
              <ButtonOutline
                ThemeData={ThemeData}
                border={'none'}
                fontSize={12}
                padding={'8px 10px'}
                textColour={ThemeData.colours.primaryAccent}
                backgroundColour={ThemeData.colours.white}
                // hoverTextColour={!props.isPortraitMode && ThemeData.colours.white}
                isRounded={true}
                onClick={() => {
                  if (dataUnit.__typename === 'Unit') {
                    props.setSelectedUnitId(dataUnit.threeDId);
                    props.setSelectedFloorplanId(-1);
                  } else {
                    props.setSelectedUnitId(-1);
                    props.setSelectedFloorplanId(dataUnit.id);
                  }

                  if (props.isSaveHome)
                    props.toggleSetIsSaveHomesInteractive(true);
                  if (props.isCompareHomes)
                    props.toggleSetIsCompareHomesInteractive(true);

                  props.toggleUnitDetailOpen();
                }}
              >

                More Details
              </ButtonOutline>
            </div>
            }
          </ButtonGroup>

          <div className={classes.hr} />

          {!props.isCompareHomes && <UnitInfoIconList
            project={project}
            className={classes.unitInfoIconList}
            dataUnit={dataUnit}
            marginTopItemsRow={25}
            doShowFacingItem={false}
            isSaveHome={props.isSaveHome}
          />}
        </div>
        {props.isPortraitMode && props.isSaveHome && <div>
          {/* TODO: commented out until the compare screen is implemented */}
          {/*{dataUnit && props.compareHomesIncludes() ?*/}
          {/*	<ButtonOutline*/}
          {/*		ThemeData={ThemeData}*/}
          {/*		border={props.isPortraitMode && 'none'}*/}
          {/*		padding={10}*/}
          {/*		fontSize={10}*/}
          {/*		className={classes.addCompareButton}*/}
          {/*		textColour={ThemeData.colours.white}*/}
          {/*		backgroundColour={ThemeData.colours.vibrantGreen}*/}
          {/*		hoverTextColour={ThemeData.colours.white}*/}
          {/*		hoverBackgroundColour={ThemeData.colours.vibrantGreen}*/}
          {/*		onClick={() => props.toggleCompareHome()}*/}
          {/*	>*/}
          {/*		Remove from compare*/}
          {/*	</ButtonOutline>*/}
          {/*	:*/}
          {/*	<ButtonOutline*/}
          {/*		ThemeData={ThemeData}*/}
          {/*		border={props.isPortraitMode && 'none'}*/}
          {/*		padding={10}*/}
          {/*		height={30}*/}
          {/*		fontSize={10}*/}
          {/*		className={classes.addCompareButton}*/}
          {/*		textColour={props.compareHomes.length < 2 ? ThemeData.colours.vibrantGreen : ThemeData.colours.clayFade}*/}
          {/*		hoverTextColour={props.compareHomes.length < 2 ? ThemeData.colours.vibrantGreen : ThemeData.colours.clayFade}*/}
          {/*		backgroundColour={ThemeData.colours.white}*/}
          {/*		hoverBackgroundColour={props.compareHomes.length < 2 ? ThemeData.colours.white : ThemeData.colours.white}*/}
          {/*		onClick={() => props.compareHomes.length < 2 && props.toggleCompareHome()}*/}
          {/*	>*/}
          {/*		Add to compare*/}
          {/*	</ButtonOutline>*/}
          {/*}*/}
        </div>
        }

        {/* {props.isPortraitMode && props.isCompareHomes && <UnitInfoIconListPortrait
					project={project}
					className={classes.unitInfoIconList}
					dataUnit={dataUnit}
					marginTopItemsRow={25}
					doShowFacingItem={false}
					isSaveHome={props.isSaveHome}
				/>} */}
      </div>
      {/*props.isPortraitMode && props.isCompareHomes && <div className={`${classes.wrapper} ${classes.secondaryWrapper} ${props.className}`} style={{ height: "546px", marginTop: "24px" }}>
				<UnitInfoIconListPortrait
					project={project}
					className={classes.unitInfoIconList}
					dataUnit={dataUnit}
					marginTopItemsRow={25}
					doShowFacingItem={false}
					isSaveHome={props.isSaveHome}
				/>
				</div>*/}
    </>
  );
};

export default UnitDetailCompareCard;