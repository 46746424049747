import React, {
  useEffect,
  useRef
} from 'react';

import {
  createUseStyles,
} from 'react-jss';

import {
  motion,
} from 'framer-motion';

import ThemeData from 'data/ThemeData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import useSavedHomes from 'hooks/useSavedHomes';
import useCompareHomes from 'hooks/useCompareHomes';
import useOpenClosePane from 'hooks/useOpenClosePane';
import accessibilityPane from 'hooks/accessibilityPane';

import preventClickEventPassthrough from 'util/preventClickEventsPassthrough';
import { useHorizontalScroll } from 'hooks/useHorizontalScroll';

import UnitDetailCompareCardPortrait from './UnitDetailCompareCardPortrait';
import SavedHomesTitle from './SavedHomesTitle';
import ButtonOutline from './ButtonOutline';
import ClearConfirmationDialogGeneric from './ClearConfirmationDialogGeneric';

import { ReactComponent as IconTrash } from 'img/icon_trash.svg';
import { portraitMode } from 'util/helper';
import { useLocation } from 'react-router-dom';
import SaveHomesBrowseCard from './SaveHomesBrowseCard';

const useStyles = createUseStyles(
  {
    wrapper: props => ({
      '--wrapper-height': '1162px',

      position: 'fixed',
      bottom: props.isPortraitMode
        ? (props.isProximation
            ? 'calc(var(--footer-height) + var(--accessibility-footer) - 5px)'
            : (
              props.noNavigation
                ? 'calc(var(--footer-height) + var(--accessibility-footer))'
                : 'calc(var(--header-height) + var(--navigation-height) + var(--accessibility-footer) - 5px)'
            )
        )
        : 0,
      borderBottom: props.isPortraitMode ? `1px solid ${props.ThemeData.colours.vibrantGreen}` : '0',
      left: 0,
      zIndex: 9999,
      width: 'calc( 100vw )',

      overflow: 'clip',
      height: 'var(--wrapper-height)',

      color: props.ThemeData.colours.black,
      // background: 'rgba(255, 255, 255, 0.50)',

      //...props.ThemeData.styles.hoverPaneBoxShadow,

      letterSpacing: '.025em',
      background: props.isPortraitMode ? ThemeData.colours.saveCompareHomesBgPortrait : ThemeData.colours.saveCompareHomesBgLandscape,
      backdropFilter: 'blur(5px)'
    }),
    bgAmp: {
      zIndex: -1,
      position: 'absolute',
      left: -50,
      bottom: -100,

      color: props => props.ThemeData.colours.primaryAccent08,
      fontFamily: props => props.ThemeData.fonts.copy,
      fontSize: 500,
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',

      padding: '24px 52px',
    },
    topRightContainer: {
      display: 'flex',
      width: '50%',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginTop: 30,
    },
    closeX: {
      marginTop: 10,
      marginLeft: 50,
      cursor: 'pointer',
    },
    closeButton: {
      position: 'absolute',
      right: 0,
      borderRadius: 15,
      gap: 10,
      color: ThemeData.colours.saveCompareHomesTextButtonPortrait

    },
    sendToEmailButton: {
      display: 'inline-block',

      width: 150,

      fontSize: 12,
      letterSpacing: '.1em',
    },
    savedHomesTitle: {
      textAlign: 'left',

      fontSize: 24,
      letterSpacing: '.15em',
      alignSelf: 'flex-start',
      width: '50%',
    },
    top: {
      display: 'flex',
      height: 50,
      alignItems: 'center',
      justifyContent: 'center'
    },
    addHomesButton: {
      width: 220,

      fontSize: 12,
      letterSpacing: '.1em',

      borderRadius: 25,
    },
    middle: props => ({
      marginTop: 34,

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 36,
    }),
    unitDetailCompareCardWrapper: {
      flex: '0 0 auto',
      width: 470,
    },
    bottom: {
      position: 'relative',
      flex: '0 0 auto',
      display: 'flex',

      paddingTop: 25,

      // height: 130,

      // borderTop: props => `1px ${props.ThemeData.colours.white50} solid`,
    },
    bottomButton: {
      justifyContent: 'center',
      fontSize: 12,
      letterSpacing: '.1em',
      color: ThemeData.colours.saveCompareHomesTextButtonPortrait,
      borderRadius: 15,
      width: 180,
      marginRight: '47px !important',
    },
    backButton: {
      width: 'auto'
    },
    trashIcon: {
      borderRadius: 15,
      width: props => props.isPortraitMode ? 140 : 150,
      color: ThemeData.colours.saveCompareHomesTextButtonPortrait,
      gap: 8,
      '& > div': {
        marginLeft: 5,
        fontSize: 10
      },
    },
    bgMountainscape: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,

      zIndex: -1,
    },
  },
  {
    name: 'SavedHomesComparePortrait',
  },
);

const wrapperAnimVariants = {
  open: {
    y: '0',
    zIndex: 999,
    display: 'block',
  },
  closed: {
    y: '100%',
    display: 'block',
    transitionEnd: {
      zIndex: -2,
      display: 'none'
    },
  }
};

const RenderSavedHomesComparePortrait = (classes, project, error, isPortraitMode, isOpen, toggleIsOpen, toggleIsSavedHomesSlideoutOpen, hooks, props) => {
  const horizScrollRef = useHorizontalScroll();

  if (error) {
    console.log('RenderSavedHomesComparePortrait', error.message);
  }

  useEffect(() => {
    if (hooks.compareHomes && hooks.compareHomes.length === 0 && isOpen) {
      toggleIsOpen();
      toggleIsSavedHomesSlideoutOpen();
    }
  }, [hooks.compareHomes, isOpen]);

  const compareHomesRef = useRef(null);

  function useOutsideClickCompareHomes (ref) {
    useEffect(() => {
      function handleClickOutside (event) {
        if (ref?.current && !ref?.current.contains(event.target)) {
          if (props.unitDetailRef?.current === null && props.confirmFormRef?.current === null) {
            toggleIsOpen();
            props.toggleSetIsCompareHomesInteractive(false);

          }
        }
      }

      document.addEventListener('mousedown', handleClickOutside, true);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside, true);
      };
    }, [ref]);
  }

  useOutsideClickCompareHomes(isOpen ? compareHomesRef : null);

  return (
    <motion.div
      className={classes.wrapper}
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      variants={wrapperAnimVariants}
      transition={{
        type: 'tween',
        ease: 'easeOut',
        duration: .5,
      }}
      onMouseDown={(e) => preventClickEventPassthrough(e)}
      onMouseUp={(e) => preventClickEventPassthrough(e)}
      ref={compareHomesRef}
    >
      <div className={classes.contentContainer}>
        <div className={classes.top}>
          <SavedHomesTitle
            project={project}
            className={classes.savedHomesTitle}
            titleText="Compare"
            numberBgColor={project.ThemeData.colours.primaryAccent}
            isCompareHomes={true}
          />
          <div className={classes.topRightContainer}>
            <ButtonOutline
              ThemeData={project.ThemeData}
              className={classes.trashIcon}
              borderColour={project.ThemeData.colours.vibrantGreen}
              // backgroundColour={project.ThemeData.colours.vibrantGreen}
              // hoverBackgroundColour={project.ThemeData.colours.vibrantGreen}
              // textColour={project.ThemeData.colours.white}
              // hoverTextColour={project.ThemeData.colours.white}
              textTransform={'capitalized'}
              padding={16}
              fontSize={12}
              onClick={() => hooks.compareHomes.length > 0 && hooks.setIsClearConfirmationDialogOpenToOpen()}
            >
              <IconTrash style={{ width: 15, height: 15 }} fill={ThemeData.colours.white} /> Remove All
            </ButtonOutline>
          </div>
        </div>

        <div
          className={classes.middle}
          style={{ height: hooks.compareHomes.length === 0 && 877 }}
          ref={horizScrollRef}
        >
          {
            project.units && hooks.compareHomes.map(compareHomeUnit => {
                let unitIdx = project.units.findIndex(elm => elm.id === compareHomeUnit.id);
                if (unitIdx !== -1)
                  return <div
                    className={classes.unitDetailCompareCardWrapper}
                    key={unitIdx}
                  >
                    <UnitDetailCompareCardPortrait
                      project={project}
                      dataUnit={project.units[unitIdx]}
                      isCompareHomes={true}
                      isPortraitMode={isPortraitMode}
                      toggleSavedHome={() => hooks.toggleSavedHome(compareHomeUnit.id)}
                      toggleCompareHome={() => hooks.toggleCompareHome(compareHomeUnit.id)}
                      toggleUnitDetailOpen={() => props.toggleUnitDetailOpen()}
                      setSelectedUnitId={props.setSelectedUnitId}
                      setSelectedFloorplanId={props.setSelectedFloorplanId}
                      toggleSetIsCompareHomesInteractive={(val) => props.toggleSetIsCompareHomesInteractive(val)}
                    />
                  </div>;
                return <></>;
              }
            )
          }

          {
            project.floorPlans && hooks.compareHomes.map(compareHomeUnit => {
                let floorplanIdx = project.floorPlans.findIndex(elm => elm.id === compareHomeUnit.id);
                if (floorplanIdx !== -1)
                  return <div
                    className={classes.unitDetailCompareCardWrapper}
                    key={floorplanIdx}
                  >
                    <UnitDetailCompareCardPortrait
                      project={project}
                      dataUnit={project.floorPlans[floorplanIdx]}
                      isCompareHomes={true}
                      isPortraitMode={isPortraitMode}
                      toggleSavedHome={() => hooks.toggleSavedHome(compareHomeUnit.id)}
                      toggleCompareHome={() => hooks.toggleCompareHome(compareHomeUnit.id)}
                      toggleUnitDetailOpen={() => props.toggleUnitDetailOpen()}
                      setSelectedUnitId={props.setSelectedUnitId}
                      setSelectedFloorplanId={props.setSelectedFloorplanId}
                      toggleSetIsCompareHomesInteractive={(val) => props.toggleSetIsCompareHomesInteractive(val)}
                    />
                  </div>;

                return <></>;

              }
            )
          }
          {hooks.compareHomes.length < 2 &&
            <SaveHomesBrowseCard
              project={project}
              isCompareHomes={true}
              savedHomes={hooks.savedHomes}
              toggleSavedHome={hooks.toggleSavedHome}
              compareHomesIncludes={hooks.compareHomesIncludes}
              compareHomes={hooks.compareHomes}
              toggleCompareHome={hooks.toggleCompareHome}
              toggleIsOpen={toggleIsOpen}
            >

            </SaveHomesBrowseCard>
          }
        </div>

        <div className={classes.bottom}>
          <ButtonOutline
            ThemeData={project.ThemeData}
            id="compare-saved-homes-button"
            border={'none'}
            hoverBackgroundColour={'none'}
            textColour={project.ThemeData.colours.black}
            // hoverTextColour={project.ThemeData.colours.vibrantGreen}
            firstInColl
            className={`${classes.bottomButton} ${classes.backButton}`}
            textTransform={'capitalized'}
            onClick={() => {
              toggleIsOpen();
              props.SavedHomesSlideout();
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} style={{ color: project.ThemeData.colours.vibrantGreen, width: 24, height: 18, marginRight: 18 }} />
            <div>Back to Save</div>
          </ButtonOutline>

          {project.settings?.showEmailButton && hooks.savedHomes && hooks.savedHomes.length > 0 && <ButtonOutline
            disabled={true}
            ThemeData={project.ThemeData}
            borderColour={project.ThemeData.colours.vibrantGreen}
            backgroundColour={'transparent'}
            hoverBackgroundColour={'transparent'}
            textColour={project.ThemeData.colours.white}
            hoverTextColour={project.ThemeData.colours.white}
            padding={8}
            firstInColl
            className={classes.bottomButton}
            onClick={() => props.toggleIsRegisterOpen()}
            textTransform={'capitalized'}
          >
            Send To Email
          </ButtonOutline>}
          {isPortraitMode && hooks.savedHomes && hooks.savedHomes.length === 0 && <div style={{ width: 180, margin: '0 10px' }}></div>}
          {isPortraitMode && <ButtonOutline
            ThemeData={project.ThemeData}
            className={classes.closeButton}
            borderColour={project.ThemeData.colours.vibrantGreen}
            onClick={() => {
              toggleIsOpen();
              props.SavedHomesSlideout();
              if (!props.isContentPageCarouselOpen) {
                props.setIsContentPageCarousel(false);
                props.setIsContentPageCarouselOpen(false);
              }
            }}
            textTransform={'capitalized'}
          >
            <FontAwesomeIcon icon={faChevronDown} />
            <div>Close</div>
          </ButtonOutline>}
          <ClearConfirmationDialogGeneric
            dataTypeToClearName={'Compare Homes'}
            project={project}
            confirmFormRef={props.confirmFormRef}
            isClearConfirmationDialogOpen={hooks.isClearConfirmationDialogOpen}
            setIsClearConfirmationDialogOpenToClosed={hooks.setIsClearConfirmationDialogOpenToClosed}
            afterClearConfirmationPerformFunction={() => {
              hooks.clearCompareHomes();
            }}
          />
        </div>
      </div>
    </motion.div>
  );
};

const SavedHomesComparePortrait = ({ isOpen, project, toggleIsOpen, toggleIsSavedHomesSlideoutOpen, ...props }) => {
  const isPortraitMode = portraitMode();
  const location = useLocation();
  const pathname = location.pathname;
  const isProximation = pathname.includes('/proximation') ? true : false;
  const noNavigation = /\/.*\/(.*)?$/.test(pathname) || pathname.includes('/gallery') || props.isContentPageCarousel === true;

  const { accessibility: showAccessibilityFooter } = accessibilityPane();
  const classes = useStyles({ ThemeData: project.ThemeData, showAccessibilityFooter, isPortraitMode, isProximation, noNavigation });

  const { savedHomes, toggleSavedHome } = useSavedHomes();
  const { compareHomes, toggleCompareHome, compareHomesIncludes, clearCompareHomes } = useCompareHomes();
  const {
    isClearConfirmationDialogOpen,
    setIsClearConfirmationDialogOpenToOpen,
    setIsClearConfirmationDialogOpenToClosed,
  } = useOpenClosePane('ClearConfirmationDialog');

  let hooks = {
    savedHomes,
    toggleSavedHome,
    compareHomes,
    toggleCompareHome,
    compareHomesIncludes,
    clearCompareHomes,
    isClearConfirmationDialogOpen,
    setIsClearConfirmationDialogOpenToOpen,
    setIsClearConfirmationDialogOpenToClosed
  };

  if (!project) return <></>;
  return RenderSavedHomesComparePortrait(classes, project, null, isPortraitMode, isOpen, toggleIsOpen, toggleIsSavedHomesSlideoutOpen, hooks, props);
};

export default SavedHomesComparePortrait;
