import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { createUseStyles } from "react-jss";

import SavedHomesTitle from "./SavedHomesTitle";
import { portraitMode } from "util/helper";
// import BottomFilterPane from "proxima-components/dist/components/BottomFilterPane/BottomFilterPane";
import Footer from "@proximahq-dev/components/dist/components/Footer";
import Nav from "./Nav";
import UnitFcnContext from "contexts/UnitFcnContext";

const useStyles = createUseStyles(
  {
    wrapper: (props) => ({
      position: props.isPortraitMode && "absolute",
      bottom: props.isPortraitMode && 0,
      left: 0,
      width: "100%",
    }),
    navBar: (props) => ({
      height: "100px",
    }),
    footer: (props) => ({
      // border:'2px solid blue',
      display: "flex",
      position: "relative",
      zIndex: 100,
      justifyContent: "space-between",
      alignItems: "center",
      boxSizing: "border-box",
     
    }),
    footerSection: {
      flex: "1 0 auto",
      width: 286,
    },
    footerLeft: {
      display: "flex",
      alignItems: "center",
      marginLeft: "24px",
      marginTop:'12px',
      marginBottom:'12px',
    },
    footerRight: (props) => ({
      display: "flex",
      alignItems: "",
      justifyContent: "flex-end",

      ...props.ThemeData.typography.nameplate,
    }),
    footerRightItem: {
      height: "calc( var( --footer-height ) - 1px )",
      lineHeight: "var( --footer-height )",
      "& > p": {
        margin: 0,
      },
    },
    savedHomes: (props) => ({
      flex: "0 0 auto",
      display: props.display,
      width: 175,
      marginRight: 43,
      cursor: "pointer",
    }),
  },
  { name: "FooterComponents" }
);
function FooterComponents({ project, children, ...props }) {
  const isPortraitMode = portraitMode();

  const { setIsOpenNavBar, isOpenNavBar, savedHomesCount, isBigScreen, } =
    useContext(UnitFcnContext);
  const siteLogo = project.logo.length > 0 ? project.logo[0].link : null;
  const classes = useStyles({
    isPortraitMode,
    isBigScreen,
    siteLogo,
    ThemeData: project.ThemeData,
  });



  return (
    <div className={classes.wrapper}>
      <div className={classes.footer}>
        <div className={`${classes.footerSection} ${classes.footerLeft}`}>
          {/* {children} */}
          <div>
            <Footer
              setIsOpenNavBar={setIsOpenNavBar}
              isOpenNavBar={isOpenNavBar}
              setIsBack={props.setIsBack}
              isBack={props.isBack}
              savedHomes={savedHomesCount}
              setIsOpenSavedHomes={props.setIsOpenSavedHomes}
              isOpenSavedHomes={props.isOpenSavedHomes}
              isBigScreen={isBigScreen}
              isModalWindow={props.isModalWindow}
              // isNavBar={true}
            />
          </div>
        </div>
      </div>

      {isOpenNavBar && (
        <div className={classes.navBar}>
          <Nav
            navItems={project.navs}
            project={project}
            navLocation='bottomNav'
            isNavBar={true}
            
          />
        </div>
      )}
    </div>
  );
}

export default FooterComponents;
