import React, {} from 'react';

import {
  createUseStyles,
} from 'react-jss';

import {
  // motion
} from 'framer-motion';
import UnitInfoPlanNameTitle from './UnitInfoPlanNameTitle';
import UnitInfoLine from './UnitInfoLine';
import ButtonCircle from './ButtonCircle';

import { ReactComponent as XSmall } from 'img/x_currentColor_small.svg';

const useStyles = createUseStyles(
  {
    wrapper: props => ({
      height: 150,
      display: 'flex',
      boxSizing: 'border-box',
      alignItems: 'center',

      padding: '20px 0 20px 0',

      textAlign: 'left',

      borderBottom: `1px ${props.ThemeData.colours.mainBorder} solid`,
    }),
    floorplanImg: {
      flex: '0 0 auto',

      width: 105,
      height: 95,

      marginRight: 25,

      borderRadius: 4,

      backgroundColor: props => props.ThemeData.colours.white,

      '& > img': {
        width: 105,
        height: 95,

        objectFit: 'contain',
      },
    },
    info: {
      flex: '1 0 auto',
    },
    unitInfoPlanNameTitle: {
      marginTop: 0,
      color: props => props.ThemeData.colours.primaryAccent,
    },
    unitInfoLine: {
      maxWidth: 200,

      color: props => props.ThemeData.colours.white75,

      textTransform: 'uppercase',
    },
    removeHomeButton: {
      flex: '0 0 auto',

      width: 48,

      color: props => props.ThemeData.colours.primaryAccent,
    },
  },
  {
    name: 'UnitDetailListItemMini',
  }
);

const UnitDetailListItemMini = ({ project, data, ...props }) => {

  const classes = useStyles({ ThemeData: project.ThemeData });

  if (data.__typename === 'FloorPlan') {
    data = {
      id: data.id,
      name: data.name,
      exteriorSize: data.exteriorSize,
      interiorSize: data.interiorSize,
      sizeUnit: data.sizeUnit,
      floorPlan: data,
      status: '',
      bedRooms: data.bedRooms,
      bathRooms: data.bathRooms,
      unitNumber: null,
      parking: null,
      __typename: 'FloorPlan'
    };
  }
  return (
    <div className={classes.wrapper}>
      {/* <div className={classes.floorplanImg}>
				{data.floorPlan.photo && <img
					src={data.floorPlan.photo[0].link}
					alt={data.floorPlan.name}
				/>}
			</div> */}
      <div className={classes.info}>
        <UnitInfoPlanNameTitle
          project={project}
          data={data}
          color={project.ThemeData.colours.primaryAccent}
          fontSize={20}
          className={classes.unitInfoPlanNameTitle}
        />
        <UnitInfoLine
          project={project}
          data={data}
          className={classes.unitInfoLine}
        />
      </div>
      <div className={classes.removeHomeButton}>
        <ButtonCircle
          ThemeData={project.ThemeData}
          alt="Remove This Saved Home"
          widthHeight={46}
          iconWidthHeight={26}
          borderColour={project.ThemeData.colours.white25}
          textColour={project.ThemeData.colours.primaryAccent}
          hoverTextColour={project.ThemeData.colours.primaryFontHighestContrast}
          onClick={() => props.toggleSavedHome()}
        >
          <XSmall />
        </ButtonCircle>
      </div>
    </div>
  );

};

export default UnitDetailListItemMini;
