import React, {
  useContext, // useRef, // useState,
} from "react";

import { createUseStyles } from "react-jss";

import { motion } from "framer-motion";

import HeaderSimple from "./HeaderSimple";
import navArrowLeftDark from "../img/nav_arrow_left_dark.svg";
import { portraitMode } from "util/helper";
import FooterComponents from "./FooterComponents";
import UnitFcnContext from "contexts/UnitFcnContext";

const useStyles = createUseStyles(
  {
    wrapper: (props) => ({
      position: "fixed",

      top:
        props.doUseHeader || props.isPortraitMode
          ? "var(--portrait-header-height)"
          : props.isOpenNavBar
          ? "0px"
          : "100px",
      left: 0,
      right: 0,

      width: "100%",
      height:
        props.doUseHeader || props.isPortraitMode
          ? "calc( 100vh - var(--portrait-header-height) - var(--header-height))"
          : "calc( 100vh - 196px )",
    }),
    header: (props) => ({
      position: "absolute",
      top: props.isPortraitMode ? "auto" : 0,
      bottom: props.isPortraitMode ? 0 : "auto",
      left: 0,

      width: "100%",
      height: 100,

      backgroundColor: props.ThemeData.colours.primaryHeaderBg,
      zIndex: 2,
    }),
    backButton: {
      width: 100,
      height: 100,

      cursor: "pointer",

      color: "#FFF",

      background: "no-repeat center",
      backgroundImage: `url( ${navArrowLeftDark} )`,
    },
    virtualTour: {
      width: "100%",
      height: "100%",
      backgroundColor: (props) => props.ThemeData.colours.white,
    },
  },
  {
    name: "ExternalLink",
  }
);

const wrapperAnimVariants = {
  open: {
    y: 0,
    zIndex: 9999,
    display: "block",
  },
  closed: {
    y: "100vh",
    display: "block",
    transitionEnd: {
      zIndex: -2,
      display: "none",
    },
  },
};

function RenderExternalLink(
  isPortraitMode,
  project,
  classes,
  error,
  isOpen,
  toggleVirtualTourFcn,
  src,
  props
) {
  const { ThemeData } = project;

  return (
    <motion.div
      id='take-the-tour'
      className={classes.wrapper}
      initial={false}
      animate={isOpen ? "open" : "closed"}
      variants={wrapperAnimVariants}
      transition={{
        type: "linear",
        duration: 0.5,
      }}>
      {props.doUseHeader && (
        <HeaderSimple
          project={project}
          backgroundColor={ThemeData.colours.primaryHeaderBg}
          onBackClick={() => toggleVirtualTourFcn()}
        />
      )}
      <div className={classes.virtualTour}>
        <iframe
          title='Virtual Tour'
          width='100%'
          height='100%'
          frameBorder='0'
          src={src}
        />
      </div>
      {/* <div className={classes.footer}>
        <FooterComponents
          project={project}
          toggleIsSavedHomesSlideoutOpen={() =>
            props.toggleIsSavedHomesSlideoutOpen()
          }
          setIsContentPageCarousel={props.setIsContentPageCarousel}
          // isContentPage={false}
          //   setIsBack={setIsBack}
          //   isBack={isBack}
          // setIsOpenSavedHomes={setIsOpenSavedHomes}
          // isOpenSavedHomes={isOpenSavedHomes}
          isNavBar={true}
          isModalWindow={true}
        />
      </div> */}
    </motion.div>
  );
}

const ExternalLink = ({
  isOpen,
  project,
  toggleVirtualTourFcn,
  src,
  ...props
}) => {
  const isPortraitMode = portraitMode();
  const { setIsOpenNavBar, isOpenNavBar, savedHomesCount, isBigScreen } =
    useContext(UnitFcnContext);
  const classes = useStyles({
    ...props,
    ThemeData: project.ThemeData,
    isPortraitMode,
    isOpenNavBar,
  });
  return RenderExternalLink(
    isPortraitMode,
    project,
    classes,
    null,
    isOpen,
    toggleVirtualTourFcn,
    src,
    props
  );
};

ExternalLink.defaultProps = {
  doUseHeader: false,
};

export default ExternalLink;
