import { AverageHexStrings } from "util/colourFunctions";

let ThemeData = {
  colours: {},
  fonts: {},
  util: {},
};

ThemeData.colours = {
  clear: "rgb( 255, 255, 255, 0 )",
  clay25: "rgb( 75, 101, 106, .25 )",
  clay50: "rgb( 75, 101, 106, .50 )",
  clay75: "rgb( 75, 101, 106, .75 )",
  clay08: "rgb( 75, 101, 106, .08 )",
  clay05: "rgba(73, 101, 107, 0.05)",
  eggshellTextBg: "#000000",
  goldText: "#DDAE5E",
  lightGrey: "#AFAFAF",
  grey: "#434343",
  grey25: "rgba( 67, 67, 67, .25 )",
  grey10: "rgba( 67, 67, 67, .10 )",
  white90: "rgb( 255, 255, 255, .9 )",
  white75: "rgb( 255, 255, 255, .75 )",
  white50: "rgb( 255, 255, 255, .4 )",
  white25: "rgb( 255, 255, 255, .25 )",
  white20: "rgb( 255, 255, 255, .2 )",
  white05: "rgb( 255, 255, 255, .05 )",
  white: "#FFFFFF",
  notWhite: "#F9F9F9",
  black: "rgba( 0, 0, 0, 1 )",
  blackBg75: "rgba( 0, 0, 0, .75 )",
  blackBg50: "rgba( 0, 0, 0, .5 )",
  blackBg40: "rgba( 0, 0, 0, .4 )",
  blackBg25: "rgba( 0, 0, 0, .25 )",
  blackBg20: "rgba( 0, 0, 0, .2 )",
  blackBg15: "rgba( 0, 0, 0, .15 )",
  blackBg10: "rgba( 0, 0, 0, .1 )",
  blackBg5: "rgba( 0, 0, 0, .05 )",
  linearBg: "",

  aaeForestGreen: "#49656B",
  aaeForestGreen25: "#49656B40",
  aaeClay: "#CE9065",
  aaeGold: "#DDAE5E",

  kindredSkyBlue: "rgb( 163, 208, 211 )",
  kindredGreen: "#4B656A",

  vibrantDarkBlue: "#012932",
  darkBlue: "#0D2831",
  darkBlueFade: "#0D283180",
  forestGreen: "#303E1B",
  vibrantGreen: "red",
  vibrantGreenFade: "#ADA74326",
  darkGreen: "#435B5F",
  clay: "#CE9065",
  clayFade: "#CE906540",
  paleOak: "#DFDACF",
  deepGrey: "#434343",
  lightTan: "#EEEDE6",
  gray: "#49656b0d",

  mapMarker: "#ADA743",
  bannerClose: "#FFFFFF",
  featureButtonHover: "#000000",
  featureButtonTextHover: "#000000",
  featureButtonTextActive: "#FFFFFF",
  swiperColour: "#FFFFFF",

  siteMapHighlightGreen: "rgba(150, 230, 130, 0.6)",
  siteMapHighlightRed: "rgba(255, 150, 140, 0.6)",
  modelHighlight: "rgba(66, 230, 245, .60)",
  topMenuFont: "#000000", //black
  tertiaryFont: "#FFFFFF",
  filterHeader: "#FFFFFF",
  cardBg: "#FFFFFF",
  featureContentPane: "",
  unitCardBg: "#FFFFFF",
  unitInfoTitle: "#000000",
  unitInfoDetail: "#000000",
  unitListItemTitle: "#434343",
  selectedUnit: "",
  inactiveIcon: "",
  formBg: "",
  hoverButtonColour: "#d7d499",
  distanceButtonColour: "#FFFFFF",
  distanceButtonHover: "#FFFFFF",
  categoryTitle: "#FFFFFF",
  carouselLineActive: "#434343",
  carouselLineInactive: "#43434340",
};

ThemeData.colours = {
  ...ThemeData.colours,

  primaryBg: ThemeData.colours.vibrantDarkBlue,
  primaryBgTransparent: ThemeData.colours.blackBg75,
  primaryHeaderBg: ThemeData.colours.darkBlue,
  secondaryBg: ThemeData.colours.paleOak,
  secondaryBgHighContrast: ThemeData.colours.lightTan,
  primaryAccent: ThemeData.colours.vibrantGreen,
  secondaryAccent: ThemeData.colours.vibrantDarkBlue,
  linearBg: ThemeData.colours.linearBg,

  primaryAccentFade: ThemeData.colours.vibrantGreenFade,

  transparentDarkening: ThemeData.colours.blackBg75,

  headerFooterBg: ThemeData.colours.blackBg50,

  primaryFont: ThemeData.colours.paleOak,
  primaryFontHighContrast: ThemeData.colours.lightTan,
  primaryFontHighestContrast: ThemeData.colours.white,
  primaryTitleFont: ThemeData.colours.paleOak,

  secondaryFont: ThemeData.colours.vibrantDarkBlue,
  secondaryTitleFont: ThemeData.colours.forestGreen,
  secondarySubTitleFont: ThemeData.colours.deepGrey,

  mainBorder: ThemeData.colours.white50,
  mainBorderLight: ThemeData.colours.white05,
  mainBorderDarkOnLight: ThemeData.colours.blackBg50,

  lightBorder: ThemeData.colours.white25,
  lightBorderDarkOnLight: ThemeData.colours.blackBg25,
  lighterBorderDarkOnLight: ThemeData.colours.grey25,

  topMenuFont: ThemeData.colours.topMenuFont,
  tertiaryFont: ThemeData.colours.tertiaryFont,
  filterHeader: ThemeData.colours.filterHeader,
  unitCardBg: ThemeData.colours.unitCardBg,
  featureContentPane: ThemeData.colours.featureContentPane,
  unitInfoTitle: ThemeData.colours.unitInfoTitle,
  unitInfoDetail: ThemeData.colours.unitInfoDetail,
  unitListItemTitle: ThemeData.colours.unitListItemTitle,
  selectedUnit: ThemeData.colours.selectedUnit,
  inactiveIcon: ThemeData.colours.inactiveIcon,
  formBg: ThemeData.colours.formBg,
  hoverButtonColour: ThemeData.colours.hoverButtonColour,
  distanceButtonColour: ThemeData.colours.distanceButtonColour,
  distanceButtonHover: ThemeData.colours.distanceButtonHover,
  categoryTitle: ThemeData.colours.categoryTitle,
  carouselLineActive: ThemeData.colours.carouselLineActive,
  carouselLineInactive: ThemeData.colours.carouselLineInactive,

  saveCompareHomesBgLandscape: ThemeData.colours.white50,
  saveCompareHomesBgPortrait: ThemeData.colours.blackBg50,
  saveCompareHomesTextButtonLandscape: ThemeData.colours.black,
  saveCompareHomesTextButtonPortrait: ThemeData.colours.white,
  featureButtonHover: ThemeData.colours.featureButtonHover,
  featureButtonTextHover: ThemeData.colours.featureButtonTextHover,
  featureButtonTextActive: ThemeData.colours.featureButtonTextActive,
  swiperColour: ThemeData.colours.swiperColour,
  rowColoursLight: [ThemeData.colours.white, ThemeData.colours.notWhite],
};

ThemeData.colours = {
  ...ThemeData.colours,
  // Cards
  cardBg: ThemeData.colours.white,
  cardFont: ThemeData.colours.secondaryFont,
  cardTitleFont: ThemeData.colours.secondaryFont,
  cardSubTitleFont: ThemeData.colours.secondaryTitleFont,
};

ThemeData.colours = {
  ...ThemeData.colours,
  primarySelected: ThemeData.colours.clay,
  primaryUnselected: ThemeData.colours.primaryFont,
};

ThemeData.colours = {
  ...ThemeData.colours,
  primarySelectHover: AverageHexStrings(
    ThemeData.colours.primarySelected,
    ThemeData.colours.primaryUnselected
  ),
};

// const opacitiesToCreate = {
// 	25: .25,
// 	50: .5,
// 	75: .75,
// 	08: .08,
// }

// ThemeData.colours = {
// 	...ThemeData.colours,
// 	opacitiesToCreate.map( opacityObj => )
// }

ThemeData.colours = {
  ...ThemeData.colours,

  thinBorder: ThemeData.colours.mainBorder,
  thinBorderDarkOnLight: ThemeData.colours.mainBorderDarkOnLight,
  paneBorders: `1px ${ThemeData.colours.mainBorder} solid`,
  paneBordersLight: `1px ${ThemeData.colours.white90} solid`,
  navCurrentUnderline: ThemeData.colours.primaryAccent,
};

ThemeData.fonts = {
  montserratBold: "Montserrat Bold, sans-serif",
  montserratRegular: "Montserrat Regular, sans-serif",
  montserratLight: "Montserrat Light, sans-serif",
};

ThemeData.fonts = {
  ...ThemeData.fonts,

  title: ThemeData.fonts.montserratBold,
  titleSub: ThemeData.fonts.montserratRegular,
  copy: ThemeData.fonts.montserratLight,
};

ThemeData.typography = {
  nameplate: {
    color: ThemeData.colours.primaryFont,
    fontFamily: ThemeData.fonts.title,
    textTransform: "uppercase",
  },
  nameplateNoColor: {
    fontFamily: ThemeData.fonts.title,
    letterSpacing: ".15em",
    textTransform: "uppercase",
  },
};

ThemeData.typography = {
  ...ThemeData.typography,

  contentCardTitles: {
    fontFamily: ThemeData.fonts.title,
    fontSize: 32,
    letterSpacing: 0,
    letterHeight: "1.1em",
  },
};

ThemeData.ux = {
  fillToLeft: {
    transition: "all 0.66s ease",

    background: `linear-gradient( to right, rgb( 0, 0, 0, 0 ) 50%, ${ThemeData.colours.primaryAccent} 50% )`,
    backgroundSize: "calc( 200% + 2px ) 100%",
    backgroundPosition: "left bottom",

    "&:hover": {
      backgroundPosition: "right bottom",
    },
  },
  fillToRight: {
    transition: "all 0.66s ease",

    background: `linear-gradient( to left, rgb( 0, 0, 0, 0 ) 50%, ${ThemeData.colours.primaryAccent} 50% )`,
    backgroundSize: "calc( 200% + 2px ) 100%",
    backgroundPosition: "right bottom",

    "&:hover": {
      backgroundPosition: "left bottom",
    },
  },
};

ThemeData.styles = {
  hoverPaneBoxShadow: {
    boxShadow: "0px 2px 35px rgb(0 0 0 / 10%)",
  },
  hrBoxShadow: {
    boxShadow: `0 10px 20px ${ThemeData.colours.blackBg15}`,
  },
};

ThemeData.styles = {
  ...ThemeData.styles,
  hoverCard: {
    color: ThemeData.colours.cardFont,
    backgroundColor: ThemeData.colours.cardBg,
    // backgroundColor: ThemeData.colours.primaryBg,

    // border: `1px solid ${ThemeData.colours.eggshellTextBg}`,
    boxShadow: "0px 4px 10px rgb(0 0 0 / 33%)",
    borderRadius: 4,
  },
  hideScrollbar: {
    // Hide scrollbar
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",

    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  linkColourRemoval: {
    "& a, & a:visited": {
      color: ThemeData.colours.primaryFont,
      textDecoration: "none",
    },
  },
  linkColourRemovalTopMenu: {
    "& a, & a:visited": {
      color: ThemeData.colours.topMenuFont,
      textDecoration: "none",
    },
  },
  roundedCorners: {
    borderRadius: 9,
  },
};

// Scrollbars
ThemeData.styles = {
  ...ThemeData.styles,

  thinScrollbarNoColour: {
    "&::-webkit-scrollbar": {
      width: "7px",
      height: "7px",
      // paddingTop: 10,
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 20,
    },
  },
};

ThemeData.styles = {
  ...ThemeData.styles,

  thinScrollbarDarkOnLight: {
    ...ThemeData.styles.thinScrollbarNoColour,

    "&::-webkit-scrollbar-track": {
      ...ThemeData.styles.thinScrollbarNoColour["&::-webkit-scrollbar-track"],
      backgroundColor: ThemeData.colours.blackBg5,
    },
    "&::-webkit-scrollbar-thumb": {
      ...ThemeData.styles.thinScrollbarNoColour["&::-webkit-scrollbar-thumb"],
      backgroundColor: ThemeData.colours.blackBg20,
    },
  },
  thinScrollbarLightOnDark: {
    ...ThemeData.styles.thinScrollbarNoColour,

    "&::-webkit-scrollbar-track": {
      ...ThemeData.styles.thinScrollbarNoColour["&::-webkit-scrollbar-track"],
      backgroundColor: ThemeData.colours.white05,
    },
    "&::-webkit-scrollbar-thumb": {
      ...ThemeData.styles.thinScrollbarNoColour["&::-webkit-scrollbar-thumb"],
      backgroundColor: ThemeData.colours.white20,
    },
  },
};
ThemeData.styles = {
  ...ThemeData.styles,

  thinScrollbar: {
    ...ThemeData.styles.thinScrollbarDarkOnLight,
  },
};

ThemeData.anim = {
  defaultOpacityFade: {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
    transition: {
      type: "linear",
      duration: 0.25,
    },
  },
};

export default ThemeData;
