import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper.scss';
import 'swiper/modules/pagination/pagination.scss';
import SwiperCore, {
  Controller,
  Pagination,
} from 'swiper';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

SwiperCore.use([Pagination]);

const SwiperText = (props) => {
  const { classes, textMediaPairs, mediaSwiper, setTextSwiper } = props;

  return (
    <Swiper
      className={classes.rightPaneSwiper}
      modules={[Controller]}
      onSwiper={setTextSwiper}
      controller={{ control: mediaSwiper }}
      allowTouchMove={false}
    >
      {textMediaPairs && textMediaPairs.length > 0 &&
        textMediaPairs
          .map((tmp, idx) => (
            <SwiperSlide key={idx}>
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>{tmp.text}</ReactMarkdown>
            </SwiperSlide>
          ))}
    </Swiper>
  );
};

export default SwiperText;
