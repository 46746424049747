export const UNIT = "Unit";

export const FLOOR_PLAN = "Floor Plan";

export const DISABLED = "disabled";

export const AVAILABLE = "available";

export const FINAL_UNIT = "finalUnit";

export const FINAL_HOME = "finalHome";

export const SOLD = "sold";

export const UNAVAILABLE = "unavailable";

export const IN_PROGRESS = "inProgress";

export const HOLD = "hold";
