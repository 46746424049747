import React from "react";

import { createUseStyles } from "react-jss";

import { motion } from "framer-motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

import ThemeData from "data/ThemeData";

import useSavedHomes from "hooks/useSavedHomes";
import preventClickEventPassthrough from "util/preventClickEventsPassthrough";
import { useHorizontalScroll } from "hooks/useHorizontalScroll";

import UnitDetailCompareCard from "./UnitDetailCompareCard";
import SavedHomesTitle from "./SavedHomesTitle";
import ButtonOutline from "./ButtonOutline";

import xWhiteMedium from "img/x_white_medium.svg";
import SavedHomesCompareList from "./SavedHomesCompareList";

const useStyles = createUseStyles(
  {
    wrapper: (props) => ({
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: 9999,
      width: "calc( 100vw )",
      height: "calc( 100vh )",
      boxSizing: "border-box",
      overflow: "clip",

      padding: 35,

      color: props.ThemeData.colours.white,
      backgroundColor: props.ThemeData.colours.primaryBg,
      ...props.ThemeData.styles.hoverPaneBoxShadow,

      letterSpacing: ".025em",
    }),
    bgAmp: {
      zIndex: -1,
      position: "absolute",
      left: -50,
      bottom: -100,

      color: (props) => props.ThemeData.colours.primaryAccent08,
      fontFamily: (props) => props.ThemeData.fonts.copy,
      fontSize: 500,
    },
    contentContainer: {
      display: "flex",
      flexDirection: "column",

      width: "100%",
      height: "100%",
    },
    topRightContainer: {
      position: "absolute",
      top: 60,
      right: 60,

      display: "flex",
      alignItems: "center",
    },
    closeX: {
      marginTop: 10,
      marginLeft: 50,

      cursor: "pointer",
    },
    sendToEmailButton: {
      display: "inline-block",

      width: 150,

      fontSize: 12,
      letterSpacing: ".1em",
    },
    savedHomesTitle: {
      textAlign: "left",

      fontSize: 24,
      letterSpacing: ".15em",
    },
    top: {
      flex: "0 0 auto",

      // height: 50,
    },
    addHomesButton: {
      width: 220,

      fontSize: 12,
      letterSpacing: ".1em",

      borderRadius: 25,
    },
    middle: (props) => ({
      marginTop: 25,

      flex: "1 0 auto",
      display: "flex",

      maxHeight: "calc( 100vh - 50px )",

      overflowX: "auto",
      overflowY: "clip",
      ...props.ThemeData.styles.thinScrollbarLightOnDark,
    }),
    unitDetailCompareCardWrapper: {
      flex: "0 0 auto",
      // display: 'flex',
      marginLeft: "20px",

      width: 575,
      height: "100%",

      padding: 20,
      "@media (max-width: 1200px)": {
        padding: "0 20px",
      },
    },
    bottom: {
      flex: "0 0 auto",
      display: "flex",

      boxSizing: "border-box",
      paddingTop: 25,

      height: 130,

      borderTop: (props) => `1px ${props.ThemeData.colours.white50} solid`,
    },
    bgMountainscape: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,

      zIndex: -1,
    },
  },
  {
    name: "SavedHomesCompare",
  }
);

const wrapperAnimVariants = {
  open: {
    x: "-50%",
    y: "-50%",
    zIndex: 999,
    display: "block",
  },
  closed: {
    x: "100%",
    y: "-50%",
    display: "block",
    transitionEnd: {
      zIndex: -2,
      display: "none",
    },
  },
};

const RenderSavedHomesCompare = (
  classes,
  project,
  error,
  isOpen,
  toggleIsOpen,
  hooks,
  props
) => {
  const toggleIsSavedHomesCompareListOpen = () =>
    props.setIsSavedHomesCompareListOpen(!props.isSavedHomesCompareListOpen);

  const horizScrollRef = useHorizontalScroll();

  if (error) {
    console.log("RenderSavedHomesCompare", error.message);
  }

  return (
    <motion.div
      className={classes.wrapper}
      initial={false}
      animate={isOpen ? "open" : "closed"}
      variants={wrapperAnimVariants}
      transition={{
        type: "tween",
        ease: "easeOut",
        duration: 0.75,
      }}
      onMouseDown={(e) => preventClickEventPassthrough(e)}
      onMouseUp={(e) => preventClickEventPassthrough(e)}>
      <div className={classes.contentContainer}>
        <div className={classes.top}>
          <SavedHomesTitle
            project={project}
            className={classes.savedHomesTitle}
            titleText='Save and Compare'
            numberBgColor={ThemeData.colours.primaryAccent}
          />
          <div className={classes.topRightContainer}>
            {project.settings?.showEmailButton && (
              <ButtonOutline
                ThemeData={project.ThemeData}
                borderColour={ThemeData.colours.white}
                className={classes.sendToEmailButton}
                onClick={() => props.toggleIsRegisterOpen()}>
                Send To Email
              </ButtonOutline>
            )}
            <div className={classes.closeX} onClick={() => toggleIsOpen()}>
              <img src={xWhiteMedium} alt='Slide Away Saved Homes' />
            </div>
          </div>
          <ButtonOutline
            ThemeData={project.ThemeData}
            id='add-homes-to-compare-button'
            borderColour={ThemeData.colours.white}
            className={classes.addHomesButton}
            onClick={() => {
              toggleIsSavedHomesCompareListOpen();
            }}>
            Add{" "}
            {project.projectType?.includes("industrial") ? "Plans" : "Homes"} To
            Compare &nbsp;
            <FontAwesomeIcon icon={faCaretDown} />
          </ButtonOutline>
        </div>
        <div className={classes.middle} ref={horizScrollRef}>
          {project.units &&
            hooks.savedHomes.map((savedHomeUnit) => {
              let unitIdx = project.units.findIndex(
                (elm) => elm.id === savedHomeUnit.id
              );
              if (unitIdx !== -1)
                return (
                  <div
                    className={classes.unitDetailCompareCardWrapper}
                    key={unitIdx}>
                    <UnitDetailCompareCard
                      project={project}
                      dataUnit={project.units[unitIdx]}
                      toggleSavedHome={() =>
                        hooks.toggleSavedHome(savedHomeUnit.id)
                      }
                    />
                  </div>
                );
            })}

          {project.floorPlans &&
            hooks.savedHomes.map((savedHomeUnit) => {
              let floorplanIdx = project.floorPlans.findIndex(
                (elm) => elm.id === savedHomeUnit.id
              );
              if (floorplanIdx !== -1)
                return (
                  <div
                    className={classes.unitDetailCompareCardWrapper}
                    key={floorplanIdx}>
                    <UnitDetailCompareCard
                      project={project}
                      dataUnit={project.floorPlans[floorplanIdx]}
                      toggleSavedHome={() =>
                        hooks.toggleSavedHome(savedHomeUnit.id)
                      }
                    />
                  </div>
                );
            })}
        </div>
        <SavedHomesCompareList
          project={project}
          isOpen={props.isSavedHomesCompareListOpen}
          toggleIsOpen={toggleIsSavedHomesCompareListOpen}
          {...props}
        />
      </div>
      {/*
			<div className={classes.bgMountainscape}>
				<img
					src={neuMountainscape}
					alt={"bg mountain"}
				/>
			</div>*/}
    </motion.div>
  );
};

const SavedHomesCompare = ({ isOpen, project, toggleIsOpen, ...props }) => {
  const classes = useStyles({ ThemeData: project.ThemeData });

  const { savedHomes, toggleSavedHome } = useSavedHomes();
  let hooks = {
    savedHomes,
    toggleSavedHome,
  };

  if (!project) return <></>;
  return RenderSavedHomesCompare(
    classes,
    project,
    null,
    isOpen,
    toggleIsOpen,
    hooks,
    props
  );
};

export default SavedHomesCompare;
