import React, {
  useState,
  useMemo,
  useEffect,
  useRef
} from 'react';

import {
  createUseStyles,
} from 'react-jss';

import { getImageBySize, getMultipleImageSize } from 'util/imgFunctions';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import {
  Controller,
  Pagination,
  Navigation,
} from 'swiper';
import { ReactComponent as FingerTap } from 'img/one_finger_tap.svg';
import { portraitMode } from 'util/helper';

const useStyles = createUseStyles(
  {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
      '--caption-height': '130px',
    },
    imageWrapper: {
      display: 'flex',
      margin: '10px 0 20px 0',
      height: 'calc(100% - var(--caption-height))',
      width: props => props.isPortraitMode ? 900 : 'auto',
    },
    swiperCarousel: {
      height: '100%',
    },
    carouselImg: props => ({
      width: '95%',
      height: '100%',
      maxHeight: 700,
      objectFit: 'contain',
      '@media (max-height: 1850px)': {
        maxHeight: 600
      }
    }),
    swiper: props => ({
      '--swiper-theme-color': props.ThemeData.colours.white,
      width: '100%',
      maxHeight: props.isPortraitMode ? 720 : 540,
    }),
    caption: {
      height: 'var(--caption-height)',
    },
    photoTitle: props => ({
      ...props.ThemeData.typography.contentPageTitle,
      fontSize: 12,
      lineHeight: '12px',
    }),
    pageIndicator: props => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '25px 0 20px 0',
      gap: 4,
      '& > span': {
        display: 'flex',
        height: 6,
        width: 6,
        borderRadius: 4,
        border: `1px solid ${props.ThemeData.colours.branding}`,
        cursor: 'pointer',
      },
    }),
    pageIndicatorLabel: props => ({
      color: props.ThemeData.colours.branding,
      fontSize: 12,
      fontWeight: 700,
      letterSpacing: '1.8px',
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      '& svg': {
        width: 18,
        height: 18,
        stroke: props.ThemeData.colours.branding,
        marginRight: 5,
      }
    }),
    selectedIndicator: props => ({
      backgroundColor: props.ThemeData.colours.branding,
    }),
  },
  {
    name: 'FloorplanPhoto',
  }
);

const FloorplanPhoto = ({ data, resetCarouselIndex, setResetCarouselIndex, ...props }) => {
  const mainPhoto = getImageBySize(data.__typename === 'Unit' ? data.floorPlan.photo : data.photo, 'large');
  let carouselPhotos = [{ ...mainPhoto, fileTitle: 'All Floors' }];

  const floorplanPhotos = getMultipleImageSize(data.__typename === 'Unit' ? data.floorPlan?.photo : data?.photo, 'large');
  if (data.__typename === 'Unit' ? data.floorPlan?.photo : data.photo?.length > 0) carouselPhotos = floorplanPhotos;

  const isPortraitMode = portraitMode();
  const classes = useStyles({ ...props, carouselLength: carouselPhotos.length, isPortraitMode: isPortraitMode });
  const swiperRef = useRef(null);

  const [textSwiper, setTextSwiper] = useState(null);
  const [setMediaSwiper] = useState(null);
  const [carouselIndex, setCarouselIndex] = useState(0);

  const setMediaSwiperHandler = () => {
    // if (swiperRef.current) {
    //   setMediaSwiper(swiperRef.current.swiper);
    // }
  };

  const textMediaPairs = useMemo(() => {
    return carouselPhotos.length > 0 ? carouselPhotos : null;
  }, [carouselPhotos]);

  useEffect(() => {
    if (carouselPhotos.length > 0) {
      setTextSwiper(carouselPhotos[carouselIndex].fileTitle);
      if (resetCarouselIndex) {
        swiperRef.current.swiper.slideTo(0);
        setResetCarouselIndex(false);
      }
    }
  }, [carouselIndex, resetCarouselIndex]);

  useEffect(() => {
    const slideToFirst = () => {
      swiperRef.current.swiper.slideTo(0);
    };

    if (!props.isOpen && !isPortraitMode) {
      slideToFirst();
      const interval = setInterval(() => {
        slideToFirst();
      }, 50000);

      return () => clearInterval(interval);
    }

  }, [props.isOpen]);

  return (
    <div className={`${classes.wrapper}`}>
      <div className={classes.imageWrapper}>
        {carouselPhotos.length > 0 && (
          <Swiper
            className={classes.swiper}
            modules={[Controller, Pagination, Navigation]}
            onSwiper={() => setMediaSwiperHandler()}
            onSlideChange={(props) => setCarouselIndex(props.activeIndex)}
            initialSlide={carouselIndex}
            controller={{ control: textSwiper }}
            slidesPerView={1}
            buttonWrapperStyle={{ marginTop: '100px' }}
            mousewheel={true}
            ref={swiperRef}
          >
            {carouselPhotos.map((tmp, idx) => (
              <SwiperSlide key={`img-carousel-${idx}`}>
                <div className={classes.swiperCarousel}>
                  <img
                    className={`${classes.carouselImg} ${classes.fullImage}`}
                    src={tmp.link} alt={data.name} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
      {textMediaPairs.length > 1 && <div className={classes.caption}>
        <div className={classes.photoTitle}><span>{textSwiper}&nbsp;</span></div>
        {carouselPhotos.length > 1 &&
          <>
            <div className={classes.pageIndicator}>
              {textMediaPairs?.map((tmp, idx) =>
                <span
                  key={`indicator-${idx}`}
                  className={`${carouselIndex === idx ? classes.selectedIndicator : ''}`}
                  onClick={() => {
                    setCarouselIndex(idx);
                    if (swiperRef.current && swiperRef.current.swiper) {
                      swiperRef.current.swiper.slideTo(idx);
                    }
                  }}
                />
              )}
            </div>
            <div className={classes.pageIndicatorLabel}><FingerTap /> Swipe to see more</div>
          </>
        }
      </div>}
    </div>
  );
};

export default FloorplanPhoto;
