import React from 'react';

import {
  createUseStyles,
} from 'react-jss';

import {
  motion,
} from 'framer-motion';

import useRegistration from 'hooks/useRegistration';
import useSavedHomes from 'hooks/useSavedHomes';

import DialogGeneric from './DialogGeneric';
import { sendGoogleAnalyticsEvent } from 'util/sendGoogleAnalyticsEvent';

import { useMutation } from '@apollo/client';
import QueryData from 'data/QueryData';
import { portraitMode } from 'util/helper';

const useStyles = createUseStyles({
  wrapper: (props) => ({
    position: 'fixed',
    top: props.isPortraitMode ? '' : '45%',
    bottom: props.isPortraitMode ? '-50%' : '',
    left: props.isPortraitMode ? '-67%' : '50%',
    padding: 30,
    paddingLeft: 50,
    paddingRight: 50,
    color: props.isPortraitMode ? props.ThemeData.colours.black : props.ThemeData.colours.white,
    backgroundColor: props.isPortraitMode ? props.ThemeData.colours.white : (props.ThemeData.colours.formBg || props.ThemeData.colours.primaryBg),
    textAlign: 'left',
    ...props.ThemeData.styles.hoverPaneBoxShadow,
  }),
  closeX: {
    position: 'absolute',
    top: 20,
    right: 20,

    cursor: 'pointer',
  },
  title: {
    fontSize: 30,
  },
  label: props => ({
    display: 'inline-block',
    marginTop: 40,

    fontFamily: props.ThemeData.fonts.titleSub,
    fontSize: 10,
    textTransform: 'uppercase',
  }),
  input: {
    width: 335,

    border: 'none',
    borderBottom: props => `1px ${props.ThemeData.colours.primaryAccent} solid`,

    color: props => props.isPortraitMode ? props.ThemeData.colours.black : props.ThemeData.colours.white,
    backgroundColor: props => props.isPortraitMode ? props.ThemeData.colours.white : (props.ThemeData.colours.formBg || props.ThemeData.colours.primaryBg),

    fontFamily: props => props.ThemeData.fonts.copy,
    fontSize: 16,

    '&:focus, &:active': {
      outline: 'none',

      border: 'none',
      borderBottom: props => `1px ${props.ThemeData.colours.primaryAccent} solid`,
    },
  },
  inputDisabled: props => ({
    width: 335,

    border: 'none',
    borderBottom: `1px gray solid`,

    color: props.ThemeData.colours.white,
    backgroundColor: props.ThemeData.colours.primaryBg,

    fontFamily: props.ThemeData.fonts.copy,
    fontSize: 16,

    '&:focus, &:active': {
      outline: 'none',

      border: 'none',
      borderBottom: `1px gray solid`,
    },
  }),
  emailInput: {
    width: 695,
  },
  firstName: {},
  lastName: {
    paddingLeft: 20,
  },
  email: {},
  registerFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  submitButton: props => ({
    marginTop: 50,
    backgroundColor: props.isPortraitMode ? props.ThemeData.colours.vibrantGreen : 'transparent',
    fontSize: 16,
    textTransform: 'upperCase',
    height: 60,
    padding: '0 20px',
    color: props.ThemeData.colours.white,
    border: `${props.isPortraitMode ? props.ThemeData.colours.vibrantGreen : props.ThemeData.colours.white} solid`,

    borderWidth: '0.1px',
    borderRadius: props.isPortraitMode ? 15 : '0px !important',
    fontFamily: props.ThemeData.fonts.titleSub,
    '&:hover': {
      cursor: 'pointer',
      border: `1px ${props.ThemeData.colours.primaryAccent} solid`,
    },
  }),
  cancelButton: props => ({
    marginTop: 50,
    height: 60,
    backgroundColor: 'transparent',
    color: props.isPortraitMode ? props.ThemeData.colours.black : props.ThemeData.colours.white,
    border: `none`,
    cursor: 'pointer',
    fontFamily: props.ThemeData.fonts.titleSub,
    fontSize: 16,
    marginRight: 30,
  }),
  buttons: {
    display: 'inline-flex',
    float: 'right',
    justifyContent: 'space-between'
  },
  disclaimer: {
    fontSize: 8,
    maxWidth: '45%',
    marginTop: 50
  }
}, { name: 'Register' });

function RenderRegister (classes, project, error, doShow, props, children, isPortraitMode) {
  const { registration, setFirstName, setLastName, setEmail, clearRegistration } = useRegistration();
  const { savedHomes } = useSavedHomes();
  const [sendSavedHomesEmail] = useMutation(QueryData.emailSavedHomes);

  const handleSubmit = (e) => {
    e.preventDefault();

    const unitIds = savedHomes ? savedHomes.map(item => item.id) : null;
    if (!unitIds || unitIds.length === 0) return;

    sendSavedHomesEmail({ variables: { projectId: project.id, unitIds, registration } })
      .then(({ data: { emailSavedHomes } }) => {
        if (emailSavedHomes.status === 'success') {
          clearRegistration();
          props.toggleIsOpen();
        } else {
          console.log('Send email error: ', emailSavedHomes.error);
        }
      });

    sendGoogleAnalyticsEvent(
      'Send Saved Homes PDF to Email',
      `User Emails PDF to ${registration.fName} ${registration.lName}`,
      `${registration.fName} ${registration.lName}`,
      `${registration.fName} ${registration.lName}`
    );
  };

  return (
    <DialogGeneric
      isDialogOpen={doShow}
      setIsDialogOpenToClosed={props.toggleIsOpen}
      ariaLabelledBy="email-dialog-title"
      ariaDescribedBy="email-dialog-confirmation-action-button"
    >
      <motion.div
        id="register-modal"
        className={classes.wrapper}
        initial={'dontShow'}
        animate={isPortraitMode ? (doShow ? 'showPortrait' : 'dontShowPortrait') : (doShow ? 'show' : 'dontShow')}
        variants={{
          show: {
            x: '-50%',
            y: '-50%',
            zIndex: 9999,
            display: 'block',
          },
          dontShow: {
            x: '100%',
            y: '-50%',
            display: 'block',
            transitionEnd: {
              zIndex: -2,
              display: 'none',
            }
          },
          showPortrait: {
            x: '100%',
            y: '-450%',
            zIndex: 9999,
            display: 'block',

          },
          dontShowPortrait: {
            x: '100%',
            y: '100%',
            display: 'block',
            transitionEnd: {
              zIndex: -2,
              display: 'none',
            }

          }
        }}
        transition={{
          duration: .5,
        }}
      >
        <div
          id={'email-dialog-title'}
          className={classes.title}>
          {props.titleText}
        </div>
        <form onSubmit={handleSubmit}>
          <label className={`${classes.label} ${classes.firstName}`}>
            First Name<br /><br />
            <input id="register-first-name" className={classes.input} value={registration.fName} onChange={evt => setFirstName(evt.target.value)}></input>
          </label>
          <label className={`${classes.label} ${classes.lastName}`}>
            Last Name<br /><br />
            <input id="register-last-name" className={classes.input} value={registration.lName} onChange={evt => setLastName(evt.target.value)}></input>
          </label><br />
          <label className={`${classes.label} ${classes.firstName}`}>
            Email*<br /><br />
            <input id="register-first-name" type="email" required={true} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" className={`${classes.input} ${classes.emailInput}`} value={registration.email}
                   onChange={evt => setEmail(evt.target.value)}></input>
          </label>
          <div className={classes.registerFooter}>
            {project?.settings.disclaimer && <div className={classes.disclaimer}>
              <p>{project.settings.disclaimer}</p>
            </div>}
            <div className={classes.buttons}>
              <button
                id="cancel-button"
                type="button"
                className={classes.cancelButton}
                onClick={props.toggleIsOpen}
              >
                CANCEL
              </button>
              <button
                id="email-dialog-confirmation-action-button"
                className={classes.submitButton}
                sx={{
                  ...classes.submitButton,
                }}
                type="submit"
                value="Send Email"
                variant="outlined"
              >
                {props.buttonText}
              </button>
              {/*
							<SubmitButton
								id="email-dialog-confirmation-action-button"
								className={classes.submitButton}
								sx={{
									...classes.submitButton,
								}}
								type='submit'
								value='Send Email'
								variant='outlined'
							>
								{props.buttonText}
							</SubmitButton>*/}
            </div>
          </div>
        </form>
      </motion.div>
    </DialogGeneric>
  );
}

const Register = ({ doShow, project, children, ...props }) => {
  const isPortraitMode = portraitMode();
  const classes = useStyles({ ThemeData: project.ThemeData, isPortraitMode });
  return RenderRegister(classes, project, null, doShow, props, children, isPortraitMode);
};

Register.defaultProps = {
  titleText: 'Send PDF to email',
  buttonText: 'Send',
};

export default Register;
