import React from 'react';

import Dialog from '@mui/material/Dialog';

const DialogGeneric = ({ children, data, className, ...props }) => {
  return (
    <Dialog
      open={props.isDialogOpen}
      onClose={props.setIsDialogOpenToClosed}
      aria-labelledby={props.ariaLabelledBy}
      aria-describedby={props.ariaDescribedBy}
    >
      {children}
    </Dialog>
  );
};

export default DialogGeneric;
