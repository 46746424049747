import React, {
	useState,
} from 'react';

import {
	createUseStyles,
} from "react-jss";

import Popover from "@mui/material/Popover";
import { portraitMode } from "util/helper";

const useStyles = createUseStyles(
	{
		wrapper: props => ( {
			height: props.height,
			marginTop: props.marginTop,
			margin: props.margin,
			padding: props.isCircle ?
				15 :
				props.padding,
			display: 'flex',
			justifyContent: props.justifyContent,
			alignItems: props.alignItems,
			

			color: props.isActive ?
				( !props.isPortraitMode && props.hoverTextColour ?
					props.hoverTextColour :
					props.backgroundColour ) :
				props.textColour,
			backgroundColor: props.isActive ?
				( !props.isPortraitMode && props.hoverBackgroundColour ?
					props.hoverBackgroundColour :
					props.textColour ) :
				props.backgroundColour,

			fontFamily: props.ThemeData.fonts.title,
			fontSize: props.fontSize,
			textDecoration: props.textDecoration ? props.textDecoration : 'none',
			textAlign: props.textAlign ? props.textAlign : 'center',
			textTransform: props.textTransform ? props.textTransform : 'uppercase',
			letterSpacing: '0.025em',

			border: props.border ? props.border : `1px ${props.borderColour} solid`,
			borderRadius: props.isRounded ? 100 :
					props.isCircle ?
						'50%' :
						'unset'
					,

			cursor: 'pointer',

			transition: 'color .25s ease-out, background-color .25s ease-out',

			'& a:hover, & a:visited': {
				color: props.textColour,
			},

			'&:hover': {
				color: props.isActive ?
					( props.hoverBackgroundColour ? props.hoverBackgroundColour : props.textColour ) :
					( props.hoverTextColour ? props.hoverTextColour : props.backgroundColour ),
				backgroundColor: props.isActive ?
					( props.hoverTextColour ? props.hoverTextColour : props.backgroundColour ) :
					( props.hoverBackgroundColour ? props.hoverBackgroundColour : props.textColour ),
			},
		} ),
		hide: {
			display: 'none !important'
		}
	},
	{ name: 'ButtonOutline' },
);



ButtonOutline.defaultProps = {
	padding: '15px 10px',
	marginTop: 'initial',
	fontSize: 12,
	textColour: 'inherit',
	hoverTextColour: null,
	backgroundColour: 'clear',
	hoverBackgroundColour: null,
	justifyContent: 'center',
	alignItems: 'center',
	textTransform: 'uppercase',
	border: "",
	isRounded: false,
	isCircle: false,
	isActive: false,
	activeLabel: null,
	textAlign: ""
};

function ButtonOutline( { ThemeData, children, ...props } ) {
	if (!props.borderColour) props.borderColour = ThemeData.colours.grey
	const classes = useStyles({...props, ThemeData, isPortraitMode: portraitMode() });
	const [anchorElm, setAnchorElm] = useState( null );

	const handleClick = ( e ) => {
		setAnchorElm( e.currentTarget );
	};
	const handleClose = () => {
		setAnchorElm( null );
	};
	const isPopoverOpen = Boolean( anchorElm );
	return (
		<>
			<div
				id={props.id}
				className={`${props.className} ${classes.wrapper} ${props.firstInColl ? "firstInColl" : ""} ${props.hideFilter ? classes.hide : ''}`}
				onClick={
					props.popupWindow ?
						handleClick :
						props.onClick
				}
				onMouseEnter={props.onMouseEnter}
				onMouseLeave={props.onMouseLeave}
				>
					{props.isActive && props.activeLabel ? props.activeLabel : props.label}
					{children}
			</div>
			{props.popupWindow &&
				<Popover
					open={isPopoverOpen}
					onClose={handleClose}
					anchorEl={anchorElm}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
				>
					<div className={classes.popupWindow}>
						{props.popupWindow}
					</div>
				</Popover>
			}
		</>
	);
}



export default ButtonOutline;