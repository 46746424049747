import React, { useEffect, useState, useMemo, useContext } from "react";

import { createUseStyles } from "react-jss";

import { useParams, useHistory } from "react-router-dom";

import { motion } from "framer-motion";
import { Fade } from "@mui/material";

import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper.scss";
import "swiper/modules/pagination/pagination.scss";
import SwiperCore, {
  Controller,
  Pagination,
  Navigation,
  Mousewheel,
} from "swiper";

import ConstantData from "../data/ConstantData";

import Map from "components/Map";
import ContentPageNeighbourhoodPois from "./ContentPageNeighbourhoodPois";
import HeaderComponents from "./HeaderComponents";
import FooterComponents from "./FooterComponents";
import HeaderContextData from "data/HeaderContextData";
import VirtualTour from "./VirtualTour";
import ButtonOutline from "./ButtonOutline";

import contentPageX from "../img/content_page_x.svg";
import contentPageXDark from "../img/content_page_x_dark.svg";
import { getImageBySize, getImageBySizeMultiple } from "util/imgFunctions";
import { portraitMode } from "util/helper";
import { filterAndGroupBySubCategory } from "util/helper";
import UnitFcnContext from "contexts/UnitFcnContext";

SwiperCore.use([Pagination, Mousewheel]);

const useStyles = createUseStyles(
  {
    wrapper: (props) => ({
      height: "100%",
      width: "100%",
      color: props.ThemeData.colours.black,
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 1001,
    }),
    contentPage: (props) => ({
      display: "flex",
      flexDirection: props.isPortraitMode ? "column" : "row",
      // border: '1px solid red',
      //			height: props.isPortraitMode ? 'var(--content-pane-height)' : "calc( 100vh - var( --header-height ) )",
      height: props.isPortraitMode
        ? "calc( 100vh - var(--portrait-header-height) - var(--footer-height) - var(--accessibility-footer))"
        : "calc( 100vh - var(--header-height))",
    }),
    footer: (props) => ({
      display: "block",
      marginTop: props.isBigScreen ? "-9vh" : "-8vh",
      // border: "1px solid red",
    }),
    backButtonWrapper: (props) => ({
      display: "flex",
      alignItems: "center",
      height: props.isPortraitMode ? "var(--footer-height)" : "auto",
      borderRight: props.isPortraitMode
        ? props.ThemeData.colours.paneBorders
        : 0,
    }),
    backButton: (props) => ({
      flex: "0 0 auto",
      cursor: "pointer",
      paddingLeft: props.isPortraitMode ? 43 : 40,
      paddingRight: props.isPortraitMode ? 43 : 0,

      color: props.ThemeData.colours.primaryFont,
      fontSize: 14,
      "& > svg": {
        fontSize: 20,
        marginRight: 5,
      },
    }),
    contentPane: (props) => ({
      position: props.isPortraitMode ? "absolute" : "static",
      bottom: props.isPortraitMode
        ? "calc(var(--footer-height) + var(--accessibility-footer))"
        : "auto",
      width: props.isPortraitMode ? "100%" : 390,
      height: props.isPortraitMode
        ? "var(--content-pane-height)"
        : "calc( 100vh - var( --footer-height ) -5vh)",

      cursor: "pointer",
      flex: "0 0 auto",
      zIndex: 10,
      display: "flex",
      flexDirection: "column",
      backgroundColor:
        props.ThemeData.colours.contentPageBg ||
        props.ThemeData.colours.secondaryBg,
      textAlign: "left",

      "&.withMap": {
        width: props.isPortraitMode ? "100%" : 390,
        "&.contentContainer": {
          height: "75%",
        },
      },
    }),
    contentContainer: (props) => ({
      flex: "1 1 auto",
      display: "flex",
      flexDirection: "column",
      boxSizing: "border-box",
      height: "70%",
      padding: props.isPortraitMode ? "36px 43px" : '60px 40px',
    }),
    rightPane: (props) => ({
      flex: props.isPortraitMode ? "0 0 auto" : "1 1 auto",
      width: "100%",
      height: props.isPortraitMode
        ? "calc(100vh - var(--portrait-header-height) - var(--footer-height) - var(--content-pane-height) - var(--accessibility-footer) + 17px)"
        : "100%",

      //			height: props.isPortraitMode ? 'calc(100vh - var(--content-pane-height))' : "100%",
      backgroundColor: "gray",
      background: "no-repeat center",
      backgroundSize: "cover",
      objectFit: "contain",
    }),
    navContainer: {
      height: 150,

      flex: "0 1 auto",

      padding: "0 40px 30px 40px",
    },
    navArrow: {
      marginTop: 30,
    },
    navArrowLeft: {},
    navArrowRight: {
      marginLeft: 20,
    },
    navArrowDisabled: {
      opacity: 0.1,
    },
    swiperSlideImg: {
      width: 10,
    },
    rightPaneVideo: {
      display: "flex",
      objectFit: "contain",
    },
    rightPaneFullsized: {
      backgroundSize: "contain",
    },
    swiper: {
      "--swiper-theme-color": (props) => props.ThemeData.colours.primaryAccent,
    },
    contentArea: {
      flex: "0 0 auto",
    },
    sectionName: {
      flex: "0 0 auto",

      color: (props) => props.ThemeData.colours.primaryAccent,

      fontFamily: (props) => props.ThemeData.fonts.title,
      fontSize: 16,
      margin: (props) => (props.doRenderInteractiveMap ? 0 : "0.7em 0"),
    },
    title: {
      flex: "0 0 auto",

      color: (props) => props.ThemeData.colours.secondaryTitleFont,

      marginTop: (props) => (props.doRenderInteractiveMap ? 0 : ".5em"),
      marginBottom: (props) => (props.doRenderInteractiveMap ? 0 : "1.2em"),

      fontFamily: (props) => props.ThemeData.fonts.title,
      fontSize: (props) => (props.doRenderInteractiveMap ? "1.5em" : "2.3em"),
      letterSpacing: 0,
      lineHeight: "1.2em",
    },
    content: (props) => ({
      flex: "1 1 auto",
      // color: props.ThemeData.colours.secondaryFont,
      color: props.ThemeData.colours.tertiaryFont,
      fontFamily: props.ThemeData.fonts.copy,
      fontSize: "1.2em",
      letterSpacing: "0.0125em",
      lineHeight: "2em",

      overflowY: "auto",

      ...props.ThemeData.styles.thinScrollbar,
    }),
    contentPageX: {
      position: "absolute",
      right: 0,

      width: 150,
      height: 150,

      background: "no-repeat center",
      backgroundImage: `url('${contentPageX}')`,

      filter: "drop-shadow(2px 2px 2px rgb(0, 0, 0, .15))",

      "&.isDark": {
        backgroundImage: `url('${contentPageXDark}')`,
      },
    },
    mapPane: (props) => ({
      display: "none",
      position: "relative",
      "&.doShow": {
        display: "block",
      },
    }),
    scrollIcon: {
      bottom: "30px !important",
      zIndex: 2,
    },
    textBox: (props) => ({
      minHeight: "25vh",
      maxHeight: "25vh",
      overflowY: "auto",
      ...props.ThemeData.styles.thinScrollbar,
      paddingRight: props.isPortraitMode ? 20 : 0,
    }),
    contentAreaWrapper: {
      display: (props) => (props.isPortraitMode ? "flex" : ""),
    },
    contentAreaPortrait: {
      width: (props) => (props.isPortraitMode ? "70%" : ""),
    },
    categoryArea: {
      padding: (props) => (props.isPortraitMode ? 20 : 0),
      height: (props) => (props.isPortraitMode ? "400px" : "160px"),
      marginTop: "20px",
      overflowY: "auto",
      width: (props) => (props.isPortraitMode ? 300 : "none"),
    },
    activeButton: {
      color: (props) => props.ThemeData.colours.vibrantGreen,
    },
    categoryName: {
      textTransform: "capitalize",
      "&:hover": {
        opacity: 0.5,
      },
    },
  },
  {
    name: "ContentPageCarousel",
  }
);

function ContentPageCarousel({
  project,
  currentSection,
  sectionSlug,
  sectionData,
  currentNav,
  tab,
  setTab,
  ...props
}) {
  const { logo = [], ThemeData } = project;
  const isPortraitMode = portraitMode();
  const siteLogo = logo.length > 0 ? logo[0].link : null;
  let contentPage =
    tab !== -1
      ? sectionData.filter((data) => data.position === tab)[0]
      : sectionData[0];
  let doRenderInteractiveMap = false;
  let doOnlyRenderPhotos = false;
  doRenderInteractiveMap =
    contentPage?.customFunction ===
    ConstantData.contentPageCustomFunctions.interactiveMap;
  doOnlyRenderPhotos =
    contentPage?.customFunction ===
    ConstantData.contentPageCustomFunctions.photosOnly;

  const history = useHistory();
  const [isBack, setIsBack] = useState(false);
  const [isOpenSavedHomes, setIsOpenSavedHomes] = useState(false);

  const { setIsOpenNavBar, isOpenNavBar, isBigScreen } =
    useContext(UnitFcnContext);

  const classes = useStyles({
    tab,
    contentPage,
    doRenderInteractiveMap,
    ThemeData,
    isPortraitMode,
    isBigScreen,
  });

  const [currentSchemeMedia, setCurrentSchemeMedia] = useState(null);
  const [fadeMedia, setFadeMedia] = useState(false);
  const [activeButton, setActiveButton] = useState("");

  let { contentPageSlug } = useParams();
  if (props.overrideUrlContentPageSlug)
    contentPageSlug = props.overrideUrlContentPageSlug;

  if (
    project &&
    currentSection &&
    contentPage.customFunction ===
      ConstantData.contentPageCustomFunctions.colourSchemes
  ) {
    // siteLogo = project && project.logo.length > 0 ? project.logo[0].link : null
    let page = currentSection.pages.filter(
      (p) => p.slug === contentPageSlug
    )[0];
    if (page) contentPage = page;
  }

  const unitFcns = {
    selectedUnitId: props.selectedUnitId,
    setSelectedUnitId: props.setSelectedUnitId,
    setSelectedFloorplanId: props.selectedFloorplanId,
    isUnitDetailOpen: props.isUnitDetailOpen,
    setIsUnitDetailOpen: props.setIsUnitDetailOpen,
    toggleIsUnitDetailOpen: props.toggleIsUnitDetailOpen,
  };

  const [distance, toggleDistance] = useState([]);

  const [highlightCatPoi, setHighlightCatPoi] = useState({
    isHighActive: false,
    highCatId: -1,
    highPoiId: -1,
  });

  const [procFlyTo, setProcFlyTo] = useState({
    doProcFlyTo: false,
    poiid: -1,
    catid: -1,
    coordinates: -1,
  });

  const textMediaPairs = useMemo(() => {
    if (sectionData) {
      if (contentPage.photo && contentPage.photo.length > 1) {
        let tmpAll = [];
        for (let tmp of contentPage.photo) {
          tmpAll.push({
            text: tmp.content,
            media: tmp,
          });
        }
        return tmpAll;
      }
    }

    return null;
  }, [
    contentPage?.content,
    contentPage.image,
    contentPage.text_media_pairs,
    sectionData,
  ]);

  const childTextMediaPairs = useMemo(() => {
    if (contentPage && contentPage.child.length > 1) {
      return contentPage.child;
    }
    return null;
  }, [contentPage]);

  const doOnlyRenderSingleTextContent = useMemo(() => {
    if (textMediaPairs && textMediaPairs.length > 0) {
      let areAllTMPTextsBlank = true;
      if (textMediaPairs.length > 1) {
        for (let i = 1; i < textMediaPairs.length; i++) {
          if (textMediaPairs[i].text) areAllTMPTextsBlank = false;
        }
      }
      return areAllTMPTextsBlank;
    }
    return false;
  }, [textMediaPairs]);

  const [textSwiper, setTextSwiper] = useState(null);
  const [mediaSwiper, setMediaSwiper] = useState(null);

  const renderContentPageMediaPane = (contentPageMedia, multiple) => {
    const photo = multiple
      ? getImageBySizeMultiple(contentPageMedia, "large")
      : getImageBySize(contentPageMedia, "large");
    return (
      <div
        className={`${classes.rightPane} ${
          doOnlyRenderPhotos ? classes.rightPaneFullsized : ""
        }`}
        style={contentPageMedia && { backgroundImage: `url('${photo.link}')` }}
      />
    );
  };

  const customFunctionContent = useMemo(() => {
    let categoryTextMediaPairs = [];
    if (
      contentPage &&
      contentPage.customFunction ===
        ConstantData.contentPageCustomFunctions.colourSchemes
    ) {
      categoryTextMediaPairs = filterAndGroupBySubCategory(childTextMediaPairs);
    }
    return {
      [ConstantData.contentPageCustomFunctions.virtualTour]: (
        <VirtualTour
          project={project}
          isOpen={true}
          src={
            sectionData
              ? sectionData.length > 0 && sectionData[0].custom_iframe_embed_url
              : ""
          }
          toggleVirtualTourFcn={() => history.goBack()}
        />
      ),
      [ConstantData.contentPageCustomFunctions.colourSchemes]: (
        <>
          <div className={classes.contentPane}>
            <div className={classes.contentContainer}>
              <h4 className={`${classes.contentArea} ${classes.sectionName}`}>
                {currentSection && currentSection.name.toUpperCase()}
              </h4>
              <div className={classes.contentAreaWrapper}>
                <div className={classes.contentAreaPortrait}>
                  <h2 className={`${classes.contentArea} ${classes.title}`}>
                    {contentPage && contentPage.title}
                  </h2>
                  <div className={`${classes.textBox} ${classes.content}`}>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                      {currentSchemeMedia !== null
                        ? currentSchemeMedia.content
                        : ""}
                    </ReactMarkdown>
                  </div>
                </div>
                <div className={classes.categoryArea}>
                  <div>
                    {categoryTextMediaPairs &&
                      categoryTextMediaPairs.map((item) => (
                        <>
                          <h3 className={classes.subTitle}>
                            {item.subCategory}
                          </h3>
                          {item.objects.length > 0 &&
                            item.objects.map((obj, idx) => (
                              <ButtonOutline
                                ThemeData={ThemeData}
                                border={"0px"}
                                alignItems={"left"}
                                justifyContent={"left"}
                                textAlign={"left"}
                                hoverTextColour={ThemeData.colours.vibrantGreen}
                                className={`${classes.categoryName} ${
                                  activeButton === obj.id
                                    ? classes.activeButton
                                    : ""
                                }`}
                                // textColour={ThemeData.colours.tertiaryFont}
                                // hoverBackgroundColour={isPortraitMode ? ThemeData.colours.vibrantGreen : "rgb(128, 14, 199, .5)"}
                                // hoverTextColor={isPortraitMode ? ThemeData.colours.white : ""}
                                // className={activeButton === obj.id ? `${classes.activeButton}` : ""}

                                key={idx}
                                onClick={() => getSelectedScheme(obj.id, idx)}>
                                {obj.title}
                              </ButtonOutline>
                            ))}
                        </>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(currentSchemeMedia || childTextMediaPairs) && (
            <Fade in={fadeMedia}>
              {renderContentPageMediaPane(
                currentSchemeMedia
                  ? currentSchemeMedia.photo
                  : childTextMediaPairs[0].photo
              )}
            </Fade>
          )}
        </>
      ),
    };
  }, [ConstantData, sectionData, unitFcns]);

  const getSelectedScheme = (id) => {
    const scheme = childTextMediaPairs.find((image) => image.id === id);
    setActiveButton(id);
    setFadeMedia(false);
    setTimeout(() => {
      setCurrentSchemeMedia(scheme);
      setFadeMedia(true);
    }, 100);
  };

  useEffect(() => {
    setFadeMedia(true);
    if (childTextMediaPairs && childTextMediaPairs.length > 0) {
      isPortraitMode && setActiveButton(childTextMediaPairs[0].id);
      setCurrentSchemeMedia(childTextMediaPairs[0]);
    }
  }, [childTextMediaPairs]);

  const handleBackButtonClick = () => {
    if (props.toggleSlideUpIsOpen) {
      props.toggleSlideUpIsOpen();
    } else {
      setTab(-1);
      props.setIsContentPageCarouselOpen(false);
    }
  };
  useEffect(() => {
    // console.log('[SOMW PROPS', props)
    if (isOpenSavedHomes) {
      console.log("asd");

      props.toggleIsSavedHomesSlideoutOpen();
    }
  }, [isOpenSavedHomes]);
  useEffect(() => {
    handleBackButtonClick();
  }, [isBack]);

  return (
    contentPage.position === tab && (
      <>
        <motion.div
          className={classes.wrapper}
          id={contentPage.id}
          initial={{ y: "100vh" }}
          animate={{ y: 0 }}
          exit={{ y: "100vh" }}
          transition={{ type: "tween", duration: 0.5 }}>
          {!isOpenNavBar && (
            <HeaderComponents
              headerContext={HeaderContextData.contentPageHeaderContext}
              setIsUnitDetailOpen={props.setIsUnitDetailOpen}
              toggleIsSavedHomesSlideoutOpen={
                props.toggleIsSavedHomesSlideoutOpen
              }
              toggleIsRegisterOpen={props.toggleIsRegisterOpen}
              backgroundColor={ThemeData.colours.primaryHeaderBg}
              siteLogo={siteLogo}
              project={project}
              noRenderSaveHomes={true}
              height={isPortraitMode ? 150 : 100}
              headerBg={
                project.ThemeData.colours.headerBg !== "rgba(0, 0, 0, .75)"
                  ? project.ThemeData.colours.headerBg
                  : ThemeData.colours.black
              }>
              {/* {!isPortraitMode && renderBackButton()} */}
            </HeaderComponents>
          )}

          <div
            className={classes.contentPage}
            id={`content-page-${contentPage.id}`}
            style={{ backgroundColor: ThemeData.colours.primaryBg }}>
            {contentPage.customFunction &&
            !(doRenderInteractiveMap || doOnlyRenderPhotos) ? (
              customFunctionContent[contentPage.customFunction]
            ) : (
              <>
                {!doOnlyRenderPhotos && (
                  <div
                    className={`${classes.contentPane} ${
                      doRenderInteractiveMap ? "withMap" : ""
                    }`}>
                    <div className={classes.contentContainer}>
                      <h4
                        className={`${classes.contentArea} ${classes.sectionName}`}>
                        {currentNav.name.toUpperCase()}
                      </h4>
                      <h2 className={`${classes.contentArea} ${classes.title}`}>
                        {contentPage.title}
                      </h2>

                      {
                        doRenderInteractiveMap ? (
                          <ContentPageNeighbourhoodPois
                            distance={distance}
                            toggleDistance={toggleDistance}
                            highlightCatPoi={highlightCatPoi}
                            setHighlightCatPoi={setHighlightCatPoi}
                            setProcFlyTo={setProcFlyTo}
                            project={project}
                            isPortraitMode={isPortraitMode}
                          />
                        ) : !doOnlyRenderSingleTextContent &&
                          textMediaPairs &&
                          textMediaPairs.length > 0 ? (
                          <Swiper
                            className={classes.rightPaneSwiper}
                            modules={[Controller]}
                            onSwiper={setTextSwiper}
                            controller={{ control: mediaSwiper }}
                            allowTouchMove={false}>
                            {textMediaPairs.map((tmp, idx) => (
                              <SwiperSlide key={idx}>
                                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                                  {tmp.text}
                                </ReactMarkdown>
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        ) : (
                          <ReactMarkdown
                            className={classes.content}
                            components={{
                              p: ({ children }) => (
                                <p className='mb-4'>{children}</p>
                              ),
                              ol: ({ children }) => (
                                <ol className='list-decimal list-inside ml'>
                                  {children}
                                </ol>
                              ),
                              ul: ({ children }) => (
                                <ul className='list-disc list-inside ml-4'>
                                  {children}
                                </ul>
                              ),
                              li: ({ children }) => (
                                <li className='mb-2'>{children}</li>
                              ),
                              br: () => <br />,
                            }}>
                            {contentPage.content}
                          </ReactMarkdown>
                        )
                        // <ReactMarkdown className={classes.content} rehypePlugins={[rehypeRaw]}>{contentPage.content}</ReactMarkdown>
                      }
                    </div>
                    {/* <div style={{ height: "150px", marginBottom: "45px"}}></div> */}
                    {/* {project.slug !== 'liveenzo' && tab !== -1 && <ContentNavigationCarousel
										project={project}
										tab={tab}
										setTab={setTab}
										tabs={sectionData}
										currentTab={contentPage}
										isPortraitMode={isPortraitMode}
									/>} */}
                  </div>
                )}
                <div
                  className={`${classes.rightPane} ${classes.mapPane} ${
                    doRenderInteractiveMap ? "doShow" : ""
                  }`}>
                  {doRenderInteractiveMap && (
                    <Map
                      project={project}
                      doShow={doRenderInteractiveMap}
                      highlightCatPoi={highlightCatPoi}
                      setHighlightCatPoi={setHighlightCatPoi}
                      procFlyTo={procFlyTo}
                      isPortraitMode={isPortraitMode}
                    />
                  )}
                </div>
                {doRenderInteractiveMap ? (
                  ""
                ) : textMediaPairs && textMediaPairs.length > 0 ? (
                  <>
                    <Swiper
                      className={classes.swiper}
                      modules={[Controller, Navigation]}
                      onSwiper={setMediaSwiper}
                      controller={{ control: textSwiper }}
                      slidesPerView={1}
                      navigation={{ clickable: true }}
                      mousewheel={true}>
                      {textMediaPairs.map((tmp, idx) => (
                        <SwiperSlide
                          className={classes.swiperSlideImg}
                          key={idx}>
                          {renderContentPageMediaPane(tmp.media, true)}
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </>
                ) : (
                  renderContentPageMediaPane(contentPage.photo)
                )}
              </>
            )}
          </div>

          <div className={classes.footer}>
            <FooterComponents
              project={project}
              toggleIsSavedHomesSlideoutOpen={() =>
                props.toggleIsSavedHomesSlideoutOpen()
              }
              setIsContentPageCarousel={props.setIsContentPageCarousel}
              isContentPage={false}
              setIsBack={setIsBack}
              isBack={isBack}
              setIsOpenSavedHomes={setIsOpenSavedHomes}
              isOpenSavedHomes={isOpenSavedHomes}
            />
          </div>
        </motion.div>
      </>
    )
  );
}

ContentPageCarousel.defaultProps = {
  doRenderHeader: true,
};

export default ContentPageCarousel;
