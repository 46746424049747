export const ConstantData = {
	projectType: {
		industrial: 'industrial',
		residential: 'residential'
	},
	contentCardForms: {
		oneUp: "oneUp",
		twoUpHoriz: "twoUpHoriz",
		twoUpVert: "twoUpVert",
		fourUp: "fourUp",
		sixUp: "sixUp",
	},
	contentPageCustomFunctions: {
		interactiveMap: "interactiveMap",
		photoGallery: "photoGallery",
		floorplans: "floorplans",
		photosOnly: "photosOnly",
		virtualTour: "virtualTour",
		enjoyTheView: "enjoyTheView",
		orange3dModel: "orange3dModel",
		colourSchemes: "colourSchemes",
		photoOnlyCard: "photoOnlyCard",
		externalLink: "externalLink",
		siteMaps: "siteMaps"
	},
	dataFormattingFunctions: {
		prices: value => `$${value.toLocaleString()}`,
	},
	imgSizes: {
		raw: 'raw',
		xlarge: 'xlarge',
		large: 'large',
		medium: 'medium',
		small: 'small',
		xsmall: 'xsmall',
	},
	localStorageKeys: {
		savedHomes: "savedHomes",
		siteFeatureToggles: "siteFeatureToggles",
		registration: "registration",
		unitsFilter: "unitsFilter",
		unitsFilterResult: "unitsFilterResult",
		unitFiltersList: "unitFiltersList",
		compareHomes: "compareHomes",
		accessibilityPane: "accessibilityPane",
	},
	galleryPhotoDefaultCategories: {
		all: "all",
	},
	gqlQueryToSubqueryTypesMap: {
		unitFloors: "unit_floor",
		unitBuildings: "unit_building",
		unitHomeTypes: "unit_home_type",
		unitPlanTypes: "unit_plan_type",
		unitExposures: "unit_exposures",
	},
	scrollIconDir: {
		vertical: "vertical",
		horizontal: "horizontal",
	},
	sectionCustomFunctions: {
		proxima: "proxima",
		floorplans: "floorplans",
		gallery: "photoGallery",
		map: "map",
	},
	TopMenu: {
		defaultSectionContentToDisplay: "default",
	},

	unitFilterViewModes: {
		card: "card",
		list: "list",
		compare: "compare",
	},
	unitFilterTypes: {
		checkedList: "checkedList",
		range: "range",
	},
	webglViewTypes: {
		byFloorplateDetailed: "byFloorplateDetailed",
		byFloorplateSimple: "byFloorplateSimple",
		building3DModel: "building3DModel",
	},
	webglWindowMessageTypes: {
		webglHotspotClicked: "webglHotspotClicked",
	},
	cookieCopy: { 
		cookie: <div>Cookies make our platform work properly and securely. By allowing cookies, you will get the best experience with developer enriched content and relevant services."</div>,
		privacyPolicy: <div>You can always find more information by visiting our “Privacy Policy” in the main menu. View Proxima’s <b style={{ cursor: 'pointer' }}>Privacy and Cookie Policy</b>.</div>
	},
	disclaimerData: {
		disclaimer_2: 'The Developer reserves the right to make modifications to floor plans, features and/or finishes without notice.',
		disclaimer_3: 'Sizes are based on architectural measurements E.&O.E.',
		disclaimer_4: `Renderings are artists' concept only.`,
		disclaimer_5: 'Prices are subject to change without notice and are subject to availability. E.&O.E. Prices do not include applicable taxes.',
		disclaimer_6: 'Marketing and Sales by LNG Studios. *subagent sales team member own brokerage name on business cards and emails.'
	},
	unitStatuses: {
		active: "Available",
		hold: "Hold",
		sold: "Sold",
		coming: "Upcoming Phrase"
	},
	floorplanCategories: {
		industrial: 'industrial', 
		commercial: 'commercial', 
		office: 'office',
		healthcare: 'healthcare'
	}
};

export default ConstantData;
