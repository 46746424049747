import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import {
  motion,
} from 'framer-motion';

import {
  createUseStyles,
} from 'react-jss';

import {
  // motion
} from 'framer-motion';
import PhotoGallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import ButtonGroup from './ButtonGroup';
import ButtonOutline from './ButtonOutline';
import ConstantData from 'data/ConstantData';

const useStyles = createUseStyles(
  {
    vertical: {
      overflowY: 'auto',
      height: 'calc(100vh  - var(--footer-height)  - var(--navigation-height) + 9px - 165px)',
      // marginTop: '-2vh'
      
    },
    wrapper: props => ({
      boxSizing: 'border-box',
      width: '100%',
      height: props.isPortraitMode
        ? 'calc(100vh  - var(--footer-height)  - var(--navigation-height) + 36px )'
        : (props.withPhotosOfCategoryName ? 'calc( 100vh - 100px  ) ' : 'calc( 100vh - 200px )'),
      overflowY: 'auto',
      paddingTop:'5vh',
      padding: !props.noPadding ? 20 : 0,
      backgroundColor: props.ThemeData.colours.white,
      backdropFilter: 'blue(6px)',
      textAlign: 'left',

      ...props.ThemeData.styles.thinScrollbarLightOnDark,
    }),
    scroll: {
      color: 'white',
      position: 'absolute',
      height: '48px',
      width: '48px',
      bottom: '120px',
    },
    categoryButtons: props => ({
      padding: props.isPortraitMode ? '20px 15px' : '5px 15px 15px',
      backgroundColor: props.isPortraitMode ? props.ThemeData.colours.white : props.ThemeData.colours.black,

    }),

    buttonGroup: {
      justifyContent: 'flex-start',
    },

    buttonOutline: props => ({
      borderRadius: 15,
      padding: 16,
 
    }),
    backButtonWrapper: props => ({
      display: 'flex',
      alignItems: 'center',
      height: props.isPortraitMode ? 'var(--footer-height)' : 'auto',
      borderRight: props.isPortraitMode ? props.ThemeData.colours.paneBorders : 0,
    }),
    backButton: props => ({
      display: 'flex',
      alignItems: 'center',
      flex: '0 0 auto',
      cursor: 'pointer',
      paddingLeft: props.isPortraitMode ? 43 : 40,
      paddingRight: props.isPortraitMode ? 43 : 0,

      color: props.ThemeData.colours.primaryFont,
      fontSize: 14,
      '& > svg': {
        fontSize: 20,
        marginRight: 5
      }
    }),
  },
  {
    name: 'Gallery',
  }
);

const RenderGalleryPortrait = (project, currentSection, prevSection, classes, props) => {
  const { ThemeData } = project;
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [showCategory, setShowCategory] = useState(ConstantData.galleryPhotoDefaultCategories.all);
  const [categories, setCategories] = useState([]);

  const openLightbox = useCallback((e, { photo, index }) => {
    setCurrentImage(photo.id);
    setIsViewerOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const photos = project.photoGallery;
  const validatePhotoCategories = (photo) => {
    return photo.category
      ? (photo.category.length === 0 ? false : true)
      : false;
  };

  const { sectionSlug = 'gallery' } = props;
  const target = sectionSlug === 'gallery' ? 'gallery' : 'showhome';
  useEffect(() => {
    let categoryTypes = [ConstantData.galleryPhotoDefaultCategories.all];
    const photoGallery = photos.filter((p) => p.target === target);
    if (photoGallery && photoGallery.length > 0) {
      let addCategoryTypes = [...photoGallery].reduce((catBuild, photo) => {
        photo.category && photo.category.forEach(cat => {
          if (!catBuild.includes(cat))
            catBuild.push(cat);
        });
        return catBuild;
      }, []);
      categoryTypes = categoryTypes
        .concat(
          addCategoryTypes.sort((catNameA, catNameB) => catNameA.localeCompare(catNameB))
        );

      setCategories(categoryTypes);
    }
  }, [photos, target]);

  const galleryPhotos = useMemo(() => {
    let galleryPhotos = [{}];
    const photoGallery = photos.filter((p) => p.target === target);
    if (photoGallery && photoGallery.length > 0) {
      galleryPhotos =
        [...photoGallery]
          .sort((phA, phB) => phA.position - phB.position)
          .map((photo, index) => {
            const largePhoto = photo.formats.filter((f) => f.size === 'large')[0] || photo;
            return {
              id: index,
              src: largePhoto.link,
              width: largePhoto.width,
              height: largePhoto.height,
              category: validatePhotoCategories(photo) ? photo.category : '',
            };
          });
    }
    return galleryPhotos;
  }, [photos, target]);

  const filteredPhotos = useMemo(() => {
    let filteredPhotos = [{}];

    if (filteredPhotos) {
      filteredPhotos = galleryPhotos.filter(photo => {
        if (showCategory === ConstantData.galleryPhotoDefaultCategories.all) {
          return photo;
        } else if (photo.category.includes(showCategory))
          return photo;

        return null;
      });
    }
    return filteredPhotos;
  }, [showCategory, galleryPhotos]);

  return (
    <motion.div
      initial={{ y: '100vh' }}
      animate={{ y: 0 }}
      exit={{ y: '100vh' }}
      transition={{ type: 'tween', duration: .5 }}
      style={{ height: '100%' }}
    >
      <div id="vertical" className={`${props.isPortraitMode && classes.vertical}`}>
        <div className={`${classes.wrapper} ${props.className}`}>
          <PhotoGallery
            photos={filteredPhotos}
            onClick={openLightbox}
            direction="column"
            columns={3}
            margin={15}
          />
          <ModalGateway>
            {isViewerOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={
                    galleryPhotos.map(x => ({
                      ...x,
                      key: x.src,
                      caption: x.caption,
                    }))
                  }
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
      </div>
      {categories && categories.length > 1 && !props.noFilter &&
        <div className={classes.categoryButtons}>
          <ButtonGroup className={classes.buttonGroup}>
            {categories.map((category, idx) =>
              <ButtonOutline
                ThemeData={ThemeData}
                className={classes.buttonOutline}
                key={category}

                textColour={ThemeData.colours.vibrantGreen}
                backgroundColour={ThemeData.colours.white}
                hoverTextColour={ThemeData.colours.vibrantGreen}
                hoverBackgroundColour={ThemeData.colours.white}
                border={`1px solid ${ThemeData.colours.branding}`}
                firstInColl={idx === 0}

                isActive={showCategory === category}
                onClick={() => setShowCategory(category)}
              >
                {category}
              </ButtonOutline>
            )}
          </ButtonGroup>
        </div>
      }
      {/* {props.isPortraitMode && <FooterComponents project={project} toggleIsSavedHomesSlideoutOpen={() => props.toggleIsSavedHomesSlideoutOpen()}>
				{renderBackButton()}
			</FooterComponents>} */}
    </motion.div>
  );
};

const GalleryPortrait = ({ project, currentSection, prevSection, ...props }) => {
  const classes = useStyles({ ...props, ThemeData: project.ThemeData, isPortraitMode: props.isPortraitMode });
  return RenderGalleryPortrait(project, currentSection, prevSection, classes, props);
};

GalleryPortrait.defaultProps = {
  noPadding: false,
  noFilter: false,
  doOverrideUseFullPhotoGallery: false,
  withPhotosOfCategoryName: null,
};

export default GalleryPortrait;
