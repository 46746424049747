import { useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { portraitMode, filterConvertBedrooms } from 'util/helper';
import ConstantData from 'data/ConstantData';

export const UnitDetailCarousel = ({ project, unit, units, setSelectedUnitId, setGlobalUnit }) => {
  const { ThemeData } = project;
  const [current] = useState(units);
  const elementRef = useRef();
  const contentRef = useRef();
  const [cssWidths] = useState({
    elementWidth: elementRef.current && elementRef.current.offsetWidth,
    parentWidth: contentRef.current && contentRef.current.offsetWidth
  });

  const isPortraitMode = portraitMode();
  const index = units.indexOf(units.filter(el => el.threeDId === unit.threeDId)[0]);
  const [activeIndex] = useState(index);

  const isIndustrial = project && project.projectType === ConstantData.projectType.industrial;
  const isFloorplan = unit.__typename === 'FloorPlan';

  const useStyles = createUseStyles({
    carouselWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      overflow: 'hidden',
    },
    carouselContentWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      overflow: 'hidden',
      justifyContent: 'center',

    },
    buttonContainer: {
      width: '20px',
      position: 'relative'
    },
    buttonLeft: {
      right: '2px',
      top: '25px',
      position: 'absolute',
      margin: 'auto 0',
      border: `solid ${ThemeData.colours.primaryAccent}`,
      borderWidth: '0 3px 3px 0',
      padding: '5px',
      display: 'inline-block',
      transform: 'rotate(135deg)',
      zIndex: 2,
      cursor: 'pointer'
    },
    buttonRight: {
      top: '25px',
      right: '2px',
      position: 'absolute',
      margin: 'auto 0',
      border: `solid ${ThemeData.colours.primaryAccent}`,
      borderWidth: '0 3px 3px 0',
      padding: '5px',
      display: 'inline-block',
      transform: 'rotate(-45deg)',
      zIndex: 2,
      cursor: 'pointer'
    },
    planText: {
      fontWeight: '700',
      fontSize: isPortraitMode ? '24px' : '20px',
      lineHeight: isPortraitMode ? '47px' : '43px',
      whiteSpace: 'nowrap',
      color: isPortraitMode ? '#fff' : 'inherrit'
    },
    bedsText: {
      fontWeight: '700',
      fontSize: '10px',
      lineHeight: '15px',
      color: isPortraitMode ? '#fff' : 'inherit'
    },
    textContainer: {
      transform: (props) => `translateX(${((props.activeIndex - (props.current.length / 2)) * (-186)) - 90}px)`,
      padding: '0 18px',
      minWidth: '150px',
      transition: 'all 0.5s ease-in-out',
      cursor: 'pointer',
      color: ThemeData.colours.aaeForestGreen
    },
    textContainerWithOpacity: {
      transform: (props) => `translateX(${((props.activeIndex - (props.current.length / 2)) * (-186)) - 90}px)`,
      padding: '0 18px',
      opacity: '25%',
      minWidth: '150px',
      transition: 'all 0.5s ease-in-out',
      cursor: 'pointer',
      color: ThemeData.colours.aaeForestGreen25
    },
    unitTitle: {
      width: '100%',
      color: ThemeData.colours.aaeForestGreen
    }
  });

  const classes = useStyles({ activeIndex, current, cssWidths });

  /*
  useEffect(() => {
      setWidths({
          elementWidth: elementRef.current.offsetWidth,
          parentWidth: contentRef.current.offsetWidth
      })
      setCurrent(units);
  },[activeIndex, unit, units])*/

  return (
    <div className={classes.carouselWrapper}>
      <div className={classes.unitTitle}>
        {isIndustrial
          ? <>{unit && <div className={classes.planText}>{isFloorplan ? unit.name : (project.settings?.showFullTitle ? `${unit.floorPlan.name} | Unit ${unit.unitNumber}` : unit.unitNumber)}</div>}</>
          :
          <>
            <div className={classes.planText}>{unit.__typename === 'Unit' && <span>Unit {unit.unitNumber} |&nbsp;</span>}{unit.floorPlan.name}</div>
            {!project?.projectType.includes('industrial') && <div className={classes.bedsText}>{filterConvertBedrooms(unit.floorPlan.bedRooms, unit.floorPlan?.studio)}</div>}
          </>
        }
      </div>
    </div>
  );

};
