import React, {
  useState,
  useRef,
  useEffect,
} from 'react';

import {
  createUseStyles,
} from 'react-jss';

import {
  motion,
} from 'framer-motion';

import { categorizeNeighbourhood } from 'util/mapBox';
import { ReactComponent as NavArrowLeft } from '../img/nav_arrow_left.svg';
import { ReactComponent as NavArrowRight } from '../img/nav_arrow_right.svg';
import ButtonOutline from './ButtonOutline';

const useStyles = createUseStyles(
  {
    wrapper: {
      padding: '0 50px',
    },
    main: {
      display: 'flex',
      height: '100%',
      gap: 37,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    },
    title: {
      fontFamily: props => props.ThemeData.fonts.title,
      fontSize: 14,
      fontWeight: 700,
      letterSpacing: '2.1px',
      marginLeft: 30,
      marginBottom: 10
    },
    filters: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: 30,
      gap: 8
    },
    filterButton: props => ({
      borderRadius: 10,
      padding: 8,
      textTransform: 'capitalize',
      ...props.ThemeData.typography.contentTemplate,
    }),
    filterActive: props => ({
      backgroundColor: props.ThemeData.colours.branding,
      color: props.ThemeData.colours.white,

      '&:hover': {
        backgroundColor: props.ThemeData.colours.branding,
      }
    }),
    category: props => ({
      paddingTop: 10,
      alignSelf: 'flex-end',

      '& $categoryTitle': {
        borderRadius: '7px',
      },
    }),
    categoryTitle: props => ({
      paddingLeft: 10,

      ...props.ThemeData.typography.contentTemplate,
      fontSize: 12,
    }),
    poi: props => ({
      display: 'flex',
      padding: 10,
      ...props.ThemeData.typography.contentTemplate,
      fontSize: 14,
      '&:hover': {
        background: props.ThemeData.colours.branding,
        color: props.ThemeData.colours.brandingText
      }
    }),
    clickableItem: {
      cursor: 'pointer',
    },
    selectedItem: props => ({
      backgroundColor: `${props.ThemeData.colours.branding} !important`,
      color: props.ThemeData.colours.brandingText || props.ThemeData.colours.white
    }),
    poiNumber: {
      flex: '0 0 auto',
      textAlign: 'center',
      paddingRight: 8,
    },
    poiName: {
      flex: '0 1 auto',
      minWidth: 0,
      textTransform: 'capitalize'
    },
    content: props => ({
      flex: '1 1 auto',
      overflowY: 'none',

    }),
    contentArea: {
      flex: '0 0 auto',
    },
    contentWrapper: props => ({
      display: 'flex',
      width: '100%',
      height: '100%',
    }),
    innerContent: props => ({
      display: 'flex',
      width: 814,
      gap: 57,
      flexDirection: 'row',
      alignItems: 'end',
      overflow: 'hidden',
      paddingBottom: 10,
      paddingTop: 20,
    }),
    poiItems: props => ({
      height: 226,
      width: 220,
      overflowY: 'scroll',
      borderRadius: 10,
      boxShadow: '0px 0px 4px 0px',
      ...props.ThemeData.styles.thinScrollbar,
    }),
    categoryName: props => ({
      ...props.ThemeData.typography.titleTemplate,
      textTransform: 'capitalize',
      fontSize: 12,
      height: 35,
      margin: '8px 0',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '& > div:first-child': {
        marginRight: 4,
        borderRadius: 4,
        width: 12,
        height: 12
      },
    }),
    filterButtonGroup: props => ({
      display: 'flex',
      paddingTop: 65
    }),
    navButton: props => ({
      width: 60,
      height: 44,
      padding: 10,
      borderRadius: 15,
      border: `1px solid ${props.ThemeData.colours.branding}`,
      backgroundColor: props.ThemeData.colours.white,
      cursor: 'pointer',
      '&:hover, &:active': {
        backgroundColor: props.ThemeData.colours.branding,
        '& > svg': {
          fill: props.ThemeData.colours.primaryFontHighestContrast
        }
      },
      '& > svg': {
        width: 22,
        height: 20,
        fill: props.ThemeData.colours.branding
      },
      '&.disabled': {
        opacity: .25,
        '& > svg': {
          opacity: .5,
        },
        '&:hover': {
          backgroundColor: 'transparent',
          '& > svg': {
            opacity: .25,
            fill: props.ThemeData.colours.branding
          },
        }
      },
    }),
    pageIndicator: props => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 20,
      marginTop: 60,
      '& > div': {
        height: 3,
        width: 120,
        borderRadius: 4,
        backgroundColor: `${props.ThemeData.colours.branding}33`
      },
      '& .current': {
        backgroundColor: props.ThemeData.colours.branding,
      }
    })
  },
  {
    name: 'NeighbourhoodPois',
  }
);

function RenderNeighbourhoodPois (project, classes, neighCategories, error, doShow, props) {
  const { ThemeData } = project;
  const scroll = useRef(null);
  const [rightButtonDisplay, setRightButtonDisplay] = useState(true);
  const [leftButtonDisplay, setLeftButtonDisplay] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);

  const maxItems = 3;
  const scrollNext = (direction) => {
    if (direction === 'right') {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    if (!props.mapFilteredCategory) {
      // Display Right Button
      if ((currentPage * maxItems) + maxItems >= Object.entries(neighCategories).length) {
        setRightButtonDisplay(false);
      } else {
        setRightButtonDisplay(true);
      }

      // Display Left Button
      if (currentPage === 0) {
        setLeftButtonDisplay(false);
      } else {
        setLeftButtonDisplay(true);
      }
    } else {
      setRightButtonDisplay(false);
      setLeftButtonDisplay(false);
    }
    //props.setMapmapFilteredCategory(mapFilteredCategory)
  }, [neighCategories, currentPage, props.mapFilteredCategory]);

  const numItems = Object.entries(neighCategories)
    .filter(([categoryName]) => !props.mapFilteredCategory || categoryName === props.mapFilteredCategory).length;

  const numPages = numItems === 0 ? 0 : parseInt(numItems / maxItems) + (numItems / maxItems ? 1 : 0);
  return (
    <>
      {/*
			<div className={classes.filterButtonGroup}>
				<h3 style={{ paddingRight: props.isPortraitMode ? 48 : 0 }}>{`Distance`.toUpperCase()}</h3>

				<DistanceFilters
					project={project}
					distance={props.distance}
					toggleDistance={props.toggleDistance}
					isPortraitMode={props.isPortraitMode}
				/>
			</div>
			*/}
      <div className={`${classes.contentArea} ${classes.content}`}>
        <div className={classes.wrapper}>
          <div className={classes.title}>Categories</div>
          <div className={classes.filters}>
            {Object.keys(neighCategories).length > 0 && <>
              <ButtonOutline
                ThemeData={ThemeData}
                className={`${classes.filterButton} ${!props.mapFilteredCategory ? classes.filterActive : ''}`}
                onClick={() => props.setMapFilteredCategory(null)}
                border={`1px solid ${ThemeData.colours.branding}`}
              >All</ButtonOutline>
              {Object.entries(neighCategories)
                .map(([categoryName, item]) => <ButtonOutline
                  ThemeData={ThemeData}
                  className={`${classes.filterButton} ${props.mapFilteredCategory && props.mapFilteredCategory.toLocaleLowerCase() === categoryName.toLocaleLowerCase() ? classes.filterActive : ''}`}
                  onClick={() => props.setMapFilteredCategory(categoryName)}
                  border={`1px solid ${ThemeData.colours.branding}`}
                >{categoryName.toLocaleLowerCase()}</ButtonOutline>)}
            </>}
          </div>
          <div className={classes.main}>
            {numPages && numPages > 1 && <button
              key={'prevButton'}
              className={`${classes.navButton} ${leftButtonDisplay ? '' : 'disabled'}`}
              onClick={() => leftButtonDisplay && scrollNext('left')}
            >
              <NavArrowLeft />
            </button>}
            <div className={classes.contentWrapper} style={{ marginLeft: numPages > 1 ? 0 : 22 }}>
              <div className={classes.innerContent} ref={scroll}>
                {Object.keys(neighCategories).length > 0 &&
                  Object.entries(neighCategories)
                    .slice(!props.mapFilteredCategory ? (currentPage === 0 ? 0 : currentPage * maxItems) : 0, neighCategories.length)
                    .filter(([categoryName, item]) => !props.mapFilteredCategory || categoryName === props.mapFilteredCategory)
                    .map(([categoryName, item]) =>
                      <div className={classes.category} key={item.CategoryId}>
                        <div className={`${classes.categoryTitle} ${classes.clickableItem}`}>
                          <div className={classes.categoryName}>
                            <div style={{ backgroundColor: `${item.Color}` }}></div>
                            <div>{categoryName.toLocaleLowerCase()}</div>
                          </div>
                          <div className={classes.poiItems}>
                            {item.Poi.length > 0 && item.Poi.map((poi) => (
                              <motion.div
                                className={`${classes.poi} ${classes.clickableItem} ${props.procFlyTo.poiid === poi.properties.Number ? classes.selectedItem : ''}`}
                                key={poi.properties.Number}
                                nlcid={poi.properties.Number}
                                nlid={poi.properties.Number}
                                onMouseEnter={() => props.setHighlightCatPoi({
                                  isHighActive: true,
                                  highCatId: -1,
                                  highPoiId: poi.properties.Number,
                                  backgroundColor: 'red'
                                })}
                                onMouseLeave={() => props.setHighlightCatPoi({
                                  isHighActive: false,
                                  highCatId: -1,
                                  highPoiId: -1,
                                })}
                                onTap={() => props.setProcFlyTo({
                                  doProcFlyTo: true,
                                  catid: -1,
                                  poiid: poi.properties.Number,
                                  coordinates: poi.geometry.coordinates,
                                })}
                              >
                                <div className={classes.poiNumber}>{poi.properties.Number}.</div>
                                <div className={classes.poiName}>{poi.properties.Name}</div>
                              </motion.div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
              </div>
            </div>
            {numPages && numPages > 1 && <button
              key={'nextButton'}
              className={`${classes.navButton} ${rightButtonDisplay ? '' : 'disabled'}`}
              onClick={() => rightButtonDisplay && scrollNext('right')}
            >
              <NavArrowRight
                width={20}
                height={20}
                fill={project.ThemeData.colours.branding}
                id="content-page-navigate-right"
              />
            </button>}
          </div>
        </div>
      </div>
      {numPages && numPages > 1 && <div className={classes.pageIndicator}>
        {[...Array(numPages)].map((val, idx) => <div className={`${idx === currentPage ? 'current' : ''}`}></div>)}
      </div>}
    </>
  );
}

const ContentPageNeighbourhoodPois = ({ project, doShow, ...props }) => {
  const classes = useStyles({ ThemeData: project.ThemeData, slug: project.slug });

  const filterMapping = {
    'fiveMinutes': 5,
    'tenMinutes': 10,
    'fifteenMinutes': 15
  };

  let filterDistance = 0;
  props.distance.forEach((f) =>
    filterMapping[f] > filterDistance ? filterDistance = filterMapping[f] : filterDistance);

  const neighGeo = project.mapBox ? JSON.parse(project.mapBox) : null;
  let neighCategories = filterDistance > 0
    ? categorizeNeighbourhood(neighGeo, project.settings.latitude, project.settings.longitude, filterDistance)
    : categorizeNeighbourhood(neighGeo, project.settings.latitude, project.settings.longitude);
  const filterdCategoriesPortrait = () => {
    let tmp = {};
    Object.entries(neighCategories).forEach(([name, item]) => {
      if (item.Poi.length > 0) {
        let obj = { [name]: item };
        Object.assign(tmp, obj);
      }
    });
    return tmp;
  };
  const neighCategoriesPortrait = filterdCategoriesPortrait();
  return RenderNeighbourhoodPois(project, classes, neighCategoriesPortrait, null, doShow, props);
};

export default ContentPageNeighbourhoodPois;
