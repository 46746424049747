import {
  useRouteMatch,
  Link,
} from 'react-router-dom';

import {
  motion,
} from 'framer-motion';

import { createUseStyles } from 'react-jss';

import ConstantData from 'data/ConstantData';
import { getImageBySize } from 'util/imgFunctions';
import richifyTextBasic from 'util/richifyTextBasic';

import { ReactComponent as CardPlusIcon } from '../img/content_card_plus.svg';

const useStyles = createUseStyles(
  {
    contentCardItem: {
      position: 'relative',
      flex: '0 0 auto',
      display: 'flex',

      overflow: 'hidden',
      borderRadius: 0,
      '&:hover': {
        borderRadius: 0
      },
      '&:hover $bgImg': {
        transform: 'scale(1.1)',
      },
      '&:hover $bgImgNoDark': {
        transform: 'none !important',
      },

      '& a, & a:visited': {
        // color: `${ThemeData.colours.eggshellTextBg}`,
        color: props => `${props.ThemeData.colours.primaryFont}`,
      },
    },
    contentCardTitleContainer: {
      display: 'flex',

      flexDirection: 'column',
      justifyContent: 'center',
    },
    contentCardTitle: props => ({
      flex: '0 1 auto',
      padding: 60,
      ...props.ThemeData.typography.contentCardTitles,
    }),
    contentCardPlus: {
      position: 'absolute',
      right: 0,
      bottom: 'auto',
      padding: 40,
    },
    contentCardWrapper: {
      cursor: 'pointer'
    },
    oneUp: props => ({
      height: 'calc( 50% - 10px )',
      width: '100%',
    }),
    twoUpHoriz: {
      width: '100%'
    },
    twoUpVert: {
      height: '100%',
    },
    fourUp: {
      width: '100%',
      height: '100% !important',
    },
    sixUp: {
      width: '100%',
      height: '100%',
    },
    equalSized: props => ({
      borderRadius: 0,
      position: 'absolute',
      width: 'auto',
      height: 'auto',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    }),
    bgImg: {
      background: `no-repeat center`,
      backgroundSize: 'cover',

      opacity: .5,

      transition: 'all .4s ease',
    },
    bgImgNoDark: {
      opacity: 1,

      backgroundSize: 'contain',
    },
    bgImgBlackBg: {
      backgroundColor: 'black',
    },
    bgImgNoBg: {
      backgroundColor: 'rgba(0,0,0,0)'
    },
    imgCard: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
  {
    name: 'ContentCard'
  }
);

function ContentCard ({ ThemeData, contentPage, section, tab, setTab, setIsContentPageCarouselOpen }) {

  const classes = useStyles({ ThemeData });
  const matchUri = useRouteMatch();

  let contentPageUrl = `${matchUri.url}/${contentPage.slug}`;

  if (!contentPage.customFunction && contentPage.customUrl) {
    contentPageUrl = `${contentPage.customUrl}`;
  }

  // Figure out if the Card should be dark with a title over it or not
  let doNotDarkenOrTitleCard = false;
  const is_photo_only = contentPage.customFunction === ConstantData.contentPageCustomFunctions.photoOnlyCard;

  if (section.slug === 'gallery')
    doNotDarkenOrTitleCard = true;
  if (is_photo_only)
    doNotDarkenOrTitleCard = true;

  const renderCardsWithLinks = (section) => {
    if (section.slug === 'neighbourhood') {
      return (
        <div className={classes.contentCardWrapper}>
          <div onClick={() => {
            setTab(contentPage.position);
            setIsContentPageCarouselOpen(true);
          }}>
            <div className={`${classes.equalSized} ${classes.contentCardTitleContainer}`}>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                exit={{ opacity: 0, transition: { duration: 0.25, delay: 0 } }}
                className={classes.contentCardTitle}>
                <p dangerouslySetInnerHTML={{
                  __html: doNotDarkenOrTitleCard ?
                    '' :
                    richifyTextBasic(contentPage.widgetTitle || contentPage.title)
                }} />
              </motion.div>
            </div>
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            exit={{ opacity: 0, transition: { duration: 0.25, delay: 0 } }}
            className={classes.contentCardPlus}>
            <CardPlusIcon width={45} height={45} stroke={ThemeData.colours.white} />
          </motion.div>
        </div>
      );
    } else {
      return (
        <Link to={contentPageUrl + '/'}>
          {(contentPage.widgetTitle || contentPage.title) && <div className={`${classes.equalSized} ${classes.contentCardTitleContainer}`}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.5 }}
              exit={{ opacity: 0, transition: { duration: 0.25, delay: 0 } }}
              className={classes.contentCardTitle}>
              <p dangerouslySetInnerHTML={{
                __html: doNotDarkenOrTitleCard ?
                  '' :
                  richifyTextBasic(contentPage.widgetTitle || contentPage.title)
              }} />
            </motion.div>
          </div>}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            exit={{ opacity: 0, transition: { duration: 0.25, delay: 0 } }}
            className={classes.contentCardPlus}>
            <CardPlusIcon width={45} height={45} stroke={ThemeData.colours.white} />
          </motion.div>
        </Link>
      );
    }
  };

  const renderContent = () => {
    const photo = getImageBySize(contentPage.photo, 'small');
    return (
      <motion.div id={contentPage.slug} className={`${contentPage.form && classes[contentPage.form]} ${classes.contentCardItem}`} key={contentPage.id}
                  initial={{ width: 0 }}
                  animate={{ width: '100%' }}
                  transition={{ duration: 0.75 }}
      >
        {contentPage.video ?
          <motion.div
            exit={{ x: window.innerWidth, transition: { type: 'linear', duration: .5 } }}
          >
            <video
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              // controls
              autoPlay
              muted
              loop
            >
              <source src={contentPage.video[0].link} type="video/mp4" />
            </video>
          </motion.div>
          :
          <>
            <motion.div
              className={`${classes.equalSized} ${is_photo_only ? classes.bgImgNoBg : classes.bgImgBlackBg}`}
              exit={{ x: window.innerWidth, transition: { type: 'linear', duration: 0.75 } }}
            >
              <Link to={contentPageUrl + '/'}>
                <motion.div
                  className={`${classes.equalSized} ${classes.bgImg} ${doNotDarkenOrTitleCard ? classes.bgImgNoDark : classes.bgImgDark}`}
                  style={!is_photo_only ? contentPage.photo && {
                    backgroundImage: `url(${photo.link})`
                  } : null}
                >
                  {is_photo_only &&
                    <img
                      className={classes.imgCard}
                      alt={contentPage.title}
                      src={photo.link}
                      width={photo.width}
                      height={photo.height}
                    />
                  }
                </motion.div>
              </Link>
            </motion.div>
            {is_photo_only ?
              <></> :
              renderCardsWithLinks(section)
            }
          </>
        }
      </motion.div>
    );
  };

  return (
    renderContent()
  );
}

export default ContentCard;