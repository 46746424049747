import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";

import { createUseStyles } from "react-jss";

import // motion,
"framer-motion";

import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

// import neighGeo from "data/neighbourhood";
// import neighGeo from "data/neu-neighbourhood";
// import walkingGeo from "data/walkingIcons";

import SiteConfigData from "data/SiteConfigData";

// import navArrowLeftDark from "../img/nav_arrow_left_dark.svg";
import mapMarkerDev from "img/map_marker_neu.svg";
import { portraitMode } from "util/helper";
// import mapIconWalking from "img/map_icon_walking.svg";

mapboxgl.accessToken = SiteConfigData.MapBox.AccessToken;
//const neighGeo = SiteConfigData.MapBox.NeighbourhoodGeoJSON;
const useStyles = createUseStyles(
  {
    wrapper: {
      position: "absolute",
      top: 0,
      left: 0,

      width: "100%",
      height: "100%",
      // height: 'calc( 100vh - 100px )',
    },
    map: {
      width: "100%",
      height: (props) =>
        props.isPortraitMode
          ? "calc(100vh - var(--portrait-header-height) - var(--footer-height) - var(--content-pane-height) - var(--accessibility-footer) + 17px)"
          : "100%",
      // height: props => props.isPortraitMode ? 'calc(100vh - 300px - var(--content-pane-height))' : 'calc( 100vh - 100px )',

      display: "none",
      "&.doShow": {
        display: "block",
      },
    },
    marker: {
      // position: 'relative',

      width: 40,
      height: 40,

      display: "flex",
      alignItems: "center",

      color: (props) => props.ThemeData.colours.white,

      fontSize: 18,
      textAlign: "center",
      letterSpacing: ".025em",

      cursor: "pointer",

      borderRadius: "50%",

      transition: "transform .5s ease-out",

      "&:hover $markerTooltip": {
        opacity: 1,
      },

      "&.walkerMarker": {
        width: 70,
        height: "auto",

        fontFamily: (props) => props.ThemeData.fonts.title,
        fontSize: 8,
        // textAlign: 'center',
        // letterSpacing: '.025em',
        textTransform: "uppercase",

        borderRadius: 0,
      },
    },
    markerDisplay: {
      flex: "1 1 auto",

      borderRadius: "50%",

      transition: "transform 1s",

      "&.walkerMarker": {
        lineHeight: "1.4em",
      },
    },
    ".markerTooltip": (props) => ({
      position: "absolute",

      opacity: 0,

      maxWidth: 200,

      textAlign: "center",

      transition: "all 2s, opacity .5s",

      // color: ThemeData.colours.primaryBg,
      // backgroundColor: 'white',
      backgroundColor: props.ThemeData.colours.primaryBg,

      border: `1px solid ${props.ThemeData.colours.eggshellTextBg}`,
      boxShadow: "0px 4px 10px rgb(0 0 0 / 33%)",
      borderRadius: 4,
    }),
    markerPopup: (props) => ({
      maxWidth: "500px !important",
      "& .mapboxgl-popup-tip": {
        borderTopColor: props.ThemeData.colours.secondaryBg,
        marginTop: -1,
      },
      "& .mapboxgl-popup-content": {
        backgroundColor: "#012932",
        color: props.ThemeData.colours.white,
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        padding: "8px 16px",
        borderRadius: 10,
        ...props.ThemeData.typography.contentTemplate,
        "& > img": {
          width: "100%",
          backgroundColor: "black",
        },
        "& h4": {
          margin: "10px 0 20px 0",
          fontSize: 12,
          textTransform: props.isPortraitMode ? "" : "uppercase",
        },
        "& p": {
          margin: 0,
          padding: 0,
          fontSize: 30,
        },
      },
      "& .mapboxgl-popup-close-button": {
        color: props.ThemeData.colours.black,

        fontSize: 24,
      },
    }),
    markerHighlight: {
      transform: "scale( 1.5 )",

      zIndex: 4,
    },
    markerParentHighlight: {
      zIndex: 4,
    },
    dev: {
      width: 58,
      height: 75,
      borderRadius: 0,
      background: "no-repeat center",
      backgroundColor: (props) =>
        `${props.ThemeData.colours.mapMarker || "#ADA743"}`,
      maskImage: `url(${mapMarkerDev})`,
    },
    walkingIcon: {
      width: 20,
    },
  },
  {
    name: "Map",
  }
);

function RenderMap(classes, project, error, doShow, props) {
  const [prevElms, setPrevElms] = useState(null);
  const [poiMapboxMarkers, setPoiMapboxMarkers] = useState({});

  useEffect(() => {
    let elms;

    if (props.highlightCatPoi.highCatId !== -1) {
      elms = document.querySelectorAll(
        `[catid='${props.highlightCatPoi.highCatId}']`
      );
    } else if (props.highlightCatPoi.highPoiId !== -1) {
      elms = document.querySelectorAll(
        `[poiid='${props.highlightCatPoi.highPoiId}']`
      );
    }
    // elms = document.querySelectorAll( )

    if (prevElms) {
      prevElms.forEach((elm) => {
        elm.classList.remove(classes.markerHighlight);
        elm.parentElement.classList.remove(classes.markerParentHighlight);
      });
      setPrevElms(null);
    }

    if (elms) {
      elms.forEach((elm) => {
        elm.classList.add(classes.markerHighlight);
        elm.parentElement.classList.add(classes.markerParentHighlight);
      });
    }

    setPrevElms(elms);
  }, [
    props.highlightCatPoi,
    classes.markerHighlight,
    classes.markerParentHighlight,
    prevElms,
  ]);

  const neighGeo = project?.mapBox ? JSON.parse(project.mapBox) : null;
  const settings = project.settings;

  // MapBox Integ
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [
    lng,
    // setLng
  ] = useState(settings.longitude);
  const [
    lat,
    // setLat
  ] = useState(settings.latitude);
  const [
    zoom,
    // setZoom
  ] = useState(props.isPortraitMode ? 14 : settings.mapBoxZoom || 16.0);

  const [allMarkers, setAllMarkers] = useState([]);
  const renderPopup = (newMarker, imgSrc) => {
    // let imageUrl = imgSrc && imgSrc.tileImage && imgSrc.tileImage.url
    let imageUrl = imgSrc;
    return imageUrl
      ? `<img src="${imageUrl}"/><h4>${newMarker.properties.Category.toLowerCase()}</h4><p>${
          newMarker.properties.Name
        }</p>`
      : `<h4>${newMarker.properties.Category.toLowerCase()}</h4><p>${
          newMarker.properties.Name
        }</p>`;
  };

  useEffect(() => {
    if (mapContainer && mapContainer.current && neighGeo) {

      if (!map.current) {
        map.current = new mapboxgl.Map({
          container: mapContainer.current,
          style: settings.mapBoxStyleUrl || SiteConfigData.MapBox.StyleURL,
          center: [lng, lat],
          zoom: zoom,
          pitch: 60,
          dragRotate: false,
          attributionControl: false,
        });

        // Add Compass control
        let compassControl = new mapboxgl.NavigationControl({
          showCompass: true,
          showZoom: false,
          backgroundColor: "red",
        });
        map.current.addControl(compassControl, "bottom-right");

        
        const lineStringFeatures = neighGeo.features.filter(
          (feature) => feature.geometry.type === "LineString"
        );
  
        if (lineStringFeatures.length > 0) {
         
          map.current.on("load", () => {
            let index = 0; 
            for (const feature of lineStringFeatures) {
              const sourceId = `lineString-${index}`;
              const layerId = `lineString-layer-${index}`;
          
              map.current.addSource(sourceId, {
                type: "geojson",
                data: {
                  type: "FeatureCollection",
                  features: [feature],
                },
              });
          
              map.current.addLayer({
                id: layerId,
                type: "line",
                source: sourceId,
                layout: {
                  "line-join": "round",
                  "line-cap": "round",
                },
                paint: {
                  "line-color": feature.properties.Color,
                  "line-width": 10,
                },
              });
        
              index++; 
              
            }
          });
        }
      } else {
        allMarkers.map((marker) => marker.remove());
      }

      let markers = [];
      for (const newMarker of neighGeo.features) {
        const geometryType = newMarker.geometry.type;
        if (geometryType === "LineString") {
          continue;
        } else if (geometryType === "Point") {
          const category = newMarker.properties.Category.trim().toLowerCase();
          if (
            category !== "development" &&
            props.mapFilteredCategory &&
            props.mapFilteredCategory.trim().toLowerCase() !== category
          ) {
            continue;
          }
          let options = {};
          let marker = <div />;
          if (newMarker.properties.Number === 0) {
            let markerStyle = {};
            if (newMarker.properties.UseProjectPin === "TRUE") {
              markerStyle = {
                backgroundImage: `url(${project.projectPin[0].link})`,
                backgroundSize: "cover",
              };
            }

            marker = (
              <div
                style={markerStyle}
                className={`${classes.marker} ${classes.dev}`}
              />
            );
            options = {
              ...options,
              anchor: "bottom",
            };
          } else {
            let markerStyle = {};
            if (newMarker.properties.IsColorOutline === "NONE") {
              markerStyle = {
                color: "#FFFFFF",
                backgroundColor: "#000000",
              };
            } else if (newMarker.properties.IsColorOutline !== "FALSE") {
              markerStyle = {
                color: newMarker.properties.Color,
                backgroundColor: "#FFFFFF",
                border: `2px ${newMarker.properties.Color} solid`,
              };
            } else {
              markerStyle = {
                backgroundColor: newMarker.properties.Color,
              };
            }

            marker = (
              <div
                className={classes.marker}
                style={markerStyle}
                catid={newMarker.properties.CategoryId}
                poiid={newMarker.properties.Number}
                onMouseEnter={() =>
                  props.setHighlightCatPoi({
                    isHighActive: true,
                    highCatId: -1,
                    highPoiId: newMarker.properties.Number.toString(),
                  })
                }
                onMouseLeave={() =>
                  props.setHighlightCatPoi({
                    isHighActive: false,
                    highCatId: -1,
                    highPoiId: -1,
                  })
                }
              >
                <div className={classes.markerDisplay}>
                  {newMarker.properties.Number}
                </div>
              </div>
            );
          }

          let imgSrc = newMarker.properties.PhotoUrl;
          const popup = new mapboxgl.Popup({
            offset: 25,
            className: classes.markerPopup,
            closeButton: false,
          })
            .setLngLat(newMarker.geometry.coordinates)
            .setHTML(renderPopup(newMarker, imgSrc));

          const markerDiv = document.createElement("div");
          ReactDOM.render(marker, markerDiv);

          const newMarkerObj = new mapboxgl.Marker(markerDiv, options)
            .setLngLat(newMarker.geometry.coordinates)
            .setPopup(popup)
            .addTo(map.current);
          markers.push(newMarkerObj);

          // Add markers to the map.
          // new mapboxgl.Marker(el, options)
          setPoiMapboxMarkers((prevPoiMapboxMarkers) => {
            return {
              ...prevPoiMapboxMarkers,
              [newMarker.properties.Number]: newMarkerObj,
            };
          });

          setAllMarkers(markers);
        }
      }
    }
  }, [
    lng,
    lat,
    zoom,
    classes.markerPopup,
    classes.marker,
    classes.dev,
    classes.markerDisplay,
    props.setHighlightCatPoi,
    props.mapFilteredCategory,
  ]);

  useEffect(() => {
    if (props.procFlyTo.doProcFlyTo) {
      if (props.procFlyTo.poiid !== -1) {
        map.current.flyTo({
          center: props.procFlyTo.coordinates,
          speed: 1,
        });

        for (const key in poiMapboxMarkers) {
          if (poiMapboxMarkers[key].getPopup().isOpen())
            poiMapboxMarkers[key].togglePopup();
        }
        poiMapboxMarkers[props.procFlyTo.poiid].togglePopup();
      }
    }
  }, [props.procFlyTo]);

  return (
    <div
      ref={mapContainer}
      className={`${classes.map} ${doShow ? "doShow" : ""}`}
    />
  );
}

const Map = ({ doShow, project, ...props }) => {
  const classes = useStyles({
    ThemeData: project.ThemeData,
    isPortraitMode: portraitMode(),
  });
  return RenderMap(classes, project, null, doShow, props);
};

export default Map;
