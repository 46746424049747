import { useState, useEffect, useRef, useMemo, useContext } from 'react';

import {
  Route,
  Switch,
  // Link,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import { motion } from 'framer-motion';

import Modal from '@mui/material/Modal';

import { createUseStyles } from 'react-jss';

import Layout from './Layout';
import PageComponents from './PageComponents';
import CardComponents from './CardComponents';

import Gallery from './Gallery';
import GalleryPortrait from './GalleryPortrait';
import ScrollIcon from './ScrollIcon';

import SlugData from '../data/SlugData';
import ConstantData from 'data/ConstantData';

import { useHorizontalScroll } from 'hooks/useHorizontalScroll';

import useOpenClosePane from 'hooks/useOpenClosePane';
import useGlobalUnitsFilter from 'hooks/useGlobalUnitsFilter';
import ButtonOutline from './ButtonOutline';

import overviewWatchVideoButton from '../img/overview_watch_video_button.svg';

import ContentPageCarousel from './ContentPageCarousel';
import { portraitMode } from 'util/helper';
import { ReactComponent as PlayIcon } from 'img/icon_play_circle.svg';
import UnitFcnContext from 'contexts/UnitFcnContext';
import FloorPlanStore from '../store/floorPlanStore';

const useStyles = createUseStyles(
  {
    allWrapper: {
      height: '100%',
    },
    section: (props) => ({
      height:
        props.function !== 'photoGallery' && props.isPortraitMode
          ? 'calc(100vh - var(--header-height) - var(--accessibility-footer))'
          : '100%',
      display: props.function === 'photoGallery' ? '' : 'flex',
      flexDirection: 'column',

      padding:
        props.function !== 'photoGallery' &&
        (props.isPortraitMode ? '36px 43px 0 43px' : '0 30px'),

      boxSizing: 'border-box',

      fontFamily: props.ThemeData.fonts.titleSub,
      fontSize: '2em',
      letterSpacing: 0,

      overflowX: props.function !== 'photoGallery' && 'auto',

      ...props.ThemeData.styles.thinScrollbar,
      //background: 'linear-gradient(180deg, rgba(125, 32, 182, 0.29) 0%, rgba(125, 32, 182, 0.00) 47.85%)'
    }),
    darkenSection: {
      backgroundColor: (props) => props.ThemeData.colours.transparentDarkening,
    },
    contentPage: {
      fontSize: 25,
    },
    marginSizer: (props) => ({
      height: props.isPortraitMode ? 0 : 30,
      flex: '0 1 auto',
    }),
    marginSizerBottom: (props) => ({
      height: props.isPortraitMode ? 0 : 30,
    }),
    contentCardsContainer: (props) => ({
      //flex: '1 0 auto',
      display: 'flex',
      flexDirection: props.isPortraitMode ? 'column' : 'row',
      justifyContent: 'space-between',
      height:
        props.function !== 'photoGallery' && props.isPortraitMode
          ? 'calc(100vh - var(--header-height) - var(--navigation-height) - var(--accessibility-footer) - 72px)'
          : '100%',

      '& $twoUpVertContainer:nth-child(2)': {
        height: props.isPortraitMode ? 'var(--small-widget-height)' : 'auto',
      },

      '& > $contentCardContainer:first-child:nth-last-child(4), & > $contentCardContainer:first-child:nth-last-child(4) ~ $contentCardContainer':
        {
          height: props.isPortraitMode
            ? 'calc((100% - 72px) / 3) !important'
            : 'auto',
        },

      '& > $contentCardContainer:first-child:nth-last-child(5), & > $contentCardContainer:first-child:nth-last-child(5) ~ $contentCardContainer':
        {
          height: props.isPortraitMode
            ? 'calc((100% - 108px) / 4) !important'
            : 'auto',
        },
    }),

    contentCardContainer: (props) => ({
      marginLeft: props.isPortraitMode ? 0 : 20,
      marginTop: props.isPortraitMode ? 36 : 0,

      '&.firstCardContainer': {
        marginLeft: 0,
        marginTop: 0,
      },
      '&.lastCardContainer': {
        paddingRight: 36,
      },
    }),
    onlyImageCard: (props) => ({
      flex: '0 0 auto !important',
      pointerEvents: 'none',
    }),
    oneUpContainer: (props) => ({
      flex: '1 0 auto',
      width: props.isPortraitMode ? 'auto' : '31%',
      height: props.isPortraitMode ? 'var(--small-widget-height)' : 'auto',

      display: 'flex',
      flexDirection: props.isPortraitMode ? 'row' : 'column',
      justifyContent: 'space-between',

      '&>div:first-child': {
        marginRight: props.isPortraitMode ? 36 : 0,
      },
    }),
    twoUpHorizContainer: (props) => ({
      flex: '0 1 auto',
    }),
    twoUpVertContainer: (props) => ({
      flex: '1 0 auto',
      width: props.isPortraitMode ? '100%' : '31%',
      height: props.isPortraitMode
        ? 'calc(60% - var(--accessibility-footer) - 36px)'
        : 'auto',
    }),
    fourUpContainer: (props) => ({
      flex: '1 0 auto',
      width: props.isPortraitMode ? '100%' : 'calc( 66% - 20px )',
      height: props.isPortraitMode
        ? 'calc(60% - var(--accessibility-footer) - 36px)'
        : 'auto',
    }),
    sixUpContainer: (props) => ({}),
    overview: (props) => props.isStPauls ? ({
      position: 'absolute',
      top: 234,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 530,
      backgroundColor: 'transparent',
      color: props.ThemeData.colours.black,
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center',
    }) : ({
      marginLeft: props.isPortraitMode ? 0 : props.isBigScreen ? 200 : 100,
      color: props.ThemeData.colours.primaryFontHighContrast,
      backgroundColor: props.ThemeData.colours.blackBg75,
      position: 'absolute',
      left: props.isPortraitMode ? 'calc((100vw - 584px) / 2)' : -20,
      bottom: props.isPortraitMode ? 1180 : props.isBigScreen ? 250 : 125,
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      borderRadius: props.isPortraitMode ? 20 : 0,
      width: props.isPortraitMode ? 504 : '25vw',
      padding: props.isPortraitMode ? 40 : props.isBigScreen ? 40 : 20,
    }),
    overviewHeader: (props) => ({
      flex: '0 1 auto',
      width: props.isPortraitMode ? 'auto' : props.isBigScreen ? 900 : 'auto',

      fontFamily: props.ThemeData.fonts.titleSub,
      '& h1': {
        textAlign: props.isStPauls ? 'center' : 'left',
        margin: props.isPortraitMode
          ? 0
          : props.isBigScreen
            ? '40px auto'
            : '20px auto',
        fontSize: props.isPortraitMode ? 72
          : props.isStPauls
            ? 40
            : props.isBigScreen
              ? '4.5em'
              : '2.3em',
        fontWeight: props.isStPauls ? 'bold' : 'normal',
        textTransform: props.isPortraitMode ? 'uppercase' : 'capitalize',
        lineHeight: props.isPortraitMode ? 'normal' : '.95em',
      },
    }),
    overviewDescription: (props) => ({
      flex: '0 1 auto',
      marginBottom: props.isStPauls ? 20 : props.isPortraitMode ? 0 : 10,
      fontFamily: props.ThemeData.fonts.copy,
      fontSize: props.isPortraitMode ? 20 : props.isBigScreen ? 32 : 16,
      fontWeight: 500,
      letterSpacing: props.isPortraitMode ? '10%' : 'auto',
      lineHeight: props.portraitMode ? '150%' : '1.3em',
      textAlign: props.isStPauls ? 'center' : 'left',
    }),
    overviewWatchVideoButton: (props) => ({
      flex: '0 1 auto',
      width: 500,
      height: 60,
      background: 'no-repeat',
      backgroundImage: `url(${overviewWatchVideoButton})`,
      cursor: 'pointer',
      marginTop: props.isStPauls ? 50 : 0,
    }),
    videoPopup: {
      width: '100%',
      height: '100%',
    },
    closeVideoButton: (props) => ({
      position: 'absolute',
      top: props.isPortraitMode ? 'auto' : 0,
      bottom: props.isPortraitMode ? 0 : 'auto',
      right: 0,
      zIndex: 1,

      padding: 20,

      cursor: 'pointer',

      color: props.ThemeData.colours.primaryFontHighestContrast,
      fontSize: 44,
      fontFamily: props.ThemeData.fonts.title,
    }),
    mapboxOpen: {
      width: 25,
      height: 25,

      cursor: 'pointer',
    },
    videoButtonPortrait: (props) => ({
      width: props.isBigScreen ? 400 : 200,
      height: props.isBigScreen ? 100 : 50,
      borderRadius: 10,
      display: props.toShowVideo ? 'none' : 'flex',
    }),
  },
  { name: 'Section' },
);

function Section ({ project, prevSection, sectionSlug, ...props }) {
  let { slug: slugParams } = useParams();
  const { isBigScreen, setCurrentSectionSlug, currentSectionSlug } =
    useContext(UnitFcnContext);
  const { ThemeData } = project;
  const { projectSlug } = FloorPlanStore();

  const slug = useMemo(() => {
    let slugMemo = slugParams;

    if (sectionSlug) slugMemo = sectionSlug;

    return slugMemo;
  }, [slugParams, sectionSlug]);

  const unitFcns = {
    selectedUnitId: props.selectedUnitId,
    isUnitDetailOpen: props.isUnitDetailOpen,
    setIsUnitDetailOpen: props.setIsUnitDetailOpen,
    toggleIsUnitDetailOpen: props.toggleIsUnitDetailOpen,
    prevSection: props.prevSection,
    setSelectedUnitId: props.setSelectedUnitId,
    setSelectedFloorplanId: props.setSelectedFloorplanId,
    isSiteMapPage: props.isSiteMapPage,
    setIsSiteMapPage: props.setIsSiteMapPage,
  };

  /**
   * Reset guf when navigating from page to page
   */
    // const globalUnitsFilter = useGlobalUnitsFilter();
  const globalUnitsFilter = useGlobalUnitsFilter();
  useEffect(() => {
    globalUnitsFilter.resetFilter();
  }, [slug]);

  const matchUri = useRouteMatch();
  const isPortraitMode = portraitMode();

  const [horizontalOverflow, setHorizontalOverflow] = useState(true);
  const [tab, setTab] = useState(-1);
  const horizScrollSectionRef = useHorizontalScroll();
  const horizScrollElmRef = useRef(null);

  function isOverflown (element) {
    if (element) {
      return element.scrollWidth > element.clientWidth;
    }
  }

  useEffect(() => {
    setHorizontalOverflow(!isOverflown(horizScrollElmRef.current));
  });

  const { isVideoOpen, setIsVideoOpen } = useOpenClosePane('Video');

  const videoRef = useRef();

  const handleClose = () => {
    setIsVideoOpen(false);
    currentSection.pages[0]?.video?.length > 0 && videoRef.current.pause();
  };

  const resizeImgOnlyCards = () => {
    let imgCards = document.getElementsByClassName(
      'Section-onlyImageCard-0-2-10',
    );
    for (let imgCard of imgCards) {
      let imgElm = imgCard.getElementsByTagName('img')[0];
      if (imgElm)
        imgCard.style.width = `${
          (imgCard.offsetHeight * imgElm.naturalWidth) / imgElm.naturalHeight
        }px`;
    }
  };

  useEffect(() => {
    window.addEventListener('resize', resizeImgOnlyCards);
    return () => window.removeEventListener('resize', resizeImgOnlyCards);
  }, []);
  useEffect(() => {
    resizeImgOnlyCards();
  });

  const currentSection = project.navs.filter((nav) => nav.slug === slug)[0];

  useEffect(() => {
    setCurrentSectionSlug(currentSection);
  }, [currentSection]);

  useEffect(() => {
    if (
      isPortraitMode &&
      prevSection !== currentSection &&
      currentSection.slug !== 'gallery'
    ) {
      props.setPrevSectionFunction(currentSection);
    }
  });

  let firstOneUpContentPage;
  let isFirstCardContainer = true;
  let isLastCardContainer = false;

  let doDarkenMiddleBG = false;
  if (
    project &&
    currentSection &&
    currentSection.pages.length > 0 &&
    currentSection.pages[0].customFunction ===
    ConstantData.contentPageCustomFunctions.photoGallery
  ) {
    doDarkenMiddleBG = true;
  }

  const isStPauls = projectSlug === 'helpstpauls';

  const section = currentSection.pages[0];
  const classes = useStyles({
    ThemeData,
    isBigScreen,
    isPortraitMode: portraitMode(),
    isStPauls,
    function:
      project &&
      currentSection &&
      currentSection.pages.length > 0 &&
      currentSection.pages[0].customFunction,
    toShowVideo:
      (!section.video || section?.video.length === 0) && !section.customUrl,
    overviewTitleLength: section.title.length,
    overviewContentLength: section.content.length,
  });

  return (
    <>
      <Switch>
        <Route path="404.html">
          <div>
            <h2>404 Error</h2>
            <p>The page you are trying to reach is not here.</p>
          </div>
        </Route>
        <Route exact path={matchUri.path}>
          <Layout
            project={project}
            toggleIsSavedHomesSlideoutOpen={
              props.toggleIsSavedHomesSlideoutOpen
            }
            isSavedHomesSlideoutOpen={props.isSavedHomesSlideoutOpen}
            toggleIsSavedHomesCompareOpen={props.toggleIsSavedHomesCompareOpen}
            isSavedHomesCompareOpen={props.isSavedHomesCompareOpen}
            toggleIsRegisterOpen={props.toggleIsRegisterOpen}
            toggleIsSendToEmailOpen={props.toggleIsSendToEmailOpen}>
            {isPortraitMode &&
            project &&
            currentSection &&
            currentSection.pages.length > 0 &&
            currentSection.pages[0].customFunction ===
            ConstantData.contentPageCustomFunctions.photoGallery ? (
              <GalleryPortrait
                project={project}
                sectionSlug={section.slug}
                isPortraitMode={isPortraitMode}
                doOverrideUseFullPhotoGallery={false}
                currentSection={currentSection}
                prevSection={prevSection}
                toggleIsSavedHomesSlideoutOpen={
                  props.toggleIsSavedHomesSlideoutOpen
                }
              />
            ) : (
              <section
                className={`${classes.section} ${
                  doDarkenMiddleBG ? classes.darkenSection : ''
                }`}
                ref={horizScrollSectionRef}>
                {project &&
                  currentSection &&
                  currentSection.pages.length > 0 &&
                  currentSection.pages[0].customFunction ===
                  ConstantData.contentPageCustomFunctions.photoGallery && (
                    <Gallery
                      project={project}
                      sectionSlug={section.slug}
                      doOverrideUseFullPhotoGallery={false}
                    />
                  )}
                {currentSection &&
                  currentSection.pages[0]?.customFunction !==
                  ConstantData.contentPageCustomFunctions.photoGallery && (
                    <>
                      <div className={classes.marginSizer} />
                      {sectionSlug === SlugData.HOME_SLUG_NAME ? (
                        <>
                          {project.settings?.showTextBox && (
                            <motion.div
                              className={classes.overview}
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              transition={{ duration: 0.5 }}>
                              <div className={classes.overviewHeader}>
                                <h1>{section.title}</h1>
                              </div>
                              <div className={classes.overviewDescription}>
                                <p>{section.content}</p>
                              </div>
                              {isPortraitMode ? (
                                <div>
                                  <ButtonOutline
                                    ThemeData={ThemeData}
                                    className={classes.videoButtonPortrait}
                                    padding={'1px 12px'}
                                    fontSize={14}>
                                    <PlayIcon
                                      style={{
                                        paddingRight: 16,
                                        height: 32,
                                        width: 32,
                                      }}
                                    />{' '}
                                    Watch Video
                                  </ButtonOutline>
                                </div>
                              ) : (
                                <div>
                                  <ButtonOutline
                                    ThemeData={ThemeData}
                                    className={classes.videoButtonPortrait}
                                    padding={'1px 12px'}
                                    fontSize={isBigScreen ? 28 : 14}
                                    hidden={
                                      (!section.video ||
                                        section?.video.length === 0) &&
                                      !section.customUrl
                                    }
                                    onClick={() => setIsVideoOpen(true)}>
                                    <PlayIcon
                                      style={{
                                        paddingRight: 16,
                                        height: 32,
                                        width: 32,
                                      }}
                                    />{' '}
                                    Watch Video
                                  </ButtonOutline>
                                </div>
                              )}
                            </motion.div>
                          )}
                          {project &&
                            currentSection &&
                            currentSection.slug === 'overview' && (
                              <Modal
                                open={isVideoOpen}
                                onClose={() => handleClose()}>
                                <>
                                  {currentSection.pages[0]?.video?.length >
                                  0 ? (
                                    <>
                                      <video
                                        className={classes.videoPopup}
                                        ref={videoRef}
                                        width={3840}
                                        height={2160}
                                        controls
                                        autoPlay>
                                        <source
                                          src={
                                            currentSection.pages[0]?.video
                                              ?.length > 0 &&
                                            currentSection.pages[0].video[0]
                                              .link
                                          }
                                          type="video/mp4"
                                        />
                                      </video>
                                    </>
                                  ) : (
                                    <iframe
                                      width="100%"
                                      height="100%"
                                      src={currentSection.pages[0]?.customUrl}
                                      allow="autoplay"
                                      title="Current section"
                                    />
                                  )}
                                  <div
                                    className={classes.closeVideoButton}
                                    onClick={() => handleClose()}>
                                    X
                                  </div>
                                </>
                              </Modal>
                            )}
                        </>
                      ) : (
                        <div
                          className={classes.contentCardsContainer}
                          ref={horizScrollElmRef}>
                          {currentSection.pages &&
                            [...currentSection.pages]
                              .filter((widget) => {
                                return isPortraitMode
                                  ? widget.customFunction !== 'photoOnlyCard'
                                  : true;
                              })
                              .sort((cpA, cpB) => cpA.position - cpB.position)
                              .map((contentPage, index) => {
                                if (
                                  index === currentSection.pages.length - 1 &&
                                  !horizontalOverflow
                                ) {
                                  isLastCardContainer = true;
                                }
                                if (
                                  contentPage.form &&
                                  contentPage.form === 'oneUp'
                                ) {
                                  if (!firstOneUpContentPage) {
                                    firstOneUpContentPage = contentPage;
                                    return null;
                                  }
                                  return (
                                    <div
                                      className={`${
                                        classes.contentCardContainer
                                      } ${classes.oneUpContainer} ${
                                        isFirstCardContainer
                                          ? 'firstCardContainer'
                                          : ''
                                      } ${
                                        isLastCardContainer
                                          ? 'lastCardContainer'
                                          : ''
                                      } ${
                                        contentPage?.customFunction ===
                                        ConstantData.contentPageCustomFunctions
                                          .photoOnlyCard
                                          ? classes.onlyImageCard
                                          : ''
                                      }`}
                                      key={contentPage.id}
                                      id={`content-card-${contentPage.id}`}>
                                      <CardComponents
                                        ThemeData={ThemeData}
                                        contentPage={firstOneUpContentPage}
                                        section={currentSection}
                                        tab={tab}
                                        setTab={setTab}
                                        setIsContentPageCarouselOpen={
                                          props.setIsContentPageCarouselOpen
                                        }
                                      />
                                      <CardComponents
                                        ThemeData={ThemeData}
                                        contentPage={contentPage}
                                        tab={tab}
                                        section={currentSection}
                                        setTab={setTab}
                                        setIsContentPageCarouselOpen={
                                          props.setIsContentPageCarouselOpen
                                        }
                                      />
                                      {(firstOneUpContentPage = null)}
                                      {(isFirstCardContainer = false)}
                                    </div>
                                  );
                                }
                                return (
                                  <div
                                    className={`${
                                      classes.contentCardContainer
                                    } ${
                                      classes[contentPage.form + 'Container']
                                    } ${
                                      isFirstCardContainer
                                        ? 'firstCardContainer'
                                        : ''
                                    } ${
                                      isLastCardContainer
                                        ? 'lastCardContainer'
                                        : ''
                                    } ${
                                      contentPage?.customFunction ===
                                      ConstantData.contentPageCustomFunctions
                                        .photoOnlyCard
                                        ? classes.onlyImageCard
                                        : ''
                                    }`}
                                    key={contentPage.id}
                                    id={`content-card-${contentPage.id}`}>
                                    <CardComponents
                                      ThemeData={ThemeData}
                                      contentPage={contentPage}
                                      section={currentSection}
                                      key={contentPage.id}
                                      tab={tab}
                                      setTab={setTab}
                                      setIsContentPageCarouselOpen={
                                        props.setIsContentPageCarouselOpen
                                      }
                                    />
                                    {(isFirstCardContainer = false)}
                                  </div>
                                );
                              })}
                          <div hidden={horizontalOverflow}>
                            <ScrollIcon
                              direction={ConstantData.scrollIconDir.horizontal}
                            />
                          </div>
                        </div>
                      )}
                      {sectionSlug === 'neighbourhood' && (
                        <div className={classes.mapboxOpen}>+</div>
                      )}
                      <div className={classes.marginSizerBottom} />
                    </>
                  )}
              </section>
            )}
          </Layout>
        </Route>
        <Route path={`${matchUri.path}/:contentPageSlug`}>
          {project && currentSection && (
            <PageComponents
              {...unitFcns}
              currentSection={currentSection}
              currentNav={project.navs.filter((nav) => nav.slug === slug)[0]}
              project={project}
              toggleIsSavedHomesSlideoutOpen={
                props.toggleIsSavedHomesSlideoutOpen
              }
              isSavedHomesSlideoutOpen={props.isSavedHomesSlideoutOpen}
              toggleIsSavedHomesCompareOpen={
                props.toggleIsSavedHomesCompareOpen
              }
              isSavedHomesCompareOpen={props.isSavedHomesCompareOpen}
              toggleIsRegisterOpen={props.toggleIsSendToEmailOpen}
              setSelectedUnitId={props.setSelectedUnitId}
              setSelectedFloorplanId={props.setSelectedFloorplanId}
              sectionSlug={sectionSlug}
              setIsContentPageCarousel={props.setIsContentPageCarousel}
              setIsContentPageCarouselOpen={props.setIsContentPageCarouselOpen}
            />
          )}
        </Route>
      </Switch>

      <ContentPageCarousel
        {...unitFcns}
        currentSection={currentSection}
        sectionSlug={sectionSlug}
        sectionData={currentSection.pages.filter(
          (page) =>
            page.customFunction !==
            ConstantData.contentPageCustomFunctions.photoOnlyCard,
        )}
        currentNav={project.navs.filter((nav) => nav.slug === slug)[0]}
        project={project}
        tab={tab}
        setTab={setTab}
        toggleIsSavedHomesSlideoutOpen={props.toggleIsSavedHomesSlideoutOpen}
        isSavedHomesSlideoutOpen={props.isSavedHomesSlideoutOpen}
        toggleIsSavedHomesCompareOpen={props.toggleIsSavedHomesCompareOpen}
        isSavedHomesCompareOpen={props.isSavedHomesCompareOpen}
        toggleIsRegisterOpen={props.toggleIsSendToEmailOpen}
        setIsContentPageCarousel={props.setIsContentPageCarousel}
        setIsContentPageCarouselOpen={props.setIsContentPageCarouselOpen}
      />
    </>
  );
}

export default Section;
