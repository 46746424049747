import React, { useContext } from 'react';

import {
  createUseStyles,
} from 'react-jss';

import {
  // FaArrowLeft,
} from 'react-icons/fa';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ThemeData from '../data/ThemeData';
import { portraitMode } from 'util/helper';
import UnitFcnContext from 'contexts/UnitFcnContext';

// import navArrowLeftDark from "../img/nav_arrow_left_dark.svg";

const useStyles = createUseStyles(
  {
    header: props => ({
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: 100,
      // height: props.isBigScreen? 200 : 100,
      // border:'2px solid red',
      // height:props.height ? props.height : (props.isPortraitMode ? ( props.isBigScreen? 'calc(2*var(--header-height))' : 'var(--header-height)') : ( props.isBigScreen? 200 : 100)),

      backgroundColor: props.ThemeData.colours.primaryHeaderBg,
      zIndex: 2,
    }),
    backButton: props => ({
      cursor: 'pointer',
      color: props.ThemeData.colours.primaryFont,
      fontSize: 14,

      backgroundColor: props.ThemeData.colours.primaryBg,
      paddingLeft: props.isPortraitMode ? 43 : 40,
      paddingRight: props.isPortraitMode ? 43 : 0,
    }),
    backButtonWrapper: props => ({
      display: 'flex',
      alignItems: 'center',
      height: props.isPortraitMode ? 'var(--header-height)' : 'auto',
      borderRight: props.isPortraitMode ? props.ThemeData.colours.paneBorders : 0,
    }),
  },
  {
    name: 'HeaderSimple',
  }
);

const HeaderSimple = ({ project, onBackClick, ...props }) => {
  const isPortraitMode = portraitMode();
  const {
    isBigScreen
  } = useContext(UnitFcnContext);
  const classes = useStyles({ ...props, ThemeData: project.ThemeData, isPortraitMode, isBigScreen });
  return (
    <Link to={props.backLocation}>
      <div className={classes.header}>
        {!isPortraitMode && <div className={classes.backButton} onClick={onBackClick}>
          <FontAwesomeIcon icon={faArrowLeft} /> BACK
        </div>}
        {isPortraitMode && <div className={classes.backButtonWrapper}>
          <div className={classes.backButton} onClick={onBackClick}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
        </div>}
      </div>
    </Link>
  );
};

HeaderSimple.defaultProps = {
  backgroundColor: ThemeData.colours.white50,
};

export default HeaderSimple;