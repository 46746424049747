import createPersistedState from 'use-persisted-state';
import ConstantData from 'data/ConstantData';

const accessibilityPaneState = createPersistedState(ConstantData.localStorageKeys.accessibilityPane);

const accessibilityPane = () => {
  const [accessibility, setAccessibility] = accessibilityPaneState(false);
  return {
    accessibility,
    toggleAccessibility: toggle => {
      setAccessibility(toggle);
    }
  };
};

export default accessibilityPane;

