import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import 'index.css';
import reportWebVitals from 'reportWebVitals';

import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import fetch from 'cross-fetch';
import App from 'App';

const createAuthLink = (apiToken) => {
  return setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: apiToken ? `Bearer ${apiToken}` : '',
      'Apollo-Require-Preflight': 'true',
    },
  }));
};

const gqlUri = `${process.env.REACT_APP_BACKEND_URI}/graphql`;
const client = new ApolloClient({
  uri: gqlUri,
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    createAuthLink(process.env.REACT_APP_BACKEND_TOKEN),
    new HttpLink({ uri: gqlUri, fetch }),
  ]),
});

const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty(` --app-height`, `${window.innerHeight}px`);
};
window.addEventListener('resize', appHeight);
appHeight();
const rootElement = document.getElementById('root');

ReactDOM.render(
  <>
    <Router>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Router>
  </>,
  rootElement,
);

reportWebVitals();
