const getDistanceFromKmInMinute = (distance, avgSpeed = 4.54) => parseInt((distance / avgSpeed) * 60)
const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2-lat1);  // deg2rad below
  var dLon = deg2rad(lon2-lon1); 
  var a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI/180)
}

export const categorizeNeighbourhood = (neighGeo, startLat, startLong, filterDistance, filterBy = "min") => {
  let neighCategories = {}
	if (neighGeo) {
		neighGeo.features.forEach((item) => {
			if (!item.properties.CategoryId || item.properties.disabled) return
			let distanceInKm = null, distanceInMin = null
			if (startLat && startLong && item.geometry && item.geometry.coordinates) {
				distanceInKm = parseFloat(getDistanceFromLatLonInKm(
					startLat,
					startLong,
					item.geometry.coordinates[1],
					item.geometry.coordinates[0]
				).toFixed(2))
				if (distanceInKm > 0) distanceInMin = getDistanceFromKmInMinute(distanceInKm)
			}
			if (!neighCategories[item.properties.Category]) {
				const { CategoryId, Color } = item.properties
				neighCategories[item.properties.Category] = { CategoryId, Color, Poi: [] }
			}
			if (filterDistance) {
				if (filterBy === "km" && filterDistance > distanceInKm) {
					neighCategories[item.properties.Category].Poi.push({...item, distanceInKm, distanceInMin })
				}
				else if (filterDistance > distanceInMin) {
					neighCategories[item.properties.Category].Poi.push({...item, distanceInKm, distanceInMin })
				}
			}
			else {
				neighCategories[item.properties.Category].Poi.push({...item, distanceInKm, distanceInMin })
			}
		})
	}
	return neighCategories
}
