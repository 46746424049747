// const { gql, useApolloClient, useQuery } = require("@apollo/client");
// const { client } = require("../index");
const { create } = require("zustand");

const FloorPlanStore = create((set) => ({
  dataFloorPlansStore: [],
  projectSlug: null,
  dataUnitsStore: null,


  setDataUnit(data) {
    set({ dataUnitsStore: data });
  },

  setFlootPlansData(data) {
    set({ dataFloorPlansStore: data });
  },
  setProjectSlug(slug) {
    set({ projectSlug: slug });
  },
}));
export default FloorPlanStore;
