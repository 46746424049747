import { useState } from 'react';

const useSiteFeatureToggles = (settings = {}) => {
  const { showPrice = false } = settings;

  const [siteFeatureToggles] = useState({
    isPricesOn: showPrice,
  });

  return siteFeatureToggles;
};

export default useSiteFeatureToggles;
