import React from 'react';

import {
	createUseStyles,
} from "react-jss";

const useStyles = createUseStyles(
	{
		wrapper: props => ( {
			// marginTop: props.marginTop,
			// padding: props.padding,

			width: props.widthHeight,
			height: props.widthHeight,

			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',

			color: props.textColour,
			background: props.backgroundColour,

			fontSize: props.fontSize,
			textAlign: 'center',
			textTransform: 'uppercase',
			letterSpacing: '0.025em',

			borderRadius: '50%',
			border: props.border ? props.border : `1px ${props.borderColor} solid`,

			cursor: 'pointer',
			pointerEvents: props.disabled ? 'none' : '',

			transition: 'color .25s ease-out, background-color .25s ease-out',

			disabled: props.disabled,
			// '& a:hover, & a:visited': {
			// 	color: props.textColour,
			// },

			"&:active": {
				backgroundColor: `${props.activeBackgroundColor} !important`
			},
			'&:hover': {
				color: props.reactOnHover ?
					( props.hoverTextColour || props.backgroundColour ) :
					props.textColour,
				backgroundColor: props.reactOnHover ?
					( props.hoverBackgroundColour || props.textColour ) :
					props.backgroundColour,
			},
		} ),
		// buttonText: props => ( {
		// 	width: props.iconWidthHeight,
		// 	height: props.iconWidthHeight,
		// }),
		icon: props => ( {
			width: props.iconWidthHeight,
			height: props.iconWidthHeight,
		} ),
	},
	{ name: 'ButtonCircle' },
);


ButtonCircle.defaultProps = {
	widthHeight: 55,
	iconWidthHeight: 32,
	padding: '15px 5px',
	marginTop: 'initial',
	fontSize: 12,
	textColour: 'inherit',
	hoverTextColour: null,
	backgroundColour: 'clear',
	activeBackgroundColor: "",
	border: "",
	reactOnHover: true,
};


function ButtonCircle( {ThemeData, children, ...props } ) {
	if (!props.borderColor) props.borderColor = ThemeData.colours.grey
	const classes = useStyles({ ...props, ThemeData});
	return (
		<div className={`${classes.wrapper} ${props.firstInColl ? "firstInColl" : ""} ${props.className}`} onClick={props.onClick} onMouseDown={props.onMouseDown} onMouseUp={props.onMouseUp}>
			{/* <div className={classes.buttonText}> */}
				{props.src &&
					<img className={classes.icon} src={props.src} alt={props.alt} />
				}
				{children}
			{/* </div> */}
		</div>
	);
}



export default ButtonCircle;