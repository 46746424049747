import React from "react";
import { useLocation } from "react-router-dom";

import { createUseStyles } from "react-jss";
import { motion } from "framer-motion";
import useSavedHomes from "hooks/useSavedHomes";
import { sendGoogleAnalyticsEvent } from "util/sendGoogleAnalyticsEvent";
import { getImageBySize } from "util/imgFunctions";
import {
  portraitMode,
  determineFloorPlanStatus,
  isAvailableStatus,
} from "util/helper";
import FloorplanDetail from "./FloorplanDetail";

const useStyles = createUseStyles(
  {
    notAvailableWrapper: {
      opacity: 0.5,
    },
    notAvailableDetails: {
      opacity: 0.5,
    },
    wrapper: (props) => ({
      position: "relative",
      width: 340,
      height: 400,
      margin: "10px 0 0 10px",
      ...props.ThemeData.typography.boxShadow,
      borderRadius: 15,
      border: `2px solid ${props.ThemeData.colours.white}`,
    }),
    selectedUnitHighlight: {
      border: (props) => `2px solid ${props.ThemeData.colours.branding}`,
    },
    hidden: {
      display: "none",
    },
    floorplanImage: (props) => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0 10px",
      height: 253,
      borderBottom: `1px solid ${props.ThemeData.colours.lightGrey}`,
      "& img": {
        height: "100%",
        maxHeight: 250,
      },
    }),
    $details: {
      borderBottom: (props) =>
        `1px ${props.ThemeData.colours.mainBorder} solid`,
    },
    details: {
      display: "flex",
      padding: "10px 17px",
      flexDirection: "column",
      textAlign: "left",
    },
    title: (props) => ({
      ...props.ThemeData.typography.contentPageTitle,
      fontSize: 17,
    }),
    infoLine: (props) => ({
      ...props.ThemeData.typography.content,
      color: props.ThemeData.colours.grey,
      letterSpacing: 0,
      fontSize: 12,
    }),
    moreDetailsButtonArea: {
      position: "absolute",
      bottom: 0,
      width: "100%",
    },
    detailButton: (props) => ({
      padding: 10,
      fontSize: 15,
      width: "100%",
      borderRadius: "0 0 15px 15px",
      textTransform: "capitalize",
      color: props.ThemeData.colours.branding,
      cursor: "pointer",
      border: "0",
      borderTop: `1px solid ${props.ThemeData.colours.branding}`,
      backgroundColor: props.ThemeData.colours.white,
      "&:hover, &:active, &.active": {
        backgroundColor: props.ThemeData.colours.branding,
        color: props.ThemeData.colours.primaryFontHighestContrast,
      },
    }),
  },
  {
    name: "FloorplanDetailCardGeneric",
  }
);

function renderFloorplanDetailCardGeneric(
  project,
  dataFloorplan,
  isPortraitMode,
  classes,
  error,
  toggleUnitDetailPaneFcn,
  hooks,
  location,
  props
) {
  const floorPlanPhoto = getImageBySize(dataFloorplan.photo, "large");

  const isTownHouse = project.settings.isTownHouse;

  const filteredUnits = project.units.filter((unit) =>
    isTownHouse
      ? unit.floorPlan.name === dataFloorplan.name
      : unit.floorPlan.id === dataFloorplan.id
  );

  if (filteredUnits.length === 0) {
    return null;
  }

  // Determine the floor plan status
  const groupedUnits = filteredUnits.reduce((acc, unit) => {
    const useIdOrName = isTownHouse ? unit.floorPlan.name : unit.floorPlan.id;
    const floorPlanId = useIdOrName;

    if (!acc[floorPlanId]) {
      acc[floorPlanId] = { units: [] };
    }
    acc[floorPlanId].units.push(unit);
    return acc;
  }, {});

  const useIdOrName = isTownHouse ? dataFloorplan.name : dataFloorplan.id;

  const floorPlanStatus = determineFloorPlanStatus(groupedUnits[useIdOrName]);

  const isAvailable = isAvailableStatus(floorPlanStatus);

  return (
    <motion.div
      className={`${props.className} ${classes.wrapper} ${
        dataFloorplan.id === props.selectedFloorplanId
          ? classes.selectedUnitHighlight
          : ""
      } ${!isAvailable ? classes.notAvailableWrapper : ""}`}
      onClick={() => {
        props.setSelectedFloorplanId(dataFloorplan.id);
      }}
    >
      <div className={classes.floorplanImage}>
        {dataFloorplan && dataFloorplan.photo && (
          <img src={`${floorPlanPhoto.link}`} alt={dataFloorplan.name} />
        )}
      </div>
      {dataFloorplan && (
        <div className={classes.details}>
          <FloorplanDetail
            project={project}
            ThemeData={project.ThemeData}
            data={dataFloorplan}
            titleClassName={classes.title}
            separator="dots"
            infoClassName={classes.infoLine}
            status={floorPlanStatus}
          />
        </div>
      )}

      <div className={classes.moreDetailsButtonArea}>
        <button
          className={`${classes.detailButton} ${
            props.isUnitDetailOpen &&
            props.selectedFloorplanId === dataFloorplan.id
              ? "active"
              : ""
          } ${!isAvailable ? classes.notAvailableDetails : ""}`}
          onClick={() => {
            if (!isAvailable) return;
            hooks.setSelectedUnitId(-1);
            hooks.setSelectedFloorplanId(dataFloorplan.id);
            toggleUnitDetailPaneFcn();
            sendGoogleAnalyticsEvent(
              `Floor Plan View - ${location.pathname}`,
              `User Clicks ${dataFloorplan.name}`,
              `${dataFloorplan.name}`,
              `${dataFloorplan.name}`
            );
          }}
        >
          More Details
        </button>
      </div>
    </motion.div>
  );
}

function FloorplanDetailCardGeneric({
  project,
  dataFloorplan,
  toggleUnitDetailPaneFcn,
  ...props
}) {
  const isPortraitMode = portraitMode();
  const classes = useStyles({
    ThemeData: project.ThemeData,
    ...props,
    isPortraitMode,
  });
  const location = useLocation();
  const { savedHomes, toggleSavedHome, savedHomesIncludes } = useSavedHomes();

  let hooks = {
    savedHomes,
    toggleSavedHome,
    savedHomesIncludes,
    setSelectedUnitId: props.setSelectedUnitId,
    setSelectedFloorplanId: props.setSelectedFloorplanId,
  };

  return renderFloorplanDetailCardGeneric(
    project,
    dataFloorplan,
    isPortraitMode,
    classes,
    null,
    toggleUnitDetailPaneFcn,
    hooks,
    location,
    props
  );
}

FloorplanDetailCardGeneric.defaultProps = {
  isToAllowSelection: false,
};

export default FloorplanDetailCardGeneric;
