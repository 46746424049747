import React, { useEffect, useState, useMemo, useContext } from 'react';

import { createUseStyles } from 'react-jss';
import { Fade } from '@mui/material';

import { Link, useParams, useHistory } from 'react-router-dom';

import { motion } from 'framer-motion';

import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import 'swiper/swiper.scss';
import 'swiper/modules/pagination/pagination.scss';
import 'swiper/modules/navigation/navigation.scss';
import SwiperCore, {
  Controller,
  Pagination,
  Navigation,
  Mousewheel,
} from 'swiper';

import ConstantData from '../data/ConstantData';
import Map from 'components/Map';
import Floorplans from './Floorplans';
import HeaderComponents from './HeaderComponents';
import AccessiblityFooter from './AccessibilityFooter';
import HeaderContextData from 'data/HeaderContextData';
import VirtualTour from './VirtualTour';
import ExternalLink from './ExternalLink';
import useOpenClosePane from 'hooks/useOpenClosePane';
import ViewStudy from './ViewStudy';

import contentPageX from '../img/content_page_x.svg';
import contentPageXDark from '../img/content_page_x_dark.svg';
import ButtonOutline from './ButtonOutline';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import SwiperText from './SwiperText';
import PhotoGallery from 'react-photo-gallery';
import { getImageBySize } from 'util/imgFunctions';
import { filterAndGroupBySubCategory } from 'util/helper';

import SiteMap from './SiteMap';
import FilterHeader from './FilterHeader';
import UnitFcnContext from 'contexts/UnitFcnContext';
import useGlobalUnitsFilterResult from 'hooks/useGlobalUnitsFilterResult';
import UnitCardSlideout from './UnitCardSlideout';
import { ReactComponent as ChevronRighMedium } from '../img/chevron_left_white_medium.svg';
import FilterPane from './FilterPane';
import FooterComponents from './FooterComponents';

SwiperCore.use([Mousewheel, Pagination]);

const useStyles = createUseStyles(
  {
    wrapper: (props) => ({
      height: '100%',
      color: props.ThemeData.colours.black,
      // border: '1px solid blue',
    }),
    contentPage: (props) => ({
      backgroundColor: props.ThemeData.colours.neuPaleOak,
      // backgroundColor:'green',
      display: 'flex',
      flexDirection: 'row',
      // border: '1px solid green',
      height: props.isBigScreen
        ? 'calc( 100vh - var(--header-height) - 9vh - 24px)'
        : 'calc( 100vh - var(--header-height) - 5vh - 24px)',
    }),
    footer: (props) => ({
      display: 'block',
      height: '120px',
      width: '100%',
      // border: "1px solid red",
    }),
    backButtonWrapper: (props) => ({
      display: 'flex',
      alignItems: 'center',
      height: 'auto',
      borderRight: 0,
    }),
    backButton: (props) => ({
      display: 'flex',
      alignItems: 'center',
      flex: '0 0 auto',
      cursor: 'pointer',
      paddingLeft: 40,
      paddingRight: 0,

      color: props.ThemeData.colours.primaryFont,
      fontSize: props.isBigScreen ? 28 : 14,

      '& > svg': {
        fontSize: props.isBigScreen ? 40 : 20,
        marginRight: props.isBigScreen ? 10 : 5,
      },
    }),
    contentPane: (props) => ({
      position: 'static',
      // border: '1px solid red',
      bottom: 'auto',
      width: 390,
      height: 'calc( 100vh - var( --header-height ))',
      flex: '0 0 auto',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor:
        props.ThemeData.colours.contentPageBg ||
        props.ThemeData.colours.secondaryBg,

      textAlign: 'left',

      '&.withMap': {
        width: 550,
      },
    }),
    contenPaneSplit: (props) => ({
      flexDirection: 'row',
    }),
    contentContainer: (props) => ({
      flex: '1 1 auto',
      height: 'calc( 100vh - var(--footer-height))',
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box',

      padding: 40,
    }),
    rightPane: (props) => ({
      flex: '1 1 auto',
      width: '100%',
      height: 'calc(100vh - var(--footer-height))',
      backgroundColor:
        props.ThemeData.colours.contentPageImageBg ||
        props.ThemeData.colours.secondaryBg,
      background: 'no-repeat center',
      backgroundSize: 'cover',
      objectFit: 'contain',
      zIndex: '1009',
    }),
    rightPaneFullsized: (props) => ({
      backgroundSize: 'contain',
      height: '100%',
    }),
    contentContainerRight: (props) => ({
      width: '60%',
    }),
    navContainer: (props) => ({
      height: 150,

      flex: '0 1 auto',

      padding: '0 40px 30px 40px',
    }),
    navArrow: (props) => ({
      marginTop: 30,
    }),
    swiperSlideImg: (props) => ({
      width: 10,
    }),
    rightPaneVideo: (props) => ({
      display: 'flex',
      objectFit: 'contain',
    }),
    swiper: (props) => ({
      '--swiper-theme-color':
        props.ThemeData.colours.swiperColour || props.ThemeData.colours.white,
      '--swiper-navigation-size': '',
      width: 'auto',
      height: '90vh',
      '& .swiper-button-next, & .swiper-button-prev': {
        marginTop: '',
      },
    }),
    contentArea: (props) => ({
      flex: '0 0 auto',
    }),
    sectionName: (props) => ({
      flex: '0 0 auto',

      color: props.ThemeData.colours.primaryAccent,

      fontFamily: props.ThemeData.fonts.title,
      fontSize: 16,
      margin: '0.7em 0',
    }),
    title: (props) => ({
      flex: '0 0 auto',

      color: props.ThemeData.colours.secondaryTitleFont,

      marginTop: '.5em',

      fontFamily: props.ThemeData.fonts.title,
      fontSize: '2.3em',
      letterSpacing: 0,
      lineHeight: '1.2em',
      marginBottom: '1.2em',
    }),
    subTitle: (props) => ({
      fontFamily: props.ThemeData.fonts.title,
      color: props.ThemeData.colours.tertiaryFont,
      textTransform: 'uppercase',
      fontSize: '1.4em',
      letterSpacing: 0,
      lineHeight: '2.4em',
    }),
    // keep left pane text box
    textBox: (props) => ({
      minHeight: '25vh',
      maxHeight: '25vh',
      overflowY: 'auto',
      ...props.ThemeData.styles.thinScrollbar,
    }),
    leftContentContainer: (props) => ({
      display: 'flex',
      flexDirection: 'column',
    }),
    content: (props) => ({
      flex: '1 1 auto',
      // height: 'calc( 100vh - 297px )',
      marginBottom: '20px',
      color: props.ThemeData.colours.tertiaryFont,

      fontFamily: props.ThemeData.fonts.copy,
      fontSize: '1.2em',
      letterSpacing: '0.0125em',
      lineHeight: '2em',
      height: 'auto',
      overflowY: 'auto',

      ...props.ThemeData.styles.thinScrollbar,
    }),
    scrollSection: (props) => ({
      height: '220px',
      overflowY: 'auto',
    }),
    sectionCategories: (props) => ({
      marginTop: '20px',
    }),
    contentPageX: (props) => ({
      position: 'absolute',
      right: 0,

      width: 150,
      height: 150,

      background: 'no-repeat center',
      backgroundImage: `url('${contentPageX}')`,

      filter: 'drop-shadow(2px 2px 2px rgb(0, 0, 0, .15))',

      '&.isDark': {
        backgroundImage: `url('${contentPageXDark}')`,
      },
    }),
    mapPane: (props) => ({
      display: 'none',

      '&.doShow': {
        display: 'block',
      },
    }),
    scrollIcon: (props) => ({
      bottom: '30px !important',
      zIndex: 2,
    }),
    floorplans: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
    },
    activeButton: {
      color: (props) =>
        props.ThemeData.colours.featureButtonTextActive || '#FFFFFF',
      backgroundColor: (props) => props.ThemeData.colours.vibrantGreen,
    },
    categoryName: (props) => ({
      textTransform: 'capitalize',
      '&:hover': {
        color: props.ThemeData.colours.featureButtonTextHover || '#000000',
        backgroundColor:
          props.ThemeData.colours.featureButtonHover || '#ADA74326',
        // opacity: .5,
      },
    }),
    openText: {
      flex: '0 0 auto',
      paddingRight: 20,
      color: (props) => props.ThemeData.colours.secondaryAccent,
      fontSize: 12,
      textTransform: 'uppercase',
      letterSpacing: 4,
    },
    top: {
      flex: '0 0 auto',
      transition: 'left 0.5s',
    },
    closeX: {
      display: 'flex',
      alignItems: 'center',
      height: 50,
      width: (props) => (props.isUnitCardSlideoutOpen ? 35 : 165),
      backgroundColor: (props) => props.ThemeData.colours.white,
      boxShadow: '0px 4px 10px rgb(0 0 0 / 33%)',
      cursor: 'pointer',
      margin: (props) => (props.isUnitCardSlideoutOpen ? '0 25px' : '0 50px'),
      zIndex: 1,
      borderRadius: 5,
    },
    openIcon: {
      flex: '0 0 auto',
      display: 'flex',
    },
    slideAndCanvasContainer: {
      display: (props) => (props.unitDetailOpen ? 'none' : 'flex'),
      flexDirection: 'row',
    },
    canvasElementsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      paddingRight: (props) => (props.isSavedHomesSlideoutOpen ? 450 : 0),
      background: (props) => props.ThemeData.colours.white,
      paddingTop: 10,
      alignItems: 'center',
    },
    canvasWrapper: {
      position: 'relative',
      width: '100%',
    },
  },
  {
    name: 'ContentPage',
  },
);

function ContentPage ({ project, currentSection, ...props }) {
  const { ThemeData } = project;

  // const apiUri = NetData.apiUri;
  let { contentPageSlug } = useParams();
  if (props.overrideUrlContentPageSlug)
    contentPageSlug = props.overrideUrlContentPageSlug;
  // const matchUri = useRouteMatch();
  const history = useHistory();
  const [isBack, setIsBack] = useState(false);
  const [isOpenSavedHomes, setIsOpenSavedHomes] = useState(false);

  let siteLogo = null,
    contentPage = null;
  if (project && currentSection) {
    siteLogo = project && project.logo.length > 0 ? project.logo[0].link : null;
    contentPage = currentSection.pages.filter(
      (p) => p.slug === contentPageSlug,
    )[0];
  }

  const {
    isViewStudySlideUpOpen,
    setIsViewStudySlideUpOpenToClosed,
    toggleIsViewStudySlideUpOpen,
  } = useOpenClosePane('ViewStudySlideUp');

  const {
    isFloorplansViewStudySlideUpOpen,
    setIsFloorplansViewStudySlideUpOpenToClosed,
    toggleIsFloorplansViewStudySlideUpOpen,
  } = useOpenClosePane('FloorplansViewStudySlideUp');

  const {
    isFloorplansVirtualTourOpen,
    setIsFloorplansVirtualTourOpenToClosed,
    toggleIsFloorplansVirtualTourOpen,
  } = useOpenClosePane('FloorplansVirtualTour');

  const unitFcns = {
    selectedUnitId: props.selectedUnitId,
    setSelectedUnitId: props.setSelectedUnitId,
    selectedFloorplanId: props.selectedFloorplanId,
    isUnitDetailOpen: props.isUnitDetailOpen,
    setIsUnitDetailOpen: props.setIsUnitDetailOpen,
    toggleIsUnitDetailOpen: props.toggleIsUnitDetailOpen,
  };

  const [highlightCatPoi, setHighlightCatPoi] = useState({
    isHighActive: false,
    highCatId: -1,
    highPoiId: -1,
  });

  useEffect(() => {}, [highlightCatPoi]);

  const [procFlyTo] = useState({
    doProcFlyTo: false,
    poiid: -1,
    catid: -1,
    coordinates: -1,
  });

  let doRenderInteractiveMap = false;
  let doOnlyRenderPhotos = false;

  const textMediaPairs = useMemo(() => {
    if (contentPage && contentPage.child.length > 1) {
      return contentPage.child;
    }
    return null;
  }, [contentPage]);

  const doOnlyRenderSingleTextContent = useMemo(() => {
    if (textMediaPairs && textMediaPairs.length > 0) {
      let areAllTMPTextsBlank = true;
      if (textMediaPairs.length > 1) {
        for (let i = 1; i < textMediaPairs.length; i++) {
          if (textMediaPairs[i].content) areAllTMPTextsBlank = false;
        }
      }
      return areAllTMPTextsBlank;
    }
    return false;
  }, [textMediaPairs]);

  const renderContentPageMediaPane = (contentPageMedia, fullSized) => {
    if (!contentPageMedia || contentPageMedia.length === 0) return;
    if (contentPageMedia[0].fileName.includes('.mp4')) {
      return (
        <div className={`${classes.rightPane} ${classes.rightPaneVideo}`}>
          <video autoPlay muted loop>
            <source src={contentPageMedia.url} type="video/mp4" />
          </video>
        </div>
      );
    } else {
      const photo = getImageBySize(contentPageMedia, 'large');
      return (
        <div
          className={`${classes.rightPane} ${
            doOnlyRenderPhotos || fullSized ? classes.rightPaneFullsized : ''
          }`}
          style={contentPageMedia && { backgroundImage: `url(${photo.link})` }}
        />
      );
    }
  };

  const [currentSchemeMedia, setCurrentSchemeMedia] = useState(null);
  const [fadeMedia, setFadeMedia] = useState(false);

  const [activeButton, setActiveButton] = useState('');

  const [mediaSwiper, setMediaSwiper] = useState(null);
  const [textSwiper, setTextSwiper] = useState(null);

  const backLocation = currentSection && `/${currentSection.slug}`;
  console.log('[backLocation]', backLocation);

  doRenderInteractiveMap =
    contentPage &&
    contentPage.customFunction ===
    ConstantData.contentPageCustomFunctions.interactiveMap;
  // doRenderInteractiveMap = true
  doOnlyRenderPhotos =
    contentPage &&
    contentPage.customFunction ===
    ConstantData.contentPageCustomFunctions.photosOnly;

  const galleryPhotos = useMemo(() => {
    if (!project) return null;

    const photos = project.photoGallery.filter((p) => p.target === 'gallery');

    let galleryPhotos = [{}];
    if (photos && photos.length > 0)
      galleryPhotos = [...photos]
        .sort((phA, phB) => phA.position - phB.position)
        .map((photo) => {
          if (photo.formats && photo.formats.length > 0) {
            const largePhoto = photo.formats.filter(
              (p) => p.size === 'large',
            )[0];
            if (largePhoto) photo = largePhoto;
          }
          return {
            src: photo.link,
            height: photo.height,
            width: photo.width,
            //category: validatePhotoCategories( photo ) ? photo.gallery_photo_categories.map( cat => cat.name ) : ''
          };
        });
    return galleryPhotos;
  }, [project]);

  const [doShowFilter, setDoShowFilter] = useState(false);
  const {
    isBigScreen,
    selectedUnitId,
    setSelectedUnitId,
    setIsUnitDetailOpen,
    interactModalFrom,
    setInteractModalFrom,
    setIsOpenNavBar,
    isOpenNavBar,
  } = useContext(UnitFcnContext);

  const {
    isUnitCardSlideoutOpen,
    setIsUnitCardSlideoutOpenToOpen,
    toggleIsUnitCardSlideoutOpen: toggleUnitCardSlideoutPane,
  } = useOpenClosePane('UnitCardSlideout');

  const unitDetailOpen = props.isUnitDetailOpen;

  const classes = useStyles({
    ThemeData,
    isUnitCardSlideoutOpen,
    unitDetailOpen,
    isBigScreen,
  });

  const { unitCustomIdsFiltered, setUnitCustomIdsFiltered } =
    useGlobalUnitsFilterResult();

  const [siteMapWidth, setSiteMapWidth] = useState(900);
  const toggleUnitDetailPane = () => {
    return props.setUnitDetailOpen(!props.isUnitDetailOpen);
  };

  const {
    isVirtualTourOpen,
    setIsVirtualTourOpenToClosed,
    toggleIsVirtualTourOpen,
  } = useOpenClosePane('VirtualTour');

  const [browserHeight, setBrowserHeight] = useState(0);

  useEffect(() => {
    const innerWidth = window.innerWidth;
    const innerHeight = window.innerHeight - 180;
    setBrowserHeight(innerHeight);
    const slideoutContainer = document.getElementById('unit-cards-slideout');

    if (slideoutContainer) {
      setSiteMapWidth(
        isUnitCardSlideoutOpen ? innerWidth - 600 : innerWidth - 265,
      );
    }
    if (contentPage?.customFunction === 'siteMaps') {
      props.setIsSiteMapPage(true);
    }
  }, [setSiteMapWidth, isUnitCardSlideoutOpen, contentPage]);

  const defaultTransition = {
    type: 'tween',
    duration: 0.55,
  };

  const unitCardsTransition = {
    type: 'tween',
    duration: 0.28,
  };

  const renderSiteMap = () => {
    const unit = project?.units.find((u) => u.threeDId === selectedUnitId);
    return (
      <div style={{ width: '100%' }}>
        <div>
          <FilterPane
            project={project}
            buildingId={contentPage?.buildingId}
            doShowFilter={doShowFilter}
            setDoShowFilter={setDoShowFilter}
            interactModalFrom={interactModalFrom}
            setInteractModalFrom={setInteractModalFrom}
            setSelectedUnitId={setSelectedUnitId}
            isSiteMapPage={props.isSiteMapPage}
          />
          <FilterHeader
            project={project}
            hideFilter={true}
            doShowFilter={doShowFilter}
            setDoShowFilter={setDoShowFilter}
            isSiteMapPage={props.isSiteMapPage}
          />
          <div className={classes.slideAndCanvasContainer}>
            <motion.div
              animate={isUnitCardSlideoutOpen ? 'open' : 'closed'}
              variants={{
                open: { width: '40%', opacity: 1 },
                closed: { width: 0, opacity: 0 },
              }}
              transition={unitCardsTransition}>
              <UnitCardSlideout
                project={project}
                buildingId={contentPage?.buildingId}
                unitId={selectedUnitId}
                isOpen={isUnitCardSlideoutOpen}
                toggleIsOpen={() => toggleUnitCardSlideoutPane()}
                toggleUnitDetailPane={() => toggleUnitDetailPane()}
                cardOnClickLeftButton={() => setIsUnitDetailOpen?.(true)}
                toggleIsViewStudySlideUpOpenFcn={toggleIsViewStudySlideUpOpen}
                selectedUnitId={selectedUnitId}
                setSelectedUnitId={setSelectedUnitId}
                setInteractModalFrom={setInteractModalFrom}
                isProximaLoaded={props.isProximaLoaded}
                setIsProximaLoaded={props.setIsProximaLoaded}
                isSiteMapPage={props.isSiteMapPage}
                toggleIsVirtualTourOpen={toggleIsVirtualTourOpen}
              />
            </motion.div>
            <div className={classes.canvasElementsWrapper}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                  height: '54px',
                }}>
                <div className={classes.top}>
                  <motion.div
                    className={classes.closeX}
                    onClick={() => toggleUnitCardSlideoutPane()}
                    id="unit-card-slideout-toggle"
                    animate={isUnitCardSlideoutOpen ? 'open' : 'closed'}
                    transition={defaultTransition}>
                    <motion.div
                      className={classes.openIcon}
                      animate={isUnitCardSlideoutOpen ? 'open' : 'closed'}
                      variants={{
                        open: {
                          rotate: 0,
                        },
                        closed: {
                          rotate: 180,
                        },
                      }}
                      transition={defaultTransition}>
                      <ChevronRighMedium
                        fill={ThemeData.colours.primaryAccent}
                      />
                    </motion.div>
                    <motion.div className={classes.openText}>
                      {!isUnitCardSlideoutOpen && 'Show Plans'}
                    </motion.div>
                  </motion.div>
                </div>
              </div>
            </div>
            <div className={classes.canvasWrapper}>
              {!props.isUnitDetailOpen && (
                <SiteMap
                  project={project}
                  buildingId={contentPage?.buildingId}
                  selectedUnitId={selectedUnitId}
                  setSelectedUnitId={setSelectedUnitId}
                  unitCustomIdsFiltered={unitCustomIdsFiltered}
                  setUnitCustomIdsFiltered={setUnitCustomIdsFiltered}
                  siteMapWidget={true}
                  interactModalFrom={interactModalFrom}
                  setInteractModalFrom={setInteractModalFrom}
                  siteMapWidth={siteMapWidth}
                  browserHeight={browserHeight}
                  isUnitCardSlideoutOpen={isUnitCardSlideoutOpen}
                  setIsUnitCardSlideoutOpenToOpen={
                    setIsUnitCardSlideoutOpenToOpen
                  }
                  toggleUnitCardsSlideoutOpen={() =>
                    toggleUnitCardSlideoutPane()
                  }
                  isUnitDetailOpen={props.isUnitDetailOpen}
                />
              )}
            </div>
          </div>
        </div>
        <ViewStudy
          project={project}
          isOpen={isViewStudySlideUpOpen}
          toggleViewStudyFcn={toggleIsViewStudySlideUpOpen}
          viewUrl={unit?.viewStudyUrl && unit.viewStudyUrl}
        />
        <VirtualTour
          project={project}
          isOpen={isVirtualTourOpen}
          src={unit?.virtualTourUrl && unit.virtualTourUrl}
          toggleVirtualTourFcn={() => toggleIsVirtualTourOpen()}
          doUseHeader={false}
        />
      </div>
    );
  };

  const customFunctionContent = useMemo(() => {
    let viewStudyUrl = project.settings?.viewStudyUrl
      ? project.settings.viewStudyUrl
      : null;
    let virtualTourUrl = project.settings?.threeDUrl
      ? project.settings.threeDUrl
      : null;
    let alternativeUrl = null;
    let newTextMediaPairs = [];
    if (
      contentPage &&
      contentPage.customFunction ===
      ConstantData.contentPageCustomFunctions.enjoyTheView &&
      contentPage.customUrl
    ) {
      viewStudyUrl = contentPage.customUrl;
    } else if (
      contentPage &&
      contentPage.customFunction ===
      ConstantData.contentPageCustomFunctions.virtualTour &&
      contentPage.customUrl
    ) {
      virtualTourUrl = contentPage.customUrl;
    } else if (
      contentPage &&
      contentPage.customFunction ===
      ConstantData.contentPageCustomFunctions.externalLink &&
      contentPage.customUrl
    ) {
      virtualTourUrl = contentPage.customUrl;
    } else if (
      contentPage &&
      contentPage.customFunction ===
      ConstantData.contentPageCustomFunctions.orange3dModel &&
      contentPage.customUrl
    ) {
      alternativeUrl = contentPage.customUrl;
    } else if (
      contentPage &&
      contentPage.customFunction ===
      ConstantData.contentPageCustomFunctions.colourSchemes
    ) {
      newTextMediaPairs = filterAndGroupBySubCategory(textMediaPairs);
    }
    return {
      [ConstantData.contentPageCustomFunctions.craftPartners]: (
        <>
          <div className={classes.contentPane}>
            <div className={classes.contentContainer}>
              <h4 className={`${classes.contentArea} ${classes.sectionName}`}>
                {currentSection && currentSection.name.toUpperCase()}
              </h4>
              <h2 className={`${classes.contentArea} ${classes.title}`}>
                {contentPage && contentPage.title}
              </h2>
              <div className={`${classes.contentArea} ${classes.content}`}>
                <div>
                  <div className={classes.textBox}>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                      {currentSchemeMedia !== null
                        ? currentSchemeMedia.text
                        : ''}
                    </ReactMarkdown>
                  </div>
                  <div className={classes.sectionCategories}>
                    <h3 className={classes.subTitle}>ANDlight</h3>
                    {textMediaPairs &&
                      textMediaPairs.slice(0, 4).map((image, idx) => (
                        <span key={idx}>
                          <ButtonOutline
                            ThemeData={ThemeData}
                            border={'0px'}
                            alignItems={'left'}
                            justifyContent={'left'}
                            hoverBackgroundColour={
                              ThemeData.colours.carvenWarmGrey
                            }
                            className={
                              activeButton === image.colour
                                ? `${classes.activeButton}`
                                : ''
                            }
                            key={idx}
                            onClick={() => getSelectedScheme(image.id, idx)}>
                            {image.colour}
                          </ButtonOutline>
                        </span>
                      ))}
                    <h3 className={classes.subTitle}>Phantom Hands</h3>
                    {textMediaPairs &&
                      textMediaPairs.slice(4, 6).map((image, idx) => (
                        <span key={idx}>
                          <ButtonOutline
                            ThemeData={ThemeData}
                            border={'0px'}
                            alignItems={'left'}
                            justifyContent={'left'}
                            hoverBackgroundColour={
                              ThemeData.colours.carvenWarmGrey
                            }
                            className={
                              activeButton === image.colour
                                ? `${classes.activeButton}`
                                : ''
                            }
                            key={idx}
                            onClick={() => getSelectedScheme(image.id, idx)}>
                            {image.colour}
                          </ButtonOutline>
                        </span>
                      ))}
                    <h3 className={classes.subTitle}>Lock & Mortice</h3>
                    {textMediaPairs &&
                      textMediaPairs.slice(6, 8).map((image, idx) => (
                        <span key={idx}>
                          <ButtonOutline
                            ThemeData={ThemeData}
                            border={'0px'}
                            alignItems={'left'}
                            justifyContent={'left'}
                            hoverBackgroundColour={
                              ThemeData.colours.carvenWarmGrey
                            }
                            className={
                              activeButton === image.colour
                                ? `${classes.activeButton}`
                                : ''
                            }
                            key={idx}
                            onClick={() => getSelectedScheme(image.id, idx)}>
                            {image.colour}
                          </ButtonOutline>
                        </span>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Fade in={fadeMedia}>
            {renderContentPageMediaPane(
              currentSchemeMedia && currentSchemeMedia.photo,
            )}
          </Fade>
        </>
      ),
      [ConstantData.contentPageCustomFunctions.homeAmenityFeatures]: (
        <>
          <div className={classes.contentPane}>
            <div className={classes.contentContainer}>
              <h4 className={`${classes.contentArea} ${classes.sectionName}`}>
                {currentSection && currentSection.name.toUpperCase()}
              </h4>
              <h2 className={`${classes.contentArea} ${classes.title}`}>
                {contentPage && contentPage.title}
              </h2>
              <div className={`${classes.contentArea} ${classes.content}`}>
                <div>
                  <div className={classes.textBox}>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                      {currentSchemeMedia !== null
                        ? currentSchemeMedia.content
                        : ''}
                    </ReactMarkdown>
                  </div>
                  <div className={classes.sectionCategories}>
                    <h3 className={classes.subTitle}>Interior</h3>
                    {textMediaPairs &&
                      textMediaPairs.slice(0, 7).map((image, idx) => (
                        <span key={idx}>
                          <ButtonOutline
                            ThemeData={ThemeData}
                            border={'0px'}
                            alignItems={'left'}
                            justifyContent={'left'}
                            hoverBackgroundColour={
                              ThemeData.colours.carvenWarmGrey
                            }
                            className={
                              activeButton === image.colour
                                ? `${classes.activeButton}`
                                : ''
                            }
                            key={idx}
                            onClick={() => getSelectedScheme(image.id, idx)}>
                            {image.colour}
                          </ButtonOutline>
                        </span>
                      ))}
                    <h3 className={classes.subTitle}>Amenities</h3>
                    {textMediaPairs &&
                      textMediaPairs.slice(7, 11).map((image, idx) => (
                        <span key={idx}>
                          <ButtonOutline
                            ThemeData={ThemeData}
                            border={'0px'}
                            alignItems={'left'}
                            justifyContent={'left'}
                            hoverBackgroundColour={
                              ThemeData.colours.carvenWarmGrey
                            }
                            className={
                              activeButton === image.colour
                                ? `${classes.activeButton}`
                                : ''
                            }
                            key={idx}
                            onClick={() => getSelectedScheme(image.id, idx)}>
                            {image.colour}
                          </ButtonOutline>
                        </span>
                      ))}
                    <h3 className={classes.subTitle}>Exterior</h3>
                    {textMediaPairs &&
                      textMediaPairs.slice(11, 16).map((image, idx) => (
                        <span key={idx}>
                          <ButtonOutline
                            ThemeData={ThemeData}
                            border={'0px'}
                            alignItems={'left'}
                            justifyContent={'left'}
                            hoverBackgroundColour={
                              ThemeData.colours.carvenWarmGrey
                            }
                            className={
                              activeButton === image.colour
                                ? `${classes.activeButton}`
                                : ''
                            }
                            key={idx}
                            onClick={() => getSelectedScheme(image.id, idx)}>
                            {image.colour}
                          </ButtonOutline>
                        </span>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Fade in={fadeMedia}>
            {renderContentPageMediaPane(
              currentSchemeMedia && currentSchemeMedia.photo,
            )}
          </Fade>
        </>
      ),
      [ConstantData.contentPageCustomFunctions.colourSchemes]: (
        <>
          <div className={classes.contentPane}>
            <div className={`${classes.contentContainer}`}>
              <div>
                <h4 className={`${classes.contentArea} ${classes.sectionName}`}>
                  {currentSection && currentSection.name.toUpperCase()}
                </h4>
                <h2 className={`${classes.contentArea} ${classes.title}`}>
                  {contentPage && contentPage.title}
                </h2>
                <div
                  className={`${classes.textBox} ${classes.content} ${classes.scrollSection}`}>
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {currentSchemeMedia !== null
                      ? currentSchemeMedia.content
                      : ''}
                  </ReactMarkdown>
                </div>
              </div>

              <div style={{ paddingTop: '20px' }}>
                <div
                  className={`${classes.sectionCategories} ${classes.scrollSection}`}>
                  {newTextMediaPairs &&
                    newTextMediaPairs.map((item, idx) => (
                      <React.Fragment key={idx}>
                        <h3 className={classes.subTitle}>{item.subCategory}</h3>
                        {item.objects.length > 0 &&
                          item.objects.map((obj, idx) => (
                            <ButtonOutline
                              ThemeData={ThemeData}
                              border={'0px'}
                              alignItems={'left'}
                              justifyContent={'left'}
                              hoverTextColour={
                                props.isPortraitMode
                                  ? ThemeData.colours.vibrantGreen
                                  : ''
                              }
                              className={`${classes.categoryName} ${
                                activeButton === obj.id
                                  ? classes.activeButton
                                  : ''
                              }`}
                              key={idx}
                              onClick={() => getSelectedScheme(obj.id, idx)}>
                              {obj.title}
                            </ButtonOutline>
                          ))}
                      </React.Fragment>
                    ))}
                </div>
              </div>
            </div>
          </div>
          {(currentSchemeMedia || textMediaPairs) && (
            <Fade in={fadeMedia}>
              {renderContentPageMediaPane(
                currentSchemeMedia
                  ? currentSchemeMedia.photo
                  : textMediaPairs[0].photo,
              )}
            </Fade>
          )}
        </>
      ),
      [ConstantData.contentPageCustomFunctions.floorplans]: (
        <div className={classes.floorplans}>
          <Floorplans
            {...unitFcns}
            project={project}
            isViewStudySlideUpOpen={isFloorplansViewStudySlideUpOpen}
            toggleIsViewStudySlideUpOpen={
              toggleIsFloorplansViewStudySlideUpOpen
            }
            isVirtualTourOpen={isFloorplansVirtualTourOpen}
            toggleIsVirtualTourOpen={toggleIsFloorplansVirtualTourOpen}
            setSelectedUnitId={props.setSelectedUnitId}
            setSelectedFloorplanId={props.setSelectedFloorplanId}
            viewUrl={
              project.settings?.viewStudyUrl && project.settings.viewStudyUrl
            }
            isSiteMapPage={props.isSiteMapPage}
          />
        </div>
      ),
      [ConstantData.contentPageCustomFunctions.virtualTour]: (
        <VirtualTour
          project={project}
          isOpen={true}
          src={virtualTourUrl && virtualTourUrl}
          toggleVirtualTourFcn={() => history.goBack()}
        />
      ),
      [ConstantData.contentPageCustomFunctions.externalLink]: (
        <ExternalLink
          project={project}
          isOpen={true}
          src={virtualTourUrl && virtualTourUrl}
          toggleVirtualTourFcn={() => history.goBack()}
        />
      ),
      [ConstantData.contentPageCustomFunctions.enjoyTheView]: (
        <ViewStudy
          project={project}
          isOpen={true}
          toggleViewStudyFcn={toggleIsViewStudySlideUpOpen}
          viewUrl={viewStudyUrl && viewStudyUrl}
          backLocation={props.sectionSlug}
        />
      ),
      [ConstantData.contentPageCustomFunctions.externalLink]: (
        <ExternalLink
          project={project}
          isOpen={true}
          src={virtualTourUrl && virtualTourUrl}
          toggleVirtualTourFcn={() => history.goBack()}
        />
      ),
      [ConstantData.contentPageCustomFunctions.orange3dModel]: (
        <ViewStudy
          project={project}
          isOpen={true}
          toggleViewStudyFcn={toggleIsViewStudySlideUpOpen}
          viewUrl={alternativeUrl && alternativeUrl}
          backLocation={props.sectionSlug}
        />
      ),
      [ConstantData.contentPageCustomFunctions.galleryContentPage]: (
        <div className={classes.gallery}>
          <PhotoGallery
            photos={galleryPhotos}
            direction="row"
            columns={3}
            margin={15}
          />
        </div>
      ),
    };
  }, [ConstantData, currentSection, contentPage, project, unitFcns]);

  // Pass selected colour in to function to set current scheme
  const getSelectedScheme = (id) => {
    const scheme = textMediaPairs.find((image) => image.id === id);
    setActiveButton(id);
    setFadeMedia(false);
    setTimeout(() => {
      setCurrentSchemeMedia(scheme);
      setFadeMedia(true);
    }, 100);
  };
  const handleBackButtonClick = () => {
    setIsBack(false);
    if (props.toggleSlideUpIsOpen) {
      props.toggleSlideUpIsOpen();
      //props.setIsContentPageCarouselOpen(false)
    } else if (props.isUnitDetailOpen) {
      props.setIsUnitDetailOpen?.(false);
      // props.setIsContentPageCarouselOpen(false)
    } else if (isFloorplansViewStudySlideUpOpen) {
      setIsFloorplansViewStudySlideUpOpenToClosed();
      // props.setIsContentPageCarouselOpen(false)
    } else if (isFloorplansVirtualTourOpen) {
      setIsFloorplansVirtualTourOpenToClosed();
      // props.setIsContentPageCarouselOpen(false)
    } else if (props.isSiteMapPage) {
      if (isViewStudySlideUpOpen) {
        setIsViewStudySlideUpOpenToClosed();
        // props.setIsContentPageCarouselOpen(false)
      } else if (isVirtualTourOpen) {
        setIsVirtualTourOpenToClosed();
        // props.setIsContentPageCarouselOpen(false)
      } else {
        props.setIsSiteMapPage(false);
      }
    } else {
      // Перенаправление на backLocation
      history.push(backLocation);
    }
  };
  useEffect(() => {
    if (isOpenSavedHomes) props.toggleIsSavedHomesSlideoutOpen();
  }, [isOpenSavedHomes]);

  useEffect(() => {
    if (isBack) handleBackButtonClick();
  }, [isBack]);

  // useEffect for setting default media image on page load
  useEffect(() => {
    setFadeMedia(true);
    if (textMediaPairs && textMediaPairs.length > 0) {
      setCurrentSchemeMedia(textMediaPairs[0]);
    }
  }, [textMediaPairs]);

  if (!project || !contentPage) return <div></div>;

  return (
    <>
      <motion.div
        className={classes.wrapper}
        id={contentPage.id}
        initial={{ y: '100vh' }}
        animate={{ y: 0 }}
        exit={{ y: '100vh' }}
        transition={{ type: 'tween', duration: 0.5 }}>
        {!isOpenNavBar && (
          <HeaderComponents
            headerContext={HeaderContextData.contentPageHeaderContext}
            setIsUnitDetailOpen={props.setIsUnitDetailOpen}
            toggleIsSavedHomesSlideoutOpen={
              props.toggleIsSavedHomesSlideoutOpen
            }
            toggleIsRegisterOpen={props.toggleIsRegisterOpen}
            project={project}
            siteLogo={siteLogo}
            noRenderSaveHomes={true}
            height={100}>
            {/* {renderBackButton()} */}
          </HeaderComponents>
        )}

        <div className={classes.contentPage}>
          {contentPage.customFunction &&
          !(doRenderInteractiveMap || doOnlyRenderPhotos) ? (
            <>
              {contentPage.customFunction === 'siteMaps'
                ? renderSiteMap()
                : customFunctionContent[contentPage.customFunction]}
            </>
          ) : (
            <>
              {!doOnlyRenderPhotos && (
                <>
                  <div
                    className={`${classes.contentPane} ${
                      doRenderInteractiveMap ? 'withMap' : ''
                    }`}>
                    <div className={classes.contentContainer}>
                      <h4
                        className={`${classes.contentArea} ${classes.sectionName}`}>
                        {currentSection.name.toUpperCase()}
                      </h4>
                      <h2 className={`${classes.contentArea} ${classes.title}`}>
                        {contentPage.title}
                      </h2>
                      <div
                        className={`${classes.contentArea} ${classes.content}`}>
                        {
                          !doOnlyRenderSingleTextContent &&
                          textMediaPairs &&
                          textMediaPairs.length > 0 ? (
                            <SwiperText
                              classes={classes}
                              textMediaPairs={textMediaPairs}
                              mediaSwiper={mediaSwiper}
                              setTextSwiper={setTextSwiper}
                            />
                          ) : (
                            <ReactMarkdown
                              components={{
                                p: ({ children }) => (
                                  <p className="mb-4">{children}</p>
                                ),
                                ol: ({ children }) => (
                                  <ol className="list-decimal list-inside ml-4">
                                    {children}
                                  </ol>
                                ),
                                ul: ({ children }) => (
                                  <ul className="list-disc list-inside ml-4">
                                    {children}
                                  </ul>
                                ),
                                li: ({ children }) => (
                                  <li className="mb-2">{children}</li>
                                ),
                                br: () => <br />,
                              }}>
                              {contentPage.content}
                            </ReactMarkdown>
                          )
                          // <ReactMarkdown rehypePlugins={[rehypeRaw]}>{contentPage.content}</ReactMarkdown>
                        }
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div
                className={`${classes.rightPane} ${classes.mapPane} ${
                  doRenderInteractiveMap ? 'doShow' : ''
                }`}>
                <Map
                  project={project}
                  doShow={doRenderInteractiveMap}
                  highlightCatPoi={highlightCatPoi}
                  setHighlightCatPoi={setHighlightCatPoi}
                  procFlyTo={procFlyTo}
                />
              </div>
              {doOnlyRenderSingleTextContent &&
              textMediaPairs &&
              textMediaPairs.length > 0 &&
              currentSchemeMedia !== null ? (
                <>
                  <Swiper
                    key={textMediaPairs.length}
                    className={classes.swiper}
                    modules={[Controller, Pagination, Navigation]}
                    onSwiper={setMediaSwiper}
                    controller={{ control: textSwiper }}
                    slidesPerView={1}
                    buttonWrapperStyle={{ marginTop: '100px' }}
                    navigation={{ clickable: true }}
                    mousewheel={true}>
                    {textMediaPairs.map((tmp, idx) => (
                      <SwiperSlide className={classes.swiperSlideImg} key={idx}>
                        {renderContentPageMediaPane(
                          tmp.photo,
                          tmp.showFullSize,
                        )}
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </>
              ) : contentPage.child &&
              contentPage.child.length === 1 &&
              contentPage.child[0].photo ? (
                renderContentPageMediaPane(
                  contentPage.child[0].photo,
                  contentPage.child[0].showFullSize ? true : false,
                )
              ) : (
                renderContentPageMediaPane(
                  contentPage.photo && contentPage.photo,
                )
              )}
            </>
          )}
        </div>

        <div className={classes.footer}>
          <FooterComponents
            project={project}
            noRenderSaveHomes={props.noRenderSaveHomes}
            toggleIsSavedHomesSlideoutOpen={() =>
              props.toggleIsSavedHomesSlideoutOpen()
            }
            setIsContentPageCarousel={props.setIsContentPageCarousel}
            isContentPage={true}
            setIsBack={setIsBack}
            isBack={isBack}
            setIsOpenSavedHomes={setIsOpenSavedHomes}
            isOpenSavedHomes={isOpenSavedHomes}
            // handleBackButtonClick={handleBackButtonClick()}
          />
        </div>
      </motion.div>
      <AccessiblityFooter project={project} />
    </>
  );
}

ContentPage.defaultProps = {
  doRenderHeader: true,
};

export default ContentPage;
