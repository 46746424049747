import React, { useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { portraitMode } from 'util/helper';
import UnitFcnContext from 'contexts/UnitFcnContext';

const useStyles = createUseStyles({
  footer: props => ({
    position: 'relative',
    height: props.isPortraitMode ? (props.isBigScreen ? 'calc(2 * var(--navigation-height))' : 'var(--navigation-height)') : props.isBigScreen ? 200 : 100,
    display: props.hidden ? 'none' : '',
    backgroundColor: props.isPortraitMode ? 'none' : props.ThemeData.colours.primaryBgTransparent,
    borderTop: props.isPortraitMode ? 0 : `1px ${props.ThemeData.colours.thinBorder} solid`,
  }),
  accessibilityButton: {
    position: 'absolute',
    bottom: 65,
    right: 20,
    width: 45,
    height: 45,
    cursor: 'pointer',
    border: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.20)',
  },
}, { name: 'Footer' });

const Footer = ({ children, ...props }) => {
  const hidden = props.hidden;
  const {
    isBigScreen
  } = useContext(UnitFcnContext);
  const classes = useStyles({ hidden, ThemeData: props.ThemeData, isPortraitMode: portraitMode(), isBigScreen });

  return (
    <>
      <footer className={classes.footer}>
        {children}
      </footer>
    </>
  );

};

export default Footer;