export const fetchGraphQL = async (
  query,
  variables,
  dataString,
  nestedString
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URI}/graphql`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiMDZiNDgwOWYtYWQyZS00MzE1LTkxMGItYWQ2ZDBlNDk4ZjQ1In0sImlhdCI6MTY4MDczMzMzM30.yxzZfDId5rR7jt7LLVWItSYbDOq8N5tT0juLC61dVUc`,
        },
        body: JSON.stringify({
          query,
          variables,
          dataString,
          nestedString,
        }),
      }
    );
    if (!response.ok) {
      console.error(`HTTP error: ${response.status}: ${response.statusText}`);
      throw new Error(`HTTP error: ${response.status}: ${response.statusText}`);
    }

    const json = await response.json();
    if (json.errors) {
      throw new Error(
        `GraphQL error: ${dataString}: ${json.errors
          .map((e) => e.message)
          .join(", ")}`
      );
    }
    const data = nestedString
      ? json.data?.[dataString][nestedString]
      : json.data?.[dataString];

    if (!data && !json.data.hasOwnProperty(dataString)) {
      throw new Error(`${dataString} is missing`);
    }
    return data;
  } catch (error) {
    throw error;
  }
};

export default fetchGraphQL;
