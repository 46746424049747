import React from 'react';

import {
  createUseStyles,
} from 'react-jss';

import {
  motion,
} from 'framer-motion';

import { useMutation } from '@apollo/client';
import QueryData from 'data/QueryData';
import useRegistration from 'hooks/useRegistration';
import DialogGeneric from './DialogGeneric';
import { portraitMode } from 'util/helper';

const useStyles = createUseStyles({
  wrapper: (props) => ({
    position: 'fixed',
    top: props.isPortraitMode ? '' : '45%',
    bottom: props.isPortraitMode ? '-50%' : '',
    left: props.isPortraitMode ? '-62%' : '50%',
    padding: 30,
    paddingLeft: 50,
    paddingRight: 50,
    color: props.isPortraitMode ? props.ThemeData.colours.black : props.ThemeData.colours.white,
    backgroundColor: props.ThemeData.colours.formBg || props.ThemeData.colours.primaryBg,
    textAlign: 'left',
    ...props.ThemeData.styles.hoverPaneBoxShadow,
  }),
  closeX: {
    position: 'absolute',
    top: 20,
    right: 20,

    cursor: 'pointer',
  },
  title: {
    fontSize: 30,
  },
  label: props => ({
    display: 'inline-block',
    marginTop: 40,

    fontFamily: props.ThemeData.fonts.titleSub,
    fontSize: 10,
    textTransform: 'uppercase',
  }),
  input: {
    width: 335,

    border: 'none',
    borderBottom: props => `1px ${props.ThemeData.colours.primaryAccent} solid`,
    color: props => props.isPortraitMode ? props.ThemeData.colours.black : props.ThemeData.colours.white,
    backgroundColor: props => props.isPortraitMode ? props.ThemeData.colours.white : (props.ThemeData.colours.formBg || props.ThemeData.colours.primaryBg),

    fontFamily: props => props.ThemeData.fonts.copy,
    fontSize: 16,

    '&:focus, &:active': {
      outline: 'none',

      border: 'none',
      borderBottom: props => `1px ${props.ThemeData.colours.primaryAccent} solid`,
    },
  },
  inputDisabled: props => ({
    width: 335,

    border: 'none',
    borderBottom: `1px gray solid`,

    color: props.ThemeData.colours.white,
    backgroundColor: props.ThemeData.colours.primaryBg,

    fontFamily: props.ThemeData.fonts.copy,
    fontSize: 16,

    '&:focus, &:active': {
      outline: 'none',

      border: 'none',
      borderBottom: `1px gray solid`,
    },
  }),
  emailInput: {
    width: 695,
  },
  firstName: {},
  lastName: {
    paddingLeft: 20,
  },
  email: {},
  registerFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  submitButton: props => ({
    marginTop: 50,
    backgroundColor: props.isPortraitMode ? props.ThemeData.colours.vibrantGreen : 'transparent',
    textTransform: 'upperCase',
    height: 60,
    padding: '0 20px',
    color: props.ThemeData.colours.white,
    borderWidth: '0.1px',
    border: `${props.isPortraitMode ? props.ThemeData.colours.vibrantGreen : props.ThemeData.colours.white} solid`,

    fontFamily: props.ThemeData.fonts.titleSub,
    fontSize: 16,
    '&:hover': {
      cursor: 'pointer',
      border: `1px ${props.ThemeData.colours.primaryAccent} solid`,
    },
  }),
  cancelButton: props => ({
    marginTop: 50,
    height: 60,
    backgroundColor: 'transparent',
    color: props.isPortraitMode ? props.ThemeData.colours.black : props.ThemeData.colours.white,
    border: `none`,
    cursor: 'pointer',
    fontFamily: props.ThemeData.fonts.titleSub,
    fontSize: 16,
    marginRight: 30,
  }),
  buttons: {
    display: 'inline-flex',
    float: 'right',
    justifyContent: 'space-between'
  },
  disclaimer: {
    fontSize: 8,
    maxWidth: '45%',
    marginTop: 50
  }
}, { name: 'SendEmail' });

function RenderFormModal (classes, project, doShow, isPortraitMode, props) {
  const { registration, setFirstName, setLastName, setEmail, clearRegistration } = useRegistration();

  const [emailPdf] = useMutation(QueryData.emailPdf);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (registration.email === '' || props.sendType.search(/brochure|allFloorPlans/) === -1) return;

    emailPdf({
      variables: {
        projectId: project.id,
        email: registration.email,
        fullName: `${registration.fName} ${registration.lName}`,
        type: props.sendType && props.sendType === 'brochure' ? 'brochure' : 'floorplans'
      }
    }).then((res) => {
      clearRegistration();
      props.toggleIsOpen();
    });
  };

  return (
    <DialogGeneric
      isDialogOpen={doShow}
      setIsDialogOpenToClosed={props.toggleIsOpen}
      ariaLabelledBy="email-dialog-title"
      ariaDescribedBy="email-dialog-confirmation-action-button"
    >
      <motion.div
        id="register-modal"
        className={classes.wrapper}
        initial={'dontShow'}
        animate={isPortraitMode ? (doShow ? 'showPortrait' : 'dontShowPortrait') : (doShow ? 'show' : 'dontShow')}
        variants={{
          show: {
            x: '-50%',
            y: '-50%',
            zIndex: 9999,
            display: 'block',
          },
          dontShow: {
            x: '100%',
            y: '-50%',
            display: 'block',
            transitionEnd: {
              zIndex: -2,
              display: 'none',
            }
          },
          showPortrait: {
            x: '100%',
            y: '-400%',
            zIndex: 9999,
            display: 'block',

          },
          dontShowPortrait: {
            x: '100%',
            y: '100%',
            display: 'block',
            transitionEnd: {
              zIndex: -2,
              display: 'none',
            }
          }
        }}
        transition={{
          duration: .5,
        }}
      >
        <div
          id={'email-dialog-title'}
          className={classes.title}>
          {props.titleText}
        </div>
        <form onSubmit={handleSubmit}>
          <label className={`${classes.label} ${classes.firstName}`}>
            First Name:<br /><br />
            <input id="register-first-name" className={classes.input} value={registration.fName} onChange={evt => setFirstName(evt.target.value)}></input>
          </label>
          <label className={`${classes.label} ${classes.lastName}`}>
            Last Name:<br /><br />
            <input id="register-last-name" className={classes.input} value={registration.lName} onChange={evt => setLastName(evt.target.value)}></input>
          </label><br />
          <label className={`${classes.label} ${classes.email}`}>
            Email:<br /><br />
            <input id="register-email" className={`${classes.input} ${classes.emailInput}`} value={registration.email} onChange={evt => setEmail(evt.target.value)}></input>
          </label><br />
          <div className={classes.registerFooter}>
            {project?.settings.disclaimer && <div className={classes.disclaimer}>
              <p>{project.settings.disclaimer}</p>
            </div>}
            <div className={classes.buttons}>
              <button
                id="cancel-button"
                type="button"
                className={classes.cancelButton}
                onClick={props.toggleIsOpen}
              >
                CANCEL
              </button>
              <button
                id="email-dialog-confirmation-action-button"
                className={classes.submitButton}
                sx={{
                  ...classes.submitButton,
                }}
                type="submit"
                value="Send Email"
                variant="outlined"
              >
                {props.buttonText}
              </button>
            </div>
          </div>
        </form>
      </motion.div>
    </DialogGeneric>
  );
}

const FormModal = ({ doShow, project, children, ...props }) => {
  const isPortraitMode = portraitMode();
  const classes = useStyles({ ThemeData: project.ThemeData, isPortraitMode });
  return RenderFormModal(classes, project, doShow, isPortraitMode, props);

};

FormModal.defaultProps = {
  titleText: 'Send PDF to email',
  buttonText: 'Send',
};

export default FormModal;