import React from 'react';
import { createUseStyles } from 'react-jss';

import {
  // motion
} from 'framer-motion';

import UnitInfoLine from './UnitInfoLine';
import ButtonCircle from 'components/ButtonCircle';
import ButtonOutline from 'components/ButtonOutline';
import xWhiteSmall from 'img/x_white_small.svg';
import UnitInfoIconList from './UnitInfoIconList';
import { portraitMode } from 'util/helper';

const useStyles = createUseStyles(
  {
    wrapper: props => ({
      position: 'relative',
      width: '201.7px',
      height: '219.854px',
      margin: props.isPortraitMode ? (props.isSaveHome ? 23 : 0) : 0,
      ...props.ThemeData.styles.hoverCard,
      borderRadius: '10px !important',

    }),
    contentContainer: props => ({
      display: 'flex',
      flexDirection: 'column',
      boxSizing: props.isPortraitMode ? '' : 'border-box',
      textAlign: 'left',
      borderRadius: '10px !important',
      marginBottom: props.isPortraitMode && props.isSaveHome ? 5 : 'none',
      boxShadow: '0px 0px 2.689px 0px rgba(0, 0, 0, 0.25)',
    }),
    hr: {
      width: '80%',
      marginTop: 5,
      marginLeft: 20,
      borderTop: props => props.isCompareHomes ? 'none' : `0.5px ${props.ThemeData.colours.blackBg20} solid`,

    },
    removeHomeButton: props => ({
      position: 'absolute',
      top: 7,
      right: 10,

    }),
    floorplanContainer: props => ({
      position: 'relative',
      display: 'flex',
      flex: (props.isPortraitMode && !props.isSaveHome) ? '' : '0 0 auto',

      width: '183.871px',
      height: '87.318px',
      marginLeft: props.isPortraitMode ? 0 : 0,
      marginRight: props.isPortraitMode ? 0 : 0,
      borderRadius: 4,
      marginBottom: props.isPortraitMode ? (props.isCompareHomes ? 10 : 5) : 0,
      justifyContent: 'center'
    }),
    floorplanImg: props => ({
      height: props.isPortraitMode ? (props.isSaveHome ? '100%' : 100) : '100%',
      width: '100%',

      objectFit: 'contain',
    }),
    planButtonsContainer: {
      marginTop: 10,
      '@media (max-width: 1200px)': {
        marginTop: 0
      }
    },
    floorKeyPlanButtons: {
      borderRadius: 50,

      '@media (min-width: 3800px)': {
        fontSize: '24px',
        height: '60px'
      },
    },
    info: {
      flex: props => props.isPortraitMode ? '0.02 0 auto' : '1 0 auto',
      padding: '7px 13px',
    },
    unitInfoPlanNameTitle: {
      marginTop: 0,

    },
    unitInfoLine: {
      color: props => props.ThemeData.colours.black,

      fontSize: 12,
      '@media (min-width: 3800px)': {
        fontSize: '36px',
        height: '70px'
      },
    },
    unitInfoIconList: {
      color: props => props.ThemeData.colours.black,

    },
    viewDetailButtonArea: {
      paddingTop: 10,
    },
    removeButton: {
      width: '100%',
      height: '31px',
      borderRadius: '0px 0px 10px 10px',
      //backgroundColor: props => props.ThemeData.colours.primaryAccent,
    },
    removeCompareButton: {
      borderRadius: '0 0 10px 10px',
      color: props => props.ThemeData.colours.white
    },
    addCompareButton: {
      borderTop: props => `1px solid ${props.ThemeData.colours.vibrantGreen}`,
      borderRadius: '0 0 10px 10px',
    },
    floorplanFrame: {
      position: 'relative',
    },
    imageScrollerLeft: {
      position: 'absolute',
      top: '50%'
    },
    imageScrollerRight: {
      position: 'absolute',
      top: '50%',
      right: 0,
    },
    floorLabel: {
      textAlign: 'center',
      fontSize: 12,
      fontWeight: 700,
    }
  },
  {
    name: 'UnitDetailCompareCardMini',
  }
);

// data == a single Unit
const UnitDetailCompareCardMini = ({ project, dataUnit, ...props }) => {
  const { ThemeData } = project;
  const isPortraitMode = portraitMode();

  if (dataUnit.__typename === 'FloorPlan') {
    dataUnit = {
      id: dataUnit.id,
      name: dataUnit.name,
      exteriorSize: dataUnit.exteriorSize,
      interiorSize: dataUnit.interiorSize,
      sizeUnit: dataUnit.sizeUnit,
      floorPlan: dataUnit,
      status: '',
      bedRooms: dataUnit.bedRooms,
      bathRooms: dataUnit.bathRooms,
      unitNumber: null,
      parking: null,
      __typename: 'FloorPlan'
    };
  }
  const classes = useStyles({ ThemeData, isPortraitMode });
  return (
    <>
      <div className={`${classes.wrapper} ${props.className}`} style={{ borderRadius: '10px' }}>
        <div className={classes.contentContainer} style={{ borderRadius: '10px' }}>
          <div className={classes.removeHomeButton}>
            <ButtonCircle
              ThemeData={project.ThemeData}
              src={xWhiteSmall}
              alt="Remove This Compare Homes"
              widthHeight={20}
              iconWidthHeight={20}
              borderColour={ThemeData.colours.white}
              textColour={ThemeData.colours.primaryAccent25}
              backgroundColour={ThemeData.colours.vibrantGreen || ThemeData.colours.primaryBg}
              hoverBackgroundColour={ThemeData.colours.vibrantGreen || ThemeData.colours.primaryBg}
              onClick={() => props.toggleSavedHome(dataUnit.id)}
            />
          </div>
          <div className={classes.info}>
            <UnitInfoLine
              project={project}
              data={dataUnit}
              className={classes.unitInfoLine}
              isPortraitMode={props.isPortraitMode}
              doShowUnitNumber={true}
              doShowHomeType={false}
              doShowBaths={false}
              doShowIntSqft={false}
              isCompareMiniCard={true}
            />
          </div>

          <div className={classes.floorplanContainer}>
            {dataUnit.floorPlan.photo &&
              <img
                className={classes.floorplanImg}
                src={dataUnit.floorPlan.photo[0].link}
                alt={dataUnit.floorPlan.name} />
            }
          </div>
          <div className={classes.hr} />
          <UnitInfoIconList
            project={project}
            className={classes.unitInfoIconList}
            dataUnit={dataUnit}
            marginTopItemsRow={25}
            doShowFacingItem={false}
            isSaveHome={props.isSaveHome}
            isCompareMiniCard={true}
          />
          <div>
            <ButtonOutline
              ThemeData={ThemeData}
              border={'none'}
              padding={'6.723px'}
              fontSize={'7.723px'}
              textTransform={'capitalize'}
              className={classes.addCompareButton}
              textColour={props.compareHomes.length < 2 ? ThemeData.colours.vibrantGreen : ThemeData.colours.clayFade}
              hoverTextColour={props.compareHomes.length < 2 ? ThemeData.colours.vibrantGreen : ThemeData.colours.clayFade}
              backgroundColour={ThemeData.colours.white}
              hoverBackgroundColour={props.compareHomes.length < 2 ? ThemeData.colours.white : ThemeData.colours.white}
              onClick={() => props.compareHomes.length < 2 && props.toggleCompareHome(dataUnit.id)}
            >
              Add to Compare
            </ButtonOutline>
          </div>
        </div>

      </div>

    </>
  );
};

export default UnitDetailCompareCardMini;
