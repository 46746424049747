import React from "react";
import { createUseStyles } from "react-jss";

import useSavedHomes from "hooks/useSavedHomes";

import ButtonGroup from "./ButtonGroup";
import ButtonOutline from "./ButtonOutline";
import UnitInfoPlanNameTitle from "components/UnitInfoPlanNameTitle";
import UnitInfoLine from "./UnitInfoLine";
import UnitInfoIconList from "./UnitInfoIconList";

import ConstantData from "data/ConstantData";
import { BookMarkButton } from "./BookMarkButton";
import { portraitMode, isAvailableStatus } from "util/helper";

const useStyles = createUseStyles(
  {
    wrapper: {
      position: "relative",
      width: "100%",
      height: "100%",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      padding: 30,
      backgroundColor: (props) =>
        props.ThemeData.colours.unitCardBg
          ? props.ThemeData.colours.unitCardBg
          : props.ThemeData.colours.cardBg,
      "& > div": {
        flex: "0 0 auto",
      },
    },
    hidden: {
      display: "none",
    },
    floorplanImage: {
      minHeight: 200,
      flex: "1 1 auto !important",

      borderBottom: (props) =>
        `1px ${props.ThemeData.colours.mainBorder} solid`,
      "& > img": {
        width: "100%",
        height: "100%",
        objectFit: "contain",
      },
    },
    "$floorplanImage, $details": {
      borderBottom: (props) =>
        `1px ${props.ThemeData.colours.mainBorder} solid`,
    },
    details: {
      position: "relative",
      minHeight: 63,
      textAlign: "left",
      "& p": {},
    },
    hr: {
      width: "100%",
      borderTop: (props) =>
        `1px ${props.ThemeData.colours.lightBorderDarkOnLight} solid`,
    },
    unitInfoPlanNameTitle: (props) => ({
      margin: 0,
      padding: 0,
      color: props.ThemeData.colours.secondaryFont,
      fontFamily: props.ThemeData.fonts.title,
      fontSize: props.isPortraitMode ? "36px" : "28px",
    }),
    unitInfoLine: (props) => ({
      marginTop: 10,
      color: props.ThemeData.colours.grey,
      fontFamily: props.ThemeData.fonts.titleSub,
      fontSize: "16px",
      letterSpacing: "0.05em",
    }),
    unitInfoIconList: {
      color: (props) =>
        props.ThemeData.colours.unitInfoDetail
          ? props.ThemeData.colours.unitInfoDetail
          : props.ThemeData.colours.secondaryFont,
    },
    unitInfoList: {},
    actionButton: {
      position: "absolute",
      top: 16,
      right: 20,
      fontSize: 20,
    },
    buttonArea: {
      padding: 25,
      paddingBottom: 0,
      margin: "0 auto",
      width: 200,
    },
    moreDetailsButtonArea: {
      borderTop: (props) =>
        `1px ${props.ThemeData.colours.eggshellTextBg} solid`,
    },
    disclaimerWrapper: {
      marginTop: 25,
    },
    disclaimer: {
      fontStyle: "italic",
      fontWeight: 100,
      textAlign: "center",
      color: "rgba(0, 0, 0, 0.75)",
      margin: 0,
    },
  },
  {
    name: "UnitDetailPane",
  }
);

function renderUnitDetailPane(
  selectedUnitId,
  dataUnit,
  classes,
  error,
  toggleUnitDetailPaneFcn,
  hooks,
  props
) {
  const {
    project: { ThemeData },
    project,
  } = props;

  const isAvailable = isAvailableStatus(dataUnit.status);

  const { disclaimerData } = ConstantData;
  const doDisplayCard = true;
  const imageUrl =
    dataUnit.floorPlan && dataUnit.floorPlan.formats?.small
      ? dataUnit.floorPlan.formats.small[0].link
      : dataUnit.floorPlan.photo[0].link;
  return (
    <div
      className={`${classes.wrapper} ${doDisplayCard ? "" : classes.hidden}`}
    >
      <div className={classes.details}>
        {dataUnit && (
          <UnitInfoPlanNameTitle
            project={props.project}
            data={dataUnit}
            fontSize={36}
            color={props.project.ThemeData.colours.unitInfoTitle}
            doShowUnitNumber={true}
            className={classes.unitInfoPlanNameTitle}
          />
        )}
        <div className={classes.unitInfoLine}>
          {dataUnit && <UnitInfoLine project={project} data={dataUnit} />}
        </div>
      </div>
      <div className={classes.floorplanImage}>
        {imageUrl && <img src={imageUrl} alt={dataUnit.floorPlan.name} />}
      </div>
      <div className={classes.hr} />
      <UnitInfoIconList
        project={props.project}
        className={classes.unitInfoIconList}
        dataUnit={dataUnit}
        marginTopItemsRow={25}
      />
      <div className={classes.hr} />
      <div className={classes.buttonArea}>
        <ButtonGroup>
          <ButtonOutline
            ThemeData={ThemeData}
            label="Details"
            textColour={ThemeData.colours.primaryFontHighestConstrast}
            backgroundColour={
              !isAvailable
                ? ThemeData.colours.grey10
                : ThemeData.colours.primaryAccent
            }
            borderColour={ThemeData.colours.grey10}
            isRounded={true}
            to="."
            onClick={() => {
              if (!isAvailable) {
                return;
              }

              if (dataUnit.__typename === "Unit") {
                props.setSelectedUnitId(dataUnit.threeDId);
                props.setSelectedFloorplanId(-1);
              } else {
                // props.setSelectedUnitId(-1)
                props.setSelectedFloorplanId(dataUnit.id);
                props.setSelectedUnitId(dataUnit.threeDId);
              }
              props.toggleIsUnitDetailOpen();
            }}
            firstInColl
          />

          <BookMarkButton
            ThemeData={props.project.ThemeData}
            hooks={hooks}
            dataUnit={dataUnit}
            height={40}
            width={30}
          />
        </ButtonGroup>
      </div>
      <div className={classes.disclaimerWrapper}>
        {/* {TODO: replace with strapi location: currently using hardcoded json file} */}
        {/* <p>{hooks.disclaimerData.data.contactInfo.disclaimer_2}</p> */}
        <p className={classes.disclaimer}>{disclaimerData.disclaimer_2}</p>
        <p className={classes.disclaimer}>{disclaimerData.disclaimer_3}</p>
      </div>
    </div>
  );
}

function UnitDetailPane({
  dataUnit,
  selectedUnitId,
  toggleUnitDetailPaneFcn,
  ...props
}) {
  const classes = useStyles({
    ThemeData: props.project.ThemeData,
    isPortraitMode: portraitMode(),
  });
  const { savedHomes, toggleSavedHome, savedHomesIncludes } = useSavedHomes();
  let hooks = {
    savedHomes,
    toggleSavedHome,
    savedHomesIncludes,
  };

  return renderUnitDetailPane(
    selectedUnitId,
    dataUnit,
    classes,
    null,
    toggleUnitDetailPaneFcn,
    hooks,
    props
  );
}

export default UnitDetailPane;
