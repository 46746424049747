import React, {
	useState,
	useCallback,
	useEffect,
	useMemo,
} from "react";

import {
	motion,
} from "framer-motion";

import {
	createUseStyles,
} from "react-jss";

import {
	// motion
} from "framer-motion";

import PhotoGallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";


import ButtonGroup from "./ButtonGroup";
import ButtonOutline from "./ButtonOutline";

import ConstantData from "data/ConstantData";

import ScrollIcon from "./ScrollIcon";


// import devLogo from "img/kindred_logo_darkFill_small.png";



const useStyles = createUseStyles(
	{
		wrapper: props => ({
			boxSizing: 'border-box',
			width: '100%',
			height: props.withPhotosOfCategoryName ? 'calc( 100vh - 100px ) ' : 'calc( 100vh - 200px )',

			padding: !props.noPadding ? 20 : 0,

			textAlign: 'left',

			overflowY: 'auto',

			...props.ThemeData.styles.thinScrollbarLightOnDark,
		}),

		scroll: {
			color: 'white',
			position: 'absolute',
			height: '48px',
			width: '48px',
			bottom: '120px',
		},

		categoryButtons: {
			padding: '5px 15px 15px',
		},

		buttonGroup: {
			justifyContent: 'flex-start',
		},

		buttonOutline: {
			borderRadius: '50px',
			padding: '15px 15px',
		}
	},
	{
		name: "Gallery",
	}
)


const RenderGallery = (project, classes, props) => {
	const { ThemeData } = project
	const [currentImage, setCurrentImage] = useState(0);
	const [isViewerOpen, setIsViewerOpen] = useState(false);
	const [showCategory, setShowCategory] = useState(ConstantData.galleryPhotoDefaultCategories.all);
	const [categories, setCategories] = useState([]);

	const openLightbox = useCallback((e, { photo, index }) => {
		setCurrentImage(photo.id);
		setIsViewerOpen(true);
	}, []);

	const closeLightbox = () => {
		setCurrentImage(0);
		setIsViewerOpen(false);
	};

	const photos = project.photoGallery
	const validatePhotoCategories = (photo) => {
		return photo.category
			? (photo.category.length === 0 ? false : true)
			: false
	};

	const { sectionSlug = "gallery" } = props 
	const target = sectionSlug === "gallery" ? "gallery" : "showhome"
	useEffect(() => {
		let categoryTypes = [ConstantData.galleryPhotoDefaultCategories.all];
		const photoGallery = photos.filter((p) => p.target === target)
		if (photoGallery && photoGallery.length > 0) {
			let addCategoryTypes = [...photoGallery].reduce((catBuild, photo) => {
				photo.category && photo.category.forEach(cat => {
					if (!catBuild.includes(cat))
						catBuild.push(cat);
				})
				return catBuild;
			}, []);
			categoryTypes = categoryTypes
				.concat(
					addCategoryTypes.sort((catNameA, catNameB) => catNameA.localeCompare(catNameB))
				);

			setCategories(categoryTypes)
		}
	}, [photos, target]);

	const galleryPhotos = useMemo(() => {
		let galleryPhotos = [{}];
		const photoGallery = photos.filter((p) => p.target === target)
		if (photoGallery && photoGallery.length > 0) {
			galleryPhotos =
				[...photoGallery]
					.sort((phA, phB) => phA.position - phB.position)
					.map((photo, index) => {
						const largePhoto = photo.formats.filter((f) => f.size === "large")[0] || photo
						return {
							id: index,
							src: largePhoto.link,
							width: largePhoto.width,
							height: largePhoto.height,
							category: validatePhotoCategories(photo) ? photo.category : '',
						};
					})
		}
		return galleryPhotos;
	}, [photos, target]);

	const filteredPhotos = useMemo(() => {
		let filteredPhotos = [{}]

		if (filteredPhotos) {
			filteredPhotos = galleryPhotos.filter(photo => {
				if (showCategory === ConstantData.galleryPhotoDefaultCategories.all) {
					return photo;
				} else if (photo.category.includes(showCategory))
					return photo;

				return null;
			});
		}
		return filteredPhotos;
	}, [showCategory, galleryPhotos])
	
	return (
		<div id="vertical">
			<div className={`${classes.wrapper} ${props.className}`}>
				{categories && categories.length > 1 && !props.noFilter &&
					<div className={classes.categoryButtons}>
						<ButtonGroup className={classes.buttonGroup}>
							{categories.map((category, idx) =>
								<ButtonOutline
									ThemeData={ThemeData}
									className={classes.buttonOutline}
									key={category}

									textColour={ThemeData.colours.primaryFont}
									hoverTextColour={ThemeData.colours.secondaryFont}
									// backgroundColour={'clear'}
									backgroundColour={ThemeData.colours.clear}
									borderColour={ThemeData.colours.primaryFont}
									firstInColl={idx === 0}

									isActive={showCategory === category}
									onClick={() => setShowCategory(category)}
								>
									{category}
								</ButtonOutline>
							)}
						</ButtonGroup>
					</div>
				}

				<motion.div
					initial={{opacity: 0 }}
					animate={{opacity: 1}}
					exit={{opacity: 0}}
					transition={{duration: 0.5}}
				>

					<PhotoGallery
						photos={filteredPhotos}
						onClick={openLightbox}
						direction='column'
						columns={3}
						margin={15}
					/>
					<ModalGateway>
						{isViewerOpen ? (
							<Modal onClose={closeLightbox}>
								<Carousel
									currentIndex={currentImage}
									views={
										galleryPhotos.map( x => ( {
											...x,
											key: x.src,
											caption: x.caption,
										} ) )
									}
								/>
							</Modal>
						) : null}
						</ModalGateway>
				</motion.div>
			</div>
			<ScrollIcon direction={ConstantData.scrollIconDir.vertical} />
		</div>
	);
};

const Gallery = ({ project, ...props }) => {
	const classes = useStyles({ ...props, ThemeData: project.ThemeData });
	return RenderGallery(project, classes, props);
}

Gallery.defaultProps = {
	noPadding: false,
	noFilter: false,
	doOverrideUseFullPhotoGallery: false,
	withPhotosOfCategoryName: null,
};



export default Gallery;
