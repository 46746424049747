import React from 'react';

import {
  createUseStyles,
} from 'react-jss';

import {
  motion,
} from 'framer-motion';
import { portraitMode } from 'util/helper';

const useStyles = createUseStyles(
  {
    wrapper: props => ({
      position: 'fixed',
      top: props.doUseHeader ? 0 : 'var( --header-height )',
      left: 0,
      right: 0,

      width: '100%',
      height: props.doUseHeader ? '100%' : 'calc( 100vh - var( --header-height ) )',
    }),
    genericContainer: {
      width: '100%',
      height: '100%',

      backgroundColor: props => props.ThemeData.colours.secondaryBg,
    },
  },
  {
    name: 'SlideUpGenericHolder',
  }
);
const wrapperAnimVariants = {
  open: {
    y: 0,
    zIndex: 9999,
    display: 'block',
  },
  closed: {
    y: '100vh',
    display: 'block',
    transitionEnd: {
      zIndex: -2,
      display: 'none'
    },
  },
};

function RenderSlideUpGenericHolder (project, isPortraitMode, classes, error, children, isOpen, toggleSlideUpIsOpen, src, props) {
  return (
    <motion.div
      className={classes.wrapper}
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      variants={wrapperAnimVariants}
      transition={{
        type: 'linear',
        duration: .5,
      }}
    >
      {/*props.doUseHeader && !isPortraitMode &&
				<HeaderSimple
					project={project}
					backgroundColor={ThemeData.colours.primaryHeaderBg}
					onBackClick={() => toggleSlideUpIsOpen()} />
		*/}
      <div className={classes.genericContainer}>
        {children}
      </div>
    </motion.div>
  );
}

const SlideUpGenericHolder = ({ project, children, slideUpIsOpen, toggleSlideUpIsOpen, src, ...props }) => {
  const isPortraitMode = portraitMode();
  const classes = useStyles({ ...props, isPortraitMode, ThemeData: project.ThemeData });
  return RenderSlideUpGenericHolder(project, isPortraitMode, classes, null, children, slideUpIsOpen, toggleSlideUpIsOpen, src, props);
};

SlideUpGenericHolder.defaultProps = {
  doUseHeader: false,
};

export default SlideUpGenericHolder;
