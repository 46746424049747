import { useEffect, useState } from 'react';
import { sendGoogleAnalyticsEvent } from 'util/sendGoogleAnalyticsEvent';
import { useLocation } from 'react-router-dom';

const FilterButtonInput = ({ data, setFilter, ...props }) => {
  const [isActive, setActive] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (
      props.filterSettings[props.filterType] &&
      props.filterSettings[props.filterType][props.listItemId] &&
      props.filterSettings[props.filterType][props.listItemId].doFilterByOr ===
      true
    ) {
      setActive(true);
      // setActive(!isActive);
    } else if (
      props.filterSettings[props.filterType] &&
      props.filterSettings[props.filterType][props.listItemName] &&
      props.filterSettings[props.filterType][props.listItemName]
        .doFilterByOr === true
    ) {
      setActive(true);
      // setActive(!isActive);
    } else {
      setActive(false);
      // setActive(!isActive);
    }
  }, [props, props.filterType, props.listItemId]);

  const handleToggle = (filterType, itemId, itemName) => {
    const newFilterSettings = { ...props.filterSettings };

    if (!newFilterSettings.hasOwnProperty(filterType))
      newFilterSettings[filterType] = {};

    if (
      !newFilterSettings[filterType].hasOwnProperty(
        filterType === 'floorPlans' ? itemName : itemId
      )
    )
      newFilterSettings[filterType][
        filterType === 'floorPlans' ? itemName : itemId
        ] = {
        doFilterByOr: true,
        id: itemId,
        value: filterType,
      };
    else
      newFilterSettings[props.filterType][
        props.filterType === 'floorPlans'
          ? props.listItemName
          : props.listItemId
        ].doFilterByOr =
        !newFilterSettings[props.filterType][
          props.filterType === 'floorPlans'
            ? props.listItemName
            : props.listItemId
          ].doFilterByOr;

    setFilter(newFilterSettings);
  };

  return (
    <div
      id={`filter-item-${props.name.replace(/\s+/g, '')}`}
      className={`${props.classes.filterItem} ${props.classes.unitHomeTypes} ${
        isActive && props.classes.filterActivated
      }`}
      key={props.key || props.filterObject.id}
      typeitemid={props.filterObject.id}
      onClick={() => {
        handleToggle(
          props.filterType,
          props.listItemId,
          props.filterObject.name
        );
        sendGoogleAnalyticsEvent(
          `Filter Button Click, ${props.modelType} - ${location.pathname}`,
          `User Clicks Filter - ${props.filterType}, ${props.name}`,
          `Filter - ${props.filterType} - ${props.name}`,
          `${props.filterType} - ${props.name}`
        );
      }}
    >
      <p>{props.name.toUpperCase()}</p>
    </div>
  );
};

export default FilterButtonInput;
