import React, {
  // useState,
  // useRef,
} from 'react';

import {
  createUseStyles,
} from 'react-jss';

import {
  motion,
} from 'framer-motion';

import HeaderSimple from './HeaderSimple';
import navArrowLeftDark from '../img/nav_arrow_left_dark.svg';
import { portraitMode } from 'util/helper';

const useStyles = createUseStyles(
  {
    wrapper: props => ({
      position: 'fixed',
      top: props.doUseHeader ? 0 : props.isPortraitMode ? 'var(--portrait-header-height)' : '100px',
      left: 0,
      right: 0,

      width: '100%',
      // height: '100%',
      height: props.isPortraitMode ? 'calc( 100vh - var(--portrait-header-height) - var(--header-height) )' : 'calc( 100vh - 100px )',

    }),
    header: {
      position: 'absolute',
      top: 0,
      left: 0,

      width: '100%',
      height: 100,

      // backgroundColor: ThemeData.colours.eggshellTextBg,
      backgroundColor: props => props.ThemeData.colours.primaryHeaderBg,

      zIndex: 2,
    },
    backButton: {
      width: 100,
      height: 100,

      cursor: 'pointer',

      color: '#FFF',

      background: 'no-repeat center',
      backgroundImage: `url( ${navArrowLeftDark} )`,
    },
    virtualTour: {
      width: '100%',
      height: '100%',
      // paddingTop: 100,

      backgroundColor: props => props.ThemeData.colours.white,
    },
  },
  {
    name: 'VirtualTour',
  },
);

const wrapperAnimVariants = {
  open: {
    y: 0,
    zIndex: 9999,
    display: 'block',
  },
  closed: {
    y: '100vh',
    display: 'block',
    transitionEnd: {
      zIndex: -2,
      display: 'none',
    },
  },
};

function RenderVirtualTour (project, isPortraitMode, classes, error, isOpen, toggleVirtualTourFcn, src, props) {
  const { ThemeData } = project;
  const siteLogo = project && project.logo.length > 0 ? project.logo[0].link : null;
  return (
    <div style={{ position: 'relative' }}>
      <motion.div
        id="take-the-tour"
        className={classes.wrapper}
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        variants={wrapperAnimVariants}
        transition={{
          type: 'linear',
          duration: .5,
        }}
      >
        <HeaderSimple
          project={project}
          backgroundColor={ThemeData.colours.primaryHeaderBg}
          onBackClick={() => toggleVirtualTourFcn()}
        />
        <div className={classes.virtualTour}>
          <iframe
            title="Virtual Tour"
            width="100%"
            height="100%"
            frameBorder="0"
            src={src}
          />
        </div>
      </motion.div>
    </div>
  );
}

const VirtualTour = ({ isOpen, project, toggleVirtualTourFcn, src, ...props }) => {
  const isPortraitMode = portraitMode();
  const classes = useStyles({ ...props, ThemeData: project.ThemeData, isPortraitMode });

  return RenderVirtualTour(project, isPortraitMode, classes, null, isOpen, toggleVirtualTourFcn, src, props);

};

VirtualTour.defaultProps = {
  doUseHeader: false,
};

export default VirtualTour;