import { createUseStyles } from "react-jss";
import {
  convertBedrooms,
  convertBathrooms,
  capitalizeOptions,
  showTotalSpace,
} from "../util/helper";
import ConstantData from "data/ConstantData";
import { portraitMode, getStatusClass } from "../util/helper";
const useStyles = createUseStyles(
  {
    wrapper: (props) => ({
      textAlign: "left",
    }),
    availabilityText: () => ({
      padding: "4px 10px",
      borderRadius: "10px",
      fontSize: "12px",
      fontWeight: 600,
      color: "white",
      textTransform: "uppercase",
      marginBottom: "5px",
      marginTop: "5px",
      height: "min-content",
      width: "fit-content",
    }),
    soldTag: {
      backgroundColor: "#d24d2f",
    },

    unavailableTag: {
      backgroundColor: "#d24d2f",
    },

    inProgressTag: {
      backgroundColor: "#d24d2f",
    },

    finalUnitTag: {
      backgroundColor: "#6c7079",
    },

    finalHomeTag: {
      backgroundColor: "#6c7079",
    },
    info: {
      textTransform: "capitalize",
    },
    separator: (props) => ({
      color: props.ThemeData.colours.branding,
      fontWeight: 700,
      fontSize: 15,
      lineHeight: "12px",
    }),
  },
  {
    name: "FloorplanDetail",
  }
);

const FloorplanDetail = ({ project, data, ThemeData, ...props }) => {
  const classes = useStyles({ ThemeData, ...props });
  const isIndustrial =
    project.projectType === ConstantData.projectType.industrial;
  const isPortraitMode = portraitMode();
  const isFloorplan = data.__typename === "FloorPlan";
  const showAvailability = project.settings.showAvailability;

  return (
    <div className={`${classes.wrapper}`}>
      {showAvailability && props.status && (
        <span
          className={`${classes.availabilityText} ${
            classes[getStatusClass(props.status)]
          }`}
        >
          {props.status.replace(/([A-Z])/g, " $1").toUpperCase()}
        </span>
      )}
      <div className={`${props.titleClassName && props.titleClassName}`}>
        {data.name}
      </div>
      <div
        className={`${props.infoClassName && props.infoClassName} ${
          classes.info
        }`}
      >
        {isIndustrial ? (
          <>
            {capitalizeOptions(data.floorPlanType)}
            {props.separator === "dots" && (
              <>
                {data && (
                  <>
                    <span className={classes.separator}> &#x2022;</span>{" "}
                    {showTotalSpace({ floorPlan: data }, isFloorplan)}{" "}
                    {data.sizeUnit}
                  </>
                )}
                {!isPortraitMode && data.mezzanineSize && (
                  <>
                    {" "}
                    <span className={classes.separator}> &#x2022;</span>{" "}
                    {data.mezzanineSize} {data.sizeUnit} mez.
                  </>
                )}
              </>
            )}
            {props.separator !== "dots" && (
              <>
                {data.interiorSize &&
                  ` | ${data.interiorSize} ${data.sizeUnit}`}
                {props.doShowExtSqft &&
                  data.exteriorSize &&
                  ` | ${data.exteriorSize} ext. ${data.sizeUnit}`}
                {data.mezzanineSize &&
                  ` | ${data.mezzanineSize} ${data.sizeUnit}`}{" "}
                mez.
              </>
            )}
          </>
        ) : (
          <>
            {data.bedRooms !== null
              ? `${convertBedrooms(
                  data.bedRooms,
                  data.juniorBedRooms,
                  data.studio,
                  data.flex
                )}`
              : ""}
            {props.separator === "dots" && (
              <>
                {data.bathRooms && (
                  <span> &#x2022; {convertBathrooms(data.bathRooms)}</span>
                )}
                {data && (
                  <span>
                    {" "}
                    &#x2022; {showTotalSpace(
                      { floorPlan: data },
                      isFloorplan
                    )}{" "}
                    {data.sizeUnit}
                  </span>
                )}
              </>
            )}
            {props.separator !== "dots" && (
              <>
                {data.bathRooms && ` | ${convertBathrooms(data.bathRooms)}`}
                {data.interiorSize &&
                  ` | ${data.interiorSize} ${data.sizeUnit}`}
                {props.doShowExtSqft &&
                  data.exteriorSize &&
                  ` | ${data.exteriorSize} ext. ${data.sizeUnit}`}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

FloorplanDetail.defaultProps = {
  doShowHomeType: true,
  doShowBaths: true,
  doShowIntSqft: true,
  doShowExtSqft: false,
};

export default FloorplanDetail;
