import React, { useState, useContext, useEffect } from "react";

import { createUseStyles } from "react-jss";

import { motion } from "framer-motion";

import ConstantData from "data/ConstantData";
import FloorplanCardsParent from "./FloorplanCardsParent";
import FloorplanDetailCardGeneric from "./FloorplanDetailCardGeneric";
import FloorplanDetailTableItem from "./FloorplanDetailTableItem";
import preventClickEventPassthrough from "util/preventClickEventsPassthrough";
import UnitFcnContext from "contexts/UnitFcnContext";
import FloorplanDetail from "./FloorplanDetail";
import FloorplanPhoto from "./FloorplanPhoto";

const useStyles = createUseStyles(
  {
    wrapper: (props) => ({
      overflow: "hidden",
      width: "100%",
      height: "100%",
      backgroundColor: props.ThemeData.colours.white,
      ...props.ThemeData.typography.content,
      color: props.ThemeData.colours.black,

      "--carousel-height": props.hideFloorPlanFilter ? "1014px" : "978px",
      "--carousel-bottom-height": "508px",
    }),
    contentContainer: {
      display: "flex",
      position: "relative",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      // border: '1px solid red',
    },
    bottom: (props) => ({
      display: "flex",
      height: "var(--carousel-bottom-height)",
      paddingBottom: props.hideFloorPlanFilter ? 38 : 0,
      width: "100%",
      flexDirection: "column",
      overflow: "hidden",
    }),
    viewPort: (props) => ({
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      height: "calc(100% - var(--carousel-bottom-height) - 66px)",
      minHeight: 950,
      margin: "38px 40px 28px 40px",
      padding: "20px 40px",
      overflow: "hidden",
      border: `2px solid ${props.ThemeData.colours.branding}`,

      ...props.ThemeData.typography.boxShadow,
      "@media (max-height: 1850px)": {
        minHeight: 856,
      },
    }),
    carousel: (props) => ({
      position: "relative",
      display: "flex",
      height: "100%",
      paddingLeft: 30,
    }),
    title: (props) => ({
      ...props.ThemeData.typography.contentPageTitle,
      fontSize: 30,
      lineHeight: "52px",
    }),
    infoLine: (props) => ({
      ...props.ThemeData.typography.content,
      color: props.ThemeData.colours.grey,
      letterSpacing: 0,
      fontSize: 20,
      lineHeight: "46px",
    }),
  },
  {
    name: "FloorplansGeneric",
  }
);

const RenderFloorplansGeneric = (classes, props) => {
  const { project } = props;
  const [doShowFilter, setDoShowFilter] = useState(false);
  const {
    selectedFloorplanId,
    setSelectedFloorplanId,
    isUnitDetailOpen,
    setIsUnitDetailOpen,
    toggleIsUnitDetailOpen,
  } = useContext(UnitFcnContext);

  const [selectedFloorplan, setSelectedFloorplan] = useState(null);
  const [resetCarouselIndex, setResetCarouselIndex] = useState(false);
  const [floorplanFilterViewMode, setFloorplanFilterViewMode] = useState(
    ConstantData.unitFilterViewModes.card
  );

  useEffect(() => {
    if (selectedFloorplanId) {
      const floorplan = project.floorPlans.filter(
        (plan) => plan.id === selectedFloorplanId
      )[0];
      setSelectedFloorplan(floorplan);
    }
  }, [project.floorPlans, selectedFloorplanId]);

  return (
    <>
      <motion.div
        className={classes.wrapper}
        onMouseDown={(e) => preventClickEventPassthrough(e)}
        onMouseUp={(e) => preventClickEventPassthrough(e)}>
        <div className={classes.contentContainer}>
          <div className={classes.viewPort}>
            {selectedFloorplan && (
              <>
                <FloorplanDetail
                  project={project}
                  ThemeData={project.ThemeData}
                  data={selectedFloorplan}
                  titleClassName={classes.title}
                  infoClassName={classes.infoLine}
                  separator='dots'
                />
                <FloorplanPhoto
                  ThemeData={project.ThemeData}
                  data={selectedFloorplan}
                  resetCarouselIndex={resetCarouselIndex}
                  setResetCarouselIndex={setResetCarouselIndex}
                />
              </>
            )}
          </div>
          {!props.doShowFilter && (
            <div className={classes.bottom}>
              <div className={classes.carousel}>
                <FloorplanCardsParent
                  project={project}
                  doSelectFirstItem={true}
                  selectedFloorplanId={selectedFloorplanId}
                  setSelectedFloorplanId={setSelectedFloorplanId}
                  setResetCarouselIndex={setResetCarouselIndex}
                  doShowFilter={doShowFilter}
                  setDoShowFilter={setDoShowFilter}
                  addFilteredResult={props.addFilteredResult}
                  floorplanFilterViewMode={floorplanFilterViewMode}
                  setFloorplanFilterViewMode={setFloorplanFilterViewMode}>
                  {(className, dataUnit) =>
                    floorplanFilterViewMode ===
                    ConstantData.unitFilterViewModes.card ? (
                      <FloorplanDetailCardGeneric
                        project={project}
                        className={className}
                        dataFloorplan={dataUnit.floorPlan}
                        isUnitDetailOpen={isUnitDetailOpen}
                        toggleUnitDetailPaneFcn={toggleIsUnitDetailOpen}
                        onClickLeftButton={() => setIsUnitDetailOpen(true)}
                        isToAllowSelection={props.isToAlwaysAllowSelection}
                        toggleIsViewStudySlideUpOpenFcn={
                          props.toggleIsViewStudySlideUpOpenFcn
                        }
                        selectedFloorplanId={selectedFloorplanId}
                        setSelectedFloorplanId={setSelectedFloorplanId}
                        setSelectedUnitId={props.setSelectedUnitId}
                      />
                    ) : (
                      <FloorplanDetailTableItem
                        project={project}
                        className={className}
                        dataFloorplan={dataUnit.floorPlan}
                        isUnitDetailOpen={isUnitDetailOpen}
                        toggleUnitDetailPaneFcn={toggleIsUnitDetailOpen}
                        onClickLeftButton={() => setIsUnitDetailOpen(true)}
                        isToAllowSelection={props.isToAlwaysAllowSelection}
                        toggleIsViewStudySlideUpOpenFcn={
                          props.toggleIsViewStudySlideUpOpenFcn
                        }
                        selectedFloorplanId={selectedFloorplanId}
                        setSelectedFloorplanId={setSelectedFloorplanId}
                        setSelectedUnitId={props.setSelectedUnitId}
                      />
                    )
                  }
                </FloorplanCardsParent>
              </div>
            </div>
          )}
        </div>
      </motion.div>
    </>
  );
};

const FloorplansGeneric = ({ ...props }) => {
  const classes = useStyles({ ThemeData: props.project.ThemeData, ...props });

  return RenderFloorplansGeneric(classes, props);
};

FloorplansGeneric.defaultProps = {
  isToAlwaysAllowSelection: false,
  isToShowDualPaneForTableDisplay: true,
  isToUseEmbeddedVirtualTour: true,
  overrideBackgroundColor: null,
};

export default FloorplansGeneric;
