import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { createUseStyles } from 'react-jss';

import HeaderContextData from '../data/HeaderContextData';
import SavedHomesTitle from './SavedHomesTitle';
import { portraitMode } from 'util/helper';
import UnitFcnContext from 'contexts/UnitFcnContext';
import { useMediaQuery } from 'react-responsive';

const useStyles = createUseStyles(
  {
    header: (props) => ({
      display: 'flex',
      position: 'relative',
      zIndex: 10,
      justifyContent: 'space-between',
      alignItems: 'center',
      boxSizing: 'border-box',
      height: props.isPortraitMode
        ? props.isBigScreen
          ? 'calc(2*var(--header-height))'
          : 'var(--header-height)'
        : props.isBigScreen
          ? 200
          : props.isMobile
            ? 60
            : 100,

      // background: props.background ? props.background : props.backgroundColor,
      background: props.headerBg
        ? props.headerBg
        : props.ThemeData.colours.headerBg ||
        (props.background ? props.background : props.backgroundColor),
      borderBottom: props.isPortraitMode
        ? 0
        : props.ThemeData.colours.paneBorders,
      borderTop: props.isPortraitMode ? props.ThemeData.colours.paneBorders : 0,
      backdropFilter: 'blur(1px)',
    }),
    headerSection: (props) => ({
      flex: props.isMobile ? '1 0 60px' : '1 0 auto',
      // height: 'var(--header-height)',
      height: props.isBigScreen
        ? 'calc(2*var(--header-height))'
        : props.isMobile
          ? 60
          : 'var(--header-height)',
      width: props.isPortraitMode ? '33%' : 'auto',
    }),
    headerSectionLeft: (props) => ({
      flex: '1 0 auto',
      // height: 'var(--header-height)',
      height: props.isBigScreen
        ? 'calc(2*var(--header-height))'
        : props.isMobile
          ? 60
          : 'var(--header-height)',
      width: props.isPortraitMode ? '33%' : props.isMobile ? 60 : 176,
    }),
    headerLeft: (props) => ({
      display: 'flex',
      alignItems: 'center',
    }),
    headerMiddle: (props) => ({
      margin: '0 auto',
      display: props.display,
      maxHeight: props.isBigScreen
        ? 'calc(2*var(--header-height))'
        : 'var(--header-height)',
      background: 'no-repeat center',
      backgroundImage: `url( ${props.siteLogo} )`,
      backgroundSize: 'contain',
      cursor: 'pointer',
    }),
    headerRight: (props) => ({
      display: 'flex',
      alignItems: '',
      justifyContent: 'flex-end',

      ...props.ThemeData.typography.nameplate,
    }),
    headerRightItem: (props) => ({
      height: props.isBigScreen
        ? 'calc( 2*(var( --header-height ) - 1px ))'
        : 'calc( var( --header-height ) - 1px )',
      // border: '2px solid blue',
      lineHeight: 'var( --header-height )',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: props.isPortraitMode ? '-2.5vw' : '',
      '& > p': {
        margin: 0,
      },
    }),
    savedHomes: (props) => ({
      // marginLeft:'50px',
      flex: '0 0 auto',
      // display: props.display,
      display: 'flex',
      alignItems: 'center',
      width: props.isBigScreen ? 350 : 175,
      fontSize: props.isBigScreen ? 25 : '',
      marginRight: props.isPortraitMode ? 43 : 50,
      cursor: 'pointer',
    }),
    register: (props) => ({
      display: props.isMobile ? 'none' : props.display,
      flex: '0 0 auto',

      width: props.isBigScreen ? 350 : 175,
      lineHeight: '100px',
      fontSize: props.isBigScreen ? 25 : '',

      ...props.ThemeData.ux.fillToRight,

      borderLeft: `1px ${props.ThemeData.colours.white50} solid`,

      cursor: 'pointer',
      color: 'inherit',

      '& p': {
        margin: 0,
        marginTop: props.isBigScreen ? 45 : '',
      },
    }),
  },
  { name: 'HeaderComponents' },
);

function HeaderComponents ({
  project,
  children,
  siteLogo,
  headerContext,
  ...props
}) {
  const {
    backgroundColor = project.ThemeData.colours.vibrantDarkBlue,
    display,
    height,
  } = props;

  const { isBigScreen } = useContext(UnitFcnContext);

  const isMobile = useMediaQuery({
    query: '(min-width: 360px) and (max-width: 767px)',
  });

  const isTablet = useMediaQuery({
    query: '(min-width: 768px) and (max-width: 1025px)',
  });

  const isTabletLandscape = useMediaQuery({
    query:
      '(min-width: 1023px) and (max-width: 1367px) and (min-height: 1023px) and (max-height: 1367px)',
  });

  const isPortraitMode = portraitMode();

  const classes = useStyles({
    isMobile,
    isBigScreen,
    isPortraitMode,
    backgroundColor,
    display,
    siteLogo,
    height,
    ThemeData: project.ThemeData,
    headerBg: props.headerBg,
  });

  return (
    <>
      <div className={classes.header}>
        <div className={`${classes.headerSectionLeft} ${classes.headerLeft}`}>
          {children}
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}>
          {!isPortraitMode ? (
            <Link
              to="/"
              onClick={() =>
                props.setIsUnitDetailOpen && props.setIsUnitDetailOpen(false)
              }
              className={`${classes.headerSection} ${classes.headerMiddle}`}></Link>
          ) : (
            <>
              <Link
                to="/"
                onClick={() =>
                  props.setIsUnitDetailOpen && props.setIsUnitDetailOpen(false)
                }
                className={`${classes.headerSection} ${classes.headerMiddle}`}></Link>
            </>
          )}
        </div>

        <div
          className={`${classes.headerSection} ${classes.headerRight} ${
            headerContext &&
            headerContext === HeaderContextData.mainMenuHeaderContext
              ? 'mainMenuHeader'
              : ''
          }`}>
          <div style={{ width: 175 }}>
            {!isPortraitMode && project.settings?.showRegisterButton && (
              project.settings.registerUrl ? (
                <a
                  className={`${classes.register} ${classes.headerRightItem}`}
                  href={project.settings.registerUrl}
                  target="_blank"
                  rel="noreferrer">
                  <p id="register">Register</p>
                </a>
              ) : (
                <div
                  className={`${classes.register} ${classes.headerRightItem}`}
                  onClick={() => props.toggleIsRegisterOpen()}>
                  <p id="register">Register</p>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default HeaderComponents;
