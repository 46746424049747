import Root from "Root";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { getThemeColours, getThemeFonts } from "util/colourFunctions";
import { createFilterData } from "data/FilterData";
import QueryData from "data/QueryData";
import getThemeData from "data/DefaultTheme";
import useFonts from "./fonts";
import FloorPlanStore from "store/floorPlanStore";
import { useEffect } from "react";

const App = () => {
  useFonts();
  const location = useLocation();
  const { setProjectSlug, setFlootPlansData, setDataUnit } = FloorPlanStore();

  const subDomain = window.location.host.split(".")[0];
  let projectSlug =
    subDomain.search("localhost") === 0
      ? process.env.REACT_APP_PROJECT_SLUG || "neuonthird"
      : subDomain.toLocaleLowerCase();
  if (process.env.REACT_APP_STAGING_SLUG)
    projectSlug = process.env.REACT_APP_STAGING_SLUG;

  useEffect(() => {
    setProjectSlug(projectSlug);
  }, [projectSlug]);

  const { loading, error, data } = useQuery(QueryData.project, {
    variables: {
      slug: projectSlug,
      staging: process.env.REACT_APP_STAGING_SLUG ? true : false,
    },
  });

  useEffect(() => {
    if (data) {
      setDataUnit(data.project.units);
      setFlootPlansData(data.project.floorPlans);
    }
  }, [data, error, loading]);

  if (loading) return <></>;
  if (error) return null;

  let project = data.project;
  if (
    !project ||
    project.navs.length === 0 ||
    (project.disabled &&
      location.search.indexOf(`preview=${projectSlug}`) === -1)
  )
    return (
      <div style={{ color: "red", fontSize: "20px", marginTop: "100px" }}>
        Invalid project slug: {projectSlug}
      </div>
    );

  const customColours = getThemeColours(project.settings);
  const customFonts = getThemeFonts(project.settings);
  const customThemeData = getThemeData(customColours, customFonts);
  const filterData = createFilterData(project, project.settings.showUnits);

  project = { ...project, filterData, ThemeData: customThemeData };

  const siteTitle = project.settings.siteTitle || "";
  document.title = `${siteTitle} - Powered by Proxima`;

  if (project.settings.googleTrackId) {
    ReactGA.initialize(project.settings.googleTrackId);
  }
  return <Root project={project} />;
};

export default App;
